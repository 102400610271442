export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Status': 'Status',
            'MaterialTextColor': 'Text',
            'VareNo': 'Vare nummer',
            'MaterialNo': 'Number',
            'Material': 'Material',
            'MaterialText': 'Material Text',
            'DateonCreate': 'Date',
            'Quantity': 'Quantity',
            'Unit': 'Unit',
            'RetailPrice': 'Retail Price pr. pcs',
            'TotalPrice': 'Total Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'TotalIncludedVAT': 'Offer Price incl. VAT',
            'Estimate': 'Estimate Price',
            'Price-included': 'Price incl. VAT',
            'ProfitSubProfit': 'Profit / Sub-Profit',
            'ScanBarcode': 'Scan barcode',
            'ColorCode': 'Color code',
            'PriceExclVAT': 'Price excl vat',
            'Discount': 'Discount',
            'Total': 'Total',
            'DonePercentage': 'Done %',
            'DiscountLable': 'Discount / Debtor Discount',
            'Amount': 'Amount',
            'Percentage': 'Percentage',
            'PurchasePrice': 'Purchase Price',
            'RemainsTakenFromBasements': 'Remains / taken from basements',
            'MaterialCreation': 'Material Creation',
            'All': 'All',
            "Materialtype": 'Material type',
            'Vat':'Excl. VAT',
            'Not-Found-Material': 'No material found',
            'Rows': 'Rows',
            'Minutes': 'Minutes',
            'Total-purchase-price':'Total',
            'PurchasePrise' : 'purchase price',
            'M2Quantity':'M2 QTY',
            'PCS':'PCS/ 1M2',
            'PurchaseQTY':'QTY',
            'Antal/Liter':'Antal/Liter',
            'Driving':'Driving',
            'DrivingTitle': 'DRIVING',
            'NumberOfHours': 'Number of hours',
            'DistancePerDay': 'Distance per day',
            'NumberOfDrivingDays': 'No. of driving days',
            'TravelingTime': 'Time',
            'PerDay': 'Pr. Day',
            'TotalTime': 'Total time',
            'DiscountByPostNumber': 'Discount by PostNumber',
            'EditDrivingDays': 'Edit driving days',
            'Distance': 'Distance',
            'OfferName': 'Offer Name',
            "ShowVarious": "Show various",
            "PrM2":"Pr. M2"
        },
        'Headers': {
            'AddNewMaterial': 'Add New Material',
            'Nomaterialadded': 'No material added',
            'DeleteMaterial': 'Do you want to delete "@MaterialName"?',
            'MaterialList': 'Material List',
        },
        'BUTTON': {
            'PDF':'PDF',
            'Driving': 'Driving',
            'Save': 'Save',
            'BACK': 'Back',
            'Reset': 'Reset',
            'BtnAdd': 'Add',
            'AddMaterialToProject':'Add to project'
        },
        'Validation': {
            'VareNumber': 'Vare nummer is required',
            'Number': 'Number is required',
            'Material': 'Material is required',
            'ColorCode': 'Color code is required',
            'Unit': 'Unit is required',
            'M2Quantity':'M2 QTY is required',
            'PCS':'PCS/ 1M2 is required',
            'Areal':'Areal is required',
            'PurchaseQTY':'QTY is required',
            'Antal/Liter':'Antal/Liter is required',
            'RetailPrice':'Retail Price is required',
            'PurchasePrice':'Purchase Price is required',
            'PersonalPrice':'Personal Price is required',
            'MaxQuantityAddMsg1': 'You need to order these material please check stock before start.',
            'MaxQuantityAddMsg2': ' Qty, you are trying to add ',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NoBarcodeAttachedToMaterial': 'No barcode attached to any material.',
            'EnterTextColorCode': 'Enter Text',
            'MaterialPriseMoreOne': 'Enter material price',
            'NoMaterialFound': 'No material found',
            'AddColorCode': 'Color code needs to be filled out.',
            'MaterialAlreadyExists': 'Material already added.',
        },
    }
}