export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header': 'Uniconta Credintial',
        },
        'FIELDS': {
            'UserName': 'User name',
            'Password': 'Password',
            'AccessId': 'Access id',
            'ComapnyName': 'Comapny name',
            'ComapnyId': 'Comapny id',
            'GenerateSalesOrders': 'Generate sales orders',
            'GenerateInvoices': 'Generate invoices',
            'GenerateTXTFile': 'Generate TXT File',
            'Path':'path/filename'
         },
        'VALIDATIONS': {
            'UserName': 'User name is required',
            'Password':'Password is required',
            'ComapnyName':'Comapny name is required',
            'ComapnyId':'Comapny id is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
            'CompanyAlreadyExits' : 'Company ID already exists!'
        },
        'BUTTONS': {
            'Save': 'Save',
            'AddCompany':'Tilføj firma'
        },
        'Help':{
            'Note':'Please use farward slash(/) only in path setting',
        }
    }
};