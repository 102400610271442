import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestCustomer, RequestCustomerMaterial, RequestCustomerProperty, RequestCustomerPropertyOffer, RequestForLocalStorage, RequestProjectCustomerRoom } from '../../model/RequestBase';
import { ApiConstant } from '../../../core/constant/ApiConstant';
import { Customer, CustomerProperty } from '../../model/Customer';
import { EmailSendingParam } from '../../model/common';
import { CustomerRoom } from 'app/main/model/ProjectOffer';

@Injectable({
    providedIn: 'root'
})
export class CustomersService {
    number: any;
    text: any;
    private actionUrl: string;
    customers: any[];
    ObjCustomer: any;
    customerOrders: any[];
    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.CUSTOMER + "/";
    }

    public Get<T>(RequestCustomer: any): Observable<T> {
        let search = this.GetParams(RequestCustomer);
        return this.http.get<T>(this.actionUrl, {
            params: search
        });
    }

    public GetCustomerById<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + Id);
    }

    public GetCustomerStorePurchaseCustomer<T>(Id: any): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.MATERIAL + "/QuickInvoiceStorePurchaseDefaultEmail";
        return this.http.get<T>(url);
    }

    public setRequestParameter(model) {
        var requestForLocalStorage = new RequestForLocalStorage();
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        if (val) {
            requestForLocalStorage = JSON.parse(val);
        }
        requestForLocalStorage.CustomerRequest = model;
        localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
    }

    public getRequestParameter() {
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        var requestForLocalStorage = new RequestForLocalStorage();
        var requestCustomer = new RequestCustomer();
        requestCustomer.OrderBy = "Number DESC"
        if (val) {
            requestForLocalStorage = JSON.parse(val);
            if (requestForLocalStorage.CustomerRequest) {
                requestCustomer = requestForLocalStorage.CustomerRequest;
            }
        }
        return requestCustomer;
    }

    public GetProjectsByCustomer<T>(id: any, RequestProject: any): Observable<T> {
        let search = this.GetParams(RequestProject);
        return this.http.get<T>(this.actionUrl + id + "/" + ApiConstant.PROJECT, {
            params: search
        });
    }

    public GetProjectDetailWithColorcode<T>(id: any, RequestProject: any): Observable<T> {
        let search = this.GetParams(RequestProject);
        return this.http.get<T>(this.actionUrl + id + "/projectDetailWithColorcode"  , {
            params: search
        });
    }

    public GetInquiryDetail<T>(id: any): Observable<T> {
        return this.http.get<T>(ApiConstant.API_URL + ApiConstant.INQUIRY + "/" + id);
    }

    public GetSingle<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

    public Update<T>(id: any, customer: Customer): Observable<T> {
        return this.http.put<T>(this.actionUrl + id, customer);
    }

    public Add<T>(customer: Customer): Observable<T> {
        return this.http.post<T>(this.actionUrl, customer);
    }

    public SendEmail<T>(emailSendingParam: EmailSendingParam): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.REMINDER + "/";
        return this.http.post<T>(url + "SendEmail", emailSendingParam);
        //return this.http.put<T>(url + id + "/" + emailBody, null);
    }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id);
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetCustomerLogs<T>(RequestCustomer: any): Observable<T> {
        let search = this.GetParams(RequestCustomer);
        return this.http.get<T>(this.actionUrl + "getCustomerLogs", {
            params: search
        });
    }
    public SaveFileUploadLogs<T>(RequestCustomer: any): Observable<T> {
        //let search = this.GetParams(RequestCustomer);
        return this.http.put<T>(this.actionUrl + "saveFileUploadLogs", RequestCustomer);
    }
    public GetCustomerLogDetails<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getCustomerLogDetails/" + Id);
    }

    public GetCustomerByNumber<T>(Number: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "search/customerNumber/" + Number);
    }

    public GetCustomerPropertyRooms<T>(requestProjectCustomerRoom: RequestProjectCustomerRoom): Observable<T> {
        let search = this.GetParams(requestProjectCustomerRoom);
        return this.http.get<T>(this.actionUrl + "getCustomerPropertyRooms", {
            params: search
        });
    }

    public AddUpdateCustomerPropertyRoom<T>(projectsCustomerRoom: CustomerRoom[]): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddUpdateCustomerPropertyRoom/", projectsCustomerRoom);
    }

    public DeleteCustomerPropertyRoom<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeleteCustomerPropertyRoom/" + id);
    }

    public GetCustomerProperties<T>(requestCustomerProperty: RequestCustomerProperty): Observable<T> {
        let search = this.GetParams(requestCustomerProperty);
        return this.http.get<T>(this.actionUrl + "getCustomerProperty", {
            params: search
        });
    }

    public SaveCustomerProperty<T>(customerProperty: CustomerProperty): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddUpdateCustomerProperty/", customerProperty);
    }

    public DeleteCustomerProperty<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeleteCustomerProperty/" + id);
    }

    public SaveCustomerMaterial<T>(customerMaterial: object): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddCustomerLinjeVare/", customerMaterial);
    }

    public DeleteCustomerMaterial<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeleteCustomerLinjeVare/" + id);
    }

    public SaveCustomerDefaultMaterial<T>(customerDefaultMaterial: object): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddCustomerDefaultMaterial/", customerDefaultMaterial);
    }

    public DeleteCustomerDefaultMaterial<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "deleteCustomerDefaultMaterial/" + id);
    }

    public GetCustomerMaterial<T>(reqObj: RequestCustomerMaterial): Observable<T> {
        let search = this.GetParams(reqObj);
        return this.http.get<T>(this.actionUrl + "GetCustomerLinjeVare", {
            params: search
        });
    }
    public GetCustomerPropertiesOfferList<T>(requestCustomerPropertyOffer: RequestCustomerPropertyOffer): Observable<T> {
        let search = this.GetParams(requestCustomerPropertyOffer);
        return this.http.get<T>(this.actionUrl + "getCustomerPropertyOffer", {
            params: search
        });
    }

    public CreateCustomersFromExcelRead<T>(formData: FormData) {
        return this.http.post(this.actionUrl + "CreateCustomersFromExcelRead", formData, { headers: { IsContentType: "true" } });
    }

    public IsEmailExist<T>(email: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + ApiConstant.EMAIL + "/" + email).toPromise();
    }

    public IsPhoneNo<T>(telephoneNumber: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + 'telephoneNumber' + "/" + telephoneNumber).toPromise();
    }

    public IsEmailExistWithId<T>(email: string, id: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + ApiConstant.EMAIL + "/" + email +"/"+ id).toPromise();
    }

    public IsPhoneNoExistWithId<T>(telephoneNumber: string, id: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + 'telephoneNumber' + "/" + telephoneNumber+"/"+ id).toPromise();
    }

}

