import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { Material } from 'app/main/model/Material';
import { MaterialTypeCategory, PaintSelectTime, ExpensesType, OfferTreatmentType } from 'app/constant/common.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MatDialog } from '@angular/material';
import { FormService } from 'app/core/services/form.service';
import { CKEditorComponent } from 'app/main/shared-component/ckeditor/ckeditor.component';
import { AppendixCalculationComponent } from '../appendix-calculation/appendix-calculation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'vibe-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
    @Input("MaterialObject") OfferObjects: Material;
    @Input() private uploadSuccess: EventEmitter<boolean>;
    @Input() IsViewFromOffer: boolean;
    @Output() DeleteOfferList = new EventEmitter();
    @Output() DeleteOfferPrice = new EventEmitter();
    @Output() SaveOfferImage = new EventEmitter();
    @Output() isChildFormValid: EventEmitter<any> = new EventEmitter<any>();
    @Output() updatePriceTagColor: EventEmitter<boolean> = new EventEmitter<boolean>();
    offerObj: any;
    showSpinner: boolean = false;
    selectMaterialOfferList = MaterialTypeCategory;
    offerTreatmentTypeList = OfferTreatmentType;
    SelectedCategoryTime = PaintSelectTime;
    ExpensesType = ExpensesType;
    offerList = [{ 'id': 1, 'text': 'Indoor' }, { 'id': 2, 'text': 'Outdoor' }, { 'id': 3, 'text': 'Window' }, { 'id': 4, 'text': 'Door' }];
    shadeTime = [{ 'id': 1, 'text': '1 Time' }, { 'id': 2, 'text': '2 Time' }, { 'id': 3, 'text': '3 Time' }];
    offerMaterialform: FormGroup;
    offerArray: FormGroup;
    isAddMeterRange: boolean = false;
    IsAddAutomaticallyCreate: boolean = false;
    notCalculateToDriving: boolean = false;
    materialID: string = "";
    constructor(private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private _router: Router,
        private _route: ActivatedRoute,
        public dialog: MatDialog) {
        this.translationLoader.loadTranslations(english, danish);


        this.offerMaterialform = this.formBuilder.group({
            text: [null, [Validators.required]],
            //treatmentText: [null],
            automaticallyCreate: ['F'],
            type: [null],
            price: [0],
            startMeter1: [0],
            endMeter1: [0],
            priceMeter1: [0],
            timeMeter1: [0],
            startMeter2: [0],
            endMeter2: [0],
            priceMeter2: [0],
            timeMeter2: [0],
            startMeter3: [0],
            endMeter3: [0],
            priceMeter3: [0],
            timeMeter3: [0],
            expensesType: [0],
            expensesAmount: [0],
            treatmentType: [0],
            reminder: [null]
        });


        // this.offerArray = this.formBuilder.group({
        //     startMeter1: this.formBuilder.array([this.formBuilder.control(0, Validators.required)]),
        //     startMeter2: this.formBuilder.array([this.formBuilder.control(0, Validators.required)]),
        //     startMeter3: this.formBuilder.array([this.formBuilder.control(0, Validators.required)]),
        // })
    }



    ngOnInit() {
        this._route.params.subscribe(params => {
            this.materialID = params['id'];
        });
        this.offerObj = Object.assign({}, this.OfferObjects.offerMaterial);
        var t = this.OfferObjects.offerMaterial;
        if (!this.OfferObjects.offerMaterial.treatmentType)
            this.OfferObjects.offerMaterial.treatmentType = 0;
        if (this.OfferObjects.offerMaterial && this.OfferObjects.offerMaterial.offerMaterialPriceList.length > 0) {
            this.OfferObjects.offerMaterial.offerMaterialPriceList.filter(x => {
                if (!x.expensesType)
                    x.expensesType = 0
                if (!x.expensesAmount)
                    x.expensesAmount = 0
                if (x.reminder)
                    x.reminderstr = x.reminder.replace(/<[^>]+>/g, '').replace("&nbsp;", " ");
                else
                    x.reminderstr ="";
            })

        }
        this.offerMaterialform.patchValue(this.OfferObjects.offerMaterial);
        this.isAddMeterRange = this.OfferObjects.offerMaterial.offerMaterialPriceList[0].isAddMeterRangePrice;
        if (this.OfferObjects.offerMaterial && this.OfferObjects.offerMaterial.automaticallyCreate && this.OfferObjects.offerMaterial.automaticallyCreate == "T")
            this.IsAddAutomaticallyCreate = true;
        else
            this.IsAddAutomaticallyCreate = false;
        if (this.OfferObjects.offerMaterial && this.OfferObjects.offerMaterial.notCalculateToDriving)
            this.notCalculateToDriving = true;
        else
            this.notCalculateToDriving = false;
        if (this.OfferObjects.offerMaterial)
            this.OfferObjects.offerMaterial.typeIds = this.OfferObjects.offerMaterial.type.split(",").map(Number);
    }

    deleteOffer(offerId) {
        // if (!offerId) {
        //     this.OfferObjects.offerMaterial.offerMaterialPriceList;
        // }
        this.DeleteOfferList.emit(offerId);
        if (this.OfferObjects.offerMaterial)
            this.OfferObjects.offerMaterial.typeIds = this.OfferObjects.offerMaterial.type.split(",").map(Number);
    }

    deleteOfferPrice(index, offerPriceId) {
        if (offerPriceId) {
            this.DeleteOfferPrice.emit(offerPriceId);
        } else {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'Headers.DeleteOfferPrice' }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.OfferObjects.offerMaterial.offerMaterialPriceList.splice(index, 1);
                    this.translate.get("MESSAGES.OfferPriceDel").subscribe({
                        next: (res) => {
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    addNoOfTimes() {
        var t = this.formBuilder.group({
            text: [null, [Validators.required]],
            type: [0, [Validators.required]],
            price: [0],
            startMeter1: [0],
            endMeter1: [0],
            priceMeter1: [0],
            startMeter2: [0],
            endMeter2: [0],
            priceMeter2: [0],
            startMeter3: [0],
            endMeter3: [0],
            priceMeter3: [0],
            expensesType: [0],
            expensesAmount: [0],
            reminder: [null],
            timeMeter:[0],
        });
        this.OfferObjects.offerMaterial.offerMaterialPriceList.push(t.value);

        for (let i = 0; i < this.OfferObjects.offerMaterial.offerMaterialPriceList.length; i++) {
            if (this.isAddMeterRange) {
                this.OfferObjects.offerMaterial.offerMaterialPriceList[i].isAddMeterRangePrice = true;
            } else {
                this.OfferObjects.offerMaterial.offerMaterialPriceList[i].isAddMeterRangePrice = false;
            }
        }

    }

    ChangeImageForOffer(event) {
        this.OfferObjects.image = event;
        this.SaveOfferImage.emit(event);
    }

    hideShowPriceFields(isAddMeterRange) {
        this.isAddMeterRange = isAddMeterRange;
        for (let i = 0; i < this.OfferObjects.offerMaterial.offerMaterialPriceList.length; i++) {
            if (isAddMeterRange) {
                this.OfferObjects.offerMaterial.offerMaterialPriceList[i].isAddMeterRangePrice = true;
            } else {
                this.OfferObjects.offerMaterial.offerMaterialPriceList[i].isAddMeterRangePrice = false;
            }
        }
    }

    validateOffer() {
        if (this.offerMaterialform.valid === true) {
            this.isChildFormValid.emit(true);
        } else {
            this.formService.markFormGroupTouched(this.offerMaterialform)
            this.isChildFormValid.emit(false);
        }
    }

    onSetAutomaticallyCreate(IsActive) {
        this.IsAddAutomaticallyCreate = IsActive;
        if (IsActive)
            this.OfferObjects.offerMaterial.automaticallyCreate = 'T';
        else
            this.OfferObjects.offerMaterial.automaticallyCreate = 'F';
    }

    onSetIsDefault(IsActive, item) {
        this.OfferObjects.offerMaterial.offerMaterialPriceList.filter(x => x.isDefault = false);
        setTimeout(() => {
            item.isDefault = IsActive;
        }, 500);
    }

    onCheckAddNumberOfTimesOnPDF(flag) {
        this.OfferObjects.offerMaterial.addNumberOfTimesOnPDF = flag;
    }

    onCheckAddPriceOnPDF(flag) {
        this.OfferObjects.offerMaterial.addPriceOnPDF = flag;
    }

    getselectMaterialOfferType() {
        var outdoor = this.OfferObjects.offerMaterial.typeIds.find(o => o == "2");
        if (outdoor) {
            this.updatePriceTagColor.emit(true);
        }
    }

    OpenTextareaEditerPopup(text, offerData) {
        const dialogRef = this.dialog.open(CKEditorComponent, {
            data: text
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                offerData.reminder = result;
                offerData.reminderstr = result.replace(/<[^>]+>/g, '').replace("&nbsp;", " ");
            }
            if(result==""){
                offerData.reminderstr="";
                offerData.reminder = "";
            }
        });
    }

    onCheckAddUnitOnPDF(flag) {
        this.OfferObjects.offerMaterial.addUnitOnPDF = flag;
    }

    onCheckNotCalculateToDriving(flag) {
        this.OfferObjects.offerMaterial.notCalculateToDriving=flag;
    }

    showAppenidxCalculation() {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['materials/edit/' + this.materialID +'/appendixcalculation/'+this.OfferObjects.offerMaterial.treatmentType])
        );
        window.open(url, '_blank');
        //var sendData = {};
        //sendData = {
        //    'MaterialTreatmentType': this.OfferObjects.offerMaterial.treatmentType,
        //    'isViewInModal':true,
        //}
        //var dialogRef = this.dialog.open(AppendixCalculationComponent, {
        //    height: '70%',
        //    width: '90%',
        //    data: sendData
        //});

        //dialogRef.afterClosed().subscribe(result => {

        //});
    }
}
