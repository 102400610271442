export class CreateFolder {
  //public path: string = '';
  //public autorename: boolean = false;
  public folderName: string = "";
  public folderPath: string = "";
}

export class SearchFolder {
  public path: string = '';
  public query: string = '';
}

export class MoveFolder {
  public from_path: string = '';
  public to_path: string = '';
  public allow_shared_folder: boolean;
  public autorename: boolean;
  public allow_ownership_transfer: boolean;
}

export class MoveFolderEntties {
  public from_path: string = '';
  public to_path: string = '';
}

export class MoveFolderBatch {
  public entries: Array<MoveFolderEntties>;
  public autorename: boolean;
  public allow_ownership_transfer: boolean;
}

export class ListFolder {
  //public path: string = '';
    public folderPath: string = "";
    public projectId: string = "";
    public isProjectRootFolder: boolean = false;
}

export class SharedLinkDTO {
  public path: string = "";
}

export class DeleteBatchFile {
  public pathWithName = [];
}

export class FilesObj {
  public type: string = '';
  public name: string = '';
  public extention: string = '';
  public checked: boolean = false;
  public path: string = '';
}

export class FolderListDTO {
  public isFile: boolean = false;
  public isFolder: boolean = false;
  public isDeleted: boolean = false;
  public name: string = '';
  public pathLower: string = '';
  public pathDisplay: string = '';
  public parentSharedFolderId: string = '';
  public previewUrl: string = '';
    public thumblineImage: string = '';
    public isThumblineImageURL: boolean = false;
    public checked: boolean = false;
    public isSearch: boolean = false;
}

export class FolderListNavigationDTO {
  public name: string = "";
  public isDisplay: boolean = false;
}

export class SearchFile {
  public path: string = '';
  public query: string = '';
  public start: number = 0;
  public max_results: number = 100;
  public mode: string = 'filename';
}

export class CheckFolderExist {
  rootFolderPath: string = "";
  folderName: string = "";
}

export class DeleteFolder {
  public pathWithName = [];
  public moduleId: string = "";
  public filePath: string = "";
}

export class SearchFolderOrFiles {
  public path: string = '';
    public searchValue: string = '';
    public projectId: string = "";
    public isProjectRootFolder: boolean = false;
}