import { Component, OnInit, Inject } from "@angular/core";
import { CoworkerEmployment } from 'app/main/model/CoWorker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DateAdapter, MatRadioChange, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { EmploymentType } from "app/main/model/WorkHourStatus";
import { CoWorkersService } from "../../coWorkers.service";
import * as moment from 'moment';
import { SettingsService } from "app/main/pages/settings/settings.service";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "new-coworker-employment",
    templateUrl: "./new-coworker-employment.component.html",
    styleUrls: ["./new-coworker-employment.component.scss"]
})

export class NewCoworkerEmploymentComponent implements OnInit {
    showSpinner: boolean = false;
    employmentForm: FormGroup;
    employmentObject: CoworkerEmployment ;
    coworkerID: any = ""; 
    termsOfEmploymentList: any = [];
    employmentType = EmploymentType;
    // payrollTypeSettingObject:Settings;
    // payrollTypeSettingList: any[] = [];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private coWorkersService: CoWorkersService,
        private settingsService: SettingsService,
        private notificationsService: NotificationsService,
        private adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<NewCoworkerEmploymentComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }



    ngOnInit() {
        this.adapter.setLocale('da');
        this.coworkerID = this.data.coworkerID; 
        this.termsOfEmploymentList = this.data.termsOfEmploymentList;
        this.employmentObject = new CoworkerEmployment();
        this.employmentObject.employeeID = this.coworkerID;
        this.employmentForm = this.formBuilder.group({
            id: [null],
            startDate: [null, Validators.required],
            endDate: [null],
            ofSalary: ["1", Validators.required],
            basicSalary: [0],
            basicSalaryHours: [0],
            personalSupplement: [null],
            type: [null, Validators.required],
            termsofEmploymentID: [null, Validators.required],
            employeeID: [this.coworkerID, Validators.required],
            meetingAllowance: [0],
            payrollType: [null]
        });

        if(this.data.data){
            this.employmentObject= this.data.data;
            this.employmentForm.patchValue(this.data.data);
        }
        //this.getPaymentSalaryCalculationSetting()
    }

    back() {
        this.dialogRef.close(false);
    }

    SaveEmployment() {
        this.employmentForm.markAllAsTouched();
        if (this.employmentForm.valid)
            this.AddOrUpdateEmployment();
    }

    AddOrUpdateEmployment() {
        this.showSpinner = true;
        this.coWorkersService.AddORUpdateCoworkerEmployment<ResponseBase>(this.employmentObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.ResetEmployementForm();
                    this.dialogRef.close(true);
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    EditRates(item) {
        this.employmentObject = item;
    }

    ResetEmployementForm() {
        this.employmentObject = new CoworkerEmployment();
        this.employmentForm.reset();
    }

    onFocusIn(event) {
        event.model.show();
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }

    radioChange(event: MatRadioChange) {
        //this.printSize = event.value;
    }

    // getPaymentSalaryCalculationSetting() {
    //     this.showSpinner = true;
    //     this.settingsService.GetSettingsWithMappingValue<ResponseBase>("Payment", "SalaryCalculation", null, true).subscribe({
    //         next: (response: ResponseBase) => {
    //             this.showSpinner = false;
    //             if (response != null && response.result && response.result.myResponse.length > 0) {
    //                 this.payrollTypeSettingObject = JSON.parse(response.result.myResponse[0].value);
    //                 this.payrollTypeSettingList = Object.entries(this.payrollTypeSettingObject).map(([key, value]) => ({ "name": key, "value" : value }));
    //             }
    //         },
    //         error: err => {
    //         },
    //         complete: () => {
    //             this.showSpinner = false;
    //         }
    //     });
    // }
}