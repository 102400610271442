//#region Imports 
// angular 
import { Component, OnInit, Optional, Inject, ChangeDetectorRef } from '@angular/core';

// third party tool
import { TranslateService } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Supplier } from 'app/main/model/Supplier';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestSupplierMaterialPriceListChange, SupplierRequest } from 'app/main/model/RequestBase';
import { NotificationsService } from 'angular2-notifications';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MaterialWeatherType } from 'app/constant/common.constant';
import { MaterialUnitList, MaterialLinetype } from 'app/main/model/ProjectStatus';
import { MaterialType } from 'app/constant/common.constant';
import { SupplierPriceListType } from 'app/main/model/Material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { EditSupplierMaterialComponent } from '../edit-supplier-material/edit-supplier-material.component';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';

//#endregion Imports 

@Component({
    selector: 'supplier-price-upload',
    templateUrl: './supplier-price-upload.component.html',
    styleUrls: ['./supplier-price-upload.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class SupplierPriceUploadComponent implements OnInit {
    //#region Initializations

    //#region Variables
    showSpinner: boolean = false;
    myForm: FormGroup;
    newMaterialForm: FormGroup;
    materialObject: any = {
        supplierId: '',
        type: ''
    }
    SearchedSupplierList: Supplier[] = [];
    SupplierList: Supplier[] = [];
    IsGettingSupplier: boolean = false;
    SupplierRequest: SupplierRequest;
    selectedFiles: any[] = [];
    SupplierMaterialPriceUploadList: SupplierMaterialChanges = new SupplierMaterialChanges();

    displayedColumns = ['isChecked', 'weatherType', 'itemNumber', 'text', 'holdings', 'retailPrice', 'purchasePrice'];
    newdisplayedColumns = ['isChecked', 'weatherType', 'itemNumber', 'text', 'retailPrice', 'purchasePrice', 'action'];
    dataSourceMaterialChangesList: MatTableDataSource<any>;
    dataSourceMaterialDifferentSupplierList: MatTableDataSource<any>;
    dataSourceNewMaterialList: MatTableDataSource<any>;
    weatherTypeList = MaterialWeatherType;
    isCheckedAllForMaterialChangesList: boolean = false;
    isCheckedAllForMaterialDifferentSupplierList: boolean = false;
    isCheckedAllForNewMaterialChangesList: boolean = false;

    isUpdateForMaterialChangesList: boolean = false;
    isUpdateForMaterialDifferentSupplierList: boolean = false;
    isUpdateForNewMaterialList: boolean = false;
    //materialForm: FormGroup;
    selectLinetype = MaterialLinetype;
    selectMaterialtype = MaterialType;
    materialWeatherType = MaterialWeatherType;
    selectUnitList = MaterialUnitList;
    uploadFileSupplierDetails: any;
    personalPriceIncreaseInPercentage: number = 0;
    retailPriceIncreaseInPercentage: number = 0;
    settingMasterValues: any = [];
    tempMaterialList: any = [];
    IsSupplierRequired: boolean = true;
    supplierPriceListType = SupplierPriceListType;
    acceptedFileTypes: string = "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    sampleFileUrl;
    isValidNewMaterialChangesList: boolean = false;
    invoicePDFFile: File | null = null;
    //#endregion Variables

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<SupplierPriceUploadComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private materialService: MaterialService,
        private notificationsService: NotificationsService,
        private translation: TranslateService,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private cd: ChangeDetectorRef,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }
    //#endregion  Initializations

    //#region Lifecycle Hooks
    ngOnInit() {
        this.SupplierRequest = new SupplierRequest();
        this.SupplierRequest.Index = 1;
        this.SupplierRequest.Limit = 100;
        this.GetSupplierList()
        this.GetSupplierPriceUploadList();

        this.myForm = this.formBuilder.group({
            SupplierId: [null, Validators.required],
            type: ["MaterialPriceList", Validators.required],
        });
        this.materialObject.type = "MaterialPriceList";
        // this.materialForm = this.formBuilder.group({
        //     materialList: new FormArray([])
        // });
        if (localStorage.getItem("settingMasterValues")) {
            this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues"));
            if (this.settingMasterValues && this.settingMasterValues.length > 0) {
                this.retailPriceIncreaseInPercentage = Number(this.settingMasterValues.find(s => s.key1 == "Material" && s.key2 == "RetailPriceMustBeHigherOnPurchasePriceInPercentage").value);
                this.personalPriceIncreaseInPercentage = Number(this.settingMasterValues.find(s => s.key1 == "Material" && s.key2 == "PersonalPriceMustBeHigherOnPurchasePriceInPercentage").value);
            }
        }
        this.sampleFileUrlInit();

        this.newMaterialForm = this.formBuilder.group({
            text: [null, Validators.compose([Validators.required, Validators.maxLength(80)])],
            picture: [null, !Validators.required],  // This line should likely be corrected
            holdings: [null, Validators.compose([Validators.required])],
            itemNumber: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
            retailPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
            purchasePrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
            personalPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
            purchaseQuantity: [null, Validators.required],
            unit: [null, Validators.required],
            internalText: [],
            shelfNo: [null, Validators.required],
            rows: [],
            supplierId: [null, Validators.required],
            supplierItemNumber: [null, Validators.required],
            minInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
            maxInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
            isRequiredText: [],
            noStockFeatures: ['F'],
            nonStockList: [],
            colorCode: [],
            barcode: [],
            materialsForTheCase: [false],
            lineType: ['V', Validators.required],
            reminder: [null],
            onlySummer: [false],
            weatherType: [null, Validators.required],
            postNumberAppendix: [true],
            barcodeColorStr: ['1'],
            materialType: [null, Validators.required],
            isDisplayWebshop: [false],
            duplicateEntry: [false],
            checkPurchasePrice: [false],
            purchasepriceSalesUnit: [null],
            maxOrderNumber: [null],
            excelPurchaseQty: [0],
            excelPurchasePrice: [0],
            excelRetailPrice: [0]
        });
    }
    //#endregion Lifecycle Hooks

    // convenience getters for easy access to form fields
    // get f() { return this.materialForm.controls; }
    // get t() { return this.f.materialList as FormArray; }

    //#region Public Methods
    onCloseDialog() {
        this.dialogRef.close(null)
    }

    SupplierSearch(value) {
        if (value === '') {
            return this.SearchedSupplierList = []
        }
        this.SearchedSupplierList = this.SupplierList.filter((supplier) => {
            return supplier.business.toLowerCase().startsWith(value.toLowerCase())
        })
    }

    GetSupplierList() {
        this.IsGettingSupplier = true;
        this.showSpinner = true;
        this.materialService.GetSupplierList<ResponseBase>(this.SupplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.SupplierList = response.result;

                    this.SupplierList.sort(function (a, b) {
                        return a.business.localeCompare(b.business);
                    });
                },
                error: err => { },
                complete: () => {
                    this.IsGettingSupplier = false;
                    this.showSpinner = false;
                }
            });
    }

    GetSupplierPriceUploadList() {
        this.showSpinner = true;
        this.isValidNewMaterialChangesList = false;
        this.dataSourceMaterialChangesList = null;
        this.dataSourceMaterialDifferentSupplierList = null;
        this.dataSourceNewMaterialList = null;
        this.SupplierMaterialPriceUploadList = new SupplierMaterialChanges();
        this.materialService.GetSupplierPriceChangesList<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.SupplierMaterialPriceUploadList = response.result;
                        if(this.SupplierMaterialPriceUploadList && this.SupplierMaterialPriceUploadList.tempMaterialList)
                            this.tempMaterialList = this.SupplierMaterialPriceUploadList.tempMaterialList;
                        this.uploadFileSupplierDetails = this.SupplierMaterialPriceUploadList.uploadFileSupplierDetails;
                        this.materialObject.supplierId = this.uploadFileSupplierDetails.id;
                        this.materialObject.type = this.uploadFileSupplierDetails.supplierPriceListType;
                        if(this.materialObject.type == 'SupplierInvoice')
                            this.displayedColumns = ['isChecked', 'weatherType', 'itemNumber', 'text', 'purchasePrice'];
                        else
                            this.displayedColumns = ['isChecked', 'weatherType', 'itemNumber', 'text', 'holdings', 'retailPrice', 'purchasePrice'];
                        this.setGrid();
                    }
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    OnuploadFile(event) {
        if (event.target.files.length > 0){
            this.selectedFiles = event.target.files;
            const file: File = event.target.files[0];
            if (file && file.type === 'application/pdf') 
                this.invoicePDFFile = file;
        }
    }

    Upload() {
        if (this.myForm.valid && this.selectedFiles.length) {
            // const formData = new FormData();
            // if (this.selectedFiles && this.selectedFiles.length) {
            //     formData.append("file[]", this.selectedFiles[0]);
            // }

            const formData = new FormData();
            if (this.selectedFiles && this.selectedFiles.length) {
                for (var i = 0; i < this.selectedFiles.length; i++) {
                    formData.append("file[]", this.selectedFiles[i]);
                }
            }

            var supplierName = this.SupplierList.find(i => i.id == this.materialObject.supplierId)['business'];
            var confirmationMsg = "";
            var ConfMsg = "", SupplierLbl = "";
            this.translation.get("LABELS.ConfMsg").subscribe({
                next: (res) => {
                    ConfMsg = res
                    this.translation.get("LABELS.Supplier").subscribe({
                        next: (res) => {
                            SupplierLbl = res
                            confirmationMsg = ConfMsg + supplierName + ` ` + SupplierLbl + `?`;
                        }, error: err => { }
                    });
                }, error: err => { }
            });

            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                width: '500px',
                data: { Header: confirmationMsg }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    let request = new RequestSupplierMaterialPriceListChange()
                    let supplier = this.SupplierList.find(s =>s.id == this.materialObject.supplierId);
                    if(supplier)
                        request.SupplierNumber = supplier.number;
                    request.supplierId = this.materialObject.supplierId;
                    request.supplierPriceListType = this.materialObject.type;
                    this.showSpinner = true;
                    //this.materialService.UploadSupplierPriceFile<ResponseBase>(request, formData)
                    this.materialService.UploadSupplierPriceListWithMultipleFile<ResponseBase>(request, formData)
                        .subscribe({
                            next: (response: ResponseBase) => {
                                this.showSpinner = false;
                                this.translate.get("MESSAGES.Updated").subscribe({
                                    next: (res) => {
                                        this.notificationsService.success(res);
                                    },
                                    error: err => {
                                    }
                                });
                                this.GetSupplierPriceUploadList();
                            },
                            error: err => {
                                this.showSpinner = false;
                                this.notificationsService.error(err.error.message);
                            },
                            complete: () => {
                                this.showSpinner = false;
                            }
                        });
                }
            })
        }
    }

    getWeatherIcon(typeId) {
        var icon = "";
        var data = this.weatherTypeList.filter(x => x.id == typeId);
        if (data.length == 0) {
            return icon = 'brightness_auto';
        }
        return icon = data[0].icon;
    }

    setGrid() {
        if (this.SupplierMaterialPriceUploadList.materialChangesList) {
            this.SupplierMaterialPriceUploadList.materialChangesList.filter(o => o.isChecked = false).length;
            this.isUpdateForMaterialChangesList = false;
            this.dataSourceMaterialChangesList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.materialChangesList);
        }

        if (this.SupplierMaterialPriceUploadList.materialDifferentSupplierList) {
            this.SupplierMaterialPriceUploadList.materialDifferentSupplierList.filter(o => o.isChecked = false).length;
            this.isUpdateForMaterialDifferentSupplierList = false;
            this.dataSourceMaterialDifferentSupplierList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.materialDifferentSupplierList);
        }

        if (this.SupplierMaterialPriceUploadList.newMaterialList) {
            this.SupplierMaterialPriceUploadList.newMaterialList.filter(o => {
                o.supplierPriceItemId = o.id;
                o.isChecked = false;
                o.supplierId = this.uploadFileSupplierDetails.id;
                o.supplierItemNumber = this.uploadFileSupplierDetails.number ? this.uploadFileSupplierDetails.number.toString() : "";
                o.materialType = o.materialType ? [parseInt(o.materialType)] : "";
                o.lineType = o.lineType ? o.lineType : "V";
                o.isValid = this.isObjectValid(o);
                if(o.isValid)
                    this.isValidNewMaterialChangesList = true;
            }).length;
            this.dataSourceNewMaterialList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.newMaterialList);
            //this.initilizeForm(); 
        }
    }

    onSetActiveAll(event, listType) {
        if (this.SupplierMaterialPriceUploadList.materialChangesList && listType == "materialChangesList") {
            this.SupplierMaterialPriceUploadList.materialChangesList.filter(p => {
                p.isChecked = event.checked;
            });
            this.isUpdateForMaterialChangesList = event.checked;
            this.dataSourceMaterialChangesList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.materialChangesList);
        }

        if (this.SupplierMaterialPriceUploadList.materialDifferentSupplierList && listType == "materialDifferentSupplierList") {
            this.SupplierMaterialPriceUploadList.materialDifferentSupplierList.filter(p => {
                p.isChecked = event.checked;
            });
            this.isUpdateForMaterialDifferentSupplierList = event.checked;
            this.dataSourceMaterialDifferentSupplierList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.materialDifferentSupplierList);
        }

        if (this.SupplierMaterialPriceUploadList.newMaterialList && listType == "newMaterialList") {
            this.SupplierMaterialPriceUploadList.newMaterialList.filter(p => {
                p.isChecked = event.checked;
            });
            this.isUpdateForNewMaterialList = event.checked;
            this.dataSourceNewMaterialList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.newMaterialList);
        }
    }

    onSetActive(listType) {
        if (this.SupplierMaterialPriceUploadList.materialChangesList && listType == "materialChangesList") {
            var atleastOneUncheckedMat = this.SupplierMaterialPriceUploadList.materialChangesList.filter(p => p.isChecked == false);
            if (atleastOneUncheckedMat && atleastOneUncheckedMat.length > 0) {
                this.isCheckedAllForMaterialChangesList = false;
                this.isUpdateForMaterialChangesList = false
                if (atleastOneUncheckedMat.length != this.SupplierMaterialPriceUploadList.materialChangesList.length)
                    this.isUpdateForMaterialChangesList = true;
            }
            else {
                this.isCheckedAllForMaterialChangesList = true;
                this.isUpdateForMaterialChangesList = true;
            }
            this.dataSourceMaterialChangesList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.materialChangesList);
        }

        if (this.SupplierMaterialPriceUploadList.materialDifferentSupplierList && listType == "materialDifferentSupplierList") {
            var atleastOneUncheckedMat = this.SupplierMaterialPriceUploadList.materialDifferentSupplierList.filter(p => p.isChecked == false);
            if (atleastOneUncheckedMat && atleastOneUncheckedMat.length > 0) {
                this.isCheckedAllForMaterialDifferentSupplierList = false;
                this.isUpdateForMaterialDifferentSupplierList = false;
                if (atleastOneUncheckedMat.length != this.SupplierMaterialPriceUploadList.materialDifferentSupplierList.length)
                    this.isUpdateForMaterialDifferentSupplierList = true;
            }
            else {
                this.isCheckedAllForMaterialDifferentSupplierList = true
                this.isUpdateForMaterialDifferentSupplierList = true;
            }
            this.dataSourceMaterialDifferentSupplierList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.materialDifferentSupplierList);
        }

        if (this.SupplierMaterialPriceUploadList.newMaterialList && listType == "newMaterialList") {
            var atleastOneUncheckedMat = this.SupplierMaterialPriceUploadList.newMaterialList.filter(p => p.isChecked == false);
            if (atleastOneUncheckedMat && atleastOneUncheckedMat.length > 0) {
                this.isCheckedAllForNewMaterialChangesList = false;
                this.isUpdateForNewMaterialList = false
                if (atleastOneUncheckedMat.length != this.SupplierMaterialPriceUploadList.newMaterialList.length)
                    this.isUpdateForNewMaterialList = true;
            }
            else {
                this.isCheckedAllForNewMaterialChangesList = true;
                this.isUpdateForNewMaterialList = true;
            }
            this.dataSourceNewMaterialList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.newMaterialList);
        }
    }

    UpdateMaterialPrice(listType) {
        var tempList = [];
        var isSameSupplier = false;
        if (this.SupplierMaterialPriceUploadList.materialChangesList && listType == "materialChangesList")
            tempList = this.SupplierMaterialPriceUploadList.materialChangesList;
        if ((this.SupplierMaterialPriceUploadList.materialDifferentSupplierList && this.SupplierMaterialPriceUploadList.materialDifferentSupplierList.length > 0) && listType == "materialDifferentSupplierList") {
            isSameSupplier = true;
            tempList = this.SupplierMaterialPriceUploadList.materialDifferentSupplierList;
        }
        const isUpdateValue = this.materialObject.type == 'SupplierInvoice' ? false : true;
        this.showSpinner = true;
        this.materialService.UpdateMaterialPriceFromExcelSupplier<ResponseBase>(tempList, isSameSupplier, isUpdateValue)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.SupplierMaterialPriceUploadList = new SupplierMaterialChanges();
                    // if (response.result && listType == "materialChangesList") 
                    //     this.SupplierMaterialPriceUploadList.materialChangesList = response.result;
                    // if (response.result && listType == "materialDifferentSupplierList") 
                    //     this.SupplierMaterialPriceUploadList.materialDifferentSupplierList = response.result;
                    // this.setGrid()
                    this.isUpdateForMaterialChangesList = false;
                    this.isUpdateForMaterialDifferentSupplierList = false;
                    this.isUpdateForNewMaterialList = false;
                    this.GetSupplierPriceUploadList()
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    reset() {
        this.showSpinner = true;
        this.materialService.DeleteMaterialPriceChangesExcel<ResponseBase>().subscribe({
            next: (response: ResponseBase) => {
                this.materialObject.supplierId = null;
                this.materialObject.type = "MaterialPriceList";
                this.invoicePDFFile = null;
                //this.t.controls = [];
                if (this.selectedFiles.length > 0) {
                    this.selectedFiles = [];
                }
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {

                    }
                });
                this.GetSupplierPriceUploadList();
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });

    }

    resetControl() {

    }

    // initilizeForm(){
    //     for (let index = 0; index < this.SupplierMaterialPriceUploadList.newMaterialList.length; index++) {
    //         this.t.push(this.formBuilder.group({
    //             text: [null, Validators.compose([Validators.required, Validators.maxLength(80)])],
    //             picture: [null, !Validators.required],
    //             holdings: [null, Validators.compose([Validators.required])],
    //             itemNumber: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
    //             retailPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
    //             purchasePrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
    //             personalPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
    //             purchaseQuantity: [null, Validators.required],
    //             unit: [null, Validators.required],
    //             internalText: [],
    //             shelfNo: [null, Validators.required],
    //             rows: [],
    //             supplierId: [null, Validators.required],
    //             supplierItemNumber: [null, Validators.required],
    //             minInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
    //             maxInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
    //             isRequiredText: [],
    //             noStockFeatures: ['F'],
    //             nonStockList: [],
    //             colorCode: [],
    //             barcode: [],
    //             materialsForTheCase: [false],
    //             lineType: ['V', Validators.required],
    //             reminder: [null],
    //             onlySummer: [false],
    //             weatherType: [null, Validators.required],
    //             postNumberAppendix: [true],
    //             barcodeColorStr: ['1'],
    //             materialType: [null, Validators.required],
    //             isDisplayWebshop: [false],
    //             duplicateEntry: [false],
    //             checkPurchasePrice: [false],
    //             purchasepriceSalesUnit: [null],
    //             maxOrderNumber: [null],
    //             excelPurchaseQty: [0],
    //             excelPurchasePrice: [0],
    //             excelRetailPrice: [0]
    //         }));
    //     }
    //     this.t.patchValue(this.SupplierMaterialPriceUploadList.newMaterialList);
    // }

    getIcon(icon) {
        return 'icon-' + icon;
    }
    updateRetailAndPersonalPriceValidation(MaterialObject) {
        if (MaterialObject && MaterialObject.controls["purchasePrice"].value) {
            var retailValue = MaterialObject.controls["purchasePrice"].value + ((MaterialObject.controls["purchasePrice"].value * this.retailPriceIncreaseInPercentage) / 100);
            var personalValue = MaterialObject.controls["purchasePrice"].value + ((MaterialObject.controls["purchasePrice"].value * this.personalPriceIncreaseInPercentage) / 100);
            MaterialObject.controls["retailPrice"].setValidators(Validators.min(retailValue));
            MaterialObject.controls["retailPrice"].updateValueAndValidity();
            MaterialObject.controls["personalPrice"].setValidators(Validators.min(personalValue));
            MaterialObject.controls["personalPrice"].updateValueAndValidity();
        }
    }

    UpdateValidation(MaterialObject) {
        var statusIsOffer = MaterialObject.controls["materialType"].value.find(o => o == 22);
        if (statusIsOffer) {
            this.IsSupplierRequired = false;
            MaterialObject.controls["holdings"].setValidators(null);
            MaterialObject.controls["holdings"].updateValueAndValidity();
            MaterialObject.controls["retailPrice"].setValidators(null);
            MaterialObject.controls["retailPrice"].updateValueAndValidity();
            MaterialObject.controls["purchasePrice"].setValidators(null);
            MaterialObject.controls["purchasePrice"].updateValueAndValidity();
            MaterialObject.controls["personalPrice"].setValidators(null);
            MaterialObject.controls["personalPrice"].updateValueAndValidity();
            MaterialObject.controls["purchaseQuantity"].setValidators(null);
            MaterialObject.controls["purchaseQuantity"].updateValueAndValidity();
            MaterialObject.controls["unit"].setValidators(null);
            MaterialObject.controls["unit"].updateValueAndValidity();
            MaterialObject.controls["shelfNo"].setValidators(null);
            MaterialObject.controls["shelfNo"].updateValueAndValidity();
            MaterialObject.controls["supplierId"].setValidators(null);
            MaterialObject.controls["supplierId"].updateValueAndValidity();
            MaterialObject.controls["supplierItemNumber"].setValidators(null);
            MaterialObject.controls["supplierItemNumber"].updateValueAndValidity();
            MaterialObject.controls["minInventory"].setValidators(null);
            MaterialObject.controls["minInventory"].updateValueAndValidity();
            MaterialObject.controls["maxInventory"].setValidators(null);
            MaterialObject.controls["maxInventory"].updateValueAndValidity();
            MaterialObject.controls["lineType"].setValidators(null);
            MaterialObject.controls["lineType"].updateValueAndValidity();
            MaterialObject.controls["weatherType"].setValidators(null);
            MaterialObject.controls["weatherType"].updateValueAndValidity();
            MaterialObject.controls["materialType"].setValidators(null);
        }
        else {
            this.IsSupplierRequired = true;
            MaterialObject.controls["holdings"].setValidators([Validators.compose([Validators.required])]);
            MaterialObject.controls["holdings"].updateValueAndValidity();
            MaterialObject.controls["purchasePrice"].setValidators([Validators.compose([Validators.required, Validators.max(999999999999)])]);
            MaterialObject.controls["purchasePrice"].updateValueAndValidity();
            MaterialObject.controls["purchaseQuantity"].setValidators([Validators.required]);
            MaterialObject.controls["purchaseQuantity"].updateValueAndValidity();
            MaterialObject.controls["unit"].setValidators([Validators.required]);
            MaterialObject.controls["unit"].updateValueAndValidity();
            MaterialObject.controls["shelfNo"].setValidators([Validators.required]);
            MaterialObject.controls["shelfNo"].updateValueAndValidity();
            MaterialObject.controls["supplierId"].setValidators([Validators.required]);
            MaterialObject.controls["supplierId"].updateValueAndValidity();
            MaterialObject.controls["supplierItemNumber"].setValidators([Validators.required]);
            MaterialObject.controls["supplierItemNumber"].updateValueAndValidity();
            MaterialObject.controls["minInventory"].setValidators([Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])]);
            MaterialObject.controls["minInventory"].updateValueAndValidity();
            MaterialObject.controls["maxInventory"].setValidators([Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])]);
            MaterialObject.controls["maxInventory"].updateValueAndValidity();
            MaterialObject.controls["lineType"].setValidators([Validators.required]);
            MaterialObject.controls["lineType"].updateValueAndValidity();
            MaterialObject.controls["weatherType"].setValidators([Validators.required]);
            MaterialObject.controls["weatherType"].updateValueAndValidity();
            MaterialObject.controls["materialType"].setValidators([Validators.required]);
            this.updateRetailAndPersonalPriceValidation(MaterialObject);
        }
    }

    onKeyPressCheckNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 44 && charCode != 46) {
            return false;
        }
        return true;
    }

    addNewMaterials() {
        //this.materialForm.markAllAsTouched();
        let invalidMaterialList = this.SupplierMaterialPriceUploadList.newMaterialList.filter(item => (item.isValid == false && item.isChecked == true));
        if(invalidMaterialList && invalidMaterialList.length > 0){
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'Headers.InvalidMaterialSelected', ShowOKButton: true }
            });

            dialogRef.afterClosed().subscribe(result => { });
            return;
        }

        let materialList = this.SupplierMaterialPriceUploadList.newMaterialList.filter(item => (item.isValid == true && item.isChecked == true));
        if (materialList && materialList.length >0) {
            // var materialList = this.t.value;
            materialList.filter( m => {
                if(m.materialType && m.materialType.length > 0)
                    m.materialType = m.materialType.toString();
                if(!m.noStockFeatures)
                    m.noStockFeatures = "F";
            });
            this.showSpinner = true;
            this.materialService.AddMaterialByList<ResponseBase>(materialList)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.SupplierMaterialPriceUploadList.newMaterialList = [];
                        this.newMaterialForm.reset();
                        this.isCheckedAllForMaterialChangesList = false;
                        this.isCheckedAllForMaterialDifferentSupplierList = false;
                        this.isCheckedAllForNewMaterialChangesList = false;
                        this.GetSupplierPriceUploadList();
                    },
                    error: err => {
                        this.showSpinner = false;
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        }
    }

    // deleteMaterial(index) {
    //     const dialogRef = this.dialog.open(AlertMessageModelComponent, {
    //         height: '200px',
    //         data: { Header: 'Headers.DeleteMaterial' }
    //     });

    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.t.removeAt(index)
    //             this.SupplierMaterialPriceUploadList.newMaterialList = this.t.value;
    //         }
    //     });
    // }

    sampleFileUrlInit() {
        this.sampleFileUrl = environment.url + "Upload/SupplierPriceList/Sample-SupplierPriceUpload.xlsx";
    }

    downloadSampleFile() {
        this.http.get(this.sampleFileUrl, { responseType: "blob" }).subscribe((res: any) => {
            const url = window.URL.createObjectURL(res);
            //window.open(url);
            var download = document.createElement("a");
            download.href = url;
            download.download = 'Sample-SupplierPriceUpload' + '.xlsx';
            var event = document.createEvent("MouseEvents");
            event.initMouseEvent(
                "click", true, false, window, 0, 0, 0, 0, 0
                , false, false, false, false, 0, null
            );
            download.dispatchEvent(event);
        });
    }

    isObjectValid(obj: any): boolean {
        if (!obj) {
            return false;
        }
        else {
            this.newMaterialForm.patchValue(obj);
            return this.newMaterialForm.valid;
        }
    }

    editMaterial(mat) {
        if (mat) {
            const supplierPriceItemId = mat.id;
            const dialogRef = this.dialog.open(EditSupplierMaterialComponent, {
                height: '50%',
                width: '90%',
                data: {
                    materialObject: mat,
                    supplierList: this.SupplierList,
                    tempMaterialList: this.tempMaterialList,
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    result.materialType = result.materialType.toString();
                    result.supplierPriceItemId = supplierPriceItemId;
                    const matList: any[] = [result];
                    this.saveSingleMaterial(matList);
                }
            });
        }

    }

    saveSingleMaterial(material) {        
        this.showSpinner = true;
        this.materialService.AddMaterialByList<ResponseBase>(material)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.dataSourceNewMaterialList = null;
                    this.showSpinner = false;
                    this.SupplierMaterialPriceUploadList.newMaterialList = this.SupplierMaterialPriceUploadList.newMaterialList.filter(m => m.itemNumber !== material[0].itemNumber);
                    this.isCheckedAllForNewMaterialChangesList = false;
                    this.isUpdateForNewMaterialList = false
                    this.dataSourceNewMaterialList = new MatTableDataSource(this.SupplierMaterialPriceUploadList.newMaterialList);
                    //this.GetSupplierPriceUploadList();
                    this.onSetActive('newMaterialList');
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    priceListTypeChange(){
        if(this.materialObject.type == "SupplierInvoice")
            this.acceptedFileTypes = "application/pdf";
        else
            this.acceptedFileTypes = "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }

    openPdfInNewWindow(file: File) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            if (e.target && e.target.result) {
                const pdfUrl = e.target.result.toString();
                var data = pdfUrl.replace("data:application/pdf;base64,", "");
                const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                    height: '90%',
                    width: '90%',
                    data: {
                        base64: data,
                        filename: 'SupplierInvoice.pdf'
                    }
                });
                dialogRef.afterClosed().subscribe(result => {});
            }
        };
        reader.readAsDataURL(file);
      }
    //#endregion Public Methods
}

export class SupplierMaterialChanges {
    newMaterialList: any = [];
    materialChangesList: any = [];
    materialDifferentSupplierList: any = [];
    uploadFileSupplierDetails: any = {};
    tempMaterialList: any = [];
}