import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedTextBoxPopup } from './text-box-popup.component';
// import { NgxMaskModule } from 'ngx-mask';
// import { CountryDrodownModule } from '../country-dropdown/country-dropdown.module';
import { CustomCurrencyMaskConfig } from 'app/core/constant/CurrencyMaskConfiguartion';
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
    declarations: [SharedTextBoxPopup],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        //FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        // DatePickerModule,
        // NgxMatSelectSearchModule,
        // NgxMaskModule.forRoot(),
        // CountryDrodownModule
    ],
    entryComponents: [SharedTextBoxPopup],
})

export class TextBoxPopupSharedModule {

}