// angular 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// third party tool
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { CoWorkersService } from '../../coWorkers.service';
import { TermsOfEmploymentService } from 'app/main/pages/terms-of-employment/terms-of-employment.service';
import { RequestTearmsBase } from 'app/main/model/RequestBase';
import { ProjectService } from 'app/main/pages/project/project.service';

type AOA = any[][];

@Component({
  selector: 'app-co-worker-upload',
  templateUrl: './co-worker-upload.component.html',
  styleUrls: ['./co-worker-upload.component.scss']
})
export class CoWorkerUploadComponent implements OnInit {
  selectedFiles: any[] = [];
  showSpinner: boolean = false;
  sampleFileUrl: any;
  data: any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  coWorkerUploadList: any = [];
  selectedOption: string;
  roles: any;
  coworkerForm: FormGroup;
  termsOfEmploymentList: any[] = [];
  requestBase: any;

  //#endregion Variables

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private http: HttpClient,
    private fb: FormBuilder,
    private formService: FormService,
    private projectService: ProjectService,
    private coworkersService: CoWorkersService,
    private termsOfEmploymentService: TermsOfEmploymentService,
  ) { this.translationLoader.loadTranslations(english, danish); }

  ngOnInit() {
    this.sampleFileUrlInit();
    this.getRoleArray();
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        // console.log('onLangChange', event);
        this.getRoleArray();
      });

    this.coworkerForm = this.fb.group({
      coWorkerAs: ['', Validators.required],
      termsID: [null, Validators.required],
      roleID: ['', Validators.required],
    });

    this.requestBase = new RequestTearmsBase();
    this.requestBase.Index = 1;
    this.requestBase.Limit = 100;
    this.getTermsOfEmploymentList();
  }

  getRoleArray() {
    // this.translate.get("Roles").subscribe({
    //   next: (res) => {
    //     this.roles = res;
    //     this.roles.sort(function (a, b) {
    //       return a.label.localeCompare(b.label);
    //     });
    //   }, error: err => { }
    // });
    this.showSpinner = true;
        this.projectService.GetUserRoleList().subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.roles = response.result.myResponse;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
  }
  Upload() {
    const roleId = this.coworkerForm.get('roleID').value;
    var role = this.roles.find(o => o.id == roleId);
    if (role)
      this.coworkerForm.get("coWorkerAs").setValue(role.name);
    this.formService.markFormGroupTouched(this.coworkerForm);
    if (this.coworkerForm.valid) {
      this.showSpinner = true;
      if (this.coWorkerUploadList.termsID == null) {
        for (let obj of this.coWorkerUploadList) {
          obj['termsID'] = this.coworkerForm.get('termsID').value;
          obj['roleID'] = this.coworkerForm.get('roleID').value;
          obj['coWorkerAs'] = role.name;
        }
      }
      this.coworkersService.CreateCoworkerFromExcel<ResponseBase>(this.coWorkerUploadList)
        .subscribe({
          next: (response: ResponseBase) => {
            this.showSpinner = false;
            this.translate.get("MESSAGES.Updated").subscribe({
              next: (res) => {
                this.notificationsService.success(res);
              },
              error: err => {
              }
            });
            this.coWorkerUploadList = response.result;
          },
          error: err => {
            this.showSpinner = false;
            this.notificationsService.error(err.error.message);
          },
          complete: () => {
            this.showSpinner = false;
          }
        });
    }
  }

  OnuploadFile(event) {
    if (event.target.files.length > 0)
      this.selectedFiles = event.target.files;
  }

  sampleFileUrlInit() {
    this.sampleFileUrl = environment.url + "Upload/CoworkerUpload/Sample-CoworkerUpload.xlsx";
  }

  downloadSampleFile() {
    this.http.get(this.sampleFileUrl, { responseType: "blob" }).subscribe((res: any) => {
      const url = window.URL.createObjectURL(res);
      //window.open(url);
      var download = document.createElement("a");
      download.href = url;
      download.download = 'Sample-CoworkerUpload' + '.xlsx';
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0
        , false, false, false, false, 0, null
      );
      download.dispatchEvent(event);
    });
  }
  onFileChange(evt: any) {
    const coWorkerList: any[] = [];
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      const headerRow = this.data[0];
      this.data = this.data.slice(1);
      const columnMapping: { [key: string]: number } = {
        "Medarbejdernavn": headerRow.indexOf("Medarbejdernavn"),
        "Medarbejdernummer": headerRow.indexOf("Medarbejdernummer"),
        "Adresse": headerRow.indexOf("Adresse"),
        "Postnr": headerRow.indexOf("Postnr"),
        "By": headerRow.indexOf("By"),
        "Telefon": headerRow.indexOf("Telefon"),
        "Initialer": headerRow.indexOf("Initialer"),
        "Email": headerRow.indexOf("Email"),
        // "Adgangskode": headerRow.indexOf("Adgangskode"),
        // "Ansaettelsesdato": headerRow.indexOf("Ansaettelsesdato"),
        "Cpr": headerRow.indexOf("Cpr"),
        "BankReg": headerRow.indexOf("BankReg"),
        "BankKonto": headerRow.indexOf("BankKonto")
      };


      this.data.forEach((res) => {
        if (res[0] !== "no" && res.some((element) => element !== "")) {
          const coWorker = {
            "medarbejdernavn": res[columnMapping["Medarbejdernavn"]],
            "medarbejdernummer": res[columnMapping["Medarbejdernummer"]] ? res[columnMapping["Medarbejdernummer"]].toString() : null,
            "adresse": res[columnMapping["Adresse"]],
            "postnr": res[columnMapping["Postnr"]] ? res[columnMapping["Postnr"]].toString() : null,
            "by": res[columnMapping["By"]],
            "telefon": res[columnMapping["Telefon"]] ? res[columnMapping["Telefon"]].toString() : null,
            "initialer": res[columnMapping["Initialer"]],
            "email": res[columnMapping["Email"]],
            // "adgangskode": res[columnMapping["Adgangskode"]],
            // "ansaettelsesdato": res[columnMapping["Ansaettelsesdato"]],
            "cpr": res[columnMapping["Cpr"]] ? res[columnMapping["Cpr"]].toString() : null,
            "bankReg": res[columnMapping["BankReg"]] ? res[columnMapping["BankReg"]].toString() : null,
            "bankKonto": res[columnMapping["BankKonto"]],
            "isInsertedStatus": null,
            "coWorkerAs": this.coworkerForm.get('coWorkerAs').value,
            "termsID": this.coworkerForm.get('termsID').value,
            "roleId": this.coworkerForm.get('roleID').value
          };

          if (Object.values(coWorker).some((value) => value !== "")) {
            coWorkerList.push(coWorker);
          }
        }
      });
      this.coWorkerUploadList = coWorkerList;
    };

    reader.readAsBinaryString(target.files[0]);

  }

  getTermsOfEmploymentList() {
    this.showSpinner = true;
    this.termsOfEmploymentService.Get(this.requestBase).subscribe({
      next: (response: ResponseBase) => {
        if (response) {
          this.termsOfEmploymentList = response.result;
        }
      },
      error: err => {
        this.termsOfEmploymentList = [];
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

}
