import { Component, Inject, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { Settings } from 'app/main/model/Settings';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'text-box-popup-shared',
    templateUrl: './text-box-popup.component.html',
    styleUrls: ["./text-box-popup.component.scss"]
})

export class SharedTextBoxPopup implements OnInit {
    showSpinner: boolean = false;
    SettingsObj: Settings = new Settings();
    travelingAmount: number = 0;
    DeserilizedValue: any;
    PopupTitle: string = "HEADERS.AddedToCart";
    TextBoxLabel: string = "FIELDS.Name";
    TextBoxValue: string = "";
    TextBoxType: string = "";
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<SharedTextBoxPopup>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.PopupTitle = this.data.HeadingTitleKey ? this.data.HeadingTitleKey : this.PopupTitle;
        this.TextBoxLabel = this.data.TextBoxLabelKey ? this.data.TextBoxLabelKey : this.TextBoxLabel;
        this.TextBoxValue = this.data.TextBoxValue ? this.data.TextBoxValue : "";
        this.TextBoxType= this.data.TextBoxType ? this.data.TextBoxType : "text";
    }

    close(flag) {
        var obj = { IsSuccess: flag, TextValue: this.TextBoxValue };
        this.dialogRef.close(obj);
    }
}