import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from '../../material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption } from '@angular/material';
import { Material } from 'app/main/model/Material';
import { PaintSelectTime } from 'app/constant/common.constant';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MaterialTypeCategory } from 'app/constant/common.constant';

@Component({
    selector: 'copy-offer-material',
    templateUrl: './copy-offer-material.component.html',
    styleUrls: ['./copy-offer-material.component.scss']
})
export class CopyOfferMaterialComponent implements OnInit {
    showSpinner: boolean = false;
    showNumberOfTime:boolean = false;
    Materials: Material[] = [];
    tempMaterials: Material[] = [];
    ddlSelectedMaterialId: string = "";
    selectedMaterialPriceId: string = "";
    offerMaterialId: string = "";
    materialText: string = "";
    MaterialName: any = "";
    offerType: number = 0;
    selectedMaterialData: any;
    selectedFromValue: string[] = [];
    selectMaterialTypeList = MaterialTypeCategory;
    @ViewChild('allMaterialSelect', { static: false }) private allMaterialSelect: MatOption;

    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<CopyOfferMaterialComponent>,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.Materials = this.data.materialList.filter(o => o.isAddToOffer === true);
        this.materialText = this.data.materialHeader;
        this.selectedMaterialPriceId = this.data.offersMaterialPriceId;
        this.tempMaterials = this.Materials;
    }

    GetMaterialById() {
        this.selectedFromValue = [];
        this.showSpinner = true;
        this.materialService.GetSingle<ResponseBase>(this.ddlSelectedMaterialId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.selectedMaterialData = response.result.offerMaterial;
                    this.offerMaterialId = response.result.offerMaterial.id;
                    this.showSpinner = false;
                    this.showNumberOfTime = true;
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    getPaintSElectValue(type: number) {
        const selectObj = PaintSelectTime.find(item => item.id === type);
        return selectObj ? selectObj.value : '';
    }

    AllMaterialSelect() {
        if (this.allMaterialSelect.selected) {
            this.selectedFromValue = [];
            this.selectedFromValue = this.selectedMaterialData.offerMaterialPriceList.map(o => o.id);
            this.selectedFromValue.push("0");
        }
        else {
            this.selectedFromValue = [];
        }
    }

    DropDownSearchMaterial(MaterialName) {
        if (this.offerType === 0) {
            this.Materials = this.tempMaterials.filter(o => (
                o.text.toLowerCase().includes(MaterialName.toLowerCase())
                || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase())
            ));
        }
        else {
            this.Materials = this.tempMaterials.filter(o => o.offerMaterialType.split(",").includes(this.offerType.toString())).filter(o => (
                o.text.toLowerCase().includes(MaterialName.toLowerCase())
                || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase())
            ));
        }
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    copy() {
        console.log("SElected material Price id", JSON.stringify(this.selectedFromValue));
        this.showSpinner = true;
        var reqObj = {
            "materialPriceID": this.selectedMaterialPriceId,
            "copyMaterialPriceID": this.selectedFromValue.join(","),
            "OfferMaterialId": this.offerMaterialId 
        }
        this.materialService.CopyOfferEstimateMaterial<ResponseBase>(reqObj)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Copy").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.CopyError").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    OfferTypeSearch() {
        if (this.offerType === 0) {
            this.Materials = this.tempMaterials;
        }
        else {
            this.Materials = this.tempMaterials.filter(o => o.offerMaterialType.split(",").includes(this.offerType.toString()));
        }
    }

    cancel() {
        this.dialogRef.close();
    }
}
