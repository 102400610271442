export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'NEWCOWORKER': 'Ny medarbejder',
        },
        'FIELDS': {
            'Status': 'Status',
            'InvoiceText': 'Fakturatekst',
            'TaskInfo': 'Opgaveinfo',
            'Note': 'Bemærk',
            'RequisitionNo': 'Rekvisition Nr.',
            'PaymentMode': 'Betalingsmåde',
            'Comments': 'Kommentarer',
        },
        'Headers': {
            'ProjectDetail': 'Projekt detaljer',
        },
        // 'selectedValueStatus': {
        //     'value1': 'Process',
        //     'value2': 'Planlagt besøg',
        //     'value3': 'Svare ikke',
        //     'value4': 'Tilbud sendt',
        //     'value5': 'Tilbud godkendt',
        //     'value6': 'Planlagt',
        //     'value7': 'Tilbud afvist',
        //     'value8': 'Startet',
        //     'value9': 'Udført',
        //     'value10': 'Faktureret',
        //     'value11': 'Afventer',
        //     'value12': 'Klar fakturering',
        // },
        'paymentModeList': {
            'value1': 'Dankort',
            'value2': 'Mastercard',
            'value3': 'kontant',
            'value4': 'Mobilpay',
            'value5': 'Mobilepay KK',
            'value6': 'Kredit',
            'value7': 'Andet',
        }
    }
};

