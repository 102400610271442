export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'OfferTravelingAmount': 'Tilbud Kørsel Beløb Pr. Min',
            'AddedToCart': 'Opret/Gem tilbud.',
            'RenameFile': 'Omdøb fil',
            'Designation': 'Betegnelse',
            'Company': 'Firma',
            'AdvanceInvoice':'Aconto faktura'
        },
        'FIELDS': {
            'TravelingAmount': 'Kørsel beløb pr. min',
            'Name': 'Navn',
            'AmountWithTax':'Amount include tax',
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Back': 'Tilbage',            
        },
        'MESSAGES': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Success',
        }
    }
}