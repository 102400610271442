export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForSalary': 'Lønberegning : Bluegarden',
            'HeaderForSalaryLable': 'Opsætning af overførsel af lønberegning til Bluegarden i systemet',
        },
        'FIELDS': {
            'EmployerNo': 'Arbejdsgivernr',
            'SalaryPeriod14Days': 'Løntermin -14 dage',
            'PayrollMonth': 'Løntermin-måned',
            'Group': 'Gruppe',
            'HourlyFigures': 'Timetal',
            'HolidayEligibleSalary':'Ferieberettiget løn',
            'OvertimeReplacement':'Overarbejde & afløser',
            'SalaryAdvance':'Lønforskud',
            'SeniorityAllowance':'Anciennitetstillæg',
            'Km':'km',
            'KmRate':'Km. takst',
            'GDayNumber':'G-dag antal',
            'GDayTotal':'G-dag sum',
            'HolidayDays':'Feriedage',
            'BIncome':'B-indkomst'
        },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};

