export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            "Role": "Rolle",
            "Assets_View": "Se Enheder",
            "Assets_Modify": "Rediger i Enheder",
            "Assets_Delete": "Slet i Enheder",
            "CarInfo_View": "Se Billinfo",
            "CarInfo_Modify": "Rediger i Billinfo",
            "CarInfo_Delete": "Slet i Billinfo",
            "CompanyInfo_View": "Se Firmainfo",
            "CompanyInfo_Modify": "Rediger i Firmainfo",
            "CompanyInfo_Delete": "Slet i Firmainfo",
            "Coworker_View": "Se Medarbejder",
            "Coworker_Modify": "Rediger i Medarbejder",
            "Coworker_Delete": "Slet i Medarbejder",
            "Customer_View": "Slet i Kunder",
            "Customer_Modify": "Rediger i Kunder",
            "Customer_Delete": "Se Kunder",
            "Dashboard_View": "Se Dashboard",
            "Document_View": "Se Dokumenter",
            "Document_Modify": "Rediger i Dokumenter",
            "Document_Delete": "Slet i Dokumenter",
            "Inquiry_View": "Se Forespørgsler",
            "Inquiry_Modify": "Rediger i Forespørgsler",
            "Inquiry_Delete": "Slet i Forespørgsler",
            "LiveMap_View": "Se Live kort",
            "Material_View": "Se Materialer",
            "Material_Modify": "Rediger i Materialer",
            "Material_Delete": "Slet i Materialer",
            "NanoChips_View": "Se Nanno Brikker",
            "NanoChips_Modify": "Rediger i Nanno Brikker",
            "NanoChips_Delete": "Slet i Nanno Brikker",
            "Offer_View": "Se Tilbud",
            "Offer_Modify": "Rediger i Tilbud",
            "Offer_Delete": "Slet i Tilbud",
            "Payroll_View": "Se Lønningsliste",
            "Payroll_Modify": "Rediger i Lønningsliste",
            "Payroll_Delete": "Slet i Lønningsliste",
            "Planning_View": "Se Planlægning",
            "Planning_Modify": "Rediger i Planlægning",
            "Planning_Delete": "Slet i Planlægning",
            "Project_View": "Se Projekter",
            "Project_Modify": "Rediger i Projekter",
            "Project_Delete": "Slet i Projekter",
            "QualityTest_View": "Se Kvalitetstest",
            "QualityTest_Modify": "Rediger i Kvalitetstest",
            "QualityTest_Delete": "Slet i Kvalitetstest",
            "QuickInvoice_View": "Se Butikssalg",
            "QuickInvoice_Modify": "Rediger i Butikssalg",
            "QuickInvoice_Delete": "Slet i Butikssalg",
            "Reminder_View": "Se Påmindelser",
            "Reminder_Modify": "Rediger i Påmindelser",
            "Reminder_Delete": "Slet i Påmindelser",
            "CoworkerReport_View": "Se Medarbejder rapport",
            "Report_View": "Se Rapport",
            "MaterialReport_View": "Se Materialer rapport",
            "Setting_View": "Se Indstillinger",
            "Statistics_View": "Se Statestik",
            "StatisticsQuestion_View": "Se Statestik spørgsmål",
            "Supplier_View": "Se Leverandørinfo",
            "Supplier_Modify": "Rediger i Leverandørinfo",
            "Supplier_Delete": "Slet i Leverandørinfo",
            "TermsofEmployment_View": "Se Ansættelsesperiode",
            "TermsofEmployment_Modify": "Rediger i Ansættelsesperiode",
            "TermsofEmployment_Delete": "Slet i Ansættelsesperiode",
            "WorkHours_View": "Se Arbejdstimer",
            "WorkHours_Modify": "Rediger i Arbejdstimer",
            "WorkHours_Delete": "Slet i Arbejdstimer",
            "Own_Project_View": "Egen Projekt",
            "Own_Planning_View": "Egen Planlægning",
            "Economy_View": "Se Økonomi",
            "Planning_Leave_Approve": "Kan godkende i Planlægning",
            "Change_Role": "Skift Rolle",
            "PurchasePrice_View": "Se Indkøbspris",
            "Coworker_View_Files": "Se Filer",
            "Coworker_View_Other_Fields": "Se Medarbejder andre felter",
            "Coworker_View_Keys": "Se Nøgle",
            "Own_Payroll_View": "Egen Lønningsliste",
            "Own_Workhour_View": "Egen Arbejdstimer",
            "Upload_Material_Price": "Importere Indkøbspris **",
            "Material_Barcode_Size": "Justere stregkode størrelse **",
            "MaterialList_Order_Tab": "Se Bestil materialeliste **",
            "MaterialList_Ordered_Tab": "Materiale Bestilling historie materialeliste **",
            "MaterialList_SoldStock_Tab": "Se Solgt materialeliste **",
            "MaterialList_Log_Tab": "Se Log materialeliste **",
            "Coworker_CPR_View": "Se Medarbejder cpr-nr",
            "Coworker_Answer_View": "Se Medarbejder svar",
            "View_Email_SMS_Notification_Settings": "Se Email/SMS/Notifikation Indstillinger",
            "Project_Requisition_Modify": "Rediger i Opgave info",
            "Project_Requisition_Delete": "Slet i Opgave info",
            "Project_Material_Modify": "Rediger i Materialer",
            "Project_Material_Delete": "Slet i Materialer",
            "Project_Coworker_Modify": "Rediger i Medarbejder",
            "Project_Coworker_Delete": "Slet i Medarbejder",
            "WorkHours_Leave_Approve": "Kan godkende Arbejdstimer",
            "Material_Calculation_Container": "Se Materialeberegningsbeholder **",
            "View_Supplier_Quick_Material": "Se hurtigt materiale/leverandør",
            "Customer_Project_Tab": "Se Projekter",
            "Customer_Files_Tab": "Se Filer",
            "Customer_Log_Tab": "Se Log",
            "Customer_ColorCode_Tab": "Se Farvakode",
            "Customer_Message_Log_Tab": "Se Meddelelses log",
            "Customer_Properties_Tab": "Se Ejendomme",
            "Customer_Materials_Tab": "Se Lager",
            "Customer_Project_Recuring_Settings_Tab": "Se Projekt Tilbagevendende Indstilling",
            "Project_Files_Tab": "Se Filer",
            "Project_Workhours_Tab": "Se Arbejdstimer",
            "Project_Log_Tab": "Se Log",
            "Project_Statistic_Tab": "Se Statistik",
            "Project_Note_Tab": "Se Bemærk",
            "Project_Message_Log_Tab": "Se Meddelelses log",
            "Project_Room_Building_Tab": "Se Rum/Bygning",
            "Project_Quality_Test_Tab": "Se Kvalitetstest",
            "Project_Location_Tab": "Se Beliggenhed",            
            "Material_Files_Tab": "Se Files vare",
            "Material_SalesHistory_Tab": "Se Salg historie vare",
            "Material_OrderHistory_Tab": "Se bestillings historie vare",
            "Material_Log_Tab": "Se Log vare",
            "Material_Offer_Tab": "Se Tilbud vare",
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
            'Notes': 'Tilladelser bruges kun i webappen.'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'ModifyRoles': 'Rediger roller',
        }
    }
};
