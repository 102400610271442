import { Component, OnInit, ElementRef } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TitleService } from 'app/core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from 'app/core/services/form.service';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'app/core/services/auth.service';
import { MatDialog } from '@angular/material';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { FolderListNavigationDTO } from 'app/main/model/DropBox';
import { environment } from 'environments/environment';
import { CarInformationsService } from 'app/main/pages/car-information/car-informations.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { CarInformation, ContactInformation } from 'app/main/model/CarInformation';
import { IsNumber, spaceOnlyValidator } from 'app/core/Validators/validation';
import { ImageObj } from 'app/main/model/common';
import { BaseRequest, RequestCoWorker } from 'app/main/model/RequestBase';
import { CoWorker } from 'app/main/model/CoWorker';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import * as moment from 'moment';
import { ReminderService } from '../../reminder/reminder.service';
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { SetReminderModelComponent } from '../set-reminder-model/set-reminder-model.component';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';
import { LatLongModalComponent } from 'app/main/shared-component/google-map-address-autocomplete/lat-long-modal/lat-long-modal.component';


@Component({
    selector: 'app-new-car-information',
    templateUrl: './new-car-information.component.html',
    styleUrls: ['./new-car-information.component.scss'],
})

export class NewCarInformationComponent implements OnInit {
    showSpinner = false;
    carInformationFrom: FormGroup;
    carInformationId: any = "";
    carInformationDetail: CarInformation = new CarInformation();
    // roleName: string = "";
    // IsAdmin: boolean = false;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    isFile: boolean = false;
    selectedIndex: number = 0;
    LeasingDetail: ContactInformation = new ContactInformation();
    ServiceAgreementDetail: ContactInformation = new ContactInformation();
    RoadsideAssistanceDetail: ContactInformation = new ContactInformation();
    imageObj: ImageObj = new ImageObj();
    coWokersList: CoWorker[] = [];
    IsItemNumberAlreadyExist: boolean = false;
    displayNoteTabRed: boolean = false;
    NoteDescription: string = "";
    tempCarId="";
    isShowServiceReminder: boolean = false;
    isShowSightReminder: boolean = false;
    carInformationList: any = [];
    requestBase: any = new BaseRequest();
    deviceList: [] = [];
    roleClaims: any;
    isActive: boolean = true;
    isDeleted: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService, private elem: ElementRef,
        private carInformationsService: CarInformationsService,
        private _route: ActivatedRoute,
        private _router: Router,
        private formBuilder: FormBuilder,
        private titleService: TitleService,
        private translate: TranslateService,
        private formService: FormService,
        private notificationsService: NotificationsService,
        private nanolinkService: NanolinkService,
        private authService: AuthService,
        public dialog: MatDialog,
        public coWorkersService: CoWorkersService,
        private customerService: CustomersService,
        private reminderService: ReminderService,
        private dropboxFilesService: DropboxFilesService,) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_CARINFORMATIONS");
        this.roleClaims = this.authService.getRoleClaimsList();
        try {
            var tempData = this._router.getCurrentNavigation().extras.state;
            if(tempData && tempData.moduleDataList)
                this.carInformationList = tempData.moduleDataList;    
        } catch (error) {
            
        }
        this._route.queryParams.subscribe(params => {
            this.isActive = params['isActive'];
            this.isDeleted = params['isDeleted'];
        });
    }

    ngOnInit() {
        this.getCoWorkers();
        //this.roleName = this.authService.getUserRole();
        this.carInformationFrom = this.formBuilder.group({
            id: [null],
            imeiNo: [null, Validators.compose([Validators.maxLength(50)])],
            vehicleNo: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this), Validators.maxLength(10)])],
            carBrand: [null, Validators.compose([Validators.maxLength(50)])],
            model: [null, Validators.compose([Validators.maxLength(50)])],
            regNo: [null, Validators.compose([Validators.maxLength(50)])],
            color: [null, Validators.compose([Validators.maxLength(50)])],
            regDate: [null],
            lastSightDate: [null],
            lastSightCoworkerID: [null],
            nextSightDate: [null],
            nextSightCoworkerID: [null],
            lastServiceDate: [null],
            lastServiceCoworkerID: [null],
            nextServiceDate: [null],
            nextServiceCoworkerID: [null],
            leasing: [null],
            serviceAgreement: [true],
            roadsideAssistance: [null],
            runningKM: [null],
            nextServiceAfterKM: [null],
            nextServiceKM: [null],
            lastServiceKM: [null],
            coworkerId:[null, Validators.required],
            isAddFilter: [false],
            isDiesel: [false],
            summerTires: [false],
            winterTires: [false],
            allSeasonTires: [false],
            nanolinkDeviceID:[null],
            lat:[null],
            long:[null],
        });
        this._route.params.subscribe(params => {
            var id = params['id'];
            this.tempCarId = id;
            if (id != undefined) {
                this.getCarInformationsByID(id);
                this.getNotetabData(id);
            }
        });
        
    
        // if (this.roleName == "Admin") {
        //     this.IsAdmin = true;
        // }

        setTimeout(()=>{
            this.getDeviceList();
        },500);
    }

    ngAfterViewInit() {
    }

    getCarInformationsByID(id) {
        this.showSpinner = true;
        this.carInformationsService.GetCarInfoById<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.carInformationDetail = response.result;
                    this.imageObj.image = this.carInformationDetail.imageBase64;
                    this.imageObj.imagePath = this.carInformationDetail.imageUrl;
                    this.carInformationFrom.patchValue(this.carInformationDetail);
                    if (this.carInformationDetail.leasing && this.carInformationDetail.leasingDetails) {
                        this.LeasingDetail = JSON.parse(this.carInformationDetail.leasingDetails);
                    }
                    if (this.carInformationDetail.serviceAgreement && this.carInformationDetail.serviceAgreementDetails) {
                        this.ServiceAgreementDetail = JSON.parse(this.carInformationDetail.serviceAgreementDetails);
                    }
                    if (this.carInformationDetail.roadsideAssistance && this.carInformationDetail.roadsideAssistanceDetails) {
                        this.RoadsideAssistanceDetail = JSON.parse(this.carInformationDetail.roadsideAssistanceDetails);
                    }
                    this.storeFileFolderPathData();
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    save() {
        this.formService.markFormGroupTouched(this.carInformationFrom);
        if (this.carInformationFrom.valid) {
            if (this.carInformationDetail.leasing) {
                this.carInformationDetail.leasingDetails = JSON.stringify(this.LeasingDetail);
            }
            else {
                this.carInformationDetail.leasingDetails = null;
            }
            if (this.carInformationDetail.serviceAgreement) {
                this.carInformationDetail.serviceAgreementDetails = JSON.stringify(this.ServiceAgreementDetail);
            }
            else {
                this.carInformationDetail.serviceAgreementDetails = null;
            }
            if (this.carInformationDetail.roadsideAssistance) {
                this.carInformationDetail.roadsideAssistanceDetails = JSON.stringify(this.RoadsideAssistanceDetail);
            }
            else {
                this.carInformationDetail.roadsideAssistanceDetails = null;
            }
            if (this.carInformationDetail.id === '' || this.carInformationDetail.id == undefined) {
                this.Add();
            }
            else {
                this.Update();
            }
        }
    }

    Add() {
        this.showSpinner = true;
        this.carInformationsService.Add<ResponseBase>(this.carInformationDetail)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.carInformationDetail = response.result;
                        this.imageObj.image = this.carInformationDetail.imageBase64;
                        this.imageObj.imagePath = this.carInformationDetail.imageUrl;
                    }
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    this._router.navigate(['/carinformations/edit', this.carInformationDetail.id]);
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });

                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    Update() {
        this.formService.markFormGroupTouched(this.carInformationFrom);
        if (this.carInformationFrom.valid) {
            this.showSpinner = true;
            this.carInformationsService.Update<ResponseBase>(this.carInformationDetail.id, this.carInformationDetail)
                .subscribe({
                    next: (response: ResponseBase) => {
                        if (response.result) {
                            this.carInformationDetail = response.result;
                            this.imageObj.image = this.carInformationDetail.imageBase64;
                            this.imageObj.imagePath = this.carInformationDetail.imageUrl;
                        }
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });

                    },
                    complete: () => {
                    }
                });
        }
    }

    deleteCarInfo(Id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteCarInfo' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteCarInfo(Id);
            }
        });
    }

    DeleteCarInfo(Id) {
        this.showSpinner = true;
        this.carInformationsService.Delete<ResponseBase>(Id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => { }
                });
            },
            error: err => {
                this.showSpinner = false
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => { }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this._router.navigate(['/carinformations'])
            }
        })
    }

    getCoWorkers() {
        this.coWokersList = [];
        let model = new RequestCoWorker();
        if(this.isDeleted)
            model.IsActive = false;
        else
            model.IsActive = this.isActive;
        model.IsDeleted = false;
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokersList = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    visitSight() {
        this.translate.get("MESSAGES.CompletedNextSide").subscribe({
            next: (res) => {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: '20%',
                    data: { Header: res.replace('{Date}', moment(this.carInformationDetail.nextSightDate).format("DD.MM.YYYY")) }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.carInformationDetail.lastSightDate = this.carInformationDetail.nextSightDate;
                        this.carInformationDetail.lastSightCoworkerID = this.carInformationDetail.nextSightCoworkerID;
                        this.carInformationDetail.nextSightDate = null;
                        this.carInformationDetail.nextSightCoworkerID = null;
                        this.save();
                    }
                });
            },
            error: err => {
            }
        });
    }

    setReminder(carInformationDetail, from) { 
        let CoworkerMsg='';
        let CoworkerID='';
        let ReminderDate='';
        if(from=='Service'){
            CoworkerID=carInformationDetail['nextServiceCoworkerID'];
            CoworkerMsg='Reminder for Next Service';
            ReminderDate=moment(carInformationDetail['nextServiceDate']).format('YYYY-MM-DD') + ' 00:00';
        }else if(from=='Sight'){
            CoworkerID=carInformationDetail['nextSightCoworkerID'];
            CoworkerMsg='Reminder for Next Sight';
            ReminderDate=moment(carInformationDetail['nextSightDate']).format('YYYY-MM-DD') + ' 00:00';
        }

        const dialogRef = this.dialog.open(SetReminderModelComponent, {
            height: '50%',
            data: { 
                Header: 'MESSAGES.ReminderConfirmation', 
                CoWorkerName: this.coWokersList.find(i => i.id==CoworkerID)['name'],
                ReminderDateTime: ReminderDate,
                ReminderText: CoworkerMsg
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let coWokersList: any[] = [];
                coWokersList.push(CoworkerID)
                let reminder_obj = {
                    coWorkerId: [...coWokersList],
                    image: null,
                    imagePath: "",
                    isRepeat: false,
                    name: "",
                    reminderDate: ReminderDate,
                    reminderEndDate: null,
                    reminderTime: "00:00",
                    repeatMode: "day",
                    repeatModecount: 1,
                    replay: null,
                    replyImagePath: "",
                    text: result.ReminderText
                }
                
                this.reminderService.Add<ResponseBase>(reminder_obj)
                .subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode==200) {
                            this.translate.get("MESSAGES.ReminderAdded").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => { },
                    complete: () => {
                        if(from=='Service'){
                            this.isShowServiceReminder=false;
                        } else if(from=='Sight'){
                            this.isShowSightReminder=false;
                        }
                    }
                });
            }
        });
    }

    showServiceReminder(dateValue, idValue){
        if(dateValue && idValue) {
            this.isShowServiceReminder=true;
        } else {
            this.isShowServiceReminder=false;
        }
    }

    showSightReminder(dateValue, idValue){
        if(dateValue && idValue) {
            this.isShowSightReminder=true;
        } else {
            this.isShowSightReminder=false;
        }
    }

    visitService() {
        this.translate.get("MESSAGES.CompletedNextService").subscribe({
            next: (res) => {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: '20%',
                    data: { Header: res.replace('{Date}', moment(this.carInformationDetail.nextServiceDate).format("DD.MM.YYYY")) }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.carInformationDetail.lastServiceDate = this.carInformationDetail.nextServiceDate;
                        this.carInformationDetail.lastServiceCoworkerID = this.carInformationDetail.nextServiceCoworkerID;
                        this.carInformationDetail.lastServiceKM = this.carInformationDetail.runningKM;
                        this.carInformationDetail.nextServiceKM = this.carInformationDetail.runningKM + this.carInformationDetail.nextServiceAfterKM;
                        this.carInformationDetail.nextServiceDate = null;
                        this.carInformationDetail.nextServiceCoworkerID = null;
                        this.save();
                    }
                });
            },
            error: err => {
            }
        });
    }

    sendSMS() {
        //var moduleObj = new SMSModules()
        //moduleObj.name = this.SupplierDetail.business;
        //moduleObj.moduleID = this.SupplierDetail.id;
        //moduleObj.mobile = this.SupplierDetail.telephone;
        //const dialogRef = this.dialog.open(SendSMSComponent, {
        //    height: '350px',
        //    width: '800px',
        //    data: moduleObj
        //});

        //dialogRef.afterClosed().subscribe(result => {
        //    if (result) {

        //    }
        //})
    }

    emailCustomer(id) {
        //var emailSendingParam = new EmailSendingParam();
        //emailSendingParam.fromName = this.authService.getUserName();
        //emailSendingParam.from = "post@vibesmalerfirma.dk";
        //emailSendingParam.to = this.SupplierDetail.email;
        //emailSendingParam.moduleName = "Supplier";
        //emailSendingParam.moduleID = this.SupplierDetail.id;
        //emailSendingParam.isLoadDefaultEmailTemplate = true;
        //const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
        //    height: '90%',
        //    width: '90%',
        //    data: emailSendingParam
        //});
        //dialogRef.afterClosed().subscribe(result => {
        //    if (result != null && result != '') {
        //        var tempLogStatusToSendEmail = new EmailSendingParam();
        //        tempLogStatusToSendEmail = result;
        //        // var ProjectRequisitionStatusLogModel = new ProjectRequisitionStatusLog();
        //        // ProjectRequisitionStatusLogModel.createdBy = this.authService.getCoWorkerId();
        //        // ProjectRequisitionStatusLogModel.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.EmailSentFromCustomer;
        //        // ProjectRequisitionStatusLogModel.status = LogStatusModuleName.Customer;
        //        // ProjectRequisitionStatusLogModel.debitorID = this.CustomerObject.id;
        //        // tempLogStatusToSendEmail.ProjectRequisitionStatusLogModel = ProjectRequisitionStatusLogModel;
        //        this.customerService.SendEmail<ResponseBase>(tempLogStatusToSendEmail)
        //            .subscribe({
        //                next: (response: ResponseBase) => {
        //                    this.showSpinner = false;
        //                    this.translate.get("MESSAGES.EmailSent").subscribe({
        //                        next: (res) => {
        //                            this.notificationsService.create(res);
        //                        },
        //                        error: err => {
        //                        }
        //                    });
        //                },
        //                error: err => {
        //                    this.showSpinner = false;
        //                    this.translate.get("MESSAGES.Failed").subscribe({
        //                        next: (res) => {
        //                            this.notificationsService.error(res);
        //                        },
        //                        error: err => {
        //                        }
        //                    });
        //                },
        //                complete: () => {
        //                }
        //            });
        //    }
        //});
    }

    TabChange(event) {
        this.selectedIndex = event.index;
        if (event.index == 1) {
            this.storeFileFolderPathData();
        }
        if (event.index == 2) 
            this.getNotetabData(this.tempCarId);
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: 'CarInformations', isDisplay: false },
            { name: this.carInformationDetail.vehicleNo, isDisplay: true }
        );
    }
    IsFileExist(flag) {
        this.isFile = flag;
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    ngOnBackClick() {
        this._router.navigate(['/carinformations'])
    }

    ChangeImage(event) {
        this.carInformationDetail.imageBase64 = event;
    }

    onFocusIn(event) {
        event.model.show();
    }
    isItemNumberExist(itemNumber) {
        this.carInformationsService.GetVehicleByItemNumber(itemNumber).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    var responseMaterial = response.result;
                    if (this.carInformationDetail.id != responseMaterial.id) {
                        this.IsItemNumberAlreadyExist = true;
                        this.translate.get("MESSAGES.ItemNumberExist").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                        });
                    } else {
                        this.IsItemNumberAlreadyExist = false;
                    }
                }
            },
            error: err => {
                this.IsItemNumberAlreadyExist = false;
            },
        });
    }

    getNotetabData(id) {
        this.showSpinner = true;
        this.carInformationsService.GetCarInfoNotes<any>(id, ApiConstant.RequisitionNote).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.showSpinner = false;
                this.NoteDescription = response.result.description;
                if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                    this.displayNoteTabRed = true;
                }
                else {
                    this.displayNoteTabRed = false;
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }


    openNotesForSupplier() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.NoteDescription;
        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;
            var data = new CompanyInfoSetting();
            data.Description = "";
            if (result && result.Description)
                data.Description = result.Description;
            this.carInformationsService.addUpdateNotesForCarInfo<ResponseBase>(this.tempCarId, ApiConstant.RequisitionNote, data)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.NoteDescription = response.result.description;
                        if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                            this.displayNoteTabRed = true;
                        }
                        else {
                            this.displayNoteTabRed = false;
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        });
    }

    gotoModule($event){
        this.carInformationDetail= new CarInformation();
        this._router.navigate(['/carinformations/edit', $event]);
    }

    changeMaterialWeather(weathertype){
        if(weathertype=="summerTires" && this.carInformationDetail.summerTires){
            this.carInformationDetail.allSeasonTires = false;
            this.carInformationDetail.winterTires=false;
        }
        else if(weathertype=="winterTires" && this.carInformationDetail.winterTires){
            this.carInformationDetail.allSeasonTires = false;
            this.carInformationDetail.summerTires=false
        }
        else if(weathertype=="allSeasonTires" && this.carInformationDetail.allSeasonTires){
            this.carInformationDetail.winterTires=false;
            this.carInformationDetail.summerTires=false
        }
    }

    getDeviceList() {
        this.showSpinner = true;
        this.requestBase = new BaseRequest();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100000;
        this.nanolinkService.GetDeviceList(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.deviceList = response.result;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.deviceList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    setInactive(value) {
        this.formService.markFormGroupTouched(this.carInformationFrom);
        this.carInformationDetail.isActive = value;
        this.Update();
    }

    openLaLongDialog() {
        const data = {
            lat: this.carInformationDetail.lat ? this.carInformationDetail.lat : null,
            long: this.carInformationDetail.long ? this.carInformationDetail.long : null
        }
        const dialogRef = this.dialog.open(LatLongModalComponent, {
            width: '30%',
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.carInformationDetail.lat = result.lat;
                this.carInformationDetail.long = result.long;
            }
        });
    }

}