import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { SettingsService } from '../../settings.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from "app/main/model/ResponseBase";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { Settings, SettingForUnicontaValue } from "app/main/model/Settings";
import { MatDialog } from '@angular/material';
import { AddUnicontaCredentialComponent } from '../add-uniconta-credential/add-uniconta-credential.component';

@Component({
    selector: 'app-credintial-uniconta',
    templateUrl: './credintial-uniconta.component.html',
    styleUrls: ['./credintial-uniconta.component.scss']
})
export class CredintialUnicontaComponent implements OnInit {
    @Input("commonSettings") commonSettings: Settings;
    showSpinner: boolean = false;
    generalForm: FormGroup;
    settingMasterValues: any[] = [];
    credintialObj: Settings;
    settingValue: SettingForUnicontaValue = new SettingForUnicontaValue();
    projectSettings: any;
    path: string = "";
    ProjectInvoicePathObj: Settings;
    unicontaCredentialObj: Settings;
    unicontaCredentialList: any[] = [];
    materialList: any[] = [];
    coworkersList: any[] = [];
    selectUnicontaUser: string = "";
    customerVATOption: string = "";
    customerVATOptionList: any[] = [];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private fb: FormBuilder,) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.materialList = JSON.parse(localStorage.getItem("Materials"));
        this.coworkersList = JSON.parse(localStorage.getItem("LocalResponseModel")).Coworkers;
        this.credintialObj = new Settings();
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.generalForm = this.fb.group({
            UserName: [null],
            Password: [null],
            AccessId: [null],
            ComapnyName: [null],
            ComapnyId: [null],
            IsIntegrateUniconta: [false],
            Customer: [false],
            Material: [false],
            ProjectInvoice: [false],
            Supplier: [false],
            FinancialDetail: [false],
            IsExecuteAconto: [false],
            IsAcontoGenerateTXTFile: [false],
            AcontoFilePath: [null],
            IsAcontoGenerateSalesOrders: [false],
            IsAcontoGenerateInvoices: [false],
            AcontoGenerateOrders: ["IsAcontoGenerateSalesOrders"],
            AcontoAmountToGenerateInvoice: [null],
            AcontoMoreAmountToGenerateInvoice: [null],
            AcontoInvoiceMaterialID: [null],
            AcontoInvoiceGenerateTime: [null],
            AcontoInvoiceListSendCoworkerIDS: [null],
            CustomerVATOption: [null],
            GeneralGenerateOrders: ["IsAcontoGenerateSalesOrders"],
            IsGeneralGenerateTXTFile: [false],
            GeneralFilePath: [null],
            IsGeneralGenerateSalesOrders: [false],
            IsGeneralGenerateInvoices: [false],
        });
        this.setSettings();
        this.setSettingsUnicontaCredentialList();
        //this.getSettingsForProjectEconomyDetails();
    }

    setSettings() {
        if (this.commonSettings && this.commonSettings != null) {
            this.credintialObj = this.commonSettings;
            this.settingValue = JSON.parse(this.commonSettings.value);
            this.selectUnicontaUser = this.settingValue.ComapnyId;
            this.generalForm.patchValue(this.settingValue);
            this.changeInLocalStorage(this.credintialObj.id, this.credintialObj);
            this.setRadioValue();
        }
    }

    onSubmit() {
        this.generalForm.markAllAsTouched();
        if (this.generalForm.valid) {
            this.changeSettings();
        }
    }

    setRadioValue() {
        if (this.generalForm.get("AcontoGenerateOrders").value == "IsAcontoGenerateSalesOrders") {
            this.generalForm.get('IsAcontoGenerateSalesOrders').setValue(true);
            this.generalForm.get('IsAcontoGenerateInvoices').setValue(false);
        } else if (this.generalForm.get("AcontoGenerateOrders").value == "IsAcontoGenerateInvoices") {
            this.generalForm.get('IsAcontoGenerateSalesOrders').setValue(false);
            this.generalForm.get('IsAcontoGenerateInvoices').setValue(true);
        } else {
            this.generalForm.get("AcontoGenerateOrders").setValue("IsAcontoGenerateSalesOrders");
            this.generalForm.get('IsAcontoGenerateSalesOrders').setValue(true);
            this.generalForm.get('IsAcontoGenerateInvoices').setValue(false);
        }

        if (this.generalForm.get("GeneralGenerateOrders").value == "IsGeneralGenerateSalesOrders") {
            this.generalForm.get('IsGeneralGenerateSalesOrders').setValue(true);
            this.generalForm.get('IsGeneralGenerateInvoices').setValue(false);
        } else if (this.generalForm.get("GeneralGenerateOrders").value == "IsGeneralGenerateInvoices") {
            this.generalForm.get('IsGeneralGenerateSalesOrders').setValue(false);
            this.generalForm.get('IsGeneralGenerateInvoices').setValue(true);
        } else {
            this.generalForm.get("GeneralGenerateOrders").setValue("IsGeneralGenerateSalesOrders");
            this.generalForm.get('IsGeneralGenerateSalesOrders').setValue(true);
            this.generalForm.get('IsGeneralGenerateInvoices').setValue(false);
        }
    }

    changeSettings() {
        this.showSpinner = true;
        this.setRadioValue();
        this.credintialObj.value = JSON.stringify(this.generalForm.value);
        this.credintialObj.isDefaultMapper = true;
        this.unicontaCredentialList = this.unicontaCredentialList.map(o => o.ComapnyId == this.generalForm.get('ComapnyId').value ? this.generalForm.value : o);
        this.unicontaCredentialObj.value = JSON.stringify(this.unicontaCredentialList);
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.credintialObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.credintialObj = response.result;
                    this.updateettingsUnicontaCredentialList(this.unicontaCredentialObj);
                    this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Credential", this.commonSettings.key2, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.credintialObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.credintialObj.value)
                    this.generalForm.patchValue(this.settingValue);
                    this.changeInLocalStorage(this.credintialObj.id, this.credintialObj);
                    this.setSettings();
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    getSettingsForProjectEconomyDetails() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "ProjectInvoicePath", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.ProjectInvoicePathObj = response.result.myResponse[0];
                    this.path = response.result.myResponse[0].value;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    addNewCompany() {
        const dialogRef = this.dialog.open(AddUnicontaCredentialComponent, {
            width: '40%',
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setSettingsUnicontaCredentialList();
            }
        });
    }

    updateettingsUnicontaCredentialList(obj) {
        this.showSpinner = true;
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(obj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    setSettingsUnicontaCredentialList() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Credential", 'UnicontaCredentialList', null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.unicontaCredentialObj = response.result.myResponse[0];
                    this.unicontaCredentialList = JSON.parse(this.unicontaCredentialObj.value);
                    this.GetCustomerVATOptionList();
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    GetCustomerVATOptionList() {
        var selectedCred = this.unicontaCredentialList.find(o => o.ComapnyId == this.selectUnicontaUser)
        this.settingsService.GetCustomerVATOptionList<ResponseBase>(selectedCred).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.customerVATOptionList = response.result;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    onChangeUser(event) {
        if (this.unicontaCredentialList && event && event.MyReturnVal) {
            this.generalForm.reset();
            const selectedUser = this.unicontaCredentialList.find(u => u.ComapnyId === event.MyReturnVal);
            this.selectUnicontaUser = selectedUser.ComapnyId;
            //selectedUser.IsIntegrateUniconta = this.generalForm.get("IsIntegrateUniconta").value
            this.generalForm.patchValue(selectedUser);
            this.GetCustomerVATOptionList();
        }
    }

    onChangeVAT(event) {
        this.customerVATOption = event.MyReturnVal;
    }
}
