export const locale = {
    lang: 'en',
    data: {
        'TITLE': {

        },
        'FIELDS': {
            "Role": "Roll",            
            "Assets_View": "Assets View",
            "Assets_Modify": "Assets Modify",
            "Assets_Delete": "Assets Delete",
            "CarInfo_View": "CarInfo View",
            "CarInfo_Modify": "CarInfo Modify",
            "CarInfo_Delete": "CarInfo Delete",
            "CompanyInfo_View": "CompanyInfo View",
            "CompanyInfo_Modify": "CompanyInfo Modify",
            "CompanyInfo_Delete": "CompanyInfo Delete",
            "Coworker_View": "Coworker View",
            "Coworker_Modify": "Coworker Modify",
            "Coworker_Delete": "Coworker Delete",
            "Customer_View": "Customer View",
            "Customer_Modify": "Customer Modify",
            "Customer_Delete": "Customer Delete",
            "Dashboard_View": "Dashboard View",
            "Document_View": "Document View",
            "Document_Modify": "Document Modify",
            "Document_Delete": "Document Delete",
            "Inquiry_View": "Inquiry View",
            "Inquiry_Modify": "Inquiry Modify",
            "Inquiry_Delete": "Inquiry Delete",
            "LiveMap_View": "LiveMap View",
            "Material_View": "Material View",
            "Material_Modify": "Material Modify",
            "Material_Delete": "Material Delete",
            "NanoChips_View": "NanoChips View",
            "NanoChips_Modify": "NanoChips Modify",
            "NanoChips_Delete": "NanoChips Delete",
            "Offer_View": "Offer View",
            "Offer_Modify": "Offer Modify",
            "Offer_Delete": "Offer Delete",
            "Payroll_View": "Payroll View",
            "Payroll_Modify": "Payroll Modify",
            "Payroll_Delete": "Payroll Delete",
            "Planning_View": "Planning View",
            "Planning_Modify": "Planning Modify",
            "Planning_Delete": "Planning Delete",
            "Project_View": "Project View",
            "Project_Modify": "Project Modify",
            "Project_Delete": "Project Delete",
            "QualityTest_View": "QualityTest View",
            "QualityTest_Modify": "QualityTest Modify",
            "QualityTest_Delete": "QualityTest Delete",
            "QuickInvoice_View": "QuickInvoice View",
            "QuickInvoice_Modify": "QuickInvoice Modify",
            "QuickInvoice_Delete": "QuickInvoice Delete",
            "Reminder_View": "Reminder View",
            "Reminder_Modify": "Reminder Modify",
            "Reminder_Delete": "Reminder Delete",
            "CoworkerReport_View": "CoworkerReport View",
            "Report_View": "Report View",
            "MaterialReport_View": "MaterialReport View",
            "Setting_View": "Setting View",
            "Statistics_View": "Statistics View",
            "StatisticsQuestion_View": "StatisticsQuestion View",
            "Supplier_View": "Supplier View",
            "Supplier_Modify": "Supplier Modify",
            "Supplier_Delete": "Supplier Delete",
            "TermsofEmployment_View": "TermsofEmployment View",
            "TermsofEmployment_Modify": "TermsofEmployment Modify",
            "TermsofEmployment_Delete": "TermsofEmployment Delete",
            "WorkHours_View": "WorkHours View",
            "WorkHours_Modify": "WorkHours Modify",
            "WorkHours_Delete": "WorkHours Delete",
            "Own_Project_View": "Own Project",
            "Own_Planning_View": "Own Planning",
            "Economy_View": "Economy View",
            "Planning_Leave_Approve": "Leave Approve",
            "Change_Role": "Change Role",
            "PurchasePrice_View": "PurchasePrice View",
            "Coworker_View_Files": "View Files",
            "Coworker_View_Other_Fields": "View Other Fields",
            "Coworker_View_Keys": "View Keys",
            "Own_Payroll_View": "Own Payroll",
            "Own_Workhour_View": "Own Workhour",
            "Upload_Material_Price": "Upload Material Price **",
            "Material_Barcode_Size": "Material Barcode Size **",
            "MaterialList_Order_Tab": "MaterialList Order Tab **",
            "MaterialList_Ordered_Tab": "MaterialList Ordered Tab **",
            "MaterialList_SoldStock_Tab": "MaterialList Sold Stock Tab **",
            "MaterialList_Log_Tab": "MaterialList Log Tab **",
            "Coworker_CPR_View": "Coworker CPR View",
            "Coworker_Answer_View": "Coworker Answer View",
            "View_Email_SMS_Notification_Settings": "View Email/SMS/Notification Settings",
            "Project_Requisition_Modify": "Requisition Modify",
            "Project_Requisition_Delete": "Requisition Delete",
            "Project_Material_Modify": "Material Modify",
            "Project_Material_Delete": "Material Delete",
            "Project_Coworker_Modify": "Coworker Modify",
            "Project_Coworker_Delete": "Coworker Delete",
            "WorkHours_Leave_Approve": "Leave Approve",
            "Material_Calculation_Container": "Material Calculation Container **",
            "View_Supplier_Quick_Material": "View Supplier Quick Material",
            "Customer_Project_Tab": "Project Tab",
            "Customer_Files_Tab": "Files Tab",
            "Customer_Log_Tab": "Log Tab",
            "Customer_ColorCode_Tab": "ColorCode Tab",
            "Customer_Message_Log_Tab": "Message Log Tab",
            "Customer_Properties_Tab": "Properties Tab",
            "Customer_Materials_Tab": "Materials Tab",
            "Customer_Project_Recuring_Settings_Tab": "Project Recuring Settings Tab",
            "Project_Files_Tab": "Files Tab",
            "Project_Workhours_Tab": "Workhours Tab",
            "Project_Log_Tab": "Log Tab",
            "Project_Statistic_Tab": "Statistic Tab",
            "Project_Note_Tab": "Note Tab",
            "Project_Message_Log_Tab": "Message Log Tab",
            "Project_Room_Building_Tab": "Room Building Tab",
            "Project_Quality_Test_Tab": "Quality Test Tab",
            "Project_Location_Tab": "Location Tab",      
            "Material_Files_Tab": "Material Files Tab",
            "Material_SalesHistory_Tab": "Material Sales History Tab",
            "Material_OrderHistory_Tab": "Material Order History Tab",
            "Material_Log_Tab": "Material Log Tab",
            "Material_Offer_Tab": "Material Offer Tab",         
        },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
            'Notes': 'Permissions are only used in the web app.'
        },
        'BUTTONS': {
            'Save': 'Save',
            'ModifyRoles': 'Modify Roles',
        }
    }
};