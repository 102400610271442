export const locale = {
    lang: 'ds',
    data: {
        'Title': {
            'NewMaterial': 'Nyt materiale',
            'TITLE': 'Materiale',

        },
        'BUTTONS': {
            'NEWCUSTOMER': 'Ny Kunde',
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
            'CREATE_FOLDER': 'Opret mappe',
            'DOWNLOAD': 'Hent',
            'DELETE': 'Slet',
            'ADDSTOCK': 'Tilføje Beholdning.',
            'ADDOFFER': 'Tilføj tilbud',
            'REMOVEOFFER': 'Fjern tilbud',
            'Return': 'retur',
            'PriceTag': 'Prisskilt',
            'Copy': 'Kopi',
            'WebUrlLink': 'Web URL-link',
        },
        'FIELDS': {
            'CaseNo': 'Sags nummer',
            'ItemNo': 'Vare nummer',
            'Number': 'Beholdning',
            'Text': 'Tekst',
            'OfferText': 'Tilbudsnavn',
            'FileUpload': 'Adresse',
            'Active': 'Indstil som inaktiv',
            'RetailPrice': 'Udsalgspris',
            'PurchasePrice': 'Indkøbspris',
            'Unit': 'Enhed',
            'InternalText': 'Intern tekst',
            'ShelfNo': 'Hylde nr.',
            'Rows': 'Række',
            'AutomaticallyCreate': 'Opret automatisk',
            'PersonalPrice': 'Personale pris',
            'SalesPrice': 'Udsalgspris',
            'EnterStock': 'Indtast Holdings',
            'PurchaseQuantity': 'Indkøbsmængde',
            'Supplier': 'Leverandør',
            'SupplierMaterialNo': 'Leverandør varenummer',
            'MinInventory': 'Min Beholdning',
            'MaxInventory': 'Max Beholdning',
            'RequiredDetail': 'Farvekode påkrævet',
            'NoStockFeatures': 'Ingen Lagertræk',
            'NotStockList': 'Medtag ikke på lagerliste',
            'ColorCode': 'Farvekode',
            'Barcode': 'Rediger Stregkode',
            'MaterialsForTheCase': 'Materialer Til Sagen',
            'Wages': 'Arbejdsløn',
            'CalculateSalesPrice': 'Beregn Udsalgspris',
            'Linetype': 'Linjetype',
            'Reminder': 'Påmindelses',
            'PriceSignWithBuyQuantity': 'Prisskilt Med Køb Antal',
            'OnlySummer': "Kun Sommer",
            "WeatherType": "Vejrtype",
            "All": "Alle",
            "IsPostNumberDiscount": "Post Nr. Tillæg",
            "Yellow": "Gul",
            "Blue": "Blå",
            "Materialtype": 'Vare type',
            'MaterialSoldOnThisYear': 'Materiale solgt i år',
            'IsDisplayWebshop': 'Er DisplayWebshop',
            'DuplicateEntry':'Dublet indtastning',
            'CheckPurchasePrice':'Check Indkøbspris',
            'PurchasepriceSalesUnit':'Indkøbspris pr. salgsenhed',
            'MaxOrderQty':'Max order antal',
            'PurchasingUnit': 'Indkøbsenhed',
            'IsSupplierQuickMaterial': 'Tilføj hurtigt materiale til projektet',
            'PriceForBucket': 'Pris for 1 spand',
        },
        'Headers': {
            'Ok': 'Gem',
            'Cancel': 'Gå tilbage',
            'NewMaterial': 'Nyt Materialer',
            'Material': 'Materialer',
            'NoFileSelected': 'Ingen fil valgt',
            'ChooseFile': 'Vælg fil',
            'FOLDEREMPTY': 'Denne folder er tom',
            'DeleteOffer': 'Er du sikker på, at du vil slette tilbud.',
            'DeleteOfferPrice': 'Er du sikker på, at du vil slette pris.',
            'Barcode': 'Stregkode',
            'Delete': 'Er du sikker på, at du vil slette dette materiale fra databasen ?'
        },
        'VALIDATIONS': {
            'ITEMNUMBER': 'Vare nummer påkrævet',
            'TEXT': 'Tekst påkrævet',
            'BEHOLDING': 'Beholdning påkrævet',
            'ITEMNUMBEREXIST': 'Nummeret eksisterer allerede',
            'ITEMNUMBERMIN': 'Gyldigt nummer påkrævet',
            'ITEMNUMBERMAX': 'Gyldigt nummer påkrævet',
            'BEHOLDINGMIN': 'Enter Valid Number',
            'BEHOLDINGMAX': 'Enter Valid Number',
            'Barcode': 'Samme stregkode findes',
            'Unit': 'Enhed påkrævet',
            'PersonalPrice': 'Personale pris påkrævet',
            'RetailPrice': 'Udsalgspris påkrævet',
            'PurchasePrice': 'Indkøbspris påkrævet',
            'Supplier': 'Vælg Leverandør',
            'MinInventory': 'Min Beholdning påkrævet',
            'MaxInventory': 'Max Beholdning påkrævet',
            'MinPrice': 'prisen skal være {0} % højere af købsprisen. ( Mere end {1} )',
            'LineType': 'Linjetype påkrævet',
            'Materialtype': 'Vare type påkrævet',
            'WeatherType': 'Vejrtype påkrævet',
            'PurchaseQuantity': 'Indkøbsmængde påkrævet',
            'SupplierMaterialNo': 'Leverandør varenummer påkrævet',
            'PurchasingUnit': 'Indkøbsenhed påkrævet',
            'ShelfNo': 'Hylde nr påkrævet',
        },
        'Tabs': {
            'BasicDetail': 'Oplysninger',
            'Files': 'Filer',
            'Offer': 'Tilbud',
            'OfferFiles': 'Tilbud Filer',
            'OfferText': 'Tilbud Tekst',
            'ProjectsMaterial': 'Projekt Lager',
            'OfferItemCalculation': 'TilbudsVare Beregning',
            'MaterialOrderHistory': 'Materiale bestillings historie',
            'Log': 'Log',
        },
        'PlaceHolder': {
            'DeleteAlert': 'Er du sikker du på vil slette?'
        },
        'MESSAGES': {
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'Created': 'Oprettet succesfuldt.',
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'mislykkedes',
            'OfferDelMessage': 'Tilbuddet er slettet.',
            'OfferPriceDel': 'Pris slettet med succes.',
            'OfferDelFail': 'Slet ikke tilbudet.',
            'OfferPriceDelFail': 'Kunne ikke slette prisen.',
            'Deleted': 'Slettet med succes',
            'Barcode': 'Stregkode findes allerede.',
            'SameMaterialBarcode': 'Stregkode er af samme materiale.',
            'ItemNumberExist': 'Nummeret eksisterer allerede',
            'CheckPurchasePrice': 'Check Indkøbspris',
            'WarningForAddingMaterial': 'Advarsel hvis antal er større end.',
            'NoteForOrderSupplier': 'Bemærkning til bestiling af varer.',
        },
        'unitList': {
            'value1': 'stk.',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Rulle',
            'value5': 'M2',
            'value6': 'KG'
        },
        'linetype': {
            'value1': 'Løn',
            'value2': 'Tekst',
            'value3': 'Vare'
        },
        'ToolTip': {
            'ShowSktToPDF': 'stk. Antal vil blive tilføjet på tilbuds PDF.'
        },
        'purchasingUnitList': {
            'value1': 'stk.',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Rulle',
        },
        
    }
};
