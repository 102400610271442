// angular 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// third party tool
import { TranslateService } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { SuppliersService } from 'app/main/pages/suppliers/suppliers.service';
type AOA = any[][];

@Component({
  selector: 'app-supplier-upload',
  templateUrl: './supplier-upload.component.html',
  styleUrls: ['./supplier-upload.component.scss']
})
export class SupplierUploadComponent implements OnInit {
  selectedFiles: any[] = [];
  showSpinner: boolean = false;
  sampleFileUrl: any;
  data: any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  supplierUploadList: any = [];
  //#endregion Variables

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private http: HttpClient,
    private SuppliersService: SuppliersService,
  ) { this.translationLoader.loadTranslations(english, danish); }

  ngOnInit() {
    this.sampleFileUrlInit();
  }

  OnuploadFile(event) {
    if (event.target.files.length > 0)
      this.selectedFiles = event.target.files;
  }

  sampleFileUrlInit() {
    this.sampleFileUrl = environment.url + "Upload/SupplierUpload/Sample-SupplierUpload.xlsx";
  }

  downloadSampleFile() {
    this.http.get(this.sampleFileUrl, { responseType: "blob" }).subscribe((res: any) => {
      const url = window.URL.createObjectURL(res);
      var download = document.createElement("a");
      download.href = url;
      download.download = 'Sample-SupplierUpload' + '.xlsx';
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0
        , false, false, false, false, 0, null
      );
      download.dispatchEvent(event);
    });
  }

  onFileChange(evt: any) {
    const supplierList: any[] = [];
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      const headerRow = this.data[0];
      this.data = this.data.slice(1);
      const columnMapping: { [key: string]: number } = {
        "Nummer": headerRow.indexOf("Nummer"),
        "LeverandorNavn": headerRow.indexOf("LeverandorNavn"),
        "Kontaktperson": headerRow.indexOf("Kontaktperson"),
        "Email": headerRow.indexOf("Email"),
        "Telefon": headerRow.indexOf("Telefon"),
        "Hjemmeside": headerRow.indexOf("Hjemmeside"),
        "AppURLlink": headerRow.indexOf("AppURLlink"),
        "Adresse": headerRow.indexOf("Adresse"),
        "Postnummer": headerRow.indexOf("Postnummer"),
        "By": headerRow.indexOf("By"),
        "Notat": headerRow.indexOf("Notat"),
      };

      this.data.forEach((res) => {
        if (res[0] !== "no" && res.some((element) => element !== "")) {
          const supplier = {
            "nummer": res[columnMapping["Nummer"]],
            "leverandorNavn": res[columnMapping["LeverandorNavn"]],
            "kontaktperson": res[columnMapping["Kontaktperson"]],
            "email": res[columnMapping["Email"]],
            "telefon": res[columnMapping["Telefon"]] ? res[columnMapping["Telefon"]].toString() : null,
            "hjemmeside": res[columnMapping["Hjemmeside"]],
            "appUrlLink": res[columnMapping["AppURLlink"]],
            "adresse": res[columnMapping["Adresse"]],
            "postnummer": res[columnMapping["Postnummer"]] ? res[columnMapping["Postnummer"]].toString() : null,
            "by": res[columnMapping["By"]],
            "notat": res[columnMapping["Notat"]],
            "isInsertedStatus": null
          };

          if (Object.values(supplier).some((value) => value !== "")) {
            supplierList.push(supplier);
          }
        }
      });
      this.supplierUploadList = supplierList;
    };

    reader.readAsBinaryString(target.files[0]);

  }

  Upload() {
    this.showSpinner = true;
    this.SuppliersService.CreateSuppliersFromExcel<ResponseBase>(this.supplierUploadList)
    .subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.translate.get("MESSAGES.Updated").subscribe({
          next: (res) => {
            this.notificationsService.success(res);
          },
          error: err => {
          }
        });
        this.supplierUploadList= response.result;
      },
      error: err => {
        this.showSpinner = false;
        this.notificationsService.error(err.error.message);
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }
}
