import { Component, OnInit, Input, Inject, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestProject } from 'app/main/model/RequestBase';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router } from '@angular/router';

@Component({
  selector: 'vibe-customer-project-colorcode',
  templateUrl: './customer-project-colorcode.component.html',
  styleUrls: ['./customer-project-colorcode.component.scss']
})

export class CustomerProjectColorCodeComponent implements OnInit {
  @Input() customerId: any;
  projects = [];
  showSpinner = false;
  After5DayDate: string = "";
  TodayDate: string = "";
  showBackbtn: boolean = false;
  search: any = '';
  orderBy: string = "CreatedDate DESC";
  tempProjectList = [];
  requestProjectDetailWithColorCode: RequestProject = new RequestProject();
  displayPagination: boolean;
  pagination: any;
  constructor(private translationLoader: FuseTranslationLoaderService,
    private customerService: CustomersService,
    public dialog: MatDialog,
    private _router: Router,
    @Optional() public dialogRef: MatDialogRef<CustomerProjectColorCodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    if (this.data && this.data.customerId) {
      this.customerId = this.data.customerId;
      this.showBackbtn = true;
    }
    if (this.customerId){
      this.requestProjectDetailWithColorCode.OrderBy = this.orderBy; 
      this.GetProjectsByCustomer(this.customerId, this.requestProjectDetailWithColorCode);
    }
  }

  GetProjectsByCustomer(id: any, model: RequestProject) {
    this.showSpinner = true;
    model.IsActive = false;
    model.IsDeleted = false;
    model.CustomerId = id;
    this.customerService.GetProjectDetailWithColorcode<ResponseBase>(id, model)
      .subscribe((response: ResponseBase) => {
        if (response != null) {
          this.projects = response.result;
          this.pagination = response.pagination;
          this.displayPagination = true;          
          this.showSpinner = false;
        }
        else {
          this.showSpinner = false;
          this.projects = [];
          this.displayPagination = false;
        }
      },
        error => (err) => {
          this.projects = [];
          this.showSpinner = false;
          this.displayPagination = false;
        },
        () => {
          this.showSpinner = false;
        });
  }

  // getColorCodeString(value) {
  //   if (value)
  //     return value.indexOf(',') == 0 ? value.substring(1) : value;
  // }

  onCloseDialog() {
    this.dialogRef.close(null)
  } 

  gotoProject(project) {
    this._router.navigate(['/projects/edit', project.sagId], { queryParams: { isActive: project.isActive } });
  }

  SearchByName(value) {   
    if (value || value == "") {
      this.requestProjectDetailWithColorCode.Address = value.trim();
      this.requestProjectDetailWithColorCode.Index = 1;
      this.GetProjectsByCustomer(this.customerId, this.requestProjectDetailWithColorCode);
    }
  }

  receiveFilterEvent(event) {
    this.requestProjectDetailWithColorCode.OrderBy = event;
    this.GetProjectsByCustomer(this.customerId, this.requestProjectDetailWithColorCode);
  }

  receivePaginatonEvent(event) {
    this.requestProjectDetailWithColorCode.Index = event.pageIndex;
    this.requestProjectDetailWithColorCode.Limit = event.pageSize;
    this.GetProjectsByCustomer(this.customerId, this.requestProjectDetailWithColorCode);
  }
}