import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue } from "app/main/model/Settings";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "vibe-payment-salary-calculation",
    templateUrl: "./payment-salary-calculation.component.html",
    styleUrls: ["./payment-salary-calculation.component.scss"]
})

export class PaymentSalaryCalculationComponent implements OnInit {
    @Input("commonSettings") commonSettings: Settings;
    @Input("CoWokers") CoWokers: CoWorker[];

    showSpinner: boolean = false;
    generalForm: FormGroup;
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    IsGettingCoWokers: boolean = false;
    commonObj: Settings;
    settingMasterValues: any[] = [];
    settingValue: SettingValue = new SettingValue();
    IsEnable: boolean = true;
    paymentObj: Settings[];
    SendReminderSMSSettingObject: Settings
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        // private coWorkersService: CoWorkersService,
        // private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.generalForm = this.fb.group({
            EmployerNo: [null],
            SalaryPeriod14Days: [null],
            PayrollMonth: [null],
            Group: [null],
            HourlyFigures: [null],
            HolidayEligibleSalary: [null],
            OvertimeReplacement: [null],
            SalaryAdvance: [null],
            SeniorityAllowance: [null],
            Km: [null],
            KmRate: [null],
            GDayNumber: [null],
            GDayTotal: [null],
            HolidayDays: [null],
            BIncome: [null]
        });
        this.setSettings();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    onSubmit() {
        this.generalForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.generalForm.valid) {
            this.updateSettings()
        }
    }

    setSettings() {
        if (this.commonSettings && this.commonSettings != null) {
            this.commonObj = this.commonSettings;
            this.settingValue = JSON.parse(this.commonSettings.value);
            this.generalForm.patchValue(this.settingValue)
            this.changeInLocalStorage(this.commonObj.id, this.commonObj)
        }
    }

    updateSettings() {
        this.showSpinner = true;
        this.commonObj.value = JSON.stringify(this.generalForm.getRawValue());
        this.commonObj.isDefaultMapper = true;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.commonObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.commonObj = response.result;
                    this.settingValue = JSON.parse(this.commonObj.value)
                    this.generalForm.patchValue(this.settingValue)
                    this.changeInLocalStorage(this.commonObj.id, this.commonObj)
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }
}