export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'Add': 'Oprettet',
    },
    'BUTTONS': {
      'Back': 'Gå tilbage',
      'Ok': 'Gem',
      'SaveAndClose': 'Gem og luk'
    },
    'FIELDS': {
      'subject': 'Emne',
    },
    'VALIDATIONS': {
      'subject': 'Gyldigt emne',
    },
    'Note':{
      'BatterynotificationEmailTemplateNote':'Fjern venligst ikke @BatteryLowDeviceList fra Email skabelon tekst.',
      
      'PlanningEmailTemplateNote':'Fjern venligst ikke @UnPlannedCoworkerList fra  Email skabelon tekst.',

      'AnniversaryEmailTextCoworkerNote':'Fjern venligst ikke @CoworkerName, @Date og @AnniversaryYear fra  Email skabelon tekst.',
      'AnniversaryEmailTextOtherCoworkerNote':'Fjern venligst ikke @CoworkerNames, @Date og @AnniversaryCoworkerNames fra Email skabelon tekst.',
      'AnniversaryEmailTextOthersBeforeXDaysNote':'Fjern venligst ikke @CoworkerNames, @Date og @AnniversaryCoworkerNames fra Email skabelon tekst.',

      'BirthdayEmailTextCoworkerNote':'Fjern venligst ikke @CoworkerName fra Email skabelon tekst.',
      'BirthdayEmailTextOtherCoworkerNote':'Fjern venligst ikke @CoworkerNames fra Email skabelon tekst.',
      'BirthdayEmailTextOthersBeforeXDaysNote':'Fjern venligst ikke @BirthDate og @CoworkerNames fra Email skabelon tekst.',

      'CoWorkerJoiningEmailTextCoworkerNote':'Fjern venligst ikke @JoiningCoworkerNames og @JoinDate fra Email skabelon tekst.',
      'CoWorkerJoiningEmailTextOtherCoworkerNote':'Fjern venligst ikke @CoworkerName,@JoiningCoworkerNames og @JoinDate fra Email skabelon tekst.',

      'InquiryEmailTextLabel': 'Fjern venligst ikke @CustomerName, @InquiryDate, @InquiryNumber og @Address fra Email skabelon tekst.',
      'PlanningEmailTextLabel': 'Fjern venligst ikke @LeaveReason, @CoworkerName, @LeaveType, @LeaveDate og @LeaveTime fra Email skabelon tekst.',
      'OfferEmailTextLabel': 'Fjern venligst ikke @CoworkerName , @CustomerName, @OfferTotal, @OfferNumber og @OfferAddress fra Email skabelon tekst.',
      'OfferAcceptedEmailTextLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber, @Name, @Address, @ZipCode, @City, @Phone og @WWW fra Email skabelon tekst.',
      'OfferRejectedEmailTextLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber, @Name, @Address, @ZipCode, @City, @Phone og @WWW fra Email skabelon tekst.',
      'ProjectStatusBeforeSevenDaysEmailTextLabel': 'Fjern venligst ikke @Count fra Email skabelon tekst.',
      'ProjectWarningMaterialColorCodeReminderEmailTextLabel': 'Fjern venligst ikke @ProjectNumber og @ProjectAddress fra Email skabelon tekst.',
    }
  }
};
