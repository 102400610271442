export const locale = {
    lang: 'en',
    data: {
        'TABS': {
            'Economy': 'Economy',
            'OtherDetails': 'Other Details',
            'Files': 'Files',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers'
        },
        'Headers': {
            'Co-workerlist': 'Co-worker List',
            'RequisionCoworkerlist': 'Requision Co-worker List',
            'ChooseFile': 'Choose File',
        },
        'MESSAGES': {
            'Add': 'Added successfully',
            'Delete': 'Deleted successfully',
            'Add_Error': 'No record found on Vagttider or Vagtplan table, please contact to administrator.',
            'Some_Coworker_Add_Error': 'Some coworker can not add work hour.',
            'CanNotAddWorkHour': '@Coworker has already applied work time on @date',
            'Updated': 'Updated successfully',
            'Failed': 'Operation failed , try later',
            'HolidayFoundInPlanning': 'Following days: @date are public holidays. You have 3 options.',
            'CoworkerOnLeave': '@coworkerName have @statusName on @date Want to plan on this day. You have 3 options.',
            'LeftAssigment': 'Lift has been already assigned on this @date',
            'NORECORD': 'No Record Found',
            'ConfirmNoOfCoworker': 'You have selected more coworkere than you have add in Number of workflows.<br/> Are you sure you want to plan? ',
            'AlreadyProjPlanned':'This project is already scheduled on this date on this employee',
            'CannotPlanning': 'You cannot planing project without economy detail',
            'ColorCodeBlank': 'Project have not color code.',
        },
        'Button': {
            'Plan': 'Plan',
            'Cancel': 'Cancel',
            'Delete': 'Delete',
            'DeleteFutureWorkHour': 'Delete future workhour',
            'GoToProject': 'Go to project',
            'ViewCoworkerPlanning': 'View Coworker Planning',
            'Material': 'Material',
        },
        'FIELDS': {
            'Coworker': 'Coworker',
            'Project': 'Project',
            'StartDate': 'Start Date ',
            'EndDate': 'End Date ',
            'Shift': 'Period of time',
            'Not-Found-Worker': 'Co-worker not found',
            'Not-Found-Project': 'Project not found',
            'Requisition': 'Requisition',
            'EnterStarttime': 'Enter Start time',
            'EndTime': 'Enter End Time',
            'Startime': 'Startime',
            'Endtime': 'Endtime',
            'BreakTime': 'Add Break time (30 min)',
            'WorkhoursType': 'Workhours type',
            'nonStatus': 'Status',
            'nonText': 'Text',
            'WeekNumber': 'Week number',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation',
            'UrgentOrNot': 'Urgent/Not Urgent',
            'Visit': 'Visit',
            'DepartmentPhone': 'Phone',
            'Size': 'Size ',
            'IsHourlyWage': 'Is case on hourly pay',
            'Status': 'Status',
            'SRNO': 'Sr No',
            'NoOfWorkflows': 'Number of workflows',
            'IsNotifyToCustomer': 'Is Notify To Customer',
            'PlaninginWeekend':'Planning in weekend',
            'ProjectBasedPlanning': 'Project-Based Planning',
            'ColorCode':'Color Code',
            'AutomaticallyCalculated':'Not calculate date',
            'VisitDays':'Visit days',
            'SelectAll': 'Select All',
            'SearchProject': 'Search Project'
        },
        'selectedOption': {
            'value1': 'Add 07:00 to 14:30',
            'value2': 'Add 07:00 to 15:00',
            'value3': 'Add Hours Manually',
        },
        'Lable': {
            'Timer': 'Timer',
            'HoursLeft': 'Hours Left',
            'PlannedHours': 'Scheduled Hours',
            'RemainingPlannedHours': 'Remaining Plan Hour',
            'Lift': 'Left',
            'Stigemand': 'Riseman',
            'RequisitionText': 'Task text',
            'No': 'No',
            'Yes': 'Yes',
            'Estimate': 'Estimate Price',
            'Price-included': 'Price incl. VAT',
            'Price-excluded': 'Price excl. VAT',
            'VAT': 'VAT/moms',
            'MaterialTotalPrice': 'Material Total',
            'MaterialTotalPriceIncludeVAT': 'Material Total incl. VAT',
            'MaterialPersonalTotalPrice': 'Material Personal Price Total',
            'hoursOnCase': 'Hours on case',
            'noOfDays': 'No of days',
            'Hoursspent': 'Hours spent',
            'Name': 'Name',
            'Hours': 'Hours',
            'TotalHours': 'Total Hours',
            'ScheduledDates': 'Scheduled dates',
            'VisitDays':'Visit days'
        },
        'selectedWorkHourStatus': {
            'value1': 'Vacation',
            'value2': 'Sick',
            'value3': 'Holiday of the work ',
            'value4': 'Holiday with payment',
            'value5': 'Holiday without payment',
            'value6': 'School or learning',
            'value7': 'No payment for extra work but holiday for it ',
            'value8': 'Other things',
            'value9': 'Offer'
        },
        'UrgentOrNot': {
            'value0': 'select',
            'value1': 'Urgent',
            'value2': 'Not Urgent',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff Purchase', //drawing of painter
            'value6': 'Store Purchase' //Nordsjø logo
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Scheduled visit',
        //     'value3': 'Ready to boot',
        //     'value4': 'Offers sent',
        //     'value5': 'Offer approved',
        //     'value6': 'Planned',
        //     'value7': 'Offer rejected',
        //     'value8': 'Started',
        //     'value9': 'Performed',
        //     'value10': 'Billed',
        //     'value11': 'Pending',
        //     'value12': 'Ready billing',
        // }
        'selectedWeekendOption': {
            'value1': 'Without Weekend',
            'value2': 'With Weekend'
        },
        'selectedShiftOption': {
            'value1': '07:00 til 14:30',
            'value2': '07:00 til 15:00',
            'value3': 'Hours Manually',
        },
        'selectedShifttimeOption': {
            'value1': 'ShiftTime',
            'value2': 'VisitTime'
        },
    }
};
