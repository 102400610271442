export const locale = {
    lang: 'ds',
    data: {
        'FILTERS': {
            'TEXTSEARCH': 'Søg efter navn, e-mail, telefon, adresse',
        },
        'FIELDS': {
            'Not-Found-Material': 'Ingen materiale fundet',
            'Number': 'Sr.',
            'MaterialNo': 'Nummer',
            'Material': 'Materiale',
            'MaterialText': 'Vare Tekst',
            'DateonCreate': 'Dato on Createt',
            'Quantity': 'Antal',
            'Unit': 'Enhed',
            'RetailPrice': 'Udsalgs pris',
            'TotalPrice': 'Pris i alt',
            'PersonalPrice': 'Personal Pris',
            'PersonalTotalPrice': 'Personal i alt',
            'PurchaseQuantity': 'Indkøbsmængde',
            'ScanBarcode': 'Scan stregkode',
            'ItemNo': 'Vare Nr',
            'QTY': 'Antal',
            'Total': 'I alt',
            'PriceExclVAT': 'Pris excl. moms',
            'PriceInclVAT': 'Pris incl. moms',
            'ColorCode': 'Farve kode',
            'Paymentmode': 'Betalingsmåde',
            'DuplicateEntry':'Dublet indtastning',
        },
        'Headers': {
            'MaterialList': 'Materialeliste',
            'Nomaterialadded': 'Ingen materialer',
            'ScanMaterial': 'Scan materiale',
            'AddMaterial': 'Tilføj materiale',
            'AddedMaterialList': 'Tilføjet materialeliste',
            'ProjectDetail': 'Projekt detaljer',
        },
        'BUTTONS': {
            'StaffPurchase': 'Personale køb',
            'StoredPurchase': 'Butiks køb',
            'ScanMaterial': 'Scan materiale',
            'AddManualMaterial': 'Tilføj manuelt materiale',
            'AddMaterial': 'Tilføj materiale / Gem',
            'AddScanMaterial': 'Tilføj scanne materiale',
            'Add': 'Tilføj',
            'Back': 'Tilbage',
            'GenerateInvoice': 'Genere faktura',
            'ShowInvoice': 'Lav Faktura',
            'TextToInvoice': 'Tekst til faktura',//'Tekst'
        },
        'MESSAGES': {
          'NoBarcodeAttachedToMaterial': 'Ingen stregkode knyttet til noget materiale.',
          'BlankMaterial':'Farvekode mangler at blive udfyldt.',
          'PaymentModeAlert': 'Er det korrekt kunden ønsker at betale',
        },
        'paymentModeList': {
            'value1': 'Dankort',
            'value2': 'Mastercard',
            'value3': 'kontant',
            'value4': 'Mobilpay',
            'value5': 'Mobilepay KK',
            'value6': 'Kredit',
            'value7': 'Andet',
        },
        'VALIDATIONS': {
            'MaxQuantityAddMsg1': 'Er du sikker på du ønsker at tilføje @qty? ',
            'MaxQuantityAddMsg2': ' Antal du forsøger at tilføje ',
        }
    }
};
