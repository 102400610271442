export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'Add': 'Added successfully',
    },
    'BUTTONS': {
      'Back': 'Cancel',
      'Ok': 'Save',
      'SaveAndClose': 'Save & Close'
    },
    'FIELDS': {
      'subject': 'Subject',
    },
    'VALIDATIONS': {
      'subject': 'Valid Subject',
    },
    'Note':{
      'BatterynotificationEmailTemplateNote':'Please do not remove @BatteryLowDeviceList from Email template text.',

      'PlanningEmailTemplateNote':'Please do not remove @UnPlannedCoworkerList from Email template text.',
      
      'AnniversaryEmailTextCoworkerNote':'Please do not remove @CoworkerName, @Date and @AnniversaryYear from Email template text.',
      'AnniversaryEmailTextOtherCoworkerNote':'Please do not remove @CoworkerNames, @Date and @AnniversaryCoworkerNames from Email template text.',
      'AnniversaryEmailTextOthersBeforeXDaysNote':'Please do not remove @CoworkerNames, @Date and @AnniversaryCoworkerNames from Email template text.',

      'BirthdayEmailTextCoworkerNote':'Please do not remove @CoworkerName from Email template text.',
      'BirthdayEmailTextOtherCoworkerNote':'Please do not remove @CoworkerNames from Email template text.',
      'BirthdayEmailTextOthersBeforeXDaysNote':'Please do not remove @BirthDate and @CoworkerNames from Email template text.',

      'CoWorkerJoiningEmailTextCoworkerNote':'Please do not remove @JoiningCoworkerNames and @JoinDate from Email template text.',
      'CoWorkerJoiningEmailTextOtherCoworkerNote':'Please do not remove @CoworkerName, @JoiningCoworkerNames and @JoinDate from Email template text.',

      'InquiryEmailTextLabel': 'Please do not remove  @CustomerName ,@InquiryDate, @InquiryNumber and @Address from Email template text.',
      'PlanningEmailTextLabel': 'Please do not remove @LeaveReason, @CoworkerName, @LeaveType, @LeaveDate and @LeaveTime from Email template text.',
      'OfferEmailTextLabel': 'Please do not remove @CoworkerName , @CustomerName, @OfferTotal, @OfferNumber and @OfferAddress from Email template text.',
      'OfferAcceptedEmailTextLabel': 'Please do not remove @CustomerName, @OfferNumber, @Name, @Address, @ZipCode, @City, @Phone and @WWW from Email template text.',
      'OfferRejectedEmailTextLabel': 'Please do not remove @CustomerName, @OfferNumber, @Name, @Address, @ZipCode, @City, @Phone and @WWW from Email template text.',
      'ProjectStatusBeforeSevenDaysEmailTextLabel': 'Please do not remove @Count from Email template text.',
      'ProjectWarningMaterialColorCodeReminderEmailTextLabel': 'Please do not remove @ProjectNumber and @ProjectAddress from Email template text.',
    }
  }
};
