export const locale = {
    lang: 'ds',
    data: {
        'MATERIALS': {
            'TITLE': 'Materiale',
            'DESC': 'Hej materiale!'
        },
        'BUTTONS': {
            'NEWMATERIAL': 'Ny vare',
            'BACK': 'Tilbage',
            'SoldItem':'Solgte vare'
        },
        'TITLE': {
            'MaterialPagination': 'Antal materialer pr. side',
            'IsDisplayWebshop': 'Er DisplayWebshop',
            'NoStockFeatures': 'Ingen Lagertræk',
            'RequiredDetail': 'Påkrævet detalje',
            "IsPostNumberDiscount": "Post Nr. Tillæg",
            'FileExists':'Filen Findes',
            'OfferCalculatedMaterial':'Tilbyd beregnet materiale'            
        },
        'FILTERS': {
            'TEXTSEARCH': 'Søg efter, navn, nummer',
            'NUMBERSEARCH': 'Materialer nr. Søg',
            'CaseNumber': 'Sagsnummer',
            'ItemNumber': 'Varenummer',
            'Text': 'Tekst',
            'Number': 'Beholdning',
            'Picture': 'Billede',
            'Actions': 'Handlinger',
            'SORTING': 'Sorter efter',
            'PAGELABEL': 'Antal Materialer pr.side',
            'NEXT': 'Næste',
            'PREVIOUS': 'Tidligere',
            'Active': 'Inkluder alle inaktive',
            'BADGE': 'Inaktive',
            'Deleted': 'Vis slettet',
            'ShowSupplierPDF': 'Vis leverandør',
            'SendSupplierEmail': 'Send leverandør e-mail',
            'Scan': 'Scan',
            'OfferType': 'Tilbud type',
            'All': 'Alle',
            "Materialtype": 'Vare type',
            'ShowMisMatchMaterial': 'Vis Mismatch',
            'MisMatchMaterialCount': "Materialers Forkerte detalje",
            'ShowOnlyWebShopMaterial': 'Vis kun Webshop Materiale',
        },
        'Tabs': {
            'MaterialList': 'Lager liste',
            'Log': 'Log',
            'OrderItem': 'Bestil vare',
            'OrderedItem': 'Bestilte vare',
            'SoldMaterialStock': 'Solgt lager',
            'Calculation': 'Materialeberegningsbeholder'
        },
        'MESSAGES': {
            'NORECORD': 'Ingen materiale fundet',
            'NoBarcodeAttachedToMaterial': 'Ingen stregkode knyttet til noget materiale.',
        },
        'SelectedOfferCategory': {
            'Value1': 'Indendørs',
            'Value2': 'Udendørs',
            'Value3': 'Vindue',
            'Value4': 'Dør',
            'Value5': 'Sprøjtekabine',
            'Value6': 'Sprærende',
            'Value7': 'Filt',
            'Value8': 'Væv',
            'Value9': 'Rutex',
            'Value10': 'Tapet',
            'Value11': 'Paneler',
            'Value12': 'Dørkarme',
            'Value13': 'Rutex',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiatore',
            'Value17': 'Døre',
            'Value18': 'Facader',
            'Value19': 'Spartling',
            'Value20': 'Afdækning',
            'Value21': 'Lofter',
            'Value22': 'Vægge',
            'Value23': 'Garanti',
            'Value24': 'Skur vg.',
            'Value25': 'Varsling',
            'Value26': 'Gulv',
        },
        'SORTBY':
            [
                //{
                //  Text: 'No Filter Select',
                //  Value: 'ID DESC'
                //},
                {
                    Text: 'Navn (A-Z)',
                    Value: 'Text'
                },
                {
                    Text: 'Navn (Z-A)',
                    Value: 'Text DESC'
                },
                {
                    Text: 'Nummer (0-9)',
                    Value: 'Number'
                },
                {
                    Text: 'Nummer (9-0)',
                    Value: 'Number DESC'
                },
                {
                    Text: 'Hylde nr (A-Z)',
                    Value: 'ShelfNo'
                },
                {
                    Text: 'Hylde nr (Z-A)',
                    Value: 'ShelfNo DESC'
                },
                {
                    Text: 'Indkøbsmængde (0-9)',
                    Value: 'PurchaseQty'
                },
                {
                    Text: 'Indkøbsmængde (9-0)',
                    Value: 'PurchaseQty DESC'
                }
            ],
        'TOOTIPORTITLE': {
            'AddMaterialManually': 'Tilføj Materiale Manuelt',
            'AddMaterialByscanner': 'Tilføj Materiale Med Scanner',
            'SearchBarcode':'Søg stregkode',
            'UploadMaterialPriceList':'Upload Materiale Pris Liste',
            'PriceSize':'Pris Størrelse',
            'Number': 'Beholdning',
            'SoldItem': 'Solgte vare',
            'AddOfferCalculatedMaterial':'Tilføj tilbudsberegnet materiale',
        }
    }
};
