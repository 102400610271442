import { Component, OnInit, Input, Inject, Optional, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/core/services/auth.service';
import { OfferItemCalculationMaterialComponent } from '../material/material.component';
import { RequestOfferMaterialCalculation } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ProjectMaterialQuantity } from 'app/main/model/Project';
import { Material, OfferMaterialCalculation } from 'app/main/model/Material';
import { ProjectService } from 'app/main/pages/project/project.service';
import { NotificationsService } from 'angular2-notifications';
import { CopyMaterialOfferItemCalculationComponent } from '../copy-material-offer-item-calculation/copy-material-offer-item-calculation/copy-material-offer-item-calculation.component';
import { CommonService } from 'app/core/services/common.service';

@Component({
    selector: 'vibe-offer-material-list',
    templateUrl: './material-list.component.html',
    styleUrls: ['./material-list.component.scss']
})
export class MaterialListComponent implements OnInit {
    @Input("MaterialObject") OfferObjects: Material;
    @Input() offerMaterialId = "";
    @Output() updateOfferMaterial: EventEmitter<any> = new EventEmitter<any>();
    //roleName: string;
    showSpinner = false;
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    materialTotalPriceProfit: number = 0;
    materialTotalPriceProfitWithVAT: number = 0;
    purchasePriceTotal: number = 0;

    offerMaterialCalculationRequest: RequestOfferMaterialCalculation;
    offerMaterialCalculationList: any = [];
    currentselectedMatObj: OfferMaterialCalculation = new OfferMaterialCalculation();
    editedOfferMaterialObj: any = {};
    projectMaterialQuantity: ProjectMaterialQuantity;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private authService: AuthService,
        private materialService: MaterialService,
        private projectService: ProjectService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        private commonService: CommonService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.offerMaterialCalculationRequest = new RequestOfferMaterialCalculation();
        this.offerMaterialCalculationRequest.Index = 1;
        this.offerMaterialCalculationRequest.Limit = 100;
        this.offerMaterialCalculationRequest.OfferMaterialID = this.offerMaterialId;
        this.GetOfferItemCalculationList();
    }

    // getTotalPrice(qty, price) {
    //     var isNegative = false;
    //     if (qty < 0)
    //         isNegative = true;
    //     if (price < 0)
    //         isNegative = true;
    //     var total = (qty * price);
    //     if (isNegative)
    //         total = -Math.abs(total)
    //     return total;
    // }

    // editOfferMaterial(material) {
    //     this.currentselectedMatObj = new OfferMaterialCalculation();
    //     this.editedOfferMaterialObj = material;
    //     this.editedOfferMaterialObj.isRequiredText = material.material.isRequiredText;
    //     this.editedOfferMaterialObj.unit = material.material.unit;
    //     this.currentselectedMatObj = material;
    //     this.currentselectedMatObj.retailPrice = material.material.retailPrice;
    //     this.currentselectedMatObj.personalPrice = material.material.personalPrice;
    //     this.currentselectedMatObj.offersMaterialId = this.offerMaterialId;
    //     this.currentselectedMatObj.text = material.material.text;
    //     this.currentselectedMatObj.itemNumber = material.material.itemNumber;
    //     this.currentselectedMatObj.colorCode = material.colorCode;
    //     this.currentselectedMatObj.imagePath = material.material.imagePath;
    //     this.currentselectedMatObj.discountPercent = 0;
    //     this.currentselectedMatObj.offersMaterialPriceId = material.offersMaterialPriceId ? material.offersMaterialPriceId :"";
    //     this.showSpinner = false;
    //     if (this.editedOfferMaterialObj) {
    //         this.AddNewMaterials(this.currentselectedMatObj.offersMaterialPriceId)
    //     }
    // }

    // openMaterialDeleteDialog(data, materialobj) {
    //     var message = "";
    //     var materialText = materialobj.text ? materialobj.text : materialobj.material.text;
    //     this.translate.get("Headers.DeleteMaterial").subscribe({
    //         next: (res) => { message = res; }, error: err => { }
    //     });
    //     message = message.replace("@MaterialName", materialText)
    //     const dialogRef = this.dialog.open(AlertMessageModelComponent, {
    //         height: '200px',
    //         data: { Header: message }
    //     });

    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.removeOfferMaterialCalculation(materialobj.id);
    //         }
    //     });
    // }

    AddNewMaterials(obj) {
        var sendData = {};
        sendData = {
            //'roleName': this.roleName,
            'editedOfferMaterialObj': this.editedOfferMaterialObj,
            'offerMaterials': obj.materialList,
            'offerMaterialId': this.offerMaterialId,
            'offerMaterialPriceId': obj.id,
            'MaterialObject': this.OfferObjects,
            'NumberOfTimes': obj
        }

        var dialogRef = this.dialog.open(OfferItemCalculationMaterialComponent, {
            height: '90%',
            width: '90%',
            disableClose: true,
            data: sendData
        });

        dialogRef.afterClosed().subscribe(result => {
            this.editedOfferMaterialObj = {}            
            if (result && result.isUpdateOfferMaterial) {
                const $offerMaterial = result.data;
                if (this.OfferObjects.offerMaterial.offerMaterialPriceList) {
                    this.OfferObjects.offerMaterial.offerMaterialPriceList = this.OfferObjects.offerMaterial.offerMaterialPriceList.map(item =>
                        item.id === $offerMaterial.id ? $offerMaterial : item
                    );
                }
                this.updateOfferMaterial.emit(true);
            }
            else if (result && result.success)
                this.GetOfferItemCalculationList();
        });
    }

    // removeOfferMaterialCalculation(id) {
    //     this.showSpinner = true;
    //     this.materialService.removeOfferMaterialCalculation<ResponseBase>(id)
    //         .subscribe({
    //             next: (response: ResponseBase) => {
    //                 this.GetOfferItemCalculationList();
    //                 this.translate.get("MESSAGES.Deleted").subscribe({
    //                     next: (res) => {
    //                         this.notificationsService.create(res);
    //                     },
    //                     error: err => {
    //                     }
    //                 });
    //             },
    //             error: err => {
    //                 this.showSpinner = false;

    //                 this.translate.get("MESSAGES.Failed").subscribe({
    //                     next: (res) => {
    //                         this.notificationsService.error(res);
    //                     },
    //                     error: err => {
    //                     }
    //                 });
    //             },
    //             complete: () => {

    //             }
    //         });
    // }

    // calculateProjMaterialPriceTotal() {
    //     this.materialTotalPriceWithVAT = 0;
    //     this.materialPersonalTotalPrice = 0;
    //     this.materialTotalPrice = 0;
    //     this.purchasePriceTotal = 0;
    //     this.offerMaterialCalculationList.forEach((e: any) => {
    //         if (!e.totalPrice) {
    //             e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.material.retailPrice);
    //         }
    //         if (!e.personalPriceTotal) {
    //             e.personalPriceTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.material.personalPrice);
    //         }
    //         this.materialTotalPrice += e.totalPrice;
    //         if (e.material.personalPrice && e.material.personalPrice != 0) {
    //             this.materialPersonalTotalPrice += e.personalPriceTotal;
    //         }
    //         else {
    //             e.personalPrice = 0;
    //         }

    //         if (e.totalPriceVAT) {
    //             this.materialTotalPriceWithVAT += e.totalPriceVAT;
    //         } else {
    //             var vat = (e.totalPrice * 25) / 100;
    //             this.materialTotalPriceWithVAT += (e.totalPrice + vat);
    //         }
    //         if (e.material.purchasePrice) {
    //             this.purchasePriceTotal += e.material.purchasePrice;
    //         }
    //     });
    // }

    GetOfferItemCalculationList() {
        this.showSpinner = true;
        this.materialService.GetOfferItemCalculationList<ResponseBase>(this.offerMaterialCalculationRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.offerMaterialCalculationList = response.result;
                    this.showSpinner = false;
                    //this.calculateProjMaterialPriceTotal();
                    this.OfferObjects.offerMaterial.offerMaterialPriceList.filter(m => {
                        m.materialList = this.offerMaterialCalculationList.filter(x => x.offersMaterialPriceID == m.id);
                    });
                },
                error: err => { },
                complete: () => { }
            });
    }

    openCopyMaterial() {
        var dialogRef = this.dialog.open(CopyMaterialOfferItemCalculationComponent, {
            height: '20%',
            width: '60%',
            data: this.OfferObjects.offerMaterial
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                let copyMaterialList = [];
                const fromMaterialList = this.offerMaterialCalculationList.filter(x => x.offersMaterialPriceID === result.fromSelectedId);
                result.toSelectedId.forEach(toId => {
                    fromMaterialList.forEach(material => {
                        const copiedMaterial = { ...material };
                        copiedMaterial.id = null; 
                        copiedMaterial.offersMaterialPriceID = toId;  
                        copyMaterialList.push(copiedMaterial);
                    });
                });
                this.materialService.addOrUpdateOfferMaterialForCalculation<ResponseBase>(copyMaterialList)
                    .subscribe((response: ResponseBase) => {
                        this.GetOfferItemCalculationList();
                        this.translate.get("MESSAGES.Copy").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                    },
                        error => (err) => {
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                    this.getFailedTone();
                                },
                                error: err => {
                                }
                            });
                        }, () => {
                            this.showSpinner = false;
                        });

            }
        });
    }

    getFailedTone() {
        this.commonService.playFailedTone();
    }

}
