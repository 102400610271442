export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
    },
    'FIELDS':{
      'GoTo':'Se tilbud',
      'SRNO': 'Sr nr.',
      'Price': 'M2 ialt',
      'QTY': 'Antal',            
      'Total': 'Pris i alt',
      'Text': 'Tekst',
      "ShowMaterials" :"Tilbud udkast",
      'TotalExclVAT': 'Total excl. moms',
      'VAT': 'Moms',
      'TotalInclVAT': 'Total incl. moms',
      'Distance': 'Km i alt',
      'TravelingTime': 'Min. pr. vej',
      'DistancePerDay': 'Km pr. dag',
      'PerDay': 'Min pr. dag',
      'TotalTime': 'Tid i alt',
      'Driving': 'KØRSEL',
      'DrivingAmt': 'Kørsel',
      'NumberOfDrivingDays': 'Antal kørselsdage',
      'NumberOfHours': 'Antal timer',
      'NumberOfDays': 'Antal dage',
      'Timer-Min': 'Timer / Min'
    },
    'BUTTONS': {
      'AddCategory': 'Tilføj kategori',
      'PDFOREMAIL': 'PDF / Email',
      'GoToOffer':'Gå til tilbud',
      'SaveOffer':'Gem tilbud'
    },
    'Headers': {
      'DeleteCategory': 'Vil du slette kategori',
    },
    'TOOTIPORTITLE':{
      'Edit':'Redigere',
        'Delete': 'Slet',
        'Copy': 'Kopi',
      //'AddOfferToCart':'Tilføj tilbud i kurv',
      'AddOfferToCart':'Tilføj i kurv',
      'ResetCategory':'Nulstil Kategori Udvælgelse',
      'GoToParentCategory':'Gå Til Forælder Kategori',
      'GoToBackCategory':'Gå Til Tilbage Kategori',
      'ShowDirection':'Vis retning',
      'CreateOrSaveOffer':'Gem tilbud',
    } 
  }
};
