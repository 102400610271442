export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header': 'Uniconta Credintial',
            'Comapny': 'Company',
            'CustomerVATOptions':'Customer VAT code',
        },
        'FIELDS': {
            'UserName': 'User name',
            'Password': 'Password',
            'AccessId': 'Access id',
            'ComapnyName': 'Comapny name',
            'ComapnyId': 'Comapny id',
            'GenerateSalesOrders': 'Generate sales orders',
            'GenerateInvoices': 'Generate invoices',
            'GenerateTXTFile': 'Generate TXT File',
            'Path': 'path/filename',
            'AmountToGenerateInvoice': 'On account invoice must be for DKK (Excl. VAT)',
            'SelectForInvoiceLine': 'Select For Invoice Line',
            'ESCHoursFromSend': 'Send advance invoice at',
            'SelectCoworker': 'Select coworker',
            'MoreAmountToGenerateInvoice': 'Send on account invoice when case is over DKK (Excl. VAT)',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
            'AddCompany':'Tilføj firma'
        },
        'Help':{
            'Note':'Please use farward slash(/) only in path setting',
        }
    }
};