import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { RequestCaseTask, RequestRequisition, RequestStatusProject, SupplierRequest } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { Material, ProjectSupplierQuickMaterials, QuickInvoiceMaterials } from "app/main/model/Material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationsService } from "angular2-notifications";
import { Supplier } from "app/main/model/Supplier";
import { MaterialService } from "app/main/pages/material/material.service";
import { ProjectRequisitionService } from "app/main/pages/project/project.requisition.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Project, projectRequisition, ProjectRequisitionStatusLogEnum } from "app/main/model/Project";
import { AlertPlanningMessageModelComponent } from "app/main/pages/alert-planning-message-model/alert-planning-message-model.component";
import { MatDialog } from "@angular/material";
import { TitleService } from "app/core/services/title.service";
import { MaterialComponent } from '../../component/material/material.component';
import { AuthService } from "app/core/services/auth.service";
import { FormService } from "app/core/services/form.service";
import { CommonService } from "app/core/services/common.service";
import * as moment from 'moment';

@Component({
    selector: "project-supplier-quick-material",
    templateUrl: "./project-supplier-quick-material.component.html",
    styleUrls: ["./project-supplier-quick-material.component.scss"]
})

export class ProjectSupplierQuickMaterial implements OnInit {
    showSpinner: boolean = false;
    projectList = [];
    projectMaterials: QuickInvoiceMaterials[] = [];
    currentselectedMatObj: QuickInvoiceMaterials = new QuickInvoiceMaterials();
    isAddQuickMaterial: boolean = false;
    quickMatObj: ProjectSupplierQuickMaterials = new ProjectSupplierQuickMaterials();
    quickMaterialForm: FormGroup;
    requisition: projectRequisition;
    requisitionList: any = [];
    requestProject = new RequestStatusProject();
    supplierText: string = "";
    isGettingProject: boolean = false;

    searchedSupplierList: Supplier[] = [];
    supplierList: Supplier[] = [];
    isGettingSupplier: boolean = false;
    supplierRequest: SupplierRequest;

    showMessage: boolean = false;
    purchasePriceTotal: number = 0;
    isStaffPriceOrStorePurchase: boolean = false;
    AddedMaterials: any[] = [];

    projectValueChanges = new Subject<string>();
    requisitionValueChanges = new Subject<string>();
    supplierValueChanges = new Subject<string>();
    editedProjectMaterialObj: any = {};
    selectedProject: Project;
    roleClaims: any;
    RequisitionOnEditForEconomy: any;
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    objAddProjectMaterial: QuickInvoiceMaterials;
    Requisition: projectRequisition;
    quickMaterialsAdded: any[] = [];

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private titleService: TitleService,
        private materialService: MaterialService,
        private projectRequisitionService: ProjectRequisitionService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private authService: AuthService,
        private formService: FormService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private translate: TranslateService) {
        this.titleService.SetTitle("TITLE_SUPPLIERPROJECTMATERIAL")
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.roleClaims = this.authService.getRoleClaimsList();
        this.quickMaterialForm = this.formBuilder.group({
            projectId: [null, Validators.required],
            requisitionId: [null, Validators.required],
            supplierId: [null, Validators.required],
            text: [null, Validators.required],
            percentage: [null],
            amount: [null, Validators.compose([Validators.required, Validators.min(0.01), Validators.max(999999999)])],
            retailPrice: [null],
            purchasePrice: [null],
            quantity: [null]
        });

        this.requestProject = this.projectService.getRequestParameter();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.requestProject.CoworkerId = "";
        this.requestProject.startDate = null;
        this.requestProject.IsActive = true;
        this.requestProject.IsDeleted = false;
        this.requestProject.Address = "";
        this.showSpinner = true;
        this.GetProjects();

        this.supplierRequest = new SupplierRequest();
        this.supplierRequest.Index = 1;
        this.supplierRequest.Limit = 100;
        this.getSupplierList()

        this.projectValueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value) {
                this.selectProjectEventHandler(value);
                this.selectedProject = this.projectList.find(r => r.sagId == value);
            }
        });

        this.requisitionValueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value) {
                this.projectRequisitionService.GetSingle(this.quickMatObj.projectId, value).subscribe({
                    next: (response: ResponseBase) => {
                        if (response) {
                            this.requisition = response.result;
                            this.checkStaffPriceOrStorePuchase();
                            this.refreshMaterialList()
                            this.RequisitionOnEditForEconomy = response.result;
                            this.requisition.discountPercent = response.result.discountPercent;
                            this.requisition.discount = response.result.discount;
                            this.requisition.invoiceText = response.result.invoiceText ? response.result.invoiceText.trim() : '';
                            this.requisition.opgaveText = response.result.opgaveText ? response.result.opgaveText.trim() : '';
                            if (response.result.doNotUpdateHour == "T")
                                this.requisition.donotUpdateWorkHour = true;
                            else
                                this.requisition.donotUpdateWorkHour = false;
                            //this.getTotalEtstimeteOrPrice();
                            this.ShowCalculatedValuesForEconomy();
                        }
                    },
                    error: err => {

                    },
                    complete: () => {
                        this.checkStaffPriceOrStorePuchase();
                        this.refreshMaterialList()
                        this.showSpinner = false;
                    }
                });
            }
        });

        this.supplierValueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value) {
                const selectSupplier = this.supplierList.find(r => r.id == value);
                if (selectSupplier && selectSupplier.chargesPercentage != null)
                    this.quickMatObj.percentage = selectSupplier.chargesPercentage;
                else
                    this.quickMatObj.percentage = null;
            }
        })
    }

    GetProjects() {
        this.projectService.setRequestParameter(this.requestProject);
        this.projectService.Get<ResponseBase>(this.requestProject)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.projectList = [];
                    this.projectList = response.result;
                    this.showSpinner = false;
                }
                else {
                    this.projectList = [];
                    this.showSpinner = false;
                }
            },
                error => (err) => {
                    this.projectList = [];
                    this.showSpinner = false;
                    this.isGettingProject = false;
                },
                () => {
                    this.showSpinner = false;
                    this.isGettingProject = false;
                });
    }

    selectProjectEventHandler(event) {
        if (event) {
            const requisition = new RequestRequisition();
            requisition.ProjectId = event;
            requisition.IsActive = true;
            requisition.IsDeleted = false;
            this.getRequisitionList(requisition);
        }
    }

    getRequisitionList(model: RequestRequisition) {
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.requisitionList = response.result;
                    if(this.requisitionList.length == 1){
                        this.quickMatObj.requisitionId = this.requisitionList[0].id;
                    }

                }
                else
                    this.requisitionList = [];
            },
            error: err => {
                this.requisitionList = [];
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    async dropDownSearchProject(searchCoworker) {
        this.requestProject.Address = searchCoworker;
        this.isGettingProject = true;
        this.GetProjects();
    }

    async dropDownSearchRequistion(requisition) {
        console.log("requistion - ", requisition.id);
    }

    supplierSearch(value) {
        if (value === '') {
            return this.searchedSupplierList = []
        }
        this.searchedSupplierList = this.supplierList.filter((supplier) => {
            return supplier.business.toLowerCase().startsWith(value.toLowerCase())
        })
    }

    getSupplierList() {
        this.isGettingSupplier = true;
        this.materialService.GetSupplierList<ResponseBase>(this.supplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.supplierList = response.result;
                    this.supplierList.sort(function (a, b) {
                        return a.business.localeCompare(b.business);
                    });
                },
                error: err => { },
                complete: () => {
                    this.isGettingSupplier = false;
                }
            });
    }

    save() {
        this.quickMaterialForm.markAllAsTouched();
        if (this.quickMaterialForm.valid) {
            if (this.isAddQuickMaterial) {
                this.AddQuickMaterial();
            }
            else {
                this.supplierText = this.supplierList.find(s => s.id == this.quickMatObj.supplierId).business
                this.showSpinner = true;
                this.quickMatObj.text = this.supplierText + " - " + this.quickMatObj.text;
                this.projectService.addProjectQuickSupplierMaterial<ResponseBase>(this.quickMatObj)
                    .subscribe((response: ResponseBase) => {
                        this.quickMatObj.amount = null;
                        this.quickMatObj.text = null;
                        this.quickMatObj.percentage = null;
                        this.translate.get("MESSAGES.Created").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });

                        this.refreshMaterialList();
                    },
                        (err: any) => {
                            this.showSpinner = false;
                            if (err.status === 404) {
                                this.notificationsService.error(err.error.message);
                            }
                        }, () => {
                            this.showSpinner = false;

                        });
            }
        }

    }

    getProjectMaterialList(modal) {
        this.showSpinner = true;
        this.projectService.GetProjectMaterialList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.projectMaterials = response.result;
                    this.projectMaterials.filter(p => {
                        if (p.quantity) {
                            p.quantity = parseFloat(p.quantity.toFixed(2));
                        }
                        if (!p.noStockTraek) {
                            p.noStockTraek = false;
                        }
                        if (p.unit == undefined || p.unit == null) {
                            if (!(p.material == undefined || p.material == null)) {
                                p.unit = p.material.unit || ''
                            } else {
                                p.unit = ''
                            }
                        }
                        if (p.isRequiredText == undefined || p.isRequiredText == null) {
                            if (!(p.material == undefined || p.material == null)) {
                                p.isRequiredText = p.material.isRequiredText || false
                            } else {
                                p.isRequiredText = false
                            }
                        }
                    });

                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.purchasePriceTotal = 0;
                    this.calculateProjMaterialPriceTotal();

                    this.AddedMaterials = this.projectMaterials;
                    this.showMessage = this.projectMaterials.length > 0 ? false : true;
                    this.calMaterialCreation()
                }
                else {
                    this.showMessage = true;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    getTotalPrice(qty, price) {
        if (price == undefined) {
            price = 0;
        }
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    checkStaffPriceOrStorePuchase() {
        if (this.requisition && (this.requisition.opgaveText == "Personale køb" || this.requisition.opgaveText == "Butiks køb"))
            this.isStaffPriceOrStorePurchase = true;
        else
            this.isStaffPriceOrStorePurchase = false;
    }

    openMaterialDeleteDialog(index, materialobj) {
        if (!(materialobj.itemNumber && materialobj.id)) {
            this.AddedMaterials.splice(index, 1);
            return;
        }
        if (materialobj.noStockTraek) {
            var deletedMat = this.AddedMaterials[index];
            if (deletedMat && deletedMat.id)
                this.removeProjectMaterial(deletedMat.id, false);
            else
                this.AddedMaterials.splice(index, 1);
        }
        else {
            var message = "";
            var materialText = materialobj.text ? materialobj.text : materialobj.material.text;
            this.translate.get("Headers.DeleteMaterial").subscribe({
                next: (res) => { message = res; }, error: err => { }
            });
            message = message.replace("@MaterialName", materialText)
            const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
                width: '40%',
                data: {
                    Header: message,
                    FirstButtonLabel: "Headers.MaterialStockUpdown",
                    SecondButtonLabel: "Headers.NoChange",
                }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                    if (result == 1) {
                        var deletedMat = this.AddedMaterials[index];
                        if (deletedMat && deletedMat.id)
                            this.removeProjectMaterial(deletedMat.id, true);
                        else
                            this.AddedMaterials.splice(index, 1);

                    }
                    else if (result == 2) {
                        var deletedMat = this.AddedMaterials[index];
                        if (deletedMat && deletedMat.id)
                            this.removeProjectMaterial(deletedMat.id, false);
                        else
                            this.AddedMaterials.splice(index, 1);
                    }
                }
            });
        }
    }

    removeProjectMaterial(id, isUpDownQty) {
        this.showSpinner = true;
        this.projectService.deleteProjectMaterial<ResponseBase>(id, isUpDownQty)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.AddedMaterials = this.AddedMaterials.filter(m => m.id != id);
                    this.AddedMaterials.forEach(i => {
                        if (i.material && i.material.isRequiredText) {
                            i.isRequiredText = i.material.isRequiredText;
                        }
                        if (i.material && i.material.unit) {
                            i.unit = i.material.unit;
                        }
                    });
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    editProjectMaterial(id) {
        this.showSpinner = true;
        this.projectService.editProjectMaterial<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.editedProjectMaterialObj = response.result;
                    this.editedProjectMaterialObj.isRequiredText = response.result.material.isRequiredText;
                    this.editedProjectMaterialObj.unit = response.result.material.unit;
                    if (this.editedProjectMaterialObj) {
                        this.AddNewMaterials()
                    }
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {

                }
            });
    }

    AddNewMaterials() {
        //let sendData = {};
        const sendData = {
            'RequisitionId': this.requisition.id,
            'sagId': this.selectedProject.sagId,
            // 'roleName': this.roleName,
            'indoorOutdoorStatusIds': this.selectedProject.indoorOutdoorStatusIds,
            'editedProjectMaterialObj': this.editedProjectMaterialObj,
            'projectMaterials': this.projectMaterials,
            'ObjProject': this.selectedProject,
            'Requisition': this.requisition,
            'materialTotalPrice': this.materialTotalPrice,
            'materialTotalPriceWithVAT': this.materialTotalPriceWithVAT,
            'materialPersonalTotalPrice': this.materialPersonalTotalPrice,
            'discountPercent': this.requisition.discountPercent,
            'discount': this.requisition.discount,
            'purchasePriceTotal': this.purchasePriceTotal
        }

        var dialogRef = this.dialog.open(MaterialComponent, {
            height: '90%',
            width: '90%',
            disableClose: true,
            data: sendData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.refreshMaterialList();
            }
        });
    }

    clearAll() {
        this.projectMaterials = [];
        this.AddedMaterials = this.projectMaterials;
        this.quickMatObj = new ProjectSupplierQuickMaterials();
        this.quickMaterialForm.reset();
    }

    refreshMaterialList() {
        let objMaterialProject = new RequestCaseTask();
        objMaterialProject.fileAssignmentId = this.requisition.id;
        objMaterialProject.IsDeleted = false;
        objMaterialProject.OrderBy = "ModifyDate DESC";
        this.getProjectMaterialList(objMaterialProject);
        this.getTotalEtstimeteOrPrice();
    }

    calculateProjMaterialPriceTotal() {
        this.materialTotalPriceWithVAT = 0;
        this.purchasePriceTotal = 0;
        this.projectMaterials.forEach((e: any) => {
            if (!e.totalPrice) {
                e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
            }
            if (!e.personalPriceTotal) {
                e.personalPriceTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
            }
            this.materialTotalPrice += e.totalPrice;
            if (e.personalPrice && e.personalPrice != 0) {
                this.materialPersonalTotalPrice += e.personalPriceTotal;
            }
            else {
                e.personalPrice = 0;
            }

            if (e.totalPriceVAT) {
                this.materialTotalPriceWithVAT += e.totalPriceVAT;
            } else {
                var vat = (e.totalPrice * 25) / 100;
                this.materialTotalPriceWithVAT += (e.totalPrice + vat);
            }
            if (e.purchasePrice) {
                this.purchasePriceTotal += e.purchasePrice;
            }
        });
        //this.calculateOfferPriceExclVAT();
    }

    calMaterialCreation() {
        var totalMaterialCreationPrice = 0
        this.projectMaterials.forEach((e: any) => {
            if (e.lineType && (e.lineType == "V" || e.material.lineType == "V")) {
                totalMaterialCreationPrice += e.totalPrice ? e.totalPrice : 0
            }
        });
        this.requisition.materialCreation = ((totalMaterialCreationPrice / this.materialTotalPrice) * 100);
    }

    setRequisitionNoOfDays() {
        this.requisition.hoursLeft = 0;
        if (this.requisition.estimatedTime) {
            //this.Requisition.hoursLeft = this.Requisition.estimatedTime - this.Requisition.totalWorkingTime;
            this.requisition.hoursLeft = parseFloat(((this.requisition.estimatedTime - this.requisition.totalWorkingTime)).toFixed(2));
            this.requisition.numberOfDays = parseFloat(((this.requisition.hoursLeft) / 7.5).toFixed(2));
        }
        else
            this.requisition.numberOfDays = 0;

    }

    getTotalEtstimeteOrPrice() {
        if (this.requisition.includeEstimatePrice > 0) {
            this.requisition.totalIncludeEstimatePrice = this.requisition.includeEstimatePrice;
            this.requisition.totalVatEstimatePrice = (this.requisition.vatEstimatePrice);
        }
        else if (this.requisition.includePrice > 0) {
            this.requisition.totalIncludeEstimatePrice = (this.requisition.includePrice);
            this.requisition.totalVatEstimatePrice = (this.requisition.vatPrice);
        }
        else {
            this.requisition.totalIncludeEstimatePrice = (this.requisition.includeEstimatePrice);
            this.requisition.totalVatEstimatePrice = (this.requisition.vatEstimatePrice);
        }
        this.setRequisitionNoOfDays();
        this.calculateOfferPriceExclVAT();
    }

    calculateOfferPriceExclVAT() {
        var totalPriceDiscount = 0;
        if (this.requisition.discountPercent && this.requisition.discountPercent > 0)
            totalPriceDiscount = (this.materialTotalPrice * this.requisition.discountPercent) / 100;
        if (this.requisition.discount && this.requisition.discount > 0)
            totalPriceDiscount = this.requisition.discount;
        //var PriceIncluded = (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100));
        var PriceIncluded = (this.materialTotalPrice - totalPriceDiscount);
        if (this.requisition.totalIncludeEstimatePrice && this.requisition.totalVatEstimatePrice) {
            var tempOfferPriceexcluded = this.requisition.totalIncludeEstimatePrice - this.requisition.totalVatEstimatePrice;
        }
        if (PriceIncluded && tempOfferPriceexcluded) {
            this.requisition.performedPercent = ((PriceIncluded / tempOfferPriceexcluded) * 100);
        }
    }

    ShowCalculatedValuesForEconomy() {
        var requisitionData = this.RequisitionOnEditForEconomy;
        if (requisitionData) {
            if (requisitionData.suggestedHours)
                this.requisition.suggestedHours = requisitionData.suggestedHours;
            if (requisitionData.excludePrice) {
                this.requisition.includePrice = Number((requisitionData.excludePrice + ((Number(requisitionData.excludePrice) * 25) / 100)).toFixed(2));
                this.requisition.vatPrice = Number((Number(requisitionData.excludePrice) * 25) / 100);
                if (!requisitionData.suggestedHours)
                    this.requisition.suggestedHours = Number((this.requisition.excludePrice / this.requisition.coworkerWorkingRate).toFixed(2));
            }
            if (requisitionData.estimatedPrice > 0) {
                this.requisition.includeEstimatePrice = Number((this.requisition.estimatedPrice + ((Number(this.requisition.estimatedPrice) * 25) / 100)));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
                this.requisition.vatEstimatePrice = Number((Number(this.requisition.estimatedPrice) * 25) / 100);
                if (!requisitionData.suggestedHours)
                    this.requisition.suggestedHours = Number((this.requisition.estimatedPrice / this.requisition.coworkerWorkingRate).toFixed(2));
            }
            if (!requisitionData.suggestedHours)
                requisitionData.suggestedHours = this.requisition.suggestedHours;
            this.requisition.suggestedHours = requisitionData.suggestedHours;
            this.requisition.estimatedTime = requisitionData.estimatedTime ? requisitionData.estimatedTime : requisitionData.suggestedHours;
            this.setRequisitionNoOfDays();
        }
    }

    AddViewQuickMaterial(){
        this.isAddQuickMaterial = true;
        this.objAddProjectMaterial = new QuickInvoiceMaterials();
        this.Requisition = new projectRequisition();
        this.quickMaterialForm.controls["quantity"].setValidators(Validators.compose([Validators.required, Validators.min(0.01), Validators.max(999999999)]));
        this.quickMaterialForm.controls["quantity"].updateValueAndValidity();
        this.quickMaterialForm.controls["amount"].setValidators(null);
        this.quickMaterialForm.controls["amount"].updateValueAndValidity();
        this.quickMaterialForm.controls["supplierId"].setValidators(null);
        this.quickMaterialForm.controls["supplierId"].updateValueAndValidity();
        
    }
    backToProjectMaterial(){
        this.isAddQuickMaterial = !this.isAddQuickMaterial;
        this.quickMaterialForm.controls["amount"].setValidators(Validators.compose([Validators.required, Validators.min(0.01), Validators.max(999999999)]));
        this.quickMaterialForm.controls["amount"].updateValueAndValidity();
        this.quickMaterialForm.controls["quantity"].setValidators(null);
        this.quickMaterialForm.controls["quantity"].updateValueAndValidity();
        this.quickMaterialForm.controls["supplierId"].setValidators(Validators.required);
        this.quickMaterialForm.controls["supplierId"].updateValueAndValidity();
    }

    AddQuickMaterial(){
        if(this.requisition && (this.requisition.status != 8 && this.requisition.status != 9 && this.requisition.status != 12 ))
        {
            this.translate.get("MESSAGES.CanNotAddMaterial").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.commonService.playFailedTone();
                },
                error: err => {
                }
            });
            return;
        }
        this.formService.markFormGroupTouched(this.quickMaterialForm);
        if (!this.quickMaterialForm.valid)
            return;
        this.currentselectedMatObj = new QuickInvoiceMaterials();
        this.objAddProjectMaterial.createdDate = new Date();
        this.objAddProjectMaterial.text = this.quickMatObj.text;
        this.objAddProjectMaterial.sagOpgaveId = this.requisition.id;
        this.objAddProjectMaterial.quantity = this.quickMatObj.quantity;
        this.objAddProjectMaterial.personalPrice = 0;
        this.objAddProjectMaterial.retailPrice = this.quickMatObj.retailPrice;
        this.objAddProjectMaterial.purchasePrice = this.quickMatObj.purchasePrice;
        this.objAddProjectMaterial.price = 0;
        this.objAddProjectMaterial.totalPrice = 0;
        this.objAddProjectMaterial.personalPriceTotal = 0;
        this.objAddProjectMaterial.totalPriceVAT = 0;
        this.objAddProjectMaterial.totalPrice = this.objAddProjectMaterial.retailPrice > 0 ? this.getTotalPrice(this.objAddProjectMaterial.quantity, this.objAddProjectMaterial.retailPrice) : 0;
        this.objAddProjectMaterial.totalPriceVAT = this.objAddProjectMaterial.totalPrice > 0 ? (((this.objAddProjectMaterial.totalPrice * 25) / 100) + this.objAddProjectMaterial.totalPrice) : 0;
        var copyObj = Object.assign({}, this.objAddProjectMaterial);
        this.quickMaterialsAdded.push(copyObj);
        this.MaterialSave();
    }

   

    MaterialSave() {
        debugger
        this.showSpinner = true;        
        this.projectService.addOrUpdateMaterialToProjectWithFlag<ResponseBase>(this.quickMaterialsAdded)
            .subscribe((response: ResponseBase) => {
                this.backToProjectMaterial();
                this.quickMaterialForm.reset();
                const tempMat = { ...this.quickMatObj };
                this.quickMatObj = new ProjectSupplierQuickMaterials();
                this.quickMatObj.projectId = tempMat.projectId;
                this.quickMatObj.requisitionId = tempMat.requisitionId;
                this.quickMatObj.supplierId = tempMat.supplierId;
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
                error => (err) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },
                        error: err => {
                        }
                    });
                }, () => {
                    this.refreshMaterialList();
                    this.showSpinner = false;
                });
    }

    onChangeStatus(event) {
        this.requisition.status = event;
        this.requisition.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.StatusUpdated;
        this.UpdateRequisition(this.requisition);
    }
    
    UpdateRequisition(model: projectRequisition) {
        if (this.requisition.donotUpdateWorkHour) {
            model.doNotUpdateHour = "T";
        }
        else {
            model.doNotUpdateHour = "F";
        }
        this.showSpinner = true;
        model.CreatedBy = this.authService.getCoWorkerId();
        //Invoiced date 
        if (this.requisition.billed && typeof this.requisition.billed !== "string") {
            this.requisition.billed = moment(this.requisition.billed).format("DD-MM-YYYY HH:mm");
        }
        this.projectRequisitionService.Update(model.projectId, model.id, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.requisition.startDate = response.result.startDate;
                    this.requisition.endDate = response.result.endDate;
                    this.requisition.comingDate = response.result.comingDate;
                    this.requisition.status = response.result.status;
                    this.requisition.discountPercent = response.result.discountPercent;
                    this.requisition.discount = response.result.discount;
                    this.requisition.materialCreation = response.result.materialCreation;
                    if (this.requisition.doNotUpdateHour == "T")
                        this.requisition.donotUpdateWorkHour = true;
                    else
                        this.requisition.donotUpdateWorkHour = false;
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });

                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}