//#region Imports 
// angular 
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatSort, MatTableDataSource, Sort } from '@angular/material';
// model
import { ResponseBase } from "app/main/model/ResponseBase";
import { RequestHolidayList } from "app/main/model/RequestBase";
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";
// third party packages
// interfaces
// services
import { SettingsService } from '../../settings.service';
// third party tool
import { DatePickerComponent } from "@syncfusion/ej2-angular-calendars";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import * as moment from "moment";
// app-core
import { AuthService } from 'app/core/services/auth.service';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { spaceOnlyValidator } from "app/core/Validators/validation";
//#endregion Imports 


export interface IHoliday {
    id: string;
    date: string;
    description: string;
    isHalfDay: boolean;
}


@Component({
    selector: "vibe-holiday-list",
    templateUrl: "./holiday-list.component.html",
    styleUrls: ["./holiday-list.component.scss"]
})

export class HolidayListComponent implements OnInit, AfterViewInit {
    //#region Initializations

    //#region @ViewChild
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('HolidayDate', { static: true }) holidayDate: DatePickerComponent;
    //#endregion @ViewChild

    //#region Form
    holidayForm = this.formBuilder.group({
        id: [null],
        date: [null, Validators.required],
        description: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
        isHalfDay:[null],
    });
    //#endregion Form

    //#region Variables
    displayedColumns: string[] = ['id', 'date', 'description', 'actions'];
    dataSource: MatTableDataSource<IHoliday> = new MatTableDataSource<IHoliday>([]);
    IsHolidayDateInvalid: boolean = false;
    showSpinner: boolean = false;
    currEditIndex: number = -1;
    requestBase: RequestHolidayList;
    //#endregion Variables

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }
    //#endregion  Initializations



    //#region Private/Helper Methods
    private GetHolidayList() {
        this.requestBase = new RequestHolidayList();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.requestBase.OrderBy = 'date';

        this.showSpinner = true;
        this.dataSource = new MatTableDataSource<IHoliday>([]);
        this.settingsService.GetHolidayList<ResponseBase>(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.dataSource.data = response.result

                    setTimeout(() => {
                        this.dataSource.sort = this.sort;

                        this.dataSource.sortingDataAccessor = (item, property) => {
                            switch (property) {
                                case 'date': return new Date(item.date);
                                default: return item[property];
                            }
                        };
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    private Add() {
        this.showSpinner = true;
        this.settingsService.CreateHoliday<ResponseBase>(this.holidayForm.value).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.statusCode == 200) {
                    this.GetHolidayList();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => { }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this.FormReset()
            }
        });
    }

    private Update() {
        this.showSpinner = true;
        this.settingsService.UpdateHoliday<ResponseBase>(this.holidayForm.value.id, this.holidayForm.value).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.statusCode == 200) {
                    this.GetHolidayList();
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => { }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this.FormReset()
            }
        });
    }
    //#endregion Private/Helper Methods



    //#region Public Methods
    public OnFocusIn(event) {
        event.model.show();
    }

    public Submit() {
        this.IsHolidayDateInvalid = false;
        this.holidayForm.markAllAsTouched()

        if (this.holidayForm.invalid) {
            if (!this.holidayForm.value.date) {
                this.IsHolidayDateInvalid = true;
            }
            return;
        }

        if (!this.holidayForm.value.id) {
            this.Add();
        } else {
            this.Update();
        }
    }

    public Edit(obj, index) {
        this.holidayForm.reset();
        this.currEditIndex = index;
        this.holidayForm.setValue(obj);
    }

    public Delete(obj) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteAlert' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.settingsService.DeleteHoliday<ResponseBase>(obj['id']).subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode == 200) {
                            this.GetHolidayList();
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => { }
                            });
                        }
                    },
                    error: err => {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => { }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }

    public FormReset() {
        this.holidayForm.reset();
        this.currEditIndex = -1;
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }
    //#endregion Public Methods



    //#region Lifecycle Hooks
    ngOnInit() {
        this.GetHolidayList();
    }

    ngAfterViewInit() {
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'date': return new Date(item.date);
                default: return item[property];
            }
        };
    }
    //#endregion Lifecycle Hooks

}