export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'NewMaterial': 'Create New Material',
            'AddMaterial': 'Do you want to add materials?',
            //'DeleteMaterial': 'Do you want to delete this materials?',
            'DeleteDevice': 'Do you want to delete this device?',
            'Material': 'Material',
            'DeleteAlert': 'Are you sure you want to delete?',
            'AllRequisitionCoworker': 'Are you sure you want to do this on all tasks?',
            'Yes': 'Yes',
            'No': 'No',
            'OK': 'OK',
            'AskForSaveProjectData': 'Do you want to save this information ?',
            'CopyCustomerDetails': 'Copy Customer Details ?',
            'ChangeStatusToAllRequisition': 'Can you change started status to all task?',
            'UnassignCustomerFromInquiry': 'Are you sure you want to unassign customer from this inquiry ?',
            'MovetoFinishFolder': 'Are you sure you want to move this file to finish folder ?',
            'DeleteProjectFileAlert': 'Are you sure you want to delete the file?',
            'DeleteProjectWorkHours': 'Are you sure you want to delete workhours?',
            'DeleteTodaysNonWorkingHours': 'Are you sure you want to delete nonworking hours',
            'DeleteFutureNonWorkingHours': 'Do you want to delete future "non-working hours"',
            'DeleteWorkHoursOnFuture': 'Delete future hour on this task?',
            'DeleteOffer': 'Are you sure you want to delete this offer?',
            'DeleteAllOffers': 'Are you sure you want to delete offer?',
            'DeleteRoom': 'Are you sure you want to delete this room?',
            'DeleteRoomAlert': 'The offer has been created with this room, are you sure you want to delete this room, room detail will be deleted on offers.',
            'ChangeDailySalesCustomer': 'Are you sure you want change daily sales customer ?',
            'AllReminderDone': 'Do you want to make all reminders "done"?',
            'NotDoneAllReminder': 'Do you want to remove completed reminders ?',
            'DeleteSingleReminder': 'Do you want to delete this reminder ?',
            'DeleteAllReminder': 'Do you want to delete all this reminders ?',
            // 'DeleteAllRooms':'Are you sure you want to delete all rooms?'
            'DeleteDiverseMaterial': 'Do you want to delete this materials?',
            'SaveChanges': 'Do you want to save changes that you have made?',
            'CopyMaterial': 'Do you want to copy this material?',
            'CreateCustomer': 'Please create a customer first',
            'CreateProject': 'Please create a project first',
            'DeleteMaterial': 'Do you want to delete this item?',
            'DeleteProject': 'Do you want to delete this Project?',
            'DeleteCustomer': 'Do you want to delete this Customer?',
            'DeleteMessageForCreatedOffers': 'You have already created an offer with these detail you want to change these details old offer must be deleted Are you sure you want to delete the old offer?',
            'DeleteStatusMessage': 'Do you want to delete this status message?',
            'DeleteRequisitionMessage': 'Do you want to delete this task?',
            'InquiryInactiveMessage': 'Do you want to make Inquiry inactive when mail has been sent Yes / No?',
            'DeleteQuestion': 'Do you want to delete this Question?',
            'DeleteSupplier': 'Do you want to delete this supplier ?',
            'DeletePriceDiscount': 'Do you want to delete this price item?',
            'EmptyEconomyFields': 'You can not change status to "Ready billing" because economy fields are not filled.',
            'RoutePlanHeader': 'You have not selected a route. Do you want to select it ?',
            'DeleteCoworker': 'Do you want to delete this Coworker ?',
            'UndoChanges': 'Are you sure you want to delete price adjustment?',
            'DeleteCarInfo': 'Do you want to delete this car ?',
            'DeleteCompanyInfoTab': 'Do you want to delete this tab ?',
            'DeleteColorCode': 'Do you want to delete this color code ?',
            'CopyMaterialForAllRooms': 'Do you want to copy this material for all rooms?',
            'MaterialUpdownQuantity': 'Do you want to up down material stock?',
            'UnsavedChanges': 'You have not saved changes - do you want to continue without saving?',
            'CopyMaterialForSelectedRooms': 'Do you want to copy this treatment to all rooms?',
            'AutoSaveToCard': 'Do you want to save offers?',
            'DeleteConformation': 'Do you want to delete',
            'DeleteOfferQualityTestAnswer': 'Do you want to delete control form answer',
            'DeleteMessage':'Are you sure you want to delete this?',
            'ViewPersonalPriceMessage':'Show staff price on pdf?',
            'MoreThan7Hours':'Do you want to spend more than 7 hours on friday?',
            'MoreThan7-5Hours':'Do you want to spend more than 7,5 hours?',
            'DeleteFeaturePlanning':'Do you want to delete planning?',
            'MaterialPermission':'Are you sure you want this project in material?',
            'CreateAnInvoice': 'Do you want to create an invoice?',
            'MaterialPriceNot-0': 'There is a materials where the price is 0.00',
            'CheckWorkHour': 'There are no reported hours you want to continue',
            'MaterialQuantityNot-0': 'There is a materials where quantity is 0.00',
            'RequisitionNumber': 'Requisition number is required',
            'CheckworkPlace':'Place of work is not filled in. Do you want to continue?',
            'DifferentHour':'Invoice hours and reported hours are different are you sure you want continue?',
            'DeleteReport': 'Do you want to delete this report?',
            'ViewPersonalPrice-Min-Max-Quantity-Message': 'Show staff price, min number and max number?',
            'Inactive-Offer-Message': 'You want to offer Inactive?',
            'InvalidMaterialSelected': 'You have selected invalid material, please check it and correct them',
            'ReactiveProjectAndTask': 'Do you want to reactivate the project and task?',
            'CreateCoworkerWithHelpData': 'Do you want help data to create an coworker?',
            'ColorCodeMisMatch': 'Color code is different then regular please make sure before start.',
            'DeleteUnicontaUser': 'Delete Uniconta info',
            'QuickMaterialWarnningMessage':'Note there is a product line with an item number that starts with "T-" do you want to continue Yes/No',
            'ApplyFulldayLeave': 'Are you sure you want apply full day planning on half day holiday'
        },
        'BUTTONS': {
            'Manually': 'Manually'
        }
    }
};
