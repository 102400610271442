export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header-Invoice-Email': 'Read invoice email Credintial',
            'SMSText': 'Please do not remove @SupplierName from SMS text.',
            'NotificationText': 'Please do not remove @SupplierName from Notification text',
        },
        'FIELDS': {
            'Email': 'Email',
            'EmailPassword': 'Password',
            'ImapServer': 'Imap Server',
            'ImapPort': 'Imap Port Number',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
         },
        'VALIDATIONS': {
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        },
        'TOOTIPORTITLE': {
            'IsSendEmail': 'Is Send Email',
            'IsSendNotification': 'Is Send Notification',
            'IsSendSMS': 'Is Send SMS',
        }
    }
};