export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'Save': 'Save',
            'BACK': 'Back',
            'Reset': 'Reset'
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'Validation': {
            'DesignationRequired': 'Designation is required',
            'ValideFromRequired': 'Valide from date is required',
            'HourlyRateRequired': 'Hourly rate is required',
            'WeekdayRequired': 'Weekday is required',
            'TypeRequired': 'Type is required',
            'WhiteSpaceNotAllowed':'Only white space not allowed',
            'StartDate': 'StartDate is required',
            'EndDate': 'EndDate is required',
            'TermsofEmployment': 'TermsofEmploymentID is required',
            'OfSalary': 'OfSalary is required',
            
        },
        'Headers': {
            'Title': 'Employment',
            'DeleteMessage': 'Are you sure you want to delete',
            'Rules':'Rules',
        },
        'FIELDS': {
            'SrNo': 'Sr no.',
            'StartDate': 'StartDate',
            'EndDate': 'EndDate',
            'Type': 'Type',
            'OfSalary': 'Of Salary',
            'BasicSalary': 'Basic salary',
            'BasicSalaryHours': 'Basic salary hours',
            'PersonalSupplement': 'Personal supplement(per hour)',
            'TermsofEmploymentID': 'Terms of employment ID',
            'MeetingAllowance': 'Meeting allowance(per hour)',
            '14Day':'14 day',
            'Month':'Month',
            'PayrollType': 'Payroll Type'
        },
        'employmentType': {
            'valueA': 'A Duty schedule always',
            'valueC': 'C Duty schedule weekdays',
            'valueD': 'D Only settlement after salary entry',
        },
        'FILTERS': {
            'All': 'All',
        },
        'selectedValueWeekDays': {
            'value1': 'Monday',
            'value2': 'Tuesday',
            'value4': 'Wednesday',
            'value8': 'Thursday',
            'value16': 'Friday',
            'value32': 'Saturday',
            'value64': 'Sunday'
          },
    }
};