export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Holiday': 'Helligdage',
        },
        'FIELDS': {
            'Date': 'Dato',
            'Desc': 'Beskrivelse',
            'Action': 'Handlinger',
            'HalfDay': 'Halvdag',
        },
        'VALIDATIONS': {
            'Date': 'Feriedato påkrævet',
            'Desc': 'Feriebeskrivelse påkrævet',
            'WhiteSpaceNotAllowed':'Kun hvidt mellemrum er ikke tilladt'
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes.',
            'Updated': 'Opdateret med succes.',
            'Deleted': 'Slettet med succes.',
            'Failed': 'Noget gik galt !',
            'NoRecords': 'Ingen registreringer fundet !'
        },
        'BUTTONS': {
            'Add': 'Tilføj',
            'Update': 'Opdatering',
            'Save':'Gem',
            'Delete': 'Slet',
            'Edit': 'Redigere',
            'Cancel': 'Fortryd',
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
        }
    }
};