export const locale = {
    lang: 'en',
    data: {
        'CUSTOMER': {
            'TITLE': 'Customer',
            'DESC': 'Hello Customer!',
            'TabBasicDetail': 'Basic Detail',
            'TabContactDetail': 'Contact Detail',
            'TabProjectDetail': 'Project',
            'TabFiles': 'Files',
            'Log': 'Log',
            'TabColorCode': 'Color Code',
            'TabMessageLog':'Message Log',
            'TabRoom': 'Room / Building',
            'TabProperties': 'Properties',
            'TabMaterials': 'Materials',
            'TabProjectRecuringSetting': 'Project Recuring Setting',
        },
        'BUTTONS': {
            'NEWCUSTOMER': 'New Customer',
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
            'BTNCONFIRM': 'Confirm',
            'CREATE_FOLDER': 'Create Folder',
            'DOWNLOAD': 'Download',
            'DELETE': 'Delete',
            'NEWProject': 'New Project',
            'NEWPROJECTOFFER': 'New Offer',

        },
        'FIELDS': {
            'CustomerNo': 'Customer No.',
            'CustomerName': 'Customer Name',
            'Email': 'E-mail',
            'PhoneNo': 'Phone No.',
            'Address-line-1': 'Address(Line 1)',
            'Address-line-2': 'Address(Line 2)',
            'City': 'City',
            'Zipcode': 'Zipcode',
            'Position': 'Position',
            'Comments': 'Comments  [ Optional ]',
            'OrderName': 'Order name',
            'EnterLocation': 'Enter your location',
            'Active': 'Set as inactive',
            'From': 'From',
            'To': 'to',
            'colorCode': 'Color code',
            'PersonnelPurchas': 'Staff Purchase',
            'EmailInvoice': 'Invoice Email',
            'Status': 'Status',
            'EANNumber': 'EAN Number',
            'SattisticQuestion2': 'Where does the customer come from',
            'Discount': 'Discount',
            'PaymentMode1': 'Dankort',
            'PaymentMode2': 'Mastercard',
            'PaymentMode3': 'Cash',
            'PaymentMode4': 'Mobile payment',
            'PaymentMode5': 'Mobile payment KK',
            'PaymentMode6': 'Credit',
            'PaymentMode7': 'Other',
            'visit': 'Visit',
            'HoursBack': 'Hours Back',
            'RemainingTime': 'RemainingTime',
            'HourlyWage': 'Hourly rate',
            'Before60Days': '*** 60 Day old.',
            'Planned': 'Planned',
            'DoNotSendSMSANDEmail': 'Do not send sms we come & Do not send questions',
            'RequisitionNumber': 'Requisition number',
            'CountryCode': 'Country Code',
            'Not-Found-Country': 'No Country found',
            'DoNotCalculatePriceForPriceDiscount': 'Do not calculate price for price discount',
            'DoNotCalculatePriceForPostNumber': 'Do not calculate price for post no.',
            'CustomerNumberAuto': 'Cust no. auto',
            'IsSendInvoiceBeforeFinished':'Is send invoice before finished',
        },
        'Headers': {
            'NewCustomer': 'New Customer Form',
            'Customer': 'Update Customer',
            'addressModalTitle': 'Please confirm your address details',
            'NoFileSelected': 'No File Selected',
            'ChooseFile': 'Choose File',
            'FOLDEREMPTY': 'This folder is empty',
            'LogTabSRNO': 'SR NO',
            'LogTabRequisitionnumber': 'Requisition number',
            'LogTabChangedon': 'Changed on',
            'LogTabChangedtime': 'Changed time',
            'LogTabChangedfrom': 'Changed from',
            'LogTabChangedto': 'Changed to',
            'Desc': 'Descripion',
            'NoLogAvailable': 'No log available',
        },
        'VALIDATIONS': {
            'ContactNumber': 'Enter Contact No',
            'TEXT': 'Enter Customer Name',
            'ONLY40CHARACTER': 'Maximum length is 40 characters.',
            'Email': 'Enter Email Address',
            'ValidEmail':'Enter Valid Email Address',
            'PHONENO': 'Enter Phone Number',
            'ValidPhoneRequired': 'Enter Valid Phone Number',
            'BEHOLDING': 'Enter Holdings',
            'ITEMNUMBEREXIST': 'Number is already exist',
            'ITEMNUMBERMIN': 'Enter Valid Number',
            'ITEMNUMBERMAX': 'Enter Valid Number',
            'BEHOLDINGMIN': 'Enter Valid Number',
            'BEHOLDINGMAX': 'Enter Valid Number',
            'colorCode': 'Enter Valid ColorCode',
            'ComingFrom': 'Coming From is required',
            'CUSTOMERNUMBER': 'Enter Customer Number',
            'EMAILEXIST': 'Email is already exist',
            'PHONENOEXIST': 'Phone No is already exist',
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'NoOrderAvailbale': 'No projects available',
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'EmailSent': 'Email sent successfully',
            'CustomerNumberExist': 'Number is already exist',
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Scheduled visit',
        //     'value3': 'Ready to boot',
        //     'value4': 'Offers sent',
        //     'value5': 'Offer approved',
        //     'value6': 'Planned',
        //     'value7': 'Offer rejected',
        //     'value8': 'Started',
        //     'value9': 'Performed',
        //     'value10': 'Billed',
        //     'value11': 'Pending',
        //     'value12': 'Ready billing',
        // },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
        'StatusLogCases': {
            'value1': 'Status Updated',
            'value2': 'Coworker Deleted',
            'value3': 'Statick Data Updated',
            'value4': 'Pricing Data Changed',
            'value5': 'Coworker Added',
            'value6': 'Material Added',
            'value7': 'Material Updated',
            'value8': 'Material Deleted',
            'value9': 'Email Sent From Project',
            'value10': 'Email Sent From Customer'
        },
        'whereDoesTheCustomerComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Website',
            'value3': 'Facebook',
            'value4': 'Recommendation',
            'value5': 'Regular customer',
            'value6': 'Manual',
            'value7': 'Telephone',
        },
        'FILTERS': {
            'BADGE': 'Inactive',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
            'Country': 'Country Name'
        },
        'TOOTIPORTITLE':{
            'SendSMS':'Send SMS',
            'SendEmail':'Send Email',
            'AddProject':'Add Project',
            'DeleteCustomer':'Delete Customer',
        }
    }
};


