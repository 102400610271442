export const RoleClaims: any = {
    Assets_View:	"3E3E77D3-4975-4994-9E59-B5B40D116F6B",
    Assets_Modify:	"492C961F-3FC4-4855-8D1C-919DBE5E7CAD",
    Assets_Delete:	"84FBE30F-BC13-4558-BE86-3C4F8263B2BC",
    CarInfo_Modify:	"4E1D3C19-6F51-4C28-A531-BC9C73EF2C96",
    CarInfo_Delete:	"42498136-6A82-4366-BF59-018B8FB9FBB1",
    CarInfo_View:	"AD8A4956-21BB-4388-9D05-2F79D6A3B3F8",
    CompanyInfo_Modify:	"DF1F2074-6F03-44DE-85F5-2B806A9F3F97",
    CompanyInfo_Delete:	"FBD60A09-9F39-4F19-9D4C-0B9DCBBAF6EC",
    CompanyInfo_View:	"76DF931F-7045-4F6E-8D3B-0F6045B0E212",
    Coworker_Modify:	"555FE86D-BBD0-4A40-B56B-969A5FB1342C",
    Coworker_Delete:	"157DEDF9-D6FE-4371-AF8A-F950A3360B2A",
    Coworker_View:	"A8B8837D-D153-406B-9FF6-CF1C7465D6ED",
    Customer_Modify:	"6A81D914-AC6E-4AC1-8B1B-356A2F8BC2F9",
    Customer_View:	"191F16A3-9863-4EC6-B27B-1D42DB5B637F",
    Customer_Delete:	"B5584F21-1718-4B56-A862-0586D9089809",
    Dashboard_View:	"F6CFC7CA-E200-40EC-AD5E-D6E8A90D112A",
    Document_View:	"AB8BED8D-8B4C-4807-BB40-87457BE79B35",
    Document_Modify:	"CE9533BC-08A4-436B-B68D-334455A93619",
    Document_Delete:	"91DB742D-329E-4DEA-8E4F-D3D8530A702D",
    Inquiry_Modify:	"87665368-2F7D-4225-902C-5DA4F0B9A3A4",
    Inquiry_View:	"2A34EC34-3271-4946-AF26-533C756CDE1B",
    Inquiry_Delete:	"BCD0D3D5-1BC3-449C-BAA2-E3F50D2C81B8",
    LiveMap_View:	"BFBB6845-5AAA-4D99-AD32-DFE4022D01EF",
    Material_View:	"4822851E-BB8A-4259-BBB9-0DCBDED42946",
    Material_Modify:	"9F084947-20A9-4894-87ED-0FB91C3350DE",
    Material_Delete:	"7F22E76B-B8E5-438F-88E0-0EF09C9F73D4",
    NanoChips_Modify:	"A5B35497-644D-4685-8CF3-D79F95E37FFD",
    NanoChips_View:	"DB782330-1CC9-413A-A7DA-81A2068A6574",
    NanoChips_Delete:	"FC11E349-BB71-4275-A8A3-A833A2B599C0",
    Offer_Delete:	"CECFD51F-E0FA-436A-B433-34916077B525",
    Offer_View:	"95CC5730-576C-4333-8570-0D0CC2AD5F01",
    Offer_Modify:	"7D09DB41-318C-4877-A1AD-E0706E31D13E",
    Payroll_Delete:	"89AED4FC-738A-4968-9936-58A2FB930D7E",
    Payroll_Modify:	"8CDF5ADC-A4EB-425C-8342-B9D776EC95EF",
    Payroll_View:	"0F8D9798-FE82-447E-92AE-424F42CC0ACC",
    Planning_Delete:	"48A5C166-E414-41A1-A64B-AFE247D5EE06",
    Planning_Modify:	"9C58B757-1253-4E5C-877F-64C253288394",
    Planning_View:	"B5AACD3D-918A-4422-8B2D-F3C61CB2C00E",
    Project_View:	"B1A33A6C-5314-4496-88CF-0110D2675FDA",
    Project_Modify:	"A7935B6E-27E8-4079-97FF-EE5E35C204DD",
    Project_Delete:	"00A4B65D-FF74-48A0-8E59-2E712654791D",    
    QualityTest_Delete:	"43CB7E5D-FE07-4A65-A322-9F2E8B7D2978",
    QualityTest_View:	"B16946BD-B838-48CC-A29E-C87A00BC4819",
    QualityTest_Modify:	"C10382C9-FB92-4758-B732-6559B7BDE74B",
    QuickInvoice_Delete : "DFECF3F7-E3E3-4784-A82D-AB8B4810756F",
    QuickInvoice_Modify :	"31B52CC8-0305-4976-BFC5-638D3BD1A049",
    QuickInvoice_View :	"062E4424-6BD9-43DF-86F5-CE0E03C57371",
    Reminder_Delete:	"20619276-DBD3-458A-8FC1-AB92B61334EF",
    Reminder_Modify:	"219F280C-CE10-4910-933E-F8222830F0C5",
    Reminder_View:	"475A2FD4-B5DE-4D6E-9B22-A21F5A79CCB1",
    CoworkerReport_View:	"2F65D7B6-327E-481E-BBDD-02374A94F3AA",
    Report_View:	"586628D1-D58C-4B73-A2F6-6AF6506508BE",
    MaterialReport_View: 	"9E3A42C9-E830-4C0F-82D9-93084F8D7D0C",
    Setting_View:	"A0AF853E-A6DB-47DB-8F68-29F3FA3F279D",
    Statistics_View:	"6812EFA6-822B-4876-B13C-4FA689ED7039",
    StatisticsQuestion_View:	"54125125-C8A2-4702-B2D4-A8A94F288896",
    Supplier_Modify:	"2B9265D0-F04C-4387-AB49-BA1F92962470",
    Supplier_Delete:	"E65A869A-0F4C-43EA-9267-253F9011B07C",
    Supplier_View:	"E7B86C72-1442-4605-828C-2D9ED1E1762D",
    TermsofEmployment_Modify:	"DD06799F-9DE9-4175-9ADB-9DA180C65653",
    TermsofEmployment_Delete:	"44D46A57-71F6-4870-90FD-2F5706448689",
    TermsofEmployment_View:	"6A2EB05F-69A2-4F72-BA2F-4156884FCD3B",
    WorkHours_View:	"70987B33-5763-4057-8D79-FFC14C2968C1",
    WorkHours_Modify:	"7637ED81-4343-4A0D-B2F0-DD771B43EFB0",
    WorkHours_Delete:	"1C2AA700-E90F-4DBB-B2E7-66215D2D9FEB",
    Own_Project_View:'B05C1897-6889-4917-B421-FA4E45739AE3',
    Own_Planning_View:'FD6443A7-F9AF-477A-8C35-9BECD03C63FB',
    Economy_View: "DCA7404B-D014-4B82-AAD3-A45071196668",
    Planning_Leave_Approve: "EFCFF742-70A0-4C66-9B7E-6584E99BA48C",
    Change_Role: "EBC03603-4B12-4974-9D1A-B41798136E45",
    PurchasePrice_View:"03F14528-C1CB-45A7-BA5E-2A0AF7C7ACE8",
    Coworker_View_Files: "BBCCC575-8AA5-4510-B6CC-902534630F1B",
    Coworker_View_Other_Fields: "2133702A-2A80-4D03-96A8-A1C33E03F5EA",
    Coworker_View_Keys: "BF12075F-5AA3-4CA9-A030-4D736DBF29DA",
    Own_Payroll_View: "03F11077-EAB2-42AF-AD7F-033C0F02917D",
    Own_Workhour_View: "418F7FC2-500A-47F4-B316-B9FBB01A9E62",
    Upload_Material_Price: "C990CA2C-DD48-473A-ACAE-036E49D4EF54",
    Material_Barcode_Size: "80222EA3-7567-4D75-9176-AF7A895E3DC7",
    Material_Order_Tab: "EC0CC07D-68DF-4ACE-99FC-B0E8524EB69D",
    Material_Ordered_Tab: "6FEFEB7D-50C3-44FC-A1E9-8FCD090A196A",
    Material_Sold_Stock: "938E5C55-C2B7-4CC3-83C6-13B60C48120A",
    Material_Log: "9C1BE25F-29AF-469C-8506-13221F3EEF30",
    Coworker_CPR_View: "8CDAAAC6-B1C1-445F-9323-573726B88B7D",
    Coworker_Answer_View:"E2025BAB-961C-465B-8AB2-E37037AF2C04",
    View_Email_SMS_Notification_Settings:'A201B43E-1C7A-4CA4-B76F-3AD93694F876',
    Project_Requisition_Modify:'C13E4589-908E-4F22-80F2-8A42899F3EC9',
    Project_Requisition_Delete:'DBC7A1FA-A1F8-4CB2-9C59-0F46D19E9C99',
    Project_Material_Modify:'50664036-141B-4411-A874-42231CC8685D',
    Project_Material_Delete:'75DAC1CA-D920-46CF-9FA2-390999F3867D',
    Project_Coworker_Modify:'C81652B4-07E6-4CAA-9CD4-34A9C38FD195',
    Project_Coworker_Delete:'57970EC8-D47F-49A8-A704-4F59E676B948',
    WorkHours_Leave_Approve: "0E591560-41F7-441A-A267-E4074F241B26",
    Material_Calculation_Container: "E0067C28-1BEC-4AB7-8607-CF8588040CF3",
    Supplier_Quick_Material: "BEFB807A-8D97-4CF8-A6A6-080FF4078B13",
    Use_Nanolink: "9A6EE586-5783-4EF0-A90A-0CD80C681B9E",
    Customer_Project_Tab: "941E262B-C969-4610-AF1B-E93E700752EF",
    Customer_Files_Tab: "A57B1BA9-4938-48EB-A215-D2BE72A97ED4",
    Customer_Log_Tab: "4B88A74C-8C03-4BA4-9933-4DEC1515B49B",
    Customer_ColorCode_Tab: "35A87488-314F-448B-A866-CC9A28F6B844",
    Customer_Message_Log_Tab: "A23B3755-F28B-47C1-A4F7-B8E2BFF4A31D",
    Customer_Properties_Tab: "9C3824C1-4519-47FF-AFF1-1318B08DB445",
    Customer_Materials_Tab: "B391DF45-5B05-49D8-9D42-816E75B4F1EA",
    Customer_Project_Recuring_Settings_Tab: "08D3FB7D-150D-411A-8E1A-082B7352F877",
    Project_Files_Tab: "BCA2B96F-B6D5-4352-BCC8-4D9652AAD4F8",
    Project_Workhours_Tab: "5F873B5C-5EFC-42F2-BAC7-1E0FD3CA7D64",
    Project_Log_Tab: "BF0A2989-A822-439A-851D-A8AE199945D6",
    Project_Statistic_Tab: "04A7D112-E044-4FE2-9EB2-63EF9008C056",
    Project_Note_Tab: "0FB1B49F-A77E-4AF1-9053-75B08EDFDFE5",
    Project_MessageLog_Tab: "D299FF84-C66B-425D-8B86-D9D545D9AB03",
    Project_RoomBuilding_Tab: "7C50C97D-B20C-468A-92AE-4E72E2C33908",
    Project_QualitityTest_Tab: "332DFC99-1224-47D3-9FFF-A2C9961EC451",
    Project_Location_Tab: "C404E3DF-D15C-479F-8BAA-0E2BC402FEDA",
  }