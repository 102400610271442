export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            //'Created': 'Created successfully',
            'OfferPriceDel': 'Pris slettet med succes.',
        },
        'Headers': {
            'DeleteOfferPrice': 'Er du sikker på, at du vil slette pris.'
        },
        'FIELDS': {
            'OfferText': 'Tilbudsnavn',
            'MaterialType': 'Materiale Type',
            'Time': 'Antal gange',
            'Price': 'Pris',
            'PaintText': 'Maling tekst',
            'StartMeter1': 'Start Meter 1',
            'EndMeter1': 'End Meter 1',
            'PriceMeter1': 'Pris Meter 1',
            'TimeMeter1': 'Arbejdstid pr. stk 1',
            'StartMeter2': 'Start Meter 2',
            'EndMeter2': 'End Meter 2',
            'PriceMeter2': 'Pris Meter 2',
            'TimeMeter2': 'Arbejdstid pr. stk 2',
            'StartMeter3': 'Start Meter 3',
            'EndMeter3': 'End Meter 3',
            'PriceMeter3': 'Pris Meter 3',
            'TimeMeter3': 'Arbejdstid pr. stk 3',
            "TreatmentText": 'Treatment tekst',
            'Reminder': 'Påmindelses',
            'ExpensesType': 'Udgifts Type',
            'ExpensesAmount': 'Udgifts beløb',
            'AddNumberOfTimesOnPDF':'Tilføj antal gange på PDF',
            'IsAddedWoodwork':'Er tilføjet træværk',
            'TreatmentType':'Behandlings type',
            'AddPriceOnPDF':'Tilføj Pris Til PDF',
            'AddUnitOnPDF':'Tilføj Enhed på PDF',
            'NotCalculateToDriving':'Ikke beregne til Kørsel',
            'TimeMeter': 'Arbejdstid pr. stk',
        },
        'BUTTONS': {
            'ADDOFFER': 'Tilføj tilbud',
            'REMOVEOFFER': 'Fjern tilbud',
            'ADDPRICE': 'Pris M2',
            'ADDNOOFTIMES': 'Antal Gange',
            'Calculation':'Prisbogen'
        },
        
        
        'Validations': {
            'StartMeter1': 'Bør ikke være null.',
            'EndMeter1': 'Bør ikke være null.',
            'PriceMeter1': 'Bør ikke være null.',
            'StartMeter2': 'Bør ikke være null.',
            'EndMeter2': 'Bør ikke være null.',
            'PriceMeter2': 'Bør ikke være null.',
            'StartMeter3': 'Bør ikke være null.',
            'EndMeter3': 'Bør ikke være null.',
            'PriceMeter3': 'Bør ikke være null.',
            'Text': 'Tilbudsnavn bør ikke være null.',
            'Type': 'Materiale type bør ikke være null.'
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
        },
    }
};

