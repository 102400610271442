import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption, MatDialog, MatExpansionPanel } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestProjectRequisitionByCowokrer, RequestCoWorker, RequestCaseTask, SMSModules, RequestResourceWorkHours } from 'app/main/model/RequestBase';
import { ProjectMaterial, Project } from "app/main/model/Project";
import { CoWorker, DeleteFutureAllEmployeeOnRequisition } from "app/main/model/CoWorker";
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/core/services/common.service';
import { NotificationsService } from 'angular2-notifications';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { AuthService } from 'app/core/services/auth.service';
import { ShiftType } from 'app/main/model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';
import { WorkHour, CoworkerDays, WorkhoursDay } from 'app/main/model/WorkHour';
import { projectRequisition, ProjectHoursDetail } from 'app/main/model/Project';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { AlertPlanningMessageModelComponent } from 'app/main/pages/alert-planning-message-model/alert-planning-message-model.component';
import { UrgentOrNot, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { WeekendOption } from 'app/constant/common.constant'
import { ShiftSelectionComponent } from 'app/main/pages/calendar/component/shift-selection/shift-selection.component'
import { CoWorkerComponent } from 'app/main/pages/calendar/component/coWorker/coWorker.component';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';

@Component({
    selector: 'vibe-calender-edit-working-hour-popup',
    templateUrl: './edit-working-hour-popup.component.html',
    styleUrls: ['./edit-working-hour-popup.component.scss']
})
export class CalenderEditWorkingHourPopupComponent implements OnInit {
    startWeek: number = 1;
    projectInfo: any = {};
    requistionInfo: any = {};
    selectProject: string = "";
    coWorkerList: CoWorker[] = new Array<CoWorker>();
    searchCoWorkerList: CoWorker[] = new Array<CoWorker>();
    selectedCoWorkerList: CoWorker[] = new Array<CoWorker>();
    selectedCoWorkerListOnEdit: CoWorker[] = new Array<CoWorker>();
    projectCoWorkers: any = [];
    showSpinner = false;
    createEventObj: any = {};
    shiftType = ShiftType;
    projectList: any = [];
    projectFullList: any = [];
    workHour = new WorkHour();
    RequisitionList: projectRequisition[] = [];
    //currentselectedCoworkerIds: any = [];
    currentselectedCoworkerIds: any = "";
    projectCoworkerObj: any = [];
    Requisition: projectRequisition;
    projectMaterials: ProjectMaterial[] = [];
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    hours: any;
    mins: any;
    isAddBreakTime: boolean = true;
    defaultCoWorkerList: any = [];
    isDisableWorkType = false;
    weekNumbers: any = [];
    isDisplayErrorMessage: boolean = false;
    isComingFromEditMode: boolean = false;
    workHourNotAddedOnCoworker: string = "";
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    UrgentOrNot = UrgentOrNot;
    workHourId: string = "";
    //roleName: string;
    isPastDate: boolean = false;
    CoWorkerName: string = "";
    isSelected: boolean = true;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    DeleteFutureEmployeeOnRequisition = new DeleteFutureAllEmployeeOnRequisition();
    isDeleteAll: boolean = false;
    selectStatus = ProjectStatus;
    IsExpand: boolean = false;
    IsExpandOtherDetails: boolean = false;
    ProjectFilePath: any;
    displayMtoMTabRed: boolean = false;
    displayNoteTabRed: boolean = false;
    displayOffersTabRed: boolean = false;
    displaySpecialInfoRed: boolean = false;
    reqTabselectedIndex: number = 0;
    previousIndex: number = 0;
    mtomDetail: boolean = false;
    noteDetail: boolean = false;
    offerDetail: boolean = false;
    coworkerListAndPlaningDates = [];
    holidayList = [];
    isHolidayInPlaning: boolean = false;
    isCoworkerInLeave: boolean = false;
    holidayListBetweenDates: any = [];
    coworkerLeaveList: any = [];
    tempCoworkerListForLeave: any = [];
    isViewCowrokerPlanning: boolean = false;
    workingDays: number = 0;
    message: any = "";
    fullMessage: any = "";
    workingdayList = [];
    totalWorkHourOfCoworker: number;
    workHoursForLiftCoworker: any = [];
    liftReserved: any = [];
    showSpinnerForEdit = false;
    ObjProject: Project;
    selectedIndex: number = 0;
    coworkerDetail: any;
    isOpenMaterialPopup: boolean = false;
    isGotoMaterial: boolean = false;
    weekendOption = WeekendOption;
    planningDates: any = [];
    selectedcoWorkerIds: any = [];
    public workHoursList: any = [];
    isChangeAll : boolean = false;
    workHourList:any = new Array<WorkHour>();
    requestWorkHourList:any = new Array<WorkHour>();
    projectHoursDetail = new ProjectHoursDetail();
    coworkerForDelete: any = [];
    isViewPastPlanning: boolean = false;
    isMoveDateSelected: boolean = false;
    roleClaims: any;
    hasPermision: boolean = false;
    hasProjectPermision: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private workhourservice: WorkhoursService,
        private projectRequisitionService: ProjectRequisitionService,
        private projectService: ProjectService,
        private calendarService: CalendarService,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private commonService: CommonService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private dropboxFilesService: DropboxFilesService,
        public dialogRef: MatDialogRef<CalenderEditWorkingHourPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
        this.getDefaultCoworkers();
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.authService.hasClaim(this.roleClaims.Planning_Modify).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasPermision = hasClaim
             else 
                this.hasPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Project_Modify).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasProjectPermision = hasClaim
             else 
                this.hasProjectPermision = false;
        });
        this.isComingFromEditMode = this.data.isComingFromEditMode;
        this.projectInfo = this.data.projectInfo;
        this.workHourId = this.projectInfo.workHourId;
        this.isGotoMaterial = this.data.isGotoMaterial;
        this.createEventObj.selectedDate = this.projectInfo.startDate;
        if (this.projectInfo.otherInfo) {
            var customDateObj = JSON.parse(this.projectInfo.otherInfo);
            if(customDateObj && customDateObj.PlanningDates)
                this.planningDates = customDateObj.PlanningDates.split(",");
            this.createEventObj.startDate = customDateObj.StartDate;
            this.createEventObj.endDate = customDateObj.EndDate;
            this.createEventObj.weekendId = customDateObj.WeekendId
            this.projectInfo.endDate = this.createEventObj.endDate;
            this.createEventObj.isNotifyToCustomer = customDateObj.IsNotifyToCustomer;
            if(customDateObj.BunchId)
                this.getWorkHoursByBunchId(customDateObj.BunchId)
        }
        else {
            this.createEventObj.startDate = (this.projectInfo.startDate ? this.projectInfo.startDate : new Date());
        }
        this.DeleteFutureEmployeeOnRequisition.startDate = this.createEventObj.selectedDate;
        this.DeleteFutureEmployeeOnRequisition.endDate = this.createEventObj.endDate;
        //this.DeleteFutureEmployeeOnRequisition.endDate = this.createEventObj.startDate;
        this.selectProject = this.projectInfo.projectTitle;
        this.coWorkerList = this.data.coworkerList;
        this.searchCoWorkerList = this.coWorkerList;
        this.createEventObj.leaveStatus = this.projectInfo.leaveStatus;
        this.createEventObj.shift = this.projectInfo.shiftType;
        this.createEventObj.projectId = this.projectInfo.sagId;
        this.createEventObj.sagOpgaveID = this.projectInfo.sagOpgaveID;
        this.createEventObj.projectNumber = this.projectInfo.sagNumber;
        this.createEventObj.status = this.projectInfo.status;
        this.createEventObj.departmentPhone = "";
        this.createEventObj.size = "";
        this.createEventObj.indoorOutdoorStatusId = this.projectInfo.indoorOutdoorStatusId;
        this.createEventObj.urgentOrNot = this.projectInfo.urgentOrNot;
        this.createEventObj.visit = this.projectInfo.visit ? moment(this.projectInfo.visit).format('DD.MM.YYYY HH:mm') : "-";
        this.createEventObj.projectBasedPlanning = this.projectInfo.projectBasedPlanning;
        this.createEventObj.indoorOutdoorStatusIds = [];
        this.ProjectFilePath = [];
        this.ProjectFilePath.push(this.dropboxFilesService.getSettingMasterValueDocument());
        this.ProjectFilePath.push('Project');
        this.ProjectFilePath.push(this.createEventObj.projectNumber);
        if (this.projectInfo.indoorOutdoorStatusId) {
            if (this.projectInfo.indoorOutdoorStatusId.includes(',')) {
                var SplitIndoorOutdoorStatusId = this.projectInfo.indoorOutdoorStatusId.split(',');
                this.selectIndoorOutDoorStatus.filter(x => {
                    SplitIndoorOutdoorStatusId.filter(y => {
                        if (parseInt(y) == x.id)
                            this.createEventObj.indoorOutdoorStatusIds.push(x);
                    })
                });
            }
            else {
                this.selectIndoorOutDoorStatus.filter(x => {
                    if (parseInt(this.projectInfo.indoorOutdoorStatusId) == x.id)
                        this.createEventObj.indoorOutdoorStatusIds.push(x);
                });
            }
        }
        var request = new RequestProjectRequisitionByCowokrer;
        request.ProjectId = this.projectInfo.sagId;
        this.getRequisitionList(request);
        if (this.isComingFromEditMode) {
            //this.createEventObj.sagOpgaveID = this.projectInfo.sagOpgaveID;
            setTimeout(() => {
                this.createEventObj.endDate = this.projectInfo.endDate;
            }, 500)
            //this.currentselectedCoworkerIds = [this.projectInfo.coworkerId];
            this.currentselectedCoworkerIds = this.projectInfo.coworkerId;
            this.workHour.fromStartHour = this.projectInfo.startHour;
            this.workHour.fromStartMinute = this.projectInfo.startMinute;
            this.workHour.fromEndHour = this.projectInfo.endHour;
            this.workHour.fromEndMinute = this.projectInfo.endMinute;
            this.isAddBreakTime = this.projectInfo.isPauseTime;
        }
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
        //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }]
        this.addWeeksNumber();
        this.GetProjectAllFileInfo();
        this.getCoworkerName()
    }

    DropDownSearchCoWorker(value) {
        this.searchCoWorkerList = this.coWorkerList.filter(o => o.name.toLowerCase().includes(value.toLowerCase()));
    }

    getCoworkerName() {
        this.coworkerDetail = this.coWorkerList.find(o => o.id == this.currentselectedCoworkerIds);
    }

    DropDownCoWorkerSelect(id) {
        var alreadySelectedCoworker = this.selectedCoWorkerList.find(o => o.id == id);
        if (alreadySelectedCoworker)
            this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        else {
            var findCoworker = this.coWorkerList.find(o => o.id == id);
            if (findCoworker)
                this.selectedCoWorkerList.push(findCoworker);
        }
    }

    selectedCoWorkerDelete(id) {
        this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        this.currentselectedCoworkerIds = this.selectedCoWorkerList.map(o => o.id);
    }

    DropDownRequistionSelect(requisition) {
        var message = "";
        this.translate.get("MESSAGES.RequisitionChangeNotification").subscribe({
            next: (res) => {
                message = res; 
            },error: err => {}
        });
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            width: '35%',
            data: { Header: message },
            disableClose : true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.Requisition = requisition;
            this.createEventObj.departmentPhone = this.Requisition.departmentPhone ? this.Requisition.departmentPhone : "";
            this.createEventObj.size = this.Requisition.size ? this.Requisition.size : "0";
            this.createEventObj.startDate = requisition.startDate ? requisition.startDate : new Date();
            this.calculatePrice();
            this.refreshMaterialList();
            if (!this.isComingFromEditMode) {
                let objCoWorkerProject = new RequestCoWorker();
                objCoWorkerProject.IsDeleted = false;
                objCoWorkerProject.ProjectId = this.createEventObj.ProjectId;
                objCoWorkerProject.fileAssignmentId = requisition.id;
                this.getprojectCoworkerList(objCoWorkerProject);
            }
            if (result) {  
                this.workHourList.filter(item => {
                    if(!item.isPastDate) {
                        item.requisitionId = this.Requisition.id;
                        item.sagOpgaveID = this.Requisition.id;
                        item.isChecked = true;
                        var tempAdditionalInfo = JSON.parse(item.otherInfo);
                        if(tempAdditionalInfo && tempAdditionalInfo.SagOpgaveID){
                            tempAdditionalInfo.SagOpgaveID = item.requisitionId;
                            item.otherInfo = JSON.stringify(tempAdditionalInfo);
                        }
                    }
                });
            }
            else {
                this.workHourList.filter(item => {
                    if(!item.isPastDate  && item.id == this.workHourId) {
                        item.requisitionId = this.Requisition.id;
                        item.sagOpgaveID = this.Requisition.id;
                        var tempAdditionalInfo = JSON.parse(item.otherInfo);
                        if(tempAdditionalInfo && tempAdditionalInfo.SagOpgaveID){
                            tempAdditionalInfo.SagOpgaveID = item.requisitionId;
                            item.otherInfo = JSON.stringify(tempAdditionalInfo);
                        }
                    }
                });
            }
            this.checkMovingDateSelected();
        });

        
    }

    getRequisitionList(model) {
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.RequisitionList = response.result;
                    if (this.RequisitionList.length > 0) {
                        this.createEventObj.status = this.RequisitionList[0].status;
                        if (!this.isComingFromEditMode) {
                            if (!this.createEventObj.sagOpgaveID) {
                                this.Requisition = this.RequisitionList[0];
                                this.createEventObj.sagOpgaveID = this.RequisitionList[0].id;

                            }
                            else {
                                var curReq = this.RequisitionList.find(o => o.id == this.createEventObj.sagOpgaveID);
                                this.Requisition = curReq;
                            }
                            this.createEventObj.startDate = this.Requisition.startDate ? this.Requisition.startDate : new Date();
                            this.calculateEndDateWithRemainingHour();
                            this.createEventObj.departmentPhone = this.RequisitionList[0].departmentPhone;
                            this.createEventObj.size = this.RequisitionList[0].size;
                            let objCoWorkerProject = new RequestCoWorker();
                            objCoWorkerProject.IsDeleted = false;
                            objCoWorkerProject.ProjectId = model.ProjectId;
                            objCoWorkerProject.fileAssignmentId = this.createEventObj.sagOpgaveID;
                            this.getprojectCoworkerList(objCoWorkerProject);
                        }
                        else {
                            var curReq = this.RequisitionList.find(o => o.id == this.createEventObj.sagOpgaveID);
                            this.Requisition = curReq;
                            if (curReq) {
                                this.createEventObj.departmentPhone = curReq.departmentPhone;
                                this.createEventObj.size = curReq.size;
                            }
                            let objCoWorkerProject = new RequestCoWorker();
                            objCoWorkerProject.IsDeleted = false;
                            objCoWorkerProject.ProjectId = model.ProjectId;
                            objCoWorkerProject.fileAssignmentId = this.createEventObj.sagOpgaveID;
                            this.getprojectCoworkerList(objCoWorkerProject);
                        }
                        if (this.Requisition) {
                            this.calculatePrice();
                            this.refreshMaterialList();
                            if (this.Requisition.doNotUpdateHour == "T")
                                this.Requisition.donotUpdateWorkHour = true;
                            else
                                this.Requisition.donotUpdateWorkHour = false;
                            if (this.isGotoMaterial)
                                this.goToStep(6);

                        }
                        var reqID = this.Requisition != null ? this.Requisition.id : null;
                        this.getCowrkerListAndPlanningDate(this.projectInfo.sagId, reqID);
                        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.selectedDate).setHours(0, 0, 0, 0)) {
                            this.isPastDate = false;
                        }
                        else {
                            this.isPastDate = moment(this.createEventObj.selectedDate, "YYYY-MM-DD").isBefore();
                        }
                    }
                    if (this.Requisition && (this.Requisition.left || this.Requisition.riseman)) {
                        this.displaySpecialInfoRed = true;
                    }
                    else {
                        this.displaySpecialInfoRed = false;
                    }
                    this.getProjectTimerFromRequisition();
                }
                else
                    this.RequisitionList = [];
            },
            error: err => {
                this.RequisitionList = [];
            },
            complete: () => {
            }
        });
    }

    getprojectCoworkerList(modal) {
        this.projectService.GetProjectCoWorkerList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.projectCoWorkers = response.result;
                    if (this.projectCoWorkers && !this.isComingFromEditMode) {
                        this.currentselectedCoworkerIds = [];
                        this.selectedCoWorkerList = [];
                        for (var i = 0; i < this.projectCoWorkers.length; i++) {
                            var selectedonPeojectCoworker = this.defaultCoWorkerList.find(o => o.id == this.projectCoWorkers[i].coworkerId);
                            if (!selectedonPeojectCoworker) {
                                this.currentselectedCoworkerIds.push(this.projectCoWorkers[i].coworkerId);
                                var selectCoworker = this.coWorkerList.find(o => o.id == this.projectCoWorkers[i].coworkerId);
                                if (selectCoworker) {
                                    this.selectedCoWorkerList.push(selectCoworker);
                                }
                            }
                        }
                    }
                    //On Edit Display Co-Worker List
                    if (this.isComingFromEditMode) {
                        for (let i = 0; i < this.projectCoWorkers.length; i++) {
                            var selectedonPeojectCoworker = this.defaultCoWorkerList.find(o => o.id == this.projectCoWorkers[i].coworkerId);
                            //if (!selectedonPeojectCoworker) {
                            var selectCoworker = this.coWorkerList.find(o => o.id == this.projectCoWorkers[i].coworkerId);
                            if (selectCoworker) {
                                if (selectCoworker.id == this.currentselectedCoworkerIds) {
                                    selectCoworker.isSelectedDelete = true
                                } else {
                                    selectCoworker.isSelectedDelete = false
                                }
                                this.selectedCoWorkerListOnEdit.push(selectCoworker);
                            }
                            //  }
                        }
                        this.coworkerForDelete=this.selectedCoWorkerListOnEdit.filter(x=>x.isSelectedDelete==true);
                    }
                }
            },
                error => (err) => { },
                () => { });
    }
    onFocusIn(event) {
        event.model.show();
    }

    emailToCustomerByProject(projectId) {
        this.projectService.GetSingle<ResponseBase>(projectId).subscribe({
            next: (response: ResponseBase) => {
                if (response.result) {
                    this.ObjProject = response.result;

                    var emailSendingParam = new EmailSendingParam();
                    emailSendingParam.fromName = this.authService.getUserName();
                    // emailSendingParam.from = "post@vibesmalerfirma.dk";
                    emailSendingParam.to = this.ObjProject.email;
                    emailSendingParam.isLoadDefaultEmailTemplate = true;
                    emailSendingParam.subject = "Vi kommer til dit sted og maler";
                    emailSendingParam.text = "Vi forventer at komme og male hos jer " + (this.createEventObj.startDate ? moment(this.createEventObj.startDate).format("DD.MM.YYYY") : "") + " kl. " + this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                    emailSendingParam.coworkerList = this.coWorkerList;
                    const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
                        height: '90%',
                        width: '90%',
                        data: emailSendingParam
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result != null && result != '') {
                            this.showSpinner = true;
                            var tempLogStatusToSendEmail = new EmailSendingParam();
                            tempLogStatusToSendEmail = result;
                            this.projectService.SendEmailToCustomerNotify<ResponseBase>(tempLogStatusToSendEmail, this.workHourId)
                                .subscribe({
                                    next: (response: ResponseBase) => {
                                        if (response.message = "Email Send Successfully")
                                            this.projectInfo.isNotifiedToTheCustomer = true;
                                        this.showSpinner = false;
                                        this.translate.get("MESSAGES.EmailSent").subscribe({
                                            next: (res) => {
                                                this.notificationsService.create(res);
                                            },
                                            error: err => {
                                            }
                                        });
                                        this.commonService. checkEmailIsToBeSent();

                                    },
                                    error: err => {
                                        this.showSpinner = false;
                                        this.translate.get("MESSAGES.Failed").subscribe({
                                            next: (res) => {
                                                this.notificationsService.error(res);
                                            },
                                            error: err => {
                                            }
                                        });

                                    },
                                    complete: () => {
                                        this.showSpinner = false;
                                    }
                                });
                        }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
        });
    }


    GetProjectAllFileInfo() {
        this.projectService.GetProjectAllFileInfo<any>(this.createEventObj.projectId)
            .subscribe({
                next: (response) => {
                    var fileInfoData = response.result;
                    this.mtomDetail = fileInfoData.mtomDetail;
                    this.noteDetail = fileInfoData.noteDetail;
                    this.offerDetail = fileInfoData.offerDetail;
                },
                error: err => { },
            });
    }

    saveTimer() {
        if (this.selectedCoWorkerList.length == 1 && this.selectedCoWorkerList[0].name == "Lift") {
            this.workHour.dayList = [];
            var localstartDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
                new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
            var localEndDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
                new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);
            for (let day = localstartDate; day <= localEndDate; day.setDate(day.getDate() + 1)) {
                this.workHour.dayList.push(moment(day).format("YYYY-MM-DD"));
            }
        }
        else
            this.workHour.dayList = this.workHour.dayList.filter(x=>x.isChecked == true).map(p => p.date);

        this.workHour.id = this.workHourId;
        this.workHour.weekendId = this.createEventObj.weekendId;
        this.workHour.projectId = this.createEventObj.projectId;
        //this.workHour.workerId = this.currentselectedCoworkerIds.join().toString();
        this.workHour.workerId = this.currentselectedCoworkerIds;
        this.workHour.sagOpgaveID = this.createEventObj.sagOpgaveID;
        this.workHour.isWorking = true;
        this.workHour.shiftType = this.createEventObj.shift;
        this.workHour.selectedDate = this.createEventObj.selectedDate;
        var tempSeletedDate = moment(this.workHour.selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD");
        this.workHour.selectedDate = tempSeletedDate;

        if (this.createEventObj.shift == 2) {
            this.workHour.from = "07:00";
            this.workHour.to = "14:30";
            this.workHour.Tid = 7.5;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "7";
        }
        else if (this.createEventObj.shift == 3) {
            this.workHour.from = "07:00";
            this.workHour.to = "15:00";
            this.workHour.Tid = 8.00;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "07.5";
        }
        else if (this.createEventObj.shift == 4) {
            this.workHour.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            this.workHour.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            var time = moment.utc(moment(this.workHour.to, "HH:mm").diff(moment(this.workHour.from, "HH:mm"))).format("HH:mm")
            var tidTime = moment(time, "HH:mm").format("mm");
            var tidHours = moment(time, "HH:mm").format("HH");
            if (tidTime != "00")
                time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
            this.workHour.Tid = Number(time.replace(':', '.'));
            if (this.isAddBreakTime)
                this.workHour.pauseTimer = 0.5;
            else
                this.workHour.pauseTimer = 0;
            this.workHour.Hours = time.replace(':', '.');
        }
        var startDate = moment(moment(this.createEventObj.startDate).format('YYYY/MM/DD'));
        var endDate = moment(moment(this.createEventObj.endDate, "DD.MM.YYYY").format('YYYY/MM/DD'));
        this.workHour.startDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
        this.workHour.endDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
        this.workHour.days = Math.abs(startDate.diff(endDate, 'days'));
        this.workHour.date = this.datePipe.transform(this.createEventObj.startDate, "MM/dd/yyyy");
        // var projectCoworkerObj = this.projectCoworkerObj;
        // var newAddedCowkerList = this.currentselectedCoworkerIds.filter(function (item) {
        //     return !projectCoworkerObj.includes(item);
        // })
        //this.workHour.newAddedCoworkerIds = newAddedCowkerList ? newAddedCowkerList.join().toString() : "";
        this.workHour.newAddedCoworkerIds = this.currentselectedCoworkerIds;
        //this.workHour.CoworkerDetailsId = [];
        //var CoworkerDetails = new CoworkerDays();
        //CoworkerDetails.CoworkerId = this.workHour.workerId;
        //CoworkerDetails.ScheduleDays.push(this.workHour.date);
        //this.workHour.CoworkerDetailsId.push(CoworkerDetails);
        if(this.isChangeAll)
            this.addWorkhourAPICall(this.workHour);
        else
            this.moveWorkhourAPICall(this.workHour);
    }

    deleteTimer() {
        var coworkerName = [];
        for (let i = 0; i < this.selectedCoWorkerListOnEdit.length; i++) {
            if (this.selectedCoWorkerListOnEdit[i].isSelectedDelete) {
                coworkerName.push(this.selectedCoWorkerListOnEdit[i].name);
            }
        }
        var date = moment(this.DeleteFutureEmployeeOnRequisition.startDate).format("DD.MM.YYYY");
        var name = coworkerName.toString();
        var message = ""; var message1 = ""; var message2 = "";
        this.translate.get("MESSAGES.DeleteToday").subscribe({
            next: (res) => {
                message1 = res; //+ ' ' + name + ' ' + 'on' + ' ' + date;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteTodayTwo").subscribe({
            next: (res) => {
                message2 = res;
            },
            error: err => {
            }
        });
        message = message1 + ' ' + name + ' ' + message2 + ' ' + date;
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            data: { Header: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var coWorkerIds = [];
                for (let i = 0; i < this.selectedCoWorkerListOnEdit.length; i++) {
                    if (this.selectedCoWorkerListOnEdit[i].isSelectedDelete == true) {
                        coWorkerIds.push(this.selectedCoWorkerListOnEdit[i].id);
                    }
                }
                this.DeleteFutureEmployeeOnRequisition.coworkerId = coWorkerIds;
                this.DeleteFutureEmployeeOnRequisition.requisitionId = this.createEventObj.sagOpgaveID;
                var tempdate = moment(this.DeleteFutureEmployeeOnRequisition.startDate, "DD.MM.YYYY").format("YYYY-MM-DD");
                this.DeleteFutureEmployeeOnRequisition.startDate = tempdate;
                //this.DeleteFutureEmployeeOnRequisition.date = moment(new Date()).format("YYYY-MM-DD");
            }
            this.calendarService.DeleteWorkHourForPlanning(this.DeleteFutureEmployeeOnRequisition).subscribe({
                next: (response: ResponseBase) => {
                    this.translate.get("MESSAGES.Delete").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            dialogRef.close();
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        response: response.statusCode == 200 ? [{...this.DeleteFutureEmployeeOnRequisition, action:"delete"}] : null,
                    });
                },
                error: err => { },
                complete: () => { }
            });
        });
    }

    deleteFutureWorkHourEntry() {
        var coworkerName = [];
        for (let i = 0; i < this.selectedCoWorkerListOnEdit.length; i++) {
            if (this.selectedCoWorkerListOnEdit[i].isSelectedDelete) {
                coworkerName.push(this.selectedCoWorkerListOnEdit[i].name);
            }
        }
        var message = ""; var message1 = ""; var message2 = ""; var message3 = "";
        var startdate = moment(this.DeleteFutureEmployeeOnRequisition.startDate).format("DD.MM.YYYY");
        if (this.DeleteFutureEmployeeOnRequisition.endDate) {
            var enddate = moment(this.DeleteFutureEmployeeOnRequisition.endDate).format("DD.MM.YYYY");
        }
        var name = coworkerName.toString();
        var message = "";

        this.translate.get("MESSAGES.DeleteToday").subscribe({
            next: (res) => {
                message1 = res;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteOn").subscribe({
            next: (res) => {
                message2 = res;
            },
            error: err => {
            }
        });
        if (!enddate || enddate == "Invalid date") {
            this.translate.get("MESSAGES.DeleteFutureEmp").subscribe({
                next: (res) => {
                    message1 = res;
                },
                error: err => {
                }
            });
            this.translate.get("MESSAGES.From").subscribe({
                next: (res) => {
                    message2 = res;
                },
                error: err => {
                }
            });
            message = message1 + ' ' + name + ' ' + message2 + ' ' + startdate;
        } else if (enddate > startdate) {
            this.translate.get("MESSAGES.DeleteToday").subscribe({
                next: (res) => {
                    message1 = res;
                },
                error: err => {
                }
            });

            this.translate.get("MESSAGES.From").subscribe({
                next: (res) => {
                    message2 = res;
                },
                error: err => {
                }
            });

            this.translate.get("MESSAGES.To").subscribe({
                next: (res) => {
                    message3 = res;
                },
                error: err => {
                }
            });
            message = message1 + ' ' + name + ' ' + message2 + ' ' + startdate + ' ' + message3 + ' ' + enddate;
        } else {
            message = message1 + ' ' + name + ' ' + message2 + ' ' + startdate;
        }
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            data: { Header: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var coWorkerIds = [];
                for (let i = 0; i < this.selectedCoWorkerListOnEdit.length; i++) {
                    if (this.selectedCoWorkerListOnEdit[i].isSelectedDelete == true) {
                        coWorkerIds.push(this.selectedCoWorkerListOnEdit[i].id);
                    }
                }
                this.DeleteFutureEmployeeOnRequisition.coworkerId = coWorkerIds;
                this.DeleteFutureEmployeeOnRequisition.requisitionId = this.createEventObj.sagOpgaveID;
                this.DeleteFutureEmployeeOnRequisition.projectId = this.createEventObj.projectId;
                var tempStartDate = moment(this.DeleteFutureEmployeeOnRequisition.startDate, "DD.MM.YYYY").format("YYYY-MM-DD");
                this.DeleteFutureEmployeeOnRequisition.startDate = tempStartDate;
                if (this.DeleteFutureEmployeeOnRequisition.endDate) {
                    var tempEndDate = moment(this.DeleteFutureEmployeeOnRequisition.endDate, "DD.MM.YYYY").format("YYYY-MM-DD");
                    this.DeleteFutureEmployeeOnRequisition.endDate = tempEndDate;
                } else {
                    this.DeleteFutureEmployeeOnRequisition.endDate = null;
                }
                //this.DeleteFutureEmployeeOnRequisition.date = moment(new Date()).format("YYYY-MM-DD");
                this.calendarService.DeleteFutureDateWorkHour<ResponseBase>(this.DeleteFutureEmployeeOnRequisition)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.translate.get("MESSAGES.Delete").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                projectId: this.projectInfo.sagId,
                                workHourId: this.workHourId,
                                response: response.statusCode == 200 ? [{...this.DeleteFutureEmployeeOnRequisition, action:"delete"}] : null,
                            });
                        },
                        error: err => { },
                        complete: () => { }
                    });
            }
        });
    }

    goToProject() {
        this.dialogRef.close({
            updateSchedule: false,
            deleteSchedule: false,
            goToProject: true,
            projectId: this.projectInfo.sagId,
            workHourId: this.workHourId,
        });
    }

    addWorkhourAPICall(workHour) {
        this.workhourservice.MoveWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinnerForEdit = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        weekNumber: moment(this.createEventObj.startDate).week().toString(),
                    });
                },
                error: err => {
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinnerForEdit = false;
                }
            });
    }

    getTransator(value) {
        if (value)
            return 'Lable.Yes'
        else
            return 'Lable.No'
    }

    refreshMaterialList() {
        let objMaterialProject = new RequestCaseTask();
        objMaterialProject.fileAssignmentId = this.Requisition.id;
        objMaterialProject.IsDeleted = false;
        this.getProjectMaterialList(objMaterialProject);
    }

    getProjectMaterialList(modal) {
        this.showSpinner = true;
        this.projectService.GetProjectMaterialList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.projectMaterials = response.result;
                    // this.materialTotalPrice = 0;
                    // this.materialTotalPriceWithVAT = 0;
                    // this.materialPersonalTotalPrice = 0;
                    // this.projectMaterials.forEach((e: any) => {
                    //   if (e.quantity && e.quantity != 0) {
                    //     this.materialTotalPrice = this.materialTotalPrice + this.getTotalPrice(e.quantity, e.retailPrice);
                    //   }
                    //   if (e.personalPris && e.personalPris != 0) {
                    //     this.materialPersonalTotalPrice = this.materialPersonalTotalPrice + this.getTotalPrice(e.quantity, e.personalPris);
                    //   }
                    //   else {
                    //     e.personalPris = 0;
                    //   }
                    // });
                    // var vat = (this.materialTotalPrice * 25) / 100;
                    // this.materialTotalPriceWithVAT = this.materialTotalPrice + vat;
                    this.calculateProjMaterialPriceTotal();
                    this.projectMaterials.filter(p => { p.quantity = parseFloat(p.quantity.toFixed(2)); });
                }
            },
                error => (err) => {
                }, () => { });
    }

    calculateProjMaterialPriceTotal() {
        this.materialTotalPrice = 0;
        this.materialTotalPriceWithVAT = 0;
        this.materialPersonalTotalPrice = 0;
        this.projectMaterials.forEach((e: any) => {
            if (!e.material.materialsForTheCase) {
                if (!e.totalPrice) {
                    e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
                }
                if (!e.personalPrisTotal) {
                    e.personalPrisTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
                }
                this.materialTotalPrice += e.totalPrice;
                if (e.personalPrice && e.personalPrice != 0) {
                    this.materialPersonalTotalPrice += e.personalPrisTotal;
                }
                else {
                    e.personalPrice = 0;
                }

                if (e.totalPriceVAT) {
                    this.materialTotalPriceWithVAT += e.totalPriceVAT;
                } else {
                    var vat = (e.totalPrice * 25) / 100;
                    this.materialTotalPriceWithVAT += (e.totalPrice + vat);
                }
            }
        });
    }

    calculatePrice() {
        this.Requisition.includePrice = Number((this.Requisition.excludePrice + ((Number(this.Requisition.excludePrice) * 25) / 100)));
        this.Requisition.vatPrice = Number((Number(this.Requisition.excludePrice) * 25) / 100);
        this.Requisition.includeEstimatePrice = Number((this.Requisition.estimatedPrice + ((Number(this.Requisition.estimatedPrice) * 25) / 100)));
        this.Requisition.vatEstimatePrice = Number((Number(this.Requisition.estimatedPrice) * 25) / 100);
        this.getTotalEtstimeteOrPrice();
    }

    getTotalEtstimeteOrPrice() {
        if (this.Requisition.includeEstimatePrice > 0) {
            this.Requisition.totalIncludeEstimatePrice = this.Requisition.includeEstimatePrice;
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatEstimatePrice);
        }
        else if (this.Requisition.includePrice > 0) {
            this.Requisition.totalIncludeEstimatePrice = (this.Requisition.includePrice);
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatPrice);
        }
        else {
            this.Requisition.totalIncludeEstimatePrice = (this.Requisition.includeEstimatePrice);
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatEstimatePrice);
        }
    }

    getTotalPrice(qty, price) {
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    calculateEndDateWithRemainingHour() {
        var days = 0;
        if (this.Requisition && this.Requisition.estimatedTime && this.Requisition.totalWorkingTime) {
            days = Math.ceil((this.Requisition.estimatedTime - this.Requisition.totalWorkingTime) / 7.4);
        }
        else if (this.Requisition && this.Requisition.estimatedTime) {
            days = this.Requisition && this.Requisition.estimatedTime != null ? Math.ceil(this.Requisition.estimatedTime / 7.4) : 0;
        }
        days = days != 0 ? Math.abs(days) - 1 : 0;
        this.createEventObj.endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(days, 'days')["_d"];
        //this.createEventObj.endDate = momentBusinessDays(this.createEventObj.startDate).businessAdd(days).format("DD.MM.YYYY")
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
    }

    onChange(args) {
        this.createEventObj.startDate = args.value;
        this.calculateEndDateWithRemainingHour();
    }

    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    getDefaultCoworkers() {
        this.showSpinner = true;
        this.coWorkersService.GetDefaultCoworkers<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    this.defaultCoWorkerList = response.result;
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString() });
        }
    }

    DropDownWeekNumberSelect() {
        this.createEventObj.startDate = moment().isoWeek(this.createEventObj.startWeekNumber || 1).startOf("week").format("DD.MM.YYYY");
        this.calculateEndDateWithRemainingHour();
    }
    onSelectCheck(coworkerId, isSelected) {
        var isChecked = !isSelected;
        this.isDeleteAll = false;
        for (let i = 0; i < this.selectedCoWorkerListOnEdit.length; i++) {
            if (this.selectedCoWorkerListOnEdit[i].id == coworkerId) {
                this.selectedCoWorkerListOnEdit[i].isSelectedDelete = isChecked;
            }
        }
        this.coworkerForDelete=this.selectedCoWorkerListOnEdit.filter(x=>x.isSelectedDelete==true);
    }
    onSelectAllDelete(isSelected) {
        this.isDeleteAll = !isSelected;
        this.selectedCoWorkerListOnEdit.forEach(item => {
            item.isSelectedDelete = this.isDeleteAll;
        });
        this.coworkerForDelete=this.selectedCoWorkerListOnEdit.filter(x=>x.isSelectedDelete==true);
    }

    UpdateRequisitionStatus() {
        var requisition = this.RequisitionList.find(o => o.id == this.createEventObj.sagOpgaveID);
        if (requisition) {
            requisition.status = this.createEventObj.status;
            //requisition.statusChangedDate = moment(this.createEventObj.startDate, 'DD.MM.YYYY').format("YYYY-MM-DD");
            this.projectRequisitionService.Update(requisition.projectId, requisition.id, requisition).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.createEventObj.status = response.result.status;
                        if (this.createEventObj.status == 9) {
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                projectId: this.projectInfo.sagId,
                                workHourId: this.workHourId,
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        }
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    UpdateRequisition(event) {
        this.Requisition = event;
        this.Requisition.CreatedBy = this.authService.getCoWorkerId();
        this.projectRequisitionService.Update(this.Requisition.projectId, this.Requisition.id, this.Requisition).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    var index = this.RequisitionList.findIndex(f => f.id == this.Requisition.id);
                    this.RequisitionList.splice(index, 1);
                    this.RequisitionList.splice(index, 0, response.result);
                    this.Requisition.startDate = response.result.startDate;
                    this.Requisition.endDate = response.result.endDate;
                    this.Requisition.comingDate = response.result.comingDate;
                    this.Requisition.status = response.result.status;
                    if (this.Requisition.left || this.Requisition.riseman) {
                        this.displaySpecialInfoRed = true;
                    }
                    else {
                        this.displaySpecialInfoRed = false;
                    }
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    // Tabs Code start
    isDisplayMtoMTabRed(event) {
        this.displayMtoMTabRed = event;
        this.GetProjectAllFileInfo();
    }

    isDisplayNoteTabRed(event) {
        this.displayNoteTabRed = event;
        this.GetProjectAllFileInfo();
    }

    isDisplayOffersTabRed(event) {
        this.displayOffersTabRed = event;
        this.GetProjectAllFileInfo();
    }

    //Tab Change Start
    TabChange(event) {
        this.selectedIndex = event.index;
    }

    ReqTabChange(event) {
        this.previousIndex = this.reqTabselectedIndex;
        this.reqTabselectedIndex = event.index;
        this.ReqTabValueChange();
    }

    ReqTabValueChange() {
        if ((this.previousIndex == 4 || this.previousIndex == 5 || this.previousIndex == 6) &&
            this.hasPermision && this.hasProjectPermision) {
            setTimeout(() => {
                this.UpdateRequisition(this.Requisition);
            }, 1000);
        }
    }

    goToStep(value) {
        this.selectedIndex = value;
        this.OpenAccordionForOtherDetail(true)
        this.isOpenMaterialPopup = true;
    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.coworkerDetail.name;
        moduleObj.moduleID = this.workHourId;
        moduleObj.mobile = this.createEventObj.departmentPhone;
        moduleObj.flag = SMSModuleEnum.Planning;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    //End

    // Accordion Start
    OpenAccordion(flag) {
        this.IsExpand = !flag;
        //setTimeout(() => {
        //  this.IsExpandOtherDetails = false;
        //}, 1000);
    }

    CloseAccordion(flag) {
        this.IsExpand = !flag;
    }

    OpenAccordionForOtherDetail(flag) {
        if (this.selectedIndex == 6) {
            this.IsExpandOtherDetails = true;
        } else {
            this.IsExpandOtherDetails = !flag;
        }
        //setTimeout(() => {
        //  this.IsExpand = false;
        //}, 1000);
    }

    CloseAccordionForOtherDetail(flag) {
        this.selectedIndex = 0;
        this.IsExpandOtherDetails = !flag;
    }

    // Code to Prevent accordion from expantion
    expandPanelForOtherDetails(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpandOtherDetails) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpand) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    // Code to Prevent accordion from expantion
    expandPanel2(matExpansionPanel2: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanel2.close(); // Here's the magic
        }
    }

    // Code to Prevent accordion from expantion
    expandPanelForFileExtraTab(matExpansionPanelForFileExtraTab: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanelForFileExtraTab.close(); // Here's the magic
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    getCowrkerListAndPlanningDate(sagId, sagOpgaveId) {
        this.workhourservice.GetCoworkerListAndPlanningDate<ResponseBase>(sagId, sagOpgaveId)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coworkerListAndPlaningDates = response.result;
                }
            },
                error => (err) => {
                }, () => { });
    }

    singleLiftCoworkerCalculation() {
        this.workHour.dayList = [];
        var days = 0;
        if (this.Requisition && this.Requisition.estimatedTime && this.Requisition.totalWorkingTime) {
            days = Math.ceil((this.Requisition.estimatedTime - this.Requisition.totalWorkingTime) / 7.5);
        }
        else if (this.Requisition && this.Requisition.estimatedTime) {
            days = this.Requisition && this.Requisition.estimatedTime != null ? Math.ceil(this.Requisition.estimatedTime / 7.5) : 0;
        }
        days = days != 0 ? Math.abs(days) - 1 : 0;
        this.workingDays = days;
        this.createEventObj.endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];

        var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            this.pushDayList(moment(day).format("YYYY-MM-DD"),true)
        }
        this.getTotalWorkHourOfCoworker();
    }

    calculateEndDate(extendEndDate) {
        this.workHour.dayList = [];
        var isDisplayHolidayPopup = false;
        var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == moment(day).format("DD.MM.YYYY"));
            if (day.getDay() == 0 || day.getDay() == 6) {
                if (extendEndDate && this.createEventObj.weekendId == 1) 
                    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                else if(this.createEventObj.weekendId == 2)    
                    this.pushDayList(moment(day).format("YYYY-MM-DD"),true)
            }
            else if (getHoliday) {
                isDisplayHolidayPopup = true;
                if (extendEndDate) {
                    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                }
            }
            else if (!getHoliday) {
                this.pushDayList(moment(day).format("YYYY-MM-DD"),true)
            }
            //}
        }
        this.createEventObj.endDate = endDate;
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
        if (isDisplayHolidayPopup) {
            this.holidayListBetweenDates = this.holidayList.filter(o => (new Date(o.date) >= this.createEventObj.startDate && new Date(o.date) <= this.createEventObj.endDate));
            if (this.holidayListBetweenDates.length > 0)
                this.displayHolidayPopup();
        }
        this.getTotalWorkHourOfCoworker();
    }

    displayHolidayPopup() {
        this.fullMessage = "";
        this.message = "";
        this.translate.get("MESSAGES.HolidayFoundInPlanning").subscribe({
            next: (res) => { this.message = res; }, error: err => { }
        });

        var dates = this.holidayListBetweenDates.map(function (a) {
            return moment(a.date).format("DD.MM.YYYY");
        });
        this.fullMessage = this.fullMessage + this.message.replace("@date", dates.join()) + "<br/>";

        const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
            width: '40%',
            data: {
                Header: this.fullMessage,
                FirstButtonLabel: "Headers.PlanWithHoliday",
                SecondButtonLabel: "Headers.PlanWithoutHoliday",

            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result == 1) {
                this.workHour.dayList = [];
                var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
                    new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
                var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
                    new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);
                // if (calculateEndDate == true) {
                //     endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                // }

                // calculate endDate only
                for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
                    var tempDate = moment(day).format("DD.MM.YYYY");
                    var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                    if (day.getDay() == 0 || day.getDay() == 6) {
                        if(this.createEventObj.weekendId == 1)    
                            continue;
                        else if(this.createEventObj.weekendId == 2)    
                            this.pushDayList(moment(day).format("YYYY-MM-DD"),true)                
                    }
                    else {
                        this.pushDayList(moment(day).format("YYYY-MM-DD"),true)
                    }
                }
                this.createEventObj.endDate = endDate;
                this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
            }
            else if (result == 2) {
                this.workHour.dayList = [];
                var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
                    new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
                var endDate = new Date(this.createEventObj.endDate.getFullYear(),
                    this.createEventObj.endDate.getMonth(), this.createEventObj.endDate.getDate(), 0, 0, 0, 0);
                // if (calculateEndDate == true) {
                //     endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                // }

                // calculate endDate only
                for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
                    var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == moment(day).format("DD.MM.YYYY"));
                    if (day.getDay() == 0 || day.getDay() == 6) {
                        if (getHoliday) {
                            endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                            // if(getHoliday)
                            //     calculateEndDate = true;
                        }
                        if(this.createEventObj.weekendId == 1)    
                            continue;
                        else if(this.createEventObj.weekendId == 2 && !getHoliday)    
                            this.pushDayList(moment(day).format("YYYY-MM-DD"),true)                
                    }
                    else if (getHoliday) {
                        endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                    }
                    else if (!getHoliday) {
                        this.pushDayList(moment(day).format("YYYY-MM-DD"),true)
                    }
                }
                this.createEventObj.endDate = endDate;
                this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();

            }
            else if (result == 3) {
            }
        });
    }

    getTotalWorkHourOfCoworker() {
        if (this.createEventObj.shift == 2 || this.createEventObj.shift == 3) {
            this.totalWorkHourOfCoworker = (this.workHour.dayList.length * (this.createEventObj.shift == 2 ? 7 : 7.5));
        }
        else if (this.createEventObj.shift == 4) {
            var from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            var to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            var time = moment.utc(moment(to, "HH:mm").diff(moment(from, "HH:mm"))).format("HH:mm")
            var tidTime = moment(time, "HH:mm").format("mm");
            var tidHours = moment(time, "HH:mm").format("HH");
            if (tidTime != "00")
                time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
            if (this.isAddBreakTime)
                time = (Number(time.replace(':', '.')) - 0.50).toString();
            else
                time = (Number(time.replace(':', '.'))).toString();
            if (Number(time) > 0)
                this.totalWorkHourOfCoworker = (this.workHour.dayList.length * (Number(time)));
            else
                this.totalWorkHourOfCoworker = 0;
        }
    }

    onStartDateChange(args) {
        var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
        if (liftSelectedCoworker.length > 0
            && this.selectedCoWorkerList.length == 1) {
            this.singleLiftCoworkerCalculation();
        }
        else {
            this.getTotalWorkHourOfCoworker();
            this.calculateEndDateWithRemainingHour();
            this.calculateEndDate(false);
        }
        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.selectedDate).setHours(0, 0, 0, 0)) {
            this.isPastDate = false;
        }
        else {
            this.isPastDate = moment(this.createEventObj.selectedDate, "YYYY-MM-DD").isBefore();
        }
    }

    onEndDateChange(args) {
        var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
        if (liftSelectedCoworker.length > 0
            && this.selectedCoWorkerList.length == 1) {
            this.singleLiftCoworkerCalculation();
        }
        else {
            this.calculateEndDate(false);
            this.getTotalWorkHourOfCoworker();
            // this.holidayListBetweenDates = this.holidayList.filter(o => (new Date(o.date) >= this.createEventObj.startDate && new Date(o.date) <= this.createEventObj.endDate));
            // if (this.holidayListBetweenDates.length > 0)
            //     this.displayHolidayPopup(false);
        }

    }

    checkCoworkersHolidayInCurrentPlaning() {
        if (this.currentselectedCoworkerIds) {
            this.tempCoworkerListForLeave = [];
            this.createEventObj.startDate = moment(this.createEventObj.startDate).toDate();
            this.createEventObj.endDate = moment(this.createEventObj.endDate).toDate();
            var sdate = new Date(this.createEventObj.startDate.setHours(0, 0, 0, 0));
            var edate = new Date(this.createEventObj.endDate.setHours(0, 0, 0, 0));
            var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
            if (liftSelectedCoworker.length > 0) {
                this.liftReserved = [];
                var data = this.workHoursForLiftCoworker.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                this.liftReserved = data.sort((a, b) => {
                    return <any>new Date(a.date) - <any>new Date(b.date);
                })
                if (this.liftReserved.length > 0) {
                    this.message = "";
                    this.translate.get("MESSAGES.LeftAssigment").subscribe({
                        next: (res) => { this.message = res; }, error: err => { }
                    });
                    var dates = this.liftReserved.map(function (a) {
                        return moment(a.date).format("DD.MM.YYYY");
                    });
                    this.message = this.message.replace("@date", dates.join());
                    this.notificationsService.error(this.message);
                    this.commonService.playFailedTone();
                    return;
                }
                else {
                    this.setScheduleDaysByCowrker();
                    this.saveTimer();
                }
            }
            else {
                this.isCoworkerInLeave = false;
                // for (var i = 0; i < this.currentselectedCoworkerIds.length; i++) {
                if (this.currentselectedCoworkerIds) {
                    this.coworkerLeaveList = [];
                    this.coworkerLeaveList = this.coWorkerList.filter(x => x.id == this.currentselectedCoworkerIds);
                    if (this.coworkerLeaveList.length > 0 && this.coworkerLeaveList[0].holidayList && this.coworkerLeaveList[0].holidayList.length > 0) {
                        var data = this.coworkerLeaveList[0].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                        if (data.length > 0) {
                            this.isCoworkerInLeave = true;
                            var tempCoworker = {
                                id: this.coworkerLeaveList[0].id,
                                name: this.coworkerLeaveList[0].name,
                                email: this.coworkerLeaveList[0].email,
                                coworkerNumber: this.coworkerLeaveList[0].coworkerNumber,
                                mobile: this.coworkerLeaveList[0].mobile,
                                isActive: this.coworkerLeaveList[0].isActive,
                                isDeleted: this.coworkerLeaveList[0].isDeleted,
                                initials: this.coworkerLeaveList[0].initials,
                                holidayList: this.coworkerLeaveList[0].holidayList.sort((a, b) => {
                                    return <any>new Date(a.date) - <any>new Date(b.date);
                                }),
                            }
                            this.tempCoworkerListForLeave.push(tempCoworker);
                        }
                    }
                    // }
                }
                if (this.isCoworkerInLeave) {
                    this.fullMessage = "";
                    this.message = "";
                    this.translate.get("MESSAGES.CoworkerOnLeave").subscribe({
                        next: (res) => { this.message = res; }, error: err => { }
                    });

                    for (var i = 0; i < this.tempCoworkerListForLeave.length; i++) {
                        var holidayListLocal = this.tempCoworkerListForLeave[i].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                        var dates = holidayListLocal.map(function (a) {
                            return moment(a.date).format("DD.MM.YYYY");
                        });
                        var status = holidayListLocal.map(function (a) {
                            return "selectedWorkHourStatus.value" + a.status;
                        });
                        var statusName = "";
                        if (status) {
                            var that = this;
                            var filterStatus = status.filter((n, i) => status.indexOf(n) === i);
                            filterStatus.filter(function (a) {
                                that.translate.get(a).subscribe({
                                    next: (res) => {
                                        statusName += statusName + res + ',';
                                    }, error: err => { }
                                });
                            });
                            statusName = statusName.substring(0, statusName.length - 1);
                        }
                        this.fullMessage = this.fullMessage + this.message.replace("@coworkerName", this.tempCoworkerListForLeave[i].name).replace("@date", dates.join()).replace("@statusName", statusName) + "<br/>";
                    }

                    const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
                        width: '40%',
                        data: {
                            Header: this.fullMessage,
                            FirstButtonLabel: "Headers.ExtendWithoutFuture",
                            SecondButtonLabel: "Headers.ExtendOnFuture",
                        }
                    });
                    dialogRef.afterClosed().subscribe((result: any) => {
                        if (result) {
                            if (result == 1) {
                                this.setScheduleDaysByCowrker();
                                this.saveTimer();
                            }
                            if (result == 2) {
                                this.workHour.CoworkerDetailsId = [];
                                this.setScheduleDaysByCowrker();
                                for (var i = 0; i < this.tempCoworkerListForLeave.length; i++) {
                                    var CoworkerId = this.tempCoworkerListForLeave[i].id;
                                    var tempDayList = [];

                                    var detail = this.workHour.CoworkerDetailsId.find(o => o.CoworkerId == CoworkerId);
                                    if (detail) {
                                        var startDateCal = new Date(detail.ScheduleDays[0]);
                                        var endDate = new Date(detail.ScheduleDays[detail.ScheduleDays.length - 1]);
                                        for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
                                            var tempDate = moment(day).format("DD.MM.YYYY");
                                            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                                            if (day.getDay() == 0 || day.getDay() == 6 || getHoliday) {
                                                endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                                                continue;
                                            }

                                            else if (!getHoliday || getHoliday.length == 0) {
                                                var data = this.tempCoworkerListForLeave[i].holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                                                if (data) {
                                                    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                                                }
                                                else {
                                                    if (this.workHour.dayList.length != tempDayList.length) {
                                                        tempDayList.push(moment(day).format("YYYY-MM-DD"));
                                                    }
                                                    else
                                                        break;
                                                }
                                            }
                                        }
                                        this.workHour.CoworkerDetailsId.filter(function (item) {
                                            if (item.CoworkerId == CoworkerId) {
                                                item.ScheduleDays = null;
                                                item.ScheduleDays = tempDayList;
                                            }
                                        });
                                    }
                                }
                                this.saveTimer();
                            }
                            if (result == 3) {
                                this.showSpinnerForEdit = false;
                            }
                        }
                    });
                }
                else {
                    this.setScheduleDaysByCowrker();
                    this.saveTimer();
                }
            }
        }
    }

    checkCoworkersHolidayInCurrentPlan() {
        this.getCalenderData(new RequestResourceWorkHours);
    }

    getCalenderData(model: RequestResourceWorkHours) {
        this.selectedcoWorkerIds = [];
        for (let i = 0; i < this.selectedCoWorkerList.length; i++) {
            this.selectedcoWorkerIds.push(this.selectedCoWorkerList[i].id);
        }
        model.CoworkerId = this.selectedcoWorkerIds.join();
        model.EndDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
        model.StartDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
        model.Index = 1;
        model.Limit = 1000;
        model.WorkingStatus = 1;
        this.showSpinnerForEdit = true;
        this.coWorkersService.GetCoworkersWithWorkHours<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.workHoursList = response.result.workHoursList;
                    var isProjectPresent = this.workHoursList.filter(x => (moment(x.date).format("YYYY-MM-DD") >= moment(this.createEventObj.startDate).format("YYYY-MM-DD") || moment(x.date).format("YYYY-MM-DD") <= moment(this.createEventObj.endDate).format("YYYY-MM-DD")) && this.createEventObj.projectNumber == x.sagNummer && this.createEventObj.sagOpgaveID == x.sagOpgaveID).length;
                    if (isProjectPresent > 0) {
                        this.showSpinnerForEdit = false;
                        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                            height: '200px',
                            data: {
                                Header: 'MESSAGES.AlreadyProjPlanned',
                                ShowOKButton: true
                            }
                        });
                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                return;
                            }
                        });
                    } else {
                        this.checkCoworkersHolidayInCurrentPlaning();
                    }
                }
            });
    }

    DropDownShiftChange() {
        this.onChangeHoursAndMinute();
    }

    onChangeHoursAndMinute() {
        this.getTotalWorkHourOfCoworker();
    }

    setScheduleDaysByCowrker() {
        this.workHour.CoworkerDetailsId = [];
        if (this.currentselectedCoworkerIds) {
            // for (var i = 0; i < this.currentselectedCoworkerIds.length; i++) {
            var CoworkerDetails = new CoworkerDays();
            //CoworkerDetails.CoworkerId = this.currentselectedCoworkerIds[i];
            // CoworkerDetails.CoworkerId = this.currentselectedCoworkerIds;
            // CoworkerDetails.ScheduleDays = this.workHour.dayList;
            // this.workHour.CoworkerDetailsId.push(CoworkerDetails);
            var CoworkerDetails = new CoworkerDays();
            CoworkerDetails.CoworkerId = this.currentselectedCoworkerIds;
            CoworkerDetails.ScheduleDays = this.workHour.dayList.filter(x=>x.isChecked == true).map(p => p.date); //this.workHour.dayList;
            this.workHour.CoworkerDetailsId.push(CoworkerDetails);
            //}
        }
    }

    closeAddMaterialPopup(event) {
        this.isOpenMaterialPopup = false;
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    DropDownWeekPlaningChange(){
        this.calculateEndDate(false);
    }

    pushDayList(date,isChecked){
        //this.workHour.dayList.push(moment(day).format("YYYY-MM-DD"));
        if(this.reverseAndTimeStamp(moment(date,"YYYY-MM-DD").format("DD-MM-YYYY"))  >= this.reverseAndTimeStamp(this.planningDates[0]) && this.reverseAndTimeStamp(moment(date,"YYYY-MM-DD").format("DD-MM-YYYY")) <= this.reverseAndTimeStamp(this.planningDates[this.planningDates.length - 1])){
            if(this.planningDates && this.planningDates.length > 0){
                var tempDate = this.planningDates.filter(x=>x == moment(date,"YYYY-MM-DD").format("DD-MM-YYYY"));
                if(tempDate.length == 0)
                    isChecked = false
            }
        }
        let workhoursDay = new  WorkhoursDay();
        workhoursDay.date = date;
        workhoursDay.isChecked = isChecked;
        this.workHour.dayList.push(workhoursDay)
    }

    getWeekName(date){
        return moment(date,"YYYY-MM-DD").format("ddd")
    }

    onCheckWorkhoursDay(value,index){
        this.workHour.dayList[index].isChecked = !value;
    }

    reverseAndTimeStamp(dateString) {
        if(dateString){
            const reverse = new Date(dateString.split("-").reverse().join("-"));
            return reverse.getTime();
        }
    }

    moveWorkhourAPICall(workHour) {
        workHour.date= new Date(moment(workHour.selectedDate).format("YYYY-MM-DD"))
        workHour.projectNumber =this.projectInfo.sagNummer;
        workHour.sagOpgaveNummer =this.projectInfo.sagOpgaveNummer;
        this.workhourservice.Move<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinnerForEdit = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        weekNumber: moment(this.createEventObj.startDate).week().toString(),
                    });
                },
                error: err => {
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinnerForEdit = false;
                }
            });
    }

    getWorkHoursByBunchId(bunchId) {
        this.workhourservice.GetWorkHoursByBunchId<ResponseBase>(bunchId)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.workHourList = response.result;
                    this.workHourList.filter(x=>{
                        x.isChecked = false;
                        if(x.shiftType == 2)
                            x.shiftTypeName = "selectedShiftOption.value1";
                        if(x.shiftType ==3)
                            x.shiftTypeName = "selectedShiftOption.value2";
                        if(x.shiftType == 4){
                            x.fromStartHour = moment(x.from,"HH:mm").format("HH"); 
                            x.fromStartMinute = moment(x.from,"HH:mm").format("mm");
                            x.fromEndHour = moment(x.to,"HH:mm").format("HH");
                            x.fromEndMinute = moment(x.to,"HH:mm").format("mm");
                            x.shiftTypeName = x.from + ' til ' + x.to;
                        }
                        if (new Date().setHours(0, 0, 0, 0) == new Date(x.date).setHours(0, 0, 0, 0))
                            x.isPastDate = false;
                        else 
                            x.isPastDate= moment(x.date, "YYYY-MM-DD").isBefore();
                        if (new Date(this.createEventObj.selectedDate).setHours(0, 0, 0, 0) == new Date(x.date).setHours(0, 0, 0, 0))
                            x.isChecked = true;
                    })
                    this.checkMovingDateSelected();
                }
            },
                error => (err) => { },
                () => { });
    }

    onCheckScheduleDay(value, index, item) {
        item.isChecked = !value;
        var tempDates = this.workHourList.filter(y => y.isChecked == true).map(x => new Date(x.date));
        const maxDate = new Date(Math.max.apply(null, tempDates));
        this.DeleteFutureEmployeeOnRequisition.endDate =moment(maxDate).format("DD.MM.YYYY");
        this.checkMovingDateSelected();
    }

    editShift(scheduleDay) {
        const dialogRef = this.dialog.open(ShiftSelectionComponent, {
            height: '45%',
            width: '40%',
            data: { scheduleDay: scheduleDay, coworker: this.coworkerDetail }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.isCheckedAll) {
                        this.workHourList.filter(item => {
                        item.from = result.from;
                        item.to = result.to;
                        item.tid = result.tid;
                        item.pauseTimer = result.pauseTimer;
                        item.hours = result.hours;
                        item.shiftType = result.shiftType;
                        item.shiftTypeName = result.shiftTypeName;
                        if(!item.isPastDate)
                            item.isChecked = true;
                        if(item.shiftType == 4){
                            item.fromStartHour = result.fromStartHour
                            item.fromStartMinute = result.fromStartMinute
                            item.fromEndHour = result.fromEndHour
                            item.fromEndMinute = result.fromEndMinute
                        }
                    })
                }
                else {
                    scheduleDay.from = result.from;
                    scheduleDay.to = result.to;
                    scheduleDay.tid = result.tid;
                    scheduleDay.pauseTimer = result.pauseTimer;
                    scheduleDay.hours = result.hours;
                    scheduleDay.shiftType = result.shiftType;
                    scheduleDay.shiftTypeName = result.shiftTypeName;
                    if(!scheduleDay.isPastDate)
                        scheduleDay.isChecked = true;
                    if(scheduleDay.shiftType == 4){
                        scheduleDay.fromStartHour = result.fromStartHour
                        scheduleDay.fromStartMinute = result.fromStartMinute
                        scheduleDay.fromEndHour = result.fromEndHour
                        scheduleDay.fromEndMinute = result.fromEndMinute
                    }
                }
                this.checkMovingDateSelected();
            }
        });
    }

    checkIsPastDate(tempDate){
        if (new Date().setHours(0, 0, 0, 0) == new Date(tempDate).setHours(0, 0, 0, 0)) 
            return false;
        else 
            return moment(tempDate, "YYYY-MM-DD").isBefore();
    }

    updateWorkhoursAPICall() {
        this.requestWorkHourList = this.workHourList.filter(x=>x.isChecked);
        this.workhourservice.UpdateWorkHours<ResponseBase>(this.requestWorkHourList)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinnerForEdit = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        weekNumber: moment(this.createEventObj.startDate).week().toString(),
                        response: this.requestWorkHourList ? [...this.requestWorkHourList] : null,
                    });
                },
                error: err => {
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinnerForEdit = false;
                }
            });
    }

    getProjectTimerFromRequisition(){
        if(this.RequisitionList.length>0) {
            this.projectHoursDetail.estimatedTime = this.RequisitionList[0].projectEstimatedTime;
            this.projectHoursDetail.totalWorkingTime = this.RequisitionList[0].projectTotalWorkingTime;
            this.projectHoursDetail.plannedWorkHour = this.RequisitionList[0].projectPlannedWorkHour;
            this.calculateEndDateWithRemainingHour();
        }
    }

    deleteWorkHourEntry(day, index) {
        var message = "";
        this.translate.get("MESSAGES.DeletePlanningOnSingleDay").subscribe({
            next: (res) => {
                message = res.replace("@date",moment(day.date).format("DD.MM.YYYY"));
            },error: err => {}
        });
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            data: { Header: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteFutureEmployeeOnRequisition.coworkerId = this.currentselectedCoworkerIds;
                this.DeleteFutureEmployeeOnRequisition.requisitionId = this.createEventObj.sagOpgaveID;
                this.DeleteFutureEmployeeOnRequisition.projectId = this.createEventObj.projectId;
                var tempStartDate = moment(day.date).format("YYYY-MM-DD");
                this.DeleteFutureEmployeeOnRequisition.startDate = tempStartDate;
                this.DeleteFutureEmployeeOnRequisition.endDate = tempStartDate;
                this.calendarService.DeleteFutureDateWorkHour<ResponseBase>(this.DeleteFutureEmployeeOnRequisition)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.translate.get("MESSAGES.Delete").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },error: err => {}
                            });
                            // this.dialogRef.close({
                            //     updateSchedule: true,
                            //     deleteSchedule: false,
                            //     goToProject: false,
                            //     projectId: this.projectInfo.sagId,
                            //     workHourId: this.workHourId,
                            //     response: response.statusCode == 200 ? [{...this.DeleteFutureEmployeeOnRequisition, action:"delete"}] : null,
                            // });
                            this.workHourList.splice(index, 1);
                            if (this.workHourList && this.workHourList.length == 0)
                                this.isPastDate = true;
                        },
                        error: err => { },
                        complete: () => { }
                    });
            }
        });
    }

    movePlanning() {
        var tempCoworker = this.searchCoWorkerList.filter(x=> x.id != this.currentselectedCoworkerIds);
        const dialogRef = this.dialog.open(CoWorkerComponent,{
            width: '30%',
            data:{
                coWokersList: tempCoworker,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                var moveObj = {
                    "id": this.workHourId,
                    "MoveDates": this.workHourList.filter(o => o.isChecked == true && !o.isPastDate).map(p => moment(p.date).format("YYYY-MM-DD")),
                    "MoveWorkerID": result
                }
                this.moveCoworkerPlan(moveObj);
            }
        });
    }

    moveCoworkerPlan(changeRecord) {
        this.showSpinner=true;
        this.workhourservice.MovePlanWithDateRange<ResponseBase>(changeRecord)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.translate.get("Message.MovedSuccessfully").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },error: err => {}
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        weekNumber: moment(this.createEventObj.startDate).week().toString(),
                        response: [{...this.DeleteFutureEmployeeOnRequisition, startDate:changeRecord[0]}],
                    });
                },
                error: err => {
                    this.translate.get("Message.CopyError").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },error: err => {}
                    });
                },
                complete: () => {
                    this.showSpinner=false;
                }
            });
    }

    checkMovingDateSelected(){
        var tempData = this.workHourList.filter(o => o.isChecked == true && !o.isPastDate);
        if(tempData && tempData.length > 0)
            this.isMoveDateSelected = true;
    }

    deleteMultiplePlanning() {
        const ids = this.workHourList.filter(w => w.isChecked == true && !w.isPastDate).map(x => x.id);
        if (ids && ids.length > 0) {
            const dates = this.workHourList.filter(w => w.isChecked == true && !w.isPastDate).map(function (a) {
                return moment(a.date).format("DD.MM.YYYY");
            });
            var message = "";
            this.translate.get("MESSAGES.DeletePlanningOnSingleDay").subscribe({
                next: (res) => {
                    message = res.replace("@date", dates.join());
                }, error: err => { }
            });
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                data: { Header: message }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.DeleteFutureEmployeeOnRequisition.coworkerId = this.currentselectedCoworkerIds;
                    this.DeleteFutureEmployeeOnRequisition.requisitionId = this.createEventObj.sagOpgaveID;
                    this.DeleteFutureEmployeeOnRequisition.projectId = this.createEventObj.projectId;
                    this.DeleteFutureEmployeeOnRequisition.planningIds = ids.join();
                    this.calendarService.DeleteFutureDateWorkHour<ResponseBase>(this.DeleteFutureEmployeeOnRequisition)
                        .subscribe({
                            next: (response: ResponseBase) => {
                                this.translate.get("MESSAGES.Delete").subscribe({
                                    next: (res) => {
                                        this.notificationsService.success(res);
                                    }, error: err => { }
                                });
                                //this.workHourList.splice(index, 1);
                                this.workHourList = this.workHourList.filter(item => !ids.includes(item.id));
                                if (this.workHourList && this.workHourList.length == 0)
                                    this.isPastDate = true;
                                this.checkMovingDateSelected();
                            },
                            error: err => { },
                            complete: () => { }
                        });
                }
            });
        }
    }
}