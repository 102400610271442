export const locale = {
  lang: 'ds',
  data: {
    'STATUS': {
      'Created': 'lavet',
      'Updated': 'Opdateret',
      'Deleted': 'Slettet',
      'FileUploaded': 'Filer uploadet',
      'FileDeleted': 'Filer slettet',
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
    },
    'BUTTONS': {
      'BACK': 'Gå tilbage',
      'BTNSAVE': 'Gem',
    },
    'FIELDS': {
      'SRNO': 'Sr nr.',
      'Status': 'Status',
      'Number': 'Nummer',
      'Name': 'Navn',
      'Createdby': 'OprettetAf',
      'CreatedDate': 'OprettetDato',
      'ModifyBy': 'ModificereAf',
      'ModifyDate': 'ModificereDato',
      'CoworkerName': 'Medarbejdernavn',
      'Date': 'Dato',
      'Description': 'Tekst',
      'CustomerNo': 'Kunde nr.',
      'CustomerName': 'Kundenavn',
      'Email': 'E-mail',
      'PhoneNo': 'Telefon',
      'Address-line-1': 'Adresse (Linje 1)',
      'Address-line-2': 'Adresse (Linje 2)',
      'City': 'By',
      'Zipcode': 'Postnummer',
      'Position': 'Position',
      'Comments': 'Kommentarer  [ Valgfri ]',
      'OrderName': 'Ordre navn',
      'EnterLocation': 'Indtast din placering',
      'Active': 'Indstil som inaktiv',
      'From': 'Fra',
      'To': 'til',
      'colorCode': 'Farvekode',
      'PersonnelPurchas': 'Personale køb',
      'EmailInvoice': 'Email Faktura',
      'EANNummer': 'EAN Nummer',
      'Address': 'Adresse',
      'Detail': 'Detalje',
      'ChangedDetail': 'Ændret detalje',

      'RequisitionText': 'OpgaveTekst',
      'Text': 'Tekst',
      'StartDate': 'Start dato',
      'EndDate': 'Slut dato',
      'InvoiceText': 'Invoice Tekst',
      'RequisitionNo': 'Opgave nr.',
      'Size': 'Kvadratmeter',
      'LeftStartDate': ' Lift start date',
      'LeftInfoEndDate': 'Lift slut dato',
      'Left': 'Lift',
      'LeftDays': 'Lift dages',
      'Riseman': 'Stigemand',
      'ExcludePrice': 'Pris excl. Moms',
      'EstimatedPrice': 'Overslagspris',
      'IncludePrice': 'Pris incl. moms',
      'EstimatedTime': 'Timer til sagen',
      'NumberOfDays': 'Hvor mange dage',
      'ReasonForProjectDropped': 'Reason',
      'TotalWorkingTime': 'Timer',
      'HoursBack': ' Timer tilbage',
      'IsHourlyWage': 'Er sag på timeløn',
      'HourlyWage': 'Timeløn',
      'PaymentMode': 'Betalingsmåde',
      'DiscountPercent': 'Rabat %',
      'PerformedPercent': 'Udført %',

    },
    'Headers': {
      'CustomerLogDetails': 'Opgave log detaljer'
    },
    'MESSAGES': {
      'NORECORD': 'Ingen registrering fundet',
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indendørs ',
      'value2': 'Udendørs',
      'value3': 'Sprøjtemaling',
      'value4': 'Reklamation',
      'value5': 'Personale køb', //drawing of painter
      'value6': 'Butiks køb'     //Nordsjø logo
    },
    'yesOrNoOptions': {
      'value1': 'Nej',
      'value2': 'Ja'
    },
    'paymentModeList': {
      'value1': 'Dankort',
      'value2': 'Mastercard',
      'value3': 'kontant',
      'value4': 'Mobilpay',
      'value5': 'Mobilepay KK',
      'value6': 'Kredit',
      'value7': 'Andet',
    },
    // 'selectedValueStatus': {
    //   'value0': 'Anbefalet',
    //   'value1': 'Process',
    //   'value2': 'Planlagt besøg',
    //   'value3': 'Klar til opstart',
    //   'value4': 'Tilbud sendt',
    //   'value5': 'Tilbud godkendt',
    //   'value6': 'Planlagt',
    //   'value7': 'Tilbud afvist',
    //   'value8': 'Startet',
    //   'value9': 'Udført',
    //   'value10': 'Faktureret',
    //   'value11': 'Afventer',
    //   'value12': 'Klar fakturering',
    // },
    'IsActive': {
      'value1': 'Rigtigt',
      'value0': 'Falsk',
    }
  }
};
