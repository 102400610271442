export const locale = {
    lang: 'ds',
    data: {
        'TABS': {
            'Economy': 'Økonomi',
            'OtherDetails': 'Andre detaljer',
            'Files': 'Filer',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'RequisitionInfo':'Opgave info',
            'SpecialInfo':'Speciel Info',
            'Materials':'Materialer'
        },
        'Headers': {
            'Co-workerlist': 'Medarbejderliste',
            'RequisionCoworkerlist': 'Rekvisition Medarbejderliste',
            'ChooseFile': 'Vælg fil',
        },
        'MESSAGES': {
            'Add': 'Oprettet',
            'Delete': 'Slettet med succes',
            'Add_Error': 'Ingen registrering fundet på Vagttider eller Vagtplan bordet, kontakt venligst til administrator.',
            'Some_Coworker_Add_Error': 'Nogle kolleger kan ikke tilføje arbejdstid.',
            'CanNotAddWorkHour': '{@Coworker} medarbejdere Kan ikke tilføje arbejdstid.',
            'Updated': 'Opdateret med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'DeleteToday': 'Ønsker du at slette',
            'DeleteOn': 'på',
            'From':'fra',
            'To':'til',
            'DeleteFutureEmp': 'Ønsker du at fremtidig slet',
            'EmailSent': 'Email sendt med succes',
            'CoworkerOnLeave': 'Medarbejder @coworkerName har @statusName @date, skal du forlænge hans dato fremover?',
            'AlreadyProjPlanned':'Dette projekt er allerede planlagt på denne dato på denne medarbejder',
            'RequisitionChangeNotification':'Vil du ændre rekvisitionen på alle datoer?',
            'DeletePlanningOnSingleDay':'Vil du slette planlægning på @date?'
        },
        'Button': {
            'Plan': 'Planlæg / Gem',
            'Cancel': 'Gå tilbage',
            'Delete': 'Slette',
            'DeleteFutureWorkHour': 'Slet fremtidig arbejdstid',
            'GoToProject': 'Gå til sagen',
            'DeleteFuture': 'Slet fremtidig',
            'BtnSave': 'Gem',
            'ViewCoworkerPlanning': 'Se medarbejderplanlægning',
            'Material':'Materialer',
            'Move':'Flyt til anden medarbejder'
        },
        'FIELDS': {
            'Coworker': 'Medarbejdere',
            'Project': 'Projekt',
            'StartDate': 'Start dato ',
            'EndDate': 'Slut dato ',
            'CurrentDate': 'Nuværende dato',
            'Shift': 'Tids rum',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'Not-Found-Project': 'Projekt not found',
            'Requisition': 'Opgave',
            'EnterStarttime': 'Indtast starttidspunkt',
            'EndTime': 'Indtast slutstidspunkt',
            'Startime': 'Starttidspunkt',
            'Endtime': 'Slut tid',
            'BreakTime': 'Tilføj Break tid (30 min)',
            'WorkhoursType': 'Arbejdstid type',
            'nonStatus': 'Status',
            'nonText': 'Tekst',
            'WeekNumber': 'Uges nummer',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation',
            'UrgentOrNot': 'Haster/Haster Ikke',
            'Visit': 'Besøg',
            'DepartmentPhone': 'Telefon',
            'Size': 'Kvadratmeter',
            'SelectAll': 'Vælg alle',
            'Status': 'Status',
            'IsHourlyWage': 'Er sag på timeløn',
            'SRNO': 'Sr nr.',
            'DoNotSendSMSANDEmail':'Send ikke sms/mail "Vi kommer"',
            'IsNotifyToCustomer': 'Er Underrette TilKunde',
            'PlaninginWeekend':'Planlægning i weekenden',
            'ChangeAll': 'Skift alle',
            'ProjectBasedPlanning':'Projektbaseret planlægning',
            'ViewPastPlannig':'Se tidligere planlægning'
        },
        'selectedOption': {
            'value1': 'Tilføj 07:00 til 14:30',
            'value2': 'Tilføj 07:00 til 15:00',
            'value3': 'Tilføj timer manuelt',
        },
        'Header': {
            'Add7to0230': 'Tilføj 07:00 til 14:30',
            'Add7to03': 'Tilføj 07:00 til 15:00',
        },
        'Lable': {
            'Timer': 'Timer',
            'HoursLeft': 'Timer tilbage',
            'PlannedHours': 'Planlagte timer',
            'RemainingPlannedHours': 'Resterende plan time',
            'Lift': 'Lift',
            'Stigemand': 'Stigemand',
            'RequisitionText': 'Opgave tekst',
            'No': 'Nej',
            'Yes': 'Ja',
            'Estimate': 'Overslagspris',
            'Price-included': 'Pris incl. Moms',
            'Price-excluded': 'Pris excl. Moms',
            'VAT': 'Moms',
            'MaterialTotalPrice': 'Materiale I alt',
            'MaterialTotalPriceIncludeVAT': 'Materiale I alt incl. Moms',
            'MaterialPersonalTotalPrice': 'Materiale Personlig Pris I alt',
            'hoursOnCase': 'Timer til sagen',
            'noOfDays': 'Hvor mange dage',
            'Hoursspent': 'Timer brugt',
            'Name': 'Navn',
            'ScheduledDates': 'Planlagte datoer',
        },
        'selectedWorkHourStatus': {
            'value1': 'Ferie ',
            'value2': 'Syg',
            'value3': 'Feriefridag',
            'value4': 'Fridag m.løn',
            'value5': 'Fridag u.løn',
            'value6': 'Kursus/skole',
            'value7': 'Afspadsering',
            'value8': 'Andet',
            'value9': 'Tilbud'
        },
        'UrgentOrNot': {
            'value0': 'Vælg',
            'value1': 'Haster',
            'value2': 'Haster Ikke',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
        // 'selectedValueStatus': {
        //     'value0': 'Anbefalet',
        //     'value1': 'Process',
        //     'value2': 'Planlagt besøg',
        //     'value3': 'Klar til opstart',
        //     'value4': 'Tilbud sendt',
        //     'value5': 'Tilbud godkendt',
        //     'value6': 'Planlagt',
        //     'value7': 'Tilbud afvist',
        //     'value8': 'Startet',
        //     'value9': 'Udført',
        //     'value10': 'Faktureret',
        //     'value11': 'Afventer',
        //     'value12': 'Klar fakturering',
        // }
        'selectedWeekendOption': {
            'value1': 'Uden Weekend',
            'value2': 'Med Weekend'
        },
        'selectedShiftOption': {
            'value1': '07:00 til 14:30',
            'value2': '07:00 til 15:00',
            'value3': 'timer manuelt',
        },
        'TOOTIPORTITLE': {
            'SendSMS':'Send SMS Til Kunden',
            'SendEmail':'Send E-mail Til Kunden',
            'DeletePlan':'Slet Plan',
            'Edit':'Redigere',
            'Delete':'Slet',
        },
    }
};
