export const MaterialTypeCategory: any[] = [
    { id: 20, key: 'MaterialTypeCategory.Value20' },
    { id: 27, key: 'MaterialTypeCategory.Value27' },
    { id: 4, key: 'MaterialTypeCategory.Value4' },
    { id: 12, key: 'MaterialTypeCategory.Value12' },
    { id: 18, key: 'MaterialTypeCategory.Value18' },
    { id: 7, key: 'MaterialTypeCategory.Value7' },
    { id: 13, key: 'MaterialTypeCategory.Value13' },
    { id: 23, key: 'MaterialTypeCategory.Value23' },
    { id: 1, key: 'MaterialTypeCategory.Value1' },
    { id: 14, key: 'MaterialTypeCategory.Value14' },
    { id: 21, key: 'MaterialTypeCategory.Value21' },
    { id: 11, key: 'MaterialTypeCategory.Value11' },
    { id: 16, key: 'MaterialTypeCategory.Value16' },
    { id: 9, key: 'MaterialTypeCategory.Value9' },
    { id: 19, key: 'MaterialTypeCategory.Value19' },
    { id: 15, key: 'MaterialTypeCategory.Value15' },
    { id: 6, key: 'MaterialTypeCategory.Value6' },
    { id: 5, key: 'MaterialTypeCategory.Value5' },
    { id: 25, key: 'MaterialTypeCategory.Value25' },
    { id: 10, key: 'MaterialTypeCategory.Value10' },
    { id: 2, key: 'MaterialTypeCategory.Value2' },
    { id: 26, key: 'MaterialTypeCategory.Value26' },
    { id: 17, key: 'MaterialTypeCategory.Value17' },
    { id: 24, key: 'MaterialTypeCategory.Value24' },
    { id: 3, key: 'MaterialTypeCategory.Value3' },
    { id: 22, key: 'MaterialTypeCategory.Value22' },
    { id: 8, key: 'MaterialTypeCategory.Value8' },
];

export const PaintSelectTime: any[] = [
    { id: 0, value: 'Vælg', key: 'SelectedCategoryTime.Value0', materialId: '' },
    { id: 1, value: '1gang', key: 'SelectedCategoryTime.Value1', materialId: '' },
    { id: 2, value: '2gange', key: 'SelectedCategoryTime.Value2', materialId: '' },
    { id: 3, value: '3gange', key: 'SelectedCategoryTime.Value3', materialId: '' },
    { id: 4, value: '4gange', key: 'SelectedCategoryTime.Value4', materialId: '' },
    { id: 5, value: '5gange', key: 'SelectedCategoryTime.Value5', materialId: '' },
    { id: 11, value: 'stk', key: 'SelectedCategoryTime.Value11', materialId: '' },
    { id: 12, value: 'meter', key: 'SelectedCategoryTime.Value12', materialId: '' },
    { id: 13, value: 'rulle', key: 'SelectedCategoryTime.Value13', materialId: '' },
    { id: 14, value: 'liter', key: 'SelectedCategoryTime.Value14', materialId: '' },
    { id: 15, value: '1Lag', key: 'SelectedCategoryTime.Value15', materialId: '' },
    { id: 16, value: '2Lag', key: 'SelectedCategoryTime.Value16', materialId: '' },
    { id: 17, value: '3Lag', key: 'SelectedCategoryTime.Value17', materialId: '' },
    { id: 18, value: 'SmaRum', key: 'SelectedCategoryTime.Value18', materialId: '' }
];

export const CkeditorConfig: any = {
    toolbar: [
        { name: 'document', items: ['Source'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', groups: ['list', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
        { name: 'links', items: ['Link'] },
        { name: 'insert', items: ['Table', 'Smiley',] },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],

    // Toolbar groups configuration.
    toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'align', 'bidi'] },
        { name: 'links' },
        { name: 'insert' },
        { name: 'styles' },
        { name: 'colors' },
    ],
    height: 500,
    allowedContent: true,
    fullPage: true,
    versionCheck: false,
}

export const ProjectStatus: any[] = [
    //{ id: 1, value: '-', key: 'selectedValueStatus.value0' },
    //{ id: 0, value: '-', key: 'selectedValueStatus.value0' },
    { id: 1, value: 'Kontaktes', key: 'selectedValueStatus.value1', ds: 'Behandle', en: 'Process' },
    { id: 2, value: 'Planlagt besøg', key: 'selectedValueStatus.value2', ds: 'Planlagt besøg', en: 'Scheduled visit' },
    { id: 3, value: 'Svare ikke', key: 'selectedValueStatus.value3', ds: 'Klar til opstart', en: 'Ready to boot' },
    //{ id: 4, value: 'Tilbud sendt', key: 'selectedValueStatus.value4', ds: 'Tilbud sendt', en: 'Offers sent' },
    { id: 5, value: 'Tilbud godkendt ', key: 'selectedValueStatus.value5', ds: 'Tilbud godkendt', en: 'Offer approved' },
    { id: 6, value: 'Planlagt', key: 'selectedValueStatus.value6', ds: 'Planlagt', en: 'Planned' },
    //{ id: 7, value: 'Tilbud afvist', key: 'selectedValueStatus.value7', ds: 'Tilbud afvist', en: 'Offer rejected' },
    { id: 8, value: 'Startet', key: 'selectedValueStatus.value8', ds: 'Startet', en: 'Started' },
    { id: 9, value: 'Udført', key: 'selectedValueStatus.value9', ds: 'Udført', en: 'Performed' },
    { id: 10, value: 'Faktureret', key: 'selectedValueStatus.value10', ds: 'Faktureret', en: 'Billed' },
    { id: 11, value: 'Afventer', key: 'selectedValueStatus.value11', ds: 'Afventer', en: 'Pending' },
    { id: 12, value: 'Klar fakturering', key: 'selectedValueStatus.value12', ds: 'Klar fakturering', en: 'Ready billing' }
]

export const ProjectStatusForMaler: any[] = [
    { id: 1, value: 'Kontaktes', key: 'selectedValueStatus.value1' },
    { id: 2, value: 'Planlagt besøg', key: 'selectedValueStatus.value2' },
    { id: 3, value: 'Svare ikke', key: 'selectedValueStatus.value3' },
    //{ id: 4, value: 'Tilbud sendt', key: 'selectedValueStatus.value4' },
    { id: 5, value: 'Tilbud godkendt ', key: 'selectedValueStatus.value5' },
    { id: 6, value: 'Planlagt', key: 'selectedValueStatus.value6' },
    //{ id: 7, value: 'Tilbud afvist', key: 'selectedValueStatus.value7' },
    { id: 8, value: 'Startet', key: 'selectedValueStatus.value8' },
    { id: 10, value: 'Faktureret', key: 'selectedValueStatus.value10' },
    { id: 11, value: 'Reklamation / internt', key: 'selectedValueStatus.value11' }
]

export const ExpensesType: any[] = [
    { id: 0, value: 'Vælg', key: 'ExpensesType.value0' },
    { id: 1, value: 'Min', key: 'ExpensesType.value1' },
    { id: 2, value: 'Beløb', key: 'ExpensesType.value2' }
];

export const QuestionStatusType: any[] = [
    { id: 0, value: 'None', key: 'QuestionStatusType.value0' },
    { id: 1, value: 'Status', key: 'QuestionStatusType.value1' },
    { id: 2, value: 'OtherInfo', key: 'QuestionStatusType.value2' }
];

export const OffMaterialTypeIds: any = {
    Dore: 4,
    Paneler: 11,
    Dorkarm: 12,
    Radiator: 16,
    CeilingType: 21,
    WallType: 22,
    FloorType: 23
};

export const OfferTreatmentType: any[] = [
    { id: 0, value: 'None', key: 'OfferTreatmentType.value0' },
    { id: 1, value: 'Trækværk', key: 'OfferTreatmentType.value1' },
    { id: 2, value: 'Vægge', key: 'OfferTreatmentType.value2' },
    { id: 3, value: 'Loft', key: 'OfferTreatmentType.value3' },
    { id: 4, value: 'Loft-Vægge', key: 'OfferTreatmentType.value4' }
];

export const TreatmentTypeIds: any = {
    WoodWork: 1,
    Walls: 2,
    Ceiling: 3
};

export const MaterialWeatherType: any[] = [
    { id: 0, key: 'MaterialWeatherType.value0', icon: '' },
    { id: 1, key: 'MaterialWeatherType.value1', icon: 'whatshot' },
    { id: 2, key: 'MaterialWeatherType.value2', icon: 'spa' },
    { id: 3, key: 'MaterialWeatherType.value3', icon: 'wb_sunny' },
    { id: 4, key: 'MaterialWeatherType.value4', icon: 'ac_unit' }
]
export const OfferRoomCalculationType: any = {
    WallType: 1,
    CeilingType: 2,
    FloorType: 3
}

export const AudienceType: any[] = [
    { id: 1, key: 'AudienceType.value1', ds: 'Medarbejdere med spørgsmål', en: 'Co-worker With Question' },
    { id: 2, key: 'AudienceType.value2', ds: 'Kunder', en: 'Customer' },
    { id: 3, key: 'AudienceType.value3', ds: 'Medarbejdere uden spørgsmål', en: 'Co-worker Without Question' },
    { id: 4, key: 'AudienceType.value4', ds: 'Medarbejdere meddelelse', en: 'Coworker notification' },
    { id: 5, key: 'AudienceType.value5', ds: 'Kunder meddelelse', en: 'Customer notification' },
]

export const PurchaseStatus: any[] = [
    //{ id: 0, key: 'PurchaseStatus.value0', ds: 'Alle', en: 'All' },
    //{ id: 5, key: 'PurchaseStatus.value1', ds: 'Personale køb', en: 'Staff Purchase' },
    //{ id: 6, key: 'PurchaseStatus.value2', ds: 'Butikskøb', en: 'store purchases' },
    { id: 3, key: 'PurchaseStatus.value3', ds: 'Pris excl. moms', en: 'Price excl. VAT' },
    { id: 4, key: 'PurchaseStatus.value4', ds: 'Pris incl. moms', en: 'Price incl. VAT' },
]

export const ProjectAndRequistionInitialStatus: any = {
    //multipleStatus: "6,8,9,11,12",
    multipleStatus: "0,1,2,3,4,5,6,7,8,9,10,11,12",
    isIndoorOutdoorTypeProjects: "1,2,3",
    isIndoorOutdoorTypeProjectsForMaler: "1,2,3,4,5",
    multipleStatusForWorkhoursOrCalender: "1,3,5,6,8,11"
};

export enum PaintType {
    OneGange = 1,
    TwoGange = 2,
    ThreeGange = 3,
    FourGange = 4,
    FiveGange = 5,
    stk = 11,
    meter = 12,
    rulle = 13,
    liter = 14,
    OneLag = 15,
    TwoLag = 16,
    ThreeLag = 17
}

export const SupplierOrderList: any[] = [
    { id: 1, value: 'Ordered', key: 'SupplierList.value1' },
    { id: 2, value: 'Received', key: 'SupplierList.value2' },
    { id: 3, value: 'PartiallyReceived', key: 'SupplierList.value3' },
    { id: 4, value: 'Delete', key: 'SupplierList.value4' },
];

export const WeekendOption: any[] = [
    { id: 1, key: 'selectedWeekendOption.value1' },
    { id: 2, key: 'selectedWeekendOption.value2' }
]

export const PropertyTypeCategory: any[] = [
    { id: 1, key: 'PropertyTypeCategory.Value1' },
    { id: 2, key: 'PropertyTypeCategory.Value2' },
];

export const AssetsGroup: any[] = [
    { id: 2, value: 'Cars', key: 'AssetsGroup.Value2' },
    { id: 1, value: 'Nanno brikker', key: 'AssetsGroup.Value1' },
];

export const AssetsLabels: any[] = [
    { id: 1, value: 'Various', key: 'AssetsLabels.Value1', showInLiveMap: false },
    { id: 2, value: 'Giraffe', key: 'AssetsLabels.Value2', showInLiveMap: true },
    { id: 3, value: 'Cable drums, Lamps', key: 'AssetsLabels.Value3', showInLiveMap: false },
    { id: 4, value: 'Machines', key: 'AssetsLabels.Value4', showInLiveMap: true },
    { id: 5, value: 'Keys', key: 'AssetsLabels.Value5', showInLiveMap: false },
    { id: 6, value: 'True', key: 'AssetsLabels.Value6', showInLiveMap: false },
    { id: 7, value: 'Security', key: 'AssetsLabels.Value7', showInLiveMap: false },
    { id: 8, value: 'Grinding cabin', key: 'AssetsLabels.Value8', showInLiveMap: true },
    { id: 9, value: 'Grinding machines', key: 'AssetsLabels.Value9', showInLiveMap: true },
    { id: 10, value: 'Spray booth', key: 'AssetsLabels.Value10', showInLiveMap: true },
    { id: 11, value: 'Spraying machines', key: 'AssetsLabels.Value11', showInLiveMap: true },
    { id: 12, value: 'Rising', key: 'AssetsLabels.Value12', showInLiveMap: true },
    { id: 13, value: 'Vacuum cleaner', key: 'AssetsLabels.Value13', showInLiveMap: true },
    { id: 14, value: 'Wallpaper table', key: 'AssetsLabels.Value14', showInLiveMap: false },
    { id: 15, value: 'Trailer', key: 'AssetsLabels.Value15', showInLiveMap: true },
    { id: 16, value: 'Conservatory', key: 'AssetsLabels.Value16', showInLiveMap: false },
    { id: 17, value: 'Car park', key: 'AssetsLabels.Value17', showInLiveMap: false },
];

export const DeviceType: any[] = [
    { id: 1, value: 'None', key: 'DeviceType.Value1' },
    { id: 2, value: 'BLE', key: 'DeviceType.Value2' },
    { id: 3, value: 'GPS', key: 'DeviceType.Value3' },
    { id: 4, value: 'GPSGATE', key: 'DeviceType.Value4' },
    { id: 5, value: 'QR', key: 'DeviceType.Value5' },
    { id: 6, value: 'LANGATE', key: 'DeviceType.Value6' },
    { id: 7, value: 'MOBILE', key: 'DeviceType.Value7' },
    { id: 8, value: 'MESHGATE', key: 'DeviceType.Value8' },
    { id: 9, value: 'CROWD', key: 'DeviceType.Value9' },
];

export const DeviceModel: any[] = [
    { id: 1, value: 'UNKNOWN', key: 'DeviceModel.Value1' },
    { id: 2, value: 'BT40', key: 'DeviceModel.Value2' },
    { id: 3, value: 'BT30S', key: 'DeviceModel.Value3' },
    { id: 4, value: 'BT41', key: 'DeviceModel.Value4' },
    { id: 5, value: 'BT80', key: 'DeviceModel.Value5' },
    { id: 6, value: 'GT100', key: 'DeviceModel.Value6' },
    { id: 7, value: 'GGB100', key: 'DeviceModel.Value7' },
    { id: 8, value: 'GGT100', key: 'DeviceModel.Value8' },
    { id: 9, value: 'LG100', key: 'DeviceModel.Value9' },
    { id: 10, value: 'MG100', key: 'DeviceModel.Value10' },
    { id: 11, value: 'DR100', key: 'DeviceModel.Value11' },
    { id: 12, value: 'DY100', key: 'DeviceModel.Value12' },
    { id: 13, value: 'TA100', key: 'DeviceModel.Value13' },
];

export const PayrollType: any[] = [
    { id: 1, value: 'isKoersel', key: 'koerselLabel', ds: 'Kørsel', en: 'Driving Kilometers' },
    { id: 2, value: 'isGDag', key: 'GDagLabel', ds: 'G-dage', en: 'G-days' },
    { id: 3, value: 'isHolidays', key: 'HolidaysLabel', ds: 'Feriedage', en: 'Holidays' },
    { id: 4, value: 'isSalaryAdvance', key: 'SalaryAdvanceLabel', ds: 'Lønforskud', en: 'Salary Advance' },
    { id: 5, value: 'isOther', key: 'OtherLabel', ds: 'Andet', en: 'Other' },
];

export const ProjectCycleModuleNames: any[] = [
    //{ id: 0, value: null, key: 'Module.All' },
    { id: 1, value: 'Project', key: 'Module.Project' },
    { id: 2, value: 'Requisition', key: 'Module.Requisition' },
    { id: 3, value: 'RequisitionMaterial', key: 'Module.RequisitionMaterial' },
    { id: 4, value: 'RequisitionCoworker', key: 'Module.RequisitionCoworker' },
    { id: 5, value: 'File', key: 'Module.File' },
    { id: 6, value: 'BookMark', key: 'Module.BookMark' },
    { id: 7, value: 'Offer', key: 'Module.Offer' },
    { id: 8, value: 'StatusChangeAnswer', key: 'Module.StatusChangeAnswer' },
    { id: 9, value: 'Email', key: 'Module.Email' },
];

export const MaterialType: any[] = [
    //{ id: 0, key: 'materialType.value0' },
    { id: 1, key: 'materialType.value1' },
    { id: 13, key: 'materialType.value13' },
    { id: 14, key: 'materialType.value14' },
    { id: 15, key: 'materialType.value15' },
    { id: 2, key: 'materialType.value2' },
    { id: 3, key: 'materialType.value3' },
    { id: 4, key: 'materialType.value4' },
    { id: 5, key: 'materialType.value5' },
    { id: 23, key: 'materialType.value23' },
    { id: 6, key: 'materialType.value6' },
    { id: 16, key: 'materialType.value16' },
    { id: 7, key: 'materialType.value7' },
    { id: 8, key: 'materialType.value8' },
    { id: 9, key: 'materialType.value9' },
    { id: 10, key: 'materialType.value10' },
    { id: 17, key: 'materialType.value17' },
    { id: 22, key: 'materialType.value22' },
    { id: 18, key: 'materialType.value18' },
    { id: 11, key: 'materialType.value11' },
    { id: 21, key: 'materialType.value21' },
    { id: 20, key: 'materialType.value20' },
    { id: 19, key: 'materialType.value19' },
    { id: 12, key: 'materialType.value12' },
]


export const Colors: string[] = [
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
    "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
    "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
    "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
    "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
    "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
    "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
    "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
    "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
    "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
    "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
    "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
    "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
    "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
    "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
    "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
    "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
    "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
    "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
    "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
    "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
    "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
    "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
    "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
    "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
    "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
    "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
    "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
    "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
    "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
    "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
    "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca",
    "#9cb64a", "#996c48", "#9ab9b7"];