export const locale = {
    lang: 'en',
    data: {
        'MATERIALS': {
            'TITLE': 'Material',
            'DESC': 'Hello Material!'
        },
        'BUTTONS': {
            'NEWMATERIAL': 'New Material',
            'BACK': 'Back',
            'SoldItem':'Sold item'
        },
        'TITLE': {
            'MaterialPagination': 'Number of materials per page',
            'IsDisplayWebshop': 'Is DisplayWebshop',
            'NoStockFeatures': 'No Stock Features',
            'RequiredDetail': 'Required Detail',
            "IsPostNumberDiscount": "ZIP code appendix",
            'FileExists':'File Exists',
            'OfferCalculatedMaterial':'Offer Calculated Material',
        },
        'FILTERS': {
            'TEXTSEARCH': 'Search By Name , Number',
            'NUMBERSEARCH': 'Number Search',
            'CaseNumber': 'CaseNumber',
            'ItemNumber': 'ItemNumber',
            'Text': 'Text',
            'Number': 'Number',
            'Picture': 'Picture',
            'Actions': 'Actions',
            'SORTING': 'Sort By',
            'PAGELABEL': 'Number of Materials per.page',
            'NEXT': 'Next',
            'PREVIOUS': 'Previous',
            'Active': 'Include all inactive',
            'BADGE': 'Inactive',
            'Deleted': 'Show Deleted',
            'ShowSupplierPDF': 'Show Supplier',
            'SendSupplierEmail': 'Send Supplier Email',
            'Scan': 'Scan',
            'OfferType': 'Offer Type',
            'All': 'All',
            "Materialtype": 'Material type',
            'ShowMisMatchMaterial': 'Show MisMatch',
            'MisMatchMaterialCount': "Material's Wrong detail",
            'ShowOnlyWebShopMaterial': 'Show Only Web Shop Material',
        },
        'Tabs': {
            'MaterialList': 'Material List',
            'Log': 'Log',
            'OrderItem': 'Order Item',
            'OrderedItem': 'Ordered Item',
            'SoldMaterialStock': 'Sold Stock',
            'Calculation': 'Material Calculation Container'
        },
        'MESSAGES': {
            'NORECORD': 'No Material Found',
            'NoBarcodeAttachedToMaterial': 'No barcode attached to any material.',
        },
        'SelectedOfferCategory': {
            'Value1': 'Indoor',
            'Value2': 'Outdoor',
            'Value3': 'Window',
            'Value4': 'Door',
            'Value5': 'Spray booth',
            'Value6': 'Talking',
            'Value7': 'Felt',
            'Value8': 'Tissue',
            'Value9': 'Rutex',
            'Value10': 'Wallpaper',
            'Value11': 'Panels',
            'Value12': 'Door frames',
            'Value13': 'Rutex',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiators',
            'Value17': 'Doors',
            'Value18': 'Facades',
            'Value19': 'Spartling',
            'Value20': 'Cover',
            'Value21': 'Ceilings',
            'Value22': 'Walls',
            'Value23': 'Guarantee',
            'Value24': 'Shed vg.',
            'Value25': 'Warning',
            'Value26': 'Floor',
        },
        'SORTBY':
            [
                //{
                //  Text: 'No Filter Select',
                //  Value: 'ID DESC'
                //},
                {
                    Text: 'Name (A-Z)',
                    Value: 'Text'
                },
                {
                    Text: 'Name (Z-A)',
                    Value: 'Text DESC'
                },
                {
                    Text: 'Number (0-9)',
                    Value: 'Number'
                },
                {
                    Text: 'Number (9-0)',
                    Value: 'Number DESC'
                },
                {
                    Text: 'Shelf no (A-Z)',
                    Value: 'ShelfNo'
                },
                {
                    Text: 'Shelf no (Z-A)',
                    Value: 'ShelfNo DESC'
                },
                {
                    Text: 'Purchase Quantity (0-9)',
                    Value: 'PurchaseQty'
                },
                {
                    Text: 'Purchase Quantity (9-0)',
                    Value: 'PurchaseQty DESC'
                }
            ],
        'TOOTIPORTITLE': {
            'AddMaterialManually':'Add Material Manually',
            'AddMaterialByscanner':'Add Material By Scanner',
            'SearchBarcode':'Search barcode',
            'UploadMaterialPriceList':'Upload Material Price List',
            'PriceSize':'Price size',
            'Number':'Number',
            'SoldItem': 'Sold item',
            'AddOfferCalculatedMaterial':'Add Offer calculated material',
        }
    }
};
