import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from 'app/main/pages/project/project.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator, MatSort, MatTable, MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, Sort, MatDialog } from '@angular/material';
import { RequestProjectHoursDetails } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectHours } from 'app/main/model/Project';
import { PdfViewerModelComponent } from "app/main/pages/pdf-viewer/pdf-viewer.component";
import { ProjectofferService } from "app/main/pages/project-offer/project-offer.service";
import { CompanyInfoService } from "app/main/pages/companyInfo/companyInfo.service";
import { NotificationsService } from "angular2-notifications";
import { CompanyInfo } from "app/main/model/CompanyInfo";
import * as ExcelJS from 'exceljs';
import * as moment from 'moment';
import { DenmarkDigitFormate } from "app/core/pipes/denmarkDigitFormate.pipe";
import { ProjectStatus } from "app/constant/common.constant";

@Component({
    selector: "project-hours-details",
    templateUrl: "./project-hours-details.html",
    styleUrls: ["./project-hours-details.scss"],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})

export class ProjectHoursDetails implements OnInit {
    displayedColumns: string[] = ['requistionCount', 'customerNumber', 'projectNumber', 'isHourlyWage', 'totalProjectHours', 'totalProjectWorkingHours', 'totalProjectRemainingHours', 'totalNegotiatedPrice', 'totalTurnover', 'totalRest', 'indoorOutdoorStatus', 'status'];
    dataSource: MatTableDataSource<ProjectHours>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
        // this.dataSource.sort = sort;
    }
    showSpinner: boolean = false;
    projectList = [];
    matserProjectList = [];
    total: number;
    requestBase: any;
    pagination: any;
    displayPagination: boolean = false;
    projectHoursTotal: number = 0;
    projectWorkingHoursTotal: number = 0;
    projectRemainingHoursTotal: number = 0;
    isShowAllProject: boolean = false;
    totalNegotiatedPrice: number = 0;
    totalTurnover: number = 0;
    totalRest: number = 0;
    negativeRest: number = 0;

    innerDisplayedColumns = ['nummer', 'afdelingNavn', 'negotiatedPrice', 'turnover', 'rest', 'status'];
    expandedElements: any[] = [];
    includeHourlyWages: boolean = true;
    includeTurnover: boolean = true;
    orderByField: string = "customerNumber";
    orderByDirection: string = "asc";
    companyInfoData: CompanyInfo;
    public denmarkDigitFormate = new DenmarkDigitFormate();
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private projectofferService: ProjectofferService,
        public companyInfoService: CompanyInfoService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<ProjectHoursDetails>,
        private router: Router,
        private translate: TranslateService,
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        //this.dataSource = new MatTableDataSource(this.projectList);
        this.includeTurnover = this.data.isIncludeTurnover
        this.requestBase = new RequestProjectHoursDetails();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        if (this.orderByDirection == "asc")
            this.requestBase.OrderBy = `${this.orderByField}`
        else
            this.requestBase.OrderBy = `${this.orderByField} ${this.orderByDirection}`
        this.getInActiveProjectList();
    }

    getInActiveProjectList() {
        this.showSpinner = true;
        this.requestBase.status = this.data.status;
        this.requestBase.indoorOutdoorStatus = this.data.indoorOutdoorStatus;
        this.projectService.getprojectallhoursdetailWithStatusBaseAndOrderBy(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response && response.result) {
                    this.showSpinner = false
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.matserProjectList = response.result;
                    this.onShowHideHoursWages();
                    // this.projectList = this.matserProjectList;
                    // this.projectList = this.projectList.filter(x => x.isHourlyWage == false && x.totalNegotiatedPrice > 0);
                    // for (var i = 0; i < this.projectList.length; i++) {
                    //     this.projectList[i].indoorOutdoorStatus = this.getIndoorOutdoorStatus(this.projectList[i]["indendoersUdendoersStatistID"])
                    //     this.projectHoursTotal = this.projectHoursTotal + this.projectList[i]["totalProjectHours"];
                    //     this.projectWorkingHoursTotal = this.projectWorkingHoursTotal + this.projectList[i]["totalProjectWorkingHours"];
                    //     if (!this.projectList[i].isHourlyWage) {
                    //         this.projectRemainingHoursTotal = this.projectRemainingHoursTotal + this.projectList[i]["totalProjectRemainingHours"];
                    //     }
                    //     this.totalNegotiatedPrice += this.projectList[i]["totalNegotiatedPrice"] ? this.projectList[i]["totalNegotiatedPrice"] : 0;
                    //     this.totalTurnover += this.projectList[i]["totalTurnover"] ? this.projectList[i]["totalTurnover"] : 0;
                    //     this.totalRest += this.projectList[i]["totalRest"] ? this.projectList[i]["totalRest"]: 0;
                    //     this.projectList[i].requisitionCount= this.projectList[i].sagOpgaveList.length;
                    //     this.projectList[i].sagOpgaveList= new MatTableDataSource(this.projectList[i].sagOpgaveList);
                    // }
                    // this.dataSource = new MatTableDataSource(this.projectList);
                    //this.dataSource.paginator = this.pagination;
                }
            },
            error: err => {
                this.showSpinner = false
            },
            complete: () => {
                this.showSpinner = false
            }
        })
    }

    getIndoorOutdoorStatus(status) {
        var statusText = "";
        var statusArr = status ? status.split(',') : [];
        if (statusArr.length > 0) {
            for (var i = 0; i < statusArr.length; i++) {
                var appendcomma = "";
                if (i != statusArr.length - 1)
                    appendcomma = ","
                if (statusArr[i] == "1")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value1').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "2")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value2').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "3")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value3').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "4")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value4').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "5")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value5').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "6")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value6').subscribe(value => { statusText += value + appendcomma; });
            }
            return statusText;
        }
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.requestBase = event.pageSize;
        this.getInActiveProjectList();
    }

    goToProject(id) {
        this.dialogRef.close(id);
    }

    close() {
        this.dialogRef.close(false);
    }

    toggleRow(element: any) {
        element.sagOpgaveList &&
            (element.sagOpgaveList as MatTableDataSource<any>).data.length
            ? this.toggleElement(element)
            : null;
        this.cd.detectChanges();
    }

    isExpanded(row: any): string {
        const index = this.expandedElements.findIndex(x => x.projectNumber == row.projectNumber);
        if (index !== -1) {
            return 'expanded';
        }
        return 'collapsed';
    }

    toggleElement(row: any) {
        const index = this.expandedElements.findIndex(x => x.projectNumber == row.projectNumber);
        if (index === -1) {
            this.expandedElements.push(row);
        } else {
            this.expandedElements.splice(index, 1);
        }
    }
    onShowHideHoursWages() {
        this.totalNegotiatedPrice = 0;
        this.totalTurnover = 0;
        this.totalRest = 0;
        this.negativeRest = 0;
        this.projectHoursTotal = 0;
        this.projectWorkingHoursTotal = 0;
        this.projectRemainingHoursTotal = 0;
        this.projectList = [];
        this.projectList = this.matserProjectList.map(x => Object.assign({}, x));
        if (!this.includeHourlyWages)
            this.projectList = this.projectList.filter(x => x.isHourlyWage == false && x.totalNegotiatedPrice > 0);
        if (this.includeTurnover)
            this.projectList = this.projectList.filter(x => x.totalTurnover > 0);
        for (var i = 0; i < this.projectList.length; i++) {
            this.projectList[i].indoorOutdoorStatus = this.getIndoorOutdoorStatus(this.projectList[i]["indendoersUdendoersStatistID"])
            this.projectHoursTotal = this.projectHoursTotal + this.projectList[i]["totalProjectHours"];
            this.projectWorkingHoursTotal = this.projectWorkingHoursTotal + this.projectList[i]["totalProjectWorkingHours"];
            if (!this.projectList[i].isHourlyWage) {
                this.projectRemainingHoursTotal = this.projectRemainingHoursTotal + this.projectList[i]["totalProjectRemainingHours"];
            }
            else {
                this.projectList[i]["totalRest"] = 0;
            }
            this.totalNegotiatedPrice += this.projectList[i]["totalNegotiatedPrice"] ? this.projectList[i]["totalNegotiatedPrice"] : 0;
            this.totalTurnover += this.projectList[i]["totalTurnover"] ? this.projectList[i]["totalTurnover"] : 0;
            this.totalRest += this.projectList[i]["totalRest"] ? this.projectList[i]["totalRest"] : 0;
            this.negativeRest += (this.projectList[i]["totalRest"] < 0) ? Math.abs(this.projectList[i]["totalRest"]) : 0;
            this.projectList[i].requisitionCount = this.projectList[i].sagOpgaveList.length;
            this.projectList[i].requistionList =this.projectList[i].sagOpgaveList;
            this.projectList[i].sagOpgaveList = new MatTableDataSource(this.projectList[i].sagOpgaveList);
        }
        this.dataSource = new MatTableDataSource(this.projectList);
    }

    goToProjectWorkhourShourtcut(project) {
        if (project.id) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(['/projects/edit', project.id])
            );
            window.open(url, '_blank');
        }
    }

    sortData(e) {
        this.orderByField = e['active'];
        this.orderByDirection = e['direction'];
        if (this.orderByDirection == "asc")
            this.requestBase.OrderBy = `${this.orderByField}`
        else
            this.requestBase.OrderBy = `${this.orderByField} ${this.orderByDirection}`
        this.getInActiveProjectList();
    }

    createPDForExcelorEmail(type, element) {
        this.showSpinner = true;
        if (type == "Excel")
            this.GetCompanyInformation();
        if (type == "PDF")
            this.downloadPDF(element);
    }

    downloadPDF(element) {
        setTimeout(() => {
            const requestModel = { invoiceHtml: element.innerHTML }
            this.projectofferService.HtmlToPDFConvert(requestModel).subscribe({
                next: (response) => {
                    if (response != null && response.result != null) {
                        var data = response.result.base64.replace("data:application/pdf;base64,", "");
                        const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                            height: '90%',
                            width: '90%',
                            data: {
                                base64: data,
                            }
                        });
                        dialogRef.afterClosed().subscribe(result => { 
                            this.showSpinner = false;
                        });
                    }
                },
                error: err => {
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
        }, 100);
    }

    GetCompanyInformation() {
        this.showSpinner = true;
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                    this.companyInfoData.imagePath = this.companyInfoData.logo;
                    this.downloadExcelFile();
                }
                else {
                }
                this.showSpinner = false;
            },
                (err: any) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                },
                () => {
                });
    }

    downloadExcelFile() {
        // Initialize ExcelJS
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Material Report');

        // Add the image to the workbook using the Base64 string
        const imageId = workbook.addImage({
            base64: this.companyInfoData.logoBase64, // Remove 'data:image/png;base64,' part
            extension: "png",
        });

        // Add the image to the worksheet (e.g., A1 to B3)
        worksheet.addImage(imageId, 'J1:K6');

        // Merge cells A4 and B4 for the company name
        worksheet.mergeCells('A1:F2');
        const companyCell = worksheet.getCell('A1');
        companyCell.value = 'Detaljer Om Projekttimer';
        companyCell.alignment = { horizontal: 'left', vertical: 'top' }; // Center align the text

        // Apply font styling
        companyCell.font = {
            name: 'Arial', // Font type
            size: 16,      // Font size
            bold: true,    // Make the text bold
            underline: true, // Underline the text
            color: { argb: 'FF0000' }
        };
        const fontTyle =  {
            name: 'Arial', 
            bold: true,    
            color: { argb: 'FF0000' },
        }; 

        // Merge cells A4 and B4 for the company name
        worksheet.mergeCells('G1:I2');
        worksheet.getCell('G1').value = this.companyInfoData.name;
        worksheet.getCell('G1').alignment = { horizontal: 'left', vertical: 'middle' }; // Center align the text

        // Merge cells A4 and B4 for the company name
        worksheet.mergeCells('G3:I3');
        worksheet.getCell('G3').value = this.companyInfoData.address;

        worksheet.mergeCells('G4:I4');
        worksheet.getCell('G4').value = this.companyInfoData.zipcode + " " + this.companyInfoData.city;

        worksheet.mergeCells('G6:I6');
        worksheet.getCell('G6').value = moment(new Date()).format("DD-MM-YYYY HH:mm");

        // Add headers under the image
        let headers = [];
        headers = ['Debitor Detaljer', 'Sag Adresse', 'Er sag på timeløn', 'Projekt timer', 'Projektets arbejdstid', 'Projektets resterende timer', 'Aftalt pris', 'Omsætning', 'Rest', 'Ind, ud, Sprøjt, Reklamation', 'Status'];

        for (let col = 0; col < headers.length; col++) {
            const cell = worksheet.getCell(`${String.fromCharCode(65 + col)}8`); // Columns A-L
            cell.value = headers[col];
            cell.font = { bold: true }; // Apply bold font to the header row
        }

        // Add the data rows from listObj
        let rowIndex = 9; // Start after the header (row 5)
        this.projectList.forEach((proj, index) => {       
            worksheet.getCell(`A${rowIndex}`).value = proj.customerNumber +'-' + proj.customerName; 
            worksheet.getCell(`B${rowIndex}`).value = proj.projectNumber +'-' + proj.projectAddress; 
            worksheet.getCell(`C${rowIndex}`).value = proj.isHourlyWage ? 'Er på timeløn' : '-';
            if(proj.isHourlyWage)
                worksheet.getCell(`C${rowIndex}`).font = { color: { argb: 'FF0000' }};
            worksheet.getCell(`D${rowIndex}`).value = proj.totalProjectHours ?  this.denmarkDigitFormate.transform(proj.totalProjectHours.toFixed(2)).toString() : '0';
            worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getCell(`E${rowIndex}`).value = proj.totalProjectWorkingHours ? this.denmarkDigitFormate.transform(proj.totalProjectWorkingHours.toFixed(2)).toString() : '0';
            worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getCell(`F${rowIndex}`).value = proj.totalProjectRemainingHours ? this.denmarkDigitFormate.transform(proj.totalProjectRemainingHours.toFixed(2)).toString() : '0';
            worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            if(proj.totalProjectRemainingHours < 0)
                worksheet.getCell(`F${rowIndex}`).font = { color: { argb: 'FF0000' }};
            worksheet.getCell(`G${rowIndex}`).value = proj.totalNegotiatedPrice ? this.denmarkDigitFormate.transform(proj.totalNegotiatedPrice.toFixed(2)).toString() : '-';
            worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getCell(`H${rowIndex}`).value = proj.totalTurnover ? this.denmarkDigitFormate.transform(proj.totalTurnover.toFixed(2)).toString() : '-';
            worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getCell(`I${rowIndex}`).value = proj.totalRest ? this.denmarkDigitFormate.transform(proj.totalRest.toFixed(2)).toString() : '-';
            worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getCell(`J${rowIndex}`).value = proj.indoorOutdoorStatus || '';
            worksheet.getCell(`K${rowIndex}`).value = proj.status ? this.getStatusValueById(proj.status) : '-';
            rowIndex++; 
            if(proj.requistionList && proj.requistionList.length > 0){
                proj.requistionList.forEach(req => {
                    worksheet.getCell(`B${rowIndex}`).value = req.nummer ? req.nummer : '';  
                    worksheet.mergeCells(`C${rowIndex}:F${rowIndex}`);                  
                    worksheet.getCell(`C${rowIndex}`).value = (req.afdelingNavn ? req.afdelingNavn :"") +"-"+ req.afdelingAdresse + "" + req.afdelingPostnummer+ "" +req.afdelingBynavn + " - "+ req.opgaveTekst; 
                    worksheet.getCell(`G${rowIndex}`).value = req.negotiatedPrice ? this.denmarkDigitFormate.transform(req.negotiatedPrice.toFixed(2)).toString() : '-'; 
                    worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                    worksheet.getCell(`H${rowIndex}`).value = req.turnover ? this.denmarkDigitFormate.transform(req.turnover.toFixed(2)).toString() : '-'; 
                    worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                    worksheet.getCell(`I${rowIndex}`).value = req.rest ? this.denmarkDigitFormate.transform(req.rest.toFixed(2)).toString() : '-'; 
                    worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                    worksheet.getCell(`K${rowIndex}`).value = req.status ? this.getStatusValueById(req.status) : '-';
                    rowIndex++; 
                  });
            }

            if (index === this.projectList.length - 1) {
                worksheet.getCell(`D${rowIndex}`).value = this.projectHoursTotal ?  this.denmarkDigitFormate.transform(this.projectHoursTotal.toFixed(2)).toString() : '0';
                worksheet.getCell(`D${rowIndex}`).font = { bold: true};
                worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                worksheet.getCell(`E${rowIndex}`).value = this.projectWorkingHoursTotal ? this.denmarkDigitFormate.transform(this.projectWorkingHoursTotal.toFixed(2)).toString() : '0';
                worksheet.getCell(`E${rowIndex}`).font = { bold: true};
                worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                worksheet.getCell(`F${rowIndex}`).value = this.projectRemainingHoursTotal? this.denmarkDigitFormate.transform(this.projectRemainingHoursTotal.toFixed(2)).toString() : '0';
                worksheet.getCell(`F${rowIndex}`).font = fontTyle;
                worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                worksheet.getCell(`G${rowIndex}`).value = this.totalNegotiatedPrice ? this.denmarkDigitFormate.transform(this.totalNegotiatedPrice.toFixed(2)).toString() : '0';
                worksheet.getCell(`G${rowIndex}`).font = fontTyle;
                worksheet.getCell(`G${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                worksheet.getCell(`H${rowIndex}`).value = this.totalTurnover ? this.denmarkDigitFormate.transform(this.totalTurnover.toFixed(2)).toString() : '0';
                worksheet.getCell(`H${rowIndex}`).font = fontTyle;
                worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                worksheet.getCell(`I${rowIndex}`).value = this.totalRest ? this.denmarkDigitFormate.transform(this.totalRest.toFixed(2)).toString() : '0';
                worksheet.getCell(`I${rowIndex}`).font = fontTyle;
                worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
                rowIndex++;
                worksheet.mergeCells(`D${rowIndex}:H${rowIndex}`);
                worksheet.getCell(`D${rowIndex}`).value = (this.totalTurnover ? this.denmarkDigitFormate.transform(this.totalTurnover.toFixed(2)).toString() : '0') +' - '+ 
                (this.negativeRest ? this.denmarkDigitFormate.transform(this.negativeRest.toFixed(2)).toString() : '0')+" = "+ (this.denmarkDigitFormate.transform((this.totalTurnover - this.negativeRest).toFixed(2)).toString());
                worksheet.getCell(`D${rowIndex}`).font = fontTyle;
                worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
            }
        });

        // Save the Excel file
        workbook.xlsx.writeBuffer().then(buffer => {
            var download = document.createElement("a");
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            download.href = window.URL.createObjectURL(blob);
            download.download = 'Materialler_rapport.xlsx';

            var event = document.createEvent("MouseEvents");
            event.initMouseEvent(
                "click", true, false, window, 0, 0, 0, 0, 0
                , false, false, false, false, 0, null
            );
            download.dispatchEvent(event);
        });
        this.showSpinner = false;
    }

    getStatusValueById(id: number): string | undefined {
        const status = ProjectStatus.find(item => item.id === parseInt(id.toString()));
        return status ? status.ds : '';
    }
}