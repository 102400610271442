import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { SettingsService } from '../../settings.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from "app/main/model/ResponseBase";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { Settings, SettingForUnicontaValue } from "app/main/model/Settings";
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
    selector: 'add-uniconta-credential',
  templateUrl: './add-uniconta-credential.component.html',
    styleUrls: ['./add-uniconta-credential.component.scss']
})
export class AddUnicontaCredentialComponent implements OnInit {
  @Input("commonSettings") commonSettings: Settings;
  showSpinner: boolean = false;
  generalForm: FormGroup;
  settingMasterValues: any[] = [];
  credintialObj: Settings;
  settingValue: SettingForUnicontaValue = new SettingForUnicontaValue();
  unicontaCredentialList: any = [];
  unicontaCredentialListObj: any;
  isListView: boolean = true;
  selectedUserIndex: number | null = null;
  isSubmitted = false;
  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private settingsService: SettingsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddUnicontaCredentialComponent>,
    private fb: FormBuilder,) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.credintialObj = new Settings();
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    this.generalForm = this.fb.group({
        UserName: [null],
        Password: [null],
        AccessId: [null],
        ComapnyName: [null],
        ComapnyId: [null],
        IsIntegrateUniconta: [false],
        Customer: [false],
        Material: [false],
        ProjectInvoice: [false],
        Supplier: [false],
        FinancialDetail: [false],
        IsExecuteAconto: [false],
        IsAcontoGenerateTXTFile: [false],
        AcontoFilePath: [null],
        IsAcontoGenerateSalesOrders: [false],
        IsAcontoGenerateInvoices: [false],
        AcontoGenerateOrders: ["IsAcontoGenerateSalesOrders"],
        AcontoAmountToGenerateInvoice: [null],
        AcontoMoreAmountToGenerateInvoice: [null],
        AcontoInvoiceMaterialID: [null],
        AcontoInvoiceGenerateTime: [null],
        AcontoInvoiceListSendCoworkerIDS: [null],
        CustomerVATOption: [null],
        GeneralGenerateOrders: ["IsAcontoGenerateSalesOrders"],
        IsGeneralGenerateTXTFile: [false],
        GeneralFilePath: [null],
        IsGeneralGenerateSalesOrders: [false],
        IsGeneralGenerateInvoices: [false],
    });
    this.getSettingsForUnicontaCredentialList();
  }

    onSubmit() {
    this.generalForm.markAllAsTouched();
    this.isSubmitted = true;    
    if (this.generalForm.valid) {
      this.changeSettings()
    }
  }

  changeSettings() {
      //if (this.generalForm.get("GenerateOrders").value == "IsGenerateSalesOrders") {
      //    this.generalForm.get('IsGenerateSalesOrders').setValue(true);
      //    this.generalForm.get('IsGenerateInvoices').setValue(false);
      //} else if (this.generalForm.get("GenerateOrders").value == "IsGenerateInvoices") {
      //    this.generalForm.get('IsGenerateSalesOrders').setValue(false);
      //    this.generalForm.get('IsGenerateInvoices').setValue(true);
      //} else {
      //    this.generalForm.get("GenerateOrders").setValue("IsGenerateSalesOrders");
      //    this.generalForm.get('IsGenerateSalesOrders').setValue(true);
      //    this.generalForm.get('IsGenerateInvoices').setValue(false);
      //}
      if(this.selectedUserIndex !== null){
        if (this.isDuplicateCompanyId(this.generalForm.get("ComapnyId").value, this.selectedUserIndex)) {
          this.translate.get("MESSAGES.CompanyAlreadyExits").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => { }
          });
          return;
        }
        this.unicontaCredentialList[this.selectedUserIndex] =  this.generalForm.value;
      }
      else      
        this.unicontaCredentialList.push(this.generalForm.value);
      this.changeSettingsForUnicontaCredentialList();
    
  } 

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }

  getSettingsForUnicontaCredentialList() {
    this.showSpinner = true;
    this.settingsService.GetSettingsValue<ResponseBase>("Credential", "UnicontaCredentialList", null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.unicontaCredentialListObj = response.result.myResponse[0];
          this.unicontaCredentialList = JSON.parse(response.result.myResponse[0].value);
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeSettingsForUnicontaCredentialList() {
    this.showSpinner = true;
    this.unicontaCredentialListObj.value = JSON.stringify(this.unicontaCredentialList);
    this.settingsService.UpdateSettingValue<ResponseBase>(this.unicontaCredentialListObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.unicontaCredentialListObj = response.result;
          this.unicontaCredentialList = JSON.parse(response.result.value);
          this.changeInLocalStorage(this.unicontaCredentialListObj.id, this.unicontaCredentialListObj)
          this.clearSelection();
        }
        this.translate.get("MESSAGES.Saved").subscribe({
          next: (res) => {
            this.notificationsService.create(res);
          },
          error: err => { }
        });
        //this.dialogRef.close(true);
        
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  addNewCompany(){
    this.isListView = !this.isListView; 
  }

  edit(index){
    this.selectedUserIndex = index;
    this.isListView = false;
    this.generalForm.patchValue(this.unicontaCredentialList[index]);
  }

  delete(index) {
    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
      data: { Header: 'Headers.DeleteMessage' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unicontaCredentialList.splice(index, 1);
        this.changeSettingsForUnicontaCredentialList();
        this.selectedUserIndex = null;
      }
    });
  }

  isDuplicateCompanyId(companyId: string, excludeIndex?: number): boolean {
    return this.unicontaCredentialList.some((user, index) => user.ComapnyId === companyId && index !== excludeIndex);
  }

  clearSelection() {
    this.selectedUserIndex = null;
    this.isSubmitted = false;
    this.isListView = true;
  }

  close() {
    this.dialogRef.close(true);
  }
}
