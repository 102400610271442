import { NgModule } from "@angular/core";
import { SettingsComponent } from './settings.component';
import { SettingsService } from './settings.service';
import { DatePickerModule, CalendarModule } from '@syncfusion/ej2-angular-calendars';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { SharedModule } from 'app/core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { IsSendEmailSMSComponent } from './component/issend-email-sms/issend-email-sms.component';
import { ProjectEconomySettingsComponent } from './component/project-economy/project-economy.component';
import { MaterialSettingsComponent } from "./component/material-settings/material-settings.component";
import { CalculateProjectPriceStatusSettingsComponent } from './component/calculate-project-price-status/calculate-project-price-status.component';
import { HolidayListComponent } from "./component/holiday-list/holiday-list.component";
import { PlanningNotificationsSettingsComponent } from "./component/planning-notifications/planning-notifications.component";
import { SendOfferSMSConfigurationComponent } from "./component/send-offer-sms-configuration/send-offer-sms-configuration.component";
import { ToolsAndLaddersSettingsComponent } from './component/tools-and-ladders/tools-and-ladders.component';
import { CustomerDefaultMaterialsComponent } from './component/customer-default-materials/customer-default-materials.component';
import { CalendarDefaultCoworkerComponent } from './component/calendar-default-coworker/calendar-default-coworker.component';
import { QuestionPopupComponent } from './component/question-popup/question-popup.component';
import { CoWorkerComponent } from './component/coWorker/coWorker.component';
import { ProjectWorkingHoursDetailComponent } from './component/project-working-hours-detail/project-working-hours-detail.component';
import { VibeCustomerDailySalesComponent } from './component/customer-daily-sales/customer-daily-sales.component';
import { PlanningWorkingHoursDetailComponent } from './component/planning-working-hours-detail/planning-working-hours-detail.component';
import { PostNummerComponent } from './component/postNummer/postNummer.component';
import { WeatherTypeComponent } from './component/weather-type/weather-type.component';
import { DrivingAmountComponent } from './component/driving-amount/driving-amount.component';
import { OtherSettingComponent } from './component/other-setting/other-setting.component';
import { MaterialColorCodeComponent } from './component/colorCode/colorCode.component';
import { CorkerSettingComponent } from './component/coworker-setting/coworker-setting.component';
import { CoworkerReviewQuestionsComponent } from './component/coworker-review-questions/coworker-review-questions.component';
import { ProjectSearchSettingsComponent } from './component/project-search-settings/project-search-settings.component';
import { VehicleBatteryNotificationsSettingsComponent } from "./component/vehicle-battery-notifications/vehicle-battery-notifications.component";
import { EmailSendConfigurationComponent } from './component/email-send-configuration/email-send-configuration.component';
import { SMSSendConfigurationComponent } from './component/smssend-configuration/smssend-configuration.component';
import { BirthdayNotificationsSettingsComponent } from './component/birthday-notifications-settings/birthday-notifications-settings.component';
import { ReminderSMSSettingsComponent } from './component/reminder-smssettings/reminder-smssettings.component';
import { NewCoworkerJoinNotificationsSettingsComponent } from './component/new-coworker-join-notifications-settings/new-coworker-join-notifications-settings.component';
import { CoworkerAnniversaryReminderSettingsComponent } from './component/coworker-anniversary-reminder-settings/coworker-anniversary-reminder-settings.component';
import { CommonNotificationsSettingsComponent } from './component/common-notifications-settings/common-notifications-settings.component';
import { PaymentGeneralSettingsComponent } from './component/payment-general-settings/payment-general-settings.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { SettingEmailTempalteEditorComponent } from './component/setting-email-tempalte-editor/setting-email-tempalte-editor.component';
import { PaymentSettingsComponent } from './component/payment-settings/payment-settings.component';
import { PaymentSalaryCalculationComponent } from './component/payment-salary-calculation/payment-salary-calculation.component';
import { RoleClaimsComponent } from './component/role-claims/role-claims.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NotificationsendConfigurationComponent } from './component/notificationsend-configuration/notificationsend-configuration.component';
import { OfferAcceptedEmailTemplateComponent } from './component/offer-accepted-email-template/offer-accepted-email-template.component';
import { EmailTemplateForAllModuleSettingComponent } from './component/email-template-allmodule-setting/email-template-allmodule-setting.component';
import { DropboxPathSettingComponent } from './component/dropbox-path-setting/dropbox-path-setting.component';
import { VonageSmsCoworkerSettingComponent } from './component/vonage-sms-coworker-setting/vonage-sms-coworker-setting.component';
import { CredintialsComponent } from './component/credintials/credintials.component';
import { CredintialEmailComponent } from './component/credintial-email/credintial-email.component';
import { CredintialInvoiceEmailComponent } from './component/credintial-invoice-email/credintial-invoice-email.component';
import { CredintialSmsComponent } from './component/credintial-sms/credintial-sms.component';
import { CredintialUnicontaComponent } from './component/credintial-uniconta/credintial-uniconta.component';
import { ListViewComponent } from './component/list-view/list-view.component';
import { CoworkerEconomySettingsComponent } from "./component/coworker-economy/coworker-economy.component";
import { UserRoleListComponent } from './component/user-role-list/user-role-list.component';
import { AddUnicontaCredentialComponent } from './component/add-uniconta-credential/add-uniconta-credential.component';

const routes = [
    {
        path: 'settings',
        component: SettingsComponent
    },
    {
        path: 'settings/reviewquestion',
        component: CoworkerReviewQuestionsComponent
    }
];

@NgModule({
    declarations: [
        SettingsComponent,
        IsSendEmailSMSComponent,
        ProjectEconomySettingsComponent,
        MaterialSettingsComponent,
        CalculateProjectPriceStatusSettingsComponent,
        HolidayListComponent,
        PlanningNotificationsSettingsComponent,
        SendOfferSMSConfigurationComponent,
        ToolsAndLaddersSettingsComponent,
        CustomerDefaultMaterialsComponent,
        CalendarDefaultCoworkerComponent,
        QuestionPopupComponent,
        CoWorkerComponent,
        ProjectWorkingHoursDetailComponent,
        VibeCustomerDailySalesComponent,
        PlanningWorkingHoursDetailComponent,
        PostNummerComponent,
        WeatherTypeComponent,
        DrivingAmountComponent,
        OtherSettingComponent,
        MaterialColorCodeComponent,
        CorkerSettingComponent,
        CoworkerReviewQuestionsComponent,
        ProjectSearchSettingsComponent,
        VehicleBatteryNotificationsSettingsComponent,
        EmailSendConfigurationComponent,
        SMSSendConfigurationComponent,
        BirthdayNotificationsSettingsComponent,
        ReminderSMSSettingsComponent,
        NewCoworkerJoinNotificationsSettingsComponent,
        CoworkerAnniversaryReminderSettingsComponent,
        SettingEmailTempalteEditorComponent,
        CommonNotificationsSettingsComponent,
        PaymentSettingsComponent,
        PaymentGeneralSettingsComponent,
        PaymentSalaryCalculationComponent,
        RoleClaimsComponent,
        NotificationsendConfigurationComponent,
        OfferAcceptedEmailTemplateComponent,
        EmailTemplateForAllModuleSettingComponent,
        DropboxPathSettingComponent,
        VonageSmsCoworkerSettingComponent,
        CredintialsComponent,
        CredintialEmailComponent,
        CredintialInvoiceEmailComponent,
        CredintialSmsComponent,
        CredintialUnicontaComponent,
        ListViewComponent,
        CoworkerEconomySettingsComponent,
        UserRoleListComponent,
        AddUnicontaCredentialComponent
    ],
    imports: [
        DatePickerModule,
        //PDFExportModule,
        SharedModule,
        ChartsModule,
        NgxChartsModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        CalendarModule,
        CKEditorModule,
        MatTooltipModule
    ],
    entryComponents: [
        SettingsComponent,
        IsSendEmailSMSComponent,
        ProjectEconomySettingsComponent,
        MaterialSettingsComponent,
        ToolsAndLaddersSettingsComponent,
        CustomerDefaultMaterialsComponent,
        CalendarDefaultCoworkerComponent,
        QuestionPopupComponent,
        CoWorkerComponent,
        ProjectWorkingHoursDetailComponent,
        VibeCustomerDailySalesComponent,
        PlanningWorkingHoursDetailComponent,
        PostNummerComponent,
        WeatherTypeComponent,
        DrivingAmountComponent,
        OtherSettingComponent,
        MaterialColorCodeComponent,
        VehicleBatteryNotificationsSettingsComponent ,
        SettingEmailTempalteEditorComponent,
        OfferAcceptedEmailTemplateComponent,       
        UserRoleListComponent,
        AddUnicontaCredentialComponent
    ],
    exports: [
        CorkerSettingComponent,
        CoworkerReviewQuestionsComponent,
        ProjectSearchSettingsComponent,
        CommonNotificationsSettingsComponent,
        PaymentSettingsComponent,
        PaymentGeneralSettingsComponent,
        PaymentSalaryCalculationComponent,
        RoleClaimsComponent,
        EmailTemplateForAllModuleSettingComponent,
        CoworkerEconomySettingsComponent     
    ],
    providers: [
        SettingsService
    ]
})

export class SettingsModule {

}