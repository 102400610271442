export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header': 'Uniconta legitimationsoplysninger',
        },
        'FIELDS': { 
            'UserName': 'Bruger navn',
            'Password': 'Adgangskode',
            'AccessId': 'Access id',
            'ComapnyName': 'Firma navn',
            'ComapnyId': 'Firma id',
            'GenerateSalesOrders': 'Generer salgsordrer',
            'GenerateInvoices': 'Generer fakturaer',
            'GenerateTXTFile': 'Generer TXT File',
            'Path':'Sti/filnavn',                    
         },
        'VALIDATIONS': {
            'UserName': 'Bruger navn er påkrævet',
            'Password':'Adgangskode er påkrævet',
            'ComapnyName':'Firma navn er påkrævet',
            'ComapnyId':'Firma id er påkrævet'
        },
        'MESSAGES': {
            'Saved': 'Gemt Succesfuldt.',
            'CompanyAlreadyExits' : 'Firma-id findes allerede!'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'AddCompany':'Tilføj firma'
        },
        'Help':{
            'Note':'Brug kun skråstreg(/) i stiindstilling',
            'Delete':'Slet',
        },
    }
};
