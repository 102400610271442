export const locale = {
  lang: 'ds',
  data: {
    'CUSTOMER': {
      'TITLE': 'Kunde',
      'DESC': 'Hej Projekt!',
      'TabBasicDetails': 'Grundlæggende oplysninger ',
      'TabContactDetails': 'Kontaktoplysninger',
      'TabOrderDetails': 'Ordre'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'Ny kunde',
      'BACK': 'Gå tilbage',
      'BTNSAVE': 'Gem',
      'BTNCONFIRM': 'Bekræfte'
    },
    'FIELDS': {
      'Address-line': 'Adresse',
      'City': 'By',
      'Zipcode': 'Postnummer',
      'Position': 'Position',
      'EnterLocation': 'Indtast din placering',
      'CONFIRM_ADDRESS': 'Bekræft din adresse',
      'CONFIRM': 'Bekræft',
      'CANCEL': 'Ændre ikke',
      'FullAddress': 'Fuld adresse',
      'Latitude':'Breddegrad',
      'Longitude':'Længde',
      'Confirm_Latlong': 'Bekræfte lat længe',
    },
    'VALIDATIONS': {
      'MapLocation':'Placering påkrævet',
      'Address':'Adresse påkrævet',
      'ZipCode':'Postnummer påkrævet',
      'City':'By påkrævet',
      'Position':'Position påkrævet',
    }
  }
};
