export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem': 'Work Item'
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon': 'Comming Soon..',
      'Deleted': 'Deleted successfully',
      'Updated': 'Updated successfully',
    },
    'FIELDS': {
      'GoTo': 'Go to',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker',
      'SRNO': 'Sr No',
      'Date': 'Date',
    },
    'BUTTONS': {
      'Add': 'Add',
        'AddInformation': 'Add information',
        'AllImage': 'All Images'
    },
    'Headers': {
      'DeleteCategory': 'Do you want to delete category',
    },
    'TOOTIPORTITLE': {
      'SendEmail': 'Send Email',
      'ViewPDF': 'View Pdf',
      'Edit': 'Edit',
      'Delete': 'Delete',
      'GoToCategory':'Go To Category',
    },
    'FILTERS': {
      'SORTING': 'Sort By',
    },
    'SORTBY':
      [
        {
          Text: 'Name (A-Z)',
          Value: 'Name'
        },
        {
          Text: 'Name (Z-A)',
          Value: 'Name DESC'
        }
      ],
  }
};
