import { OnInit, Component, Input } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from 'app/main/pages/project/project.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestProjectLogs } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MatDialog } from '@angular/material';
import { ProjectLogDetailComponent } from './component/project-log-detail/project-log-detail.component';
import { RequisitionCoworkerLogDetailComponent } from './component/requisition-coworker-log-detail/requisition-coworker-log-detail.component';
import { RequisitionLogDetailComponent } from './component/requisition-log-detail/requisition-log-detail.component';
import { RequisitionMaterialLogDetailComponent } from './component/requisition-material-log-detail/requisition-material-log-detail.component';
import { InquiryLogDetailComponent } from './component/inquiry-log-detail/inquiry-log-detail.component';
import { OfferLogDetailComponent } from './component/offer-log-detail/offer-log-detail.component';
import { environment } from 'environments/environment';
import { AnswerStatusLogComponent } from '../answer-status-log/answer-status-log.component';
import { OfferRoomLogDetailComponent } from './component/offer-room-log-detail/offer-room-log-detail.component';
import { MaterialLogDetailComponent } from './component/material-log-detail/material-log-detail.component';
import { ProjectCycleModuleNames } from 'app/constant/common.constant';
import { SharedLinkDTO } from 'app/main/model/DropBox';
import { DropboxFilesService } from '../dropbox-files/dropbox-files.service';
import { NotificationsService } from 'angular2-notifications';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vibe-project-cycle-log',
  templateUrl: 'project-cycle-log.component.html',
  styleUrls: ['./project-cycle-log.component.scss']
})
export class ProjectCycleLogComponent implements OnInit {

  showSpinner = false;
  @Input() ProjectID: string = null;
  @Input() InquiryID: string = null;
  @Input() OfferID: string = null;
  @Input() MaterialID: string = null;
  @Input() SupplierList: any = [];
  requestModel: RequestProjectLogs = new RequestProjectLogs();
  projectCycleLogData: any = [];
  displayPagination: boolean;
  pagination: any;
  limitNumber: any;
  projectCycleModuleNames = ProjectCycleModuleNames;
  moduleName = "0";

  constructor(private translationLoader: FuseTranslationLoaderService,
    private projectservice: ProjectService,
    private dropboxFilesService: DropboxFilesService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private platform: Platform,
    public dialog: MatDialog) {
    this.translationLoader.loadTranslations(english, danish);    
  }

  ngOnInit() {
    this.requestModel.Index = 1;
    this.requestModel.Limit = 200;
    this.requestModel.OrderBy = "ModifyDate DESC";
    this.requestModel.ProjectID = this.ProjectID;
    this.requestModel.InquiryID = this.InquiryID;
    this.requestModel.OfferID = this.OfferID;
    this.requestModel.MaterialID = this.MaterialID;
    this.getLogs();
  }

  getLogs() {
    this.showSpinner = true;
    this.projectservice.GetProjectLogs<ResponseBase>(this.requestModel)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          if (response != null && response.result) {
            this.projectCycleLogData = response.result;
            this.pagination = response.pagination;
            this.displayPagination = true;
          }
          else {
            this.projectCycleLogData = [];
            this.displayPagination = false;
          }
        },
        error: err => {
          this.showSpinner = false;
          this.projectCycleLogData = [];
          this.displayPagination = false;
        },
        complete: () => { }
      });
  }

  receivePaginatonEvent(event) {
    this.requestModel.Index = event.pageIndex;
    this.requestModel.Limit = event.pageSize;
    this.limitNumber = event.pageSize;
    this.getLogs();
  }
  getStatus(status) {
    var statusField = "";
    if (status == 1 || status == 101 || status == 201 || status == 301 || status == 401 || status == 501 || status == 601 || status == 801)
      statusField = "STATUS.Created"
    if (status == 2 || status == 102 || status == 202 || status == 302 || status == 402 || status == 502 || status == 602 || status == 802)
      statusField = "STATUS.Updated"
    if (status == 3 || status == 103 || status == 203 || status == 303 || status == 403 || status == 503 || status == 603 || status == 803)
      statusField = "STATUS.Inactive"
    if (status == 4 || status == 104 || status == 204 || status == 304 || status == 404 || status == 504 || status == 604 || status == 804)
      statusField = "STATUS.Active"
    if (status == 5 || status == 105 || status == 205 || status == 305 || status == 405 || status == 505 || status == 605 || status == 805)
      statusField = "STATUS.Deleted"
    if (status == 6 || status == 106 || status == 206 || status == 306 || status == 406 || status == 506)
      statusField = "STATUS.Statuschane"
    if (status == 8 || status == 9)
      statusField = "STATUS.Statuschane"
    if (status == 50)
      statusField = "STATUS.FileUploaded"  
    if (status == 51)
      statusField = "STATUS.FileDeleted" 
    if (status == 702)
      statusField = "STATUS.Updated"  
    return statusField;
  }

  viewCustomerDetailDifference(currentItem) {
    //Sag 
    if (currentItem.moduleName == 'Project') {
      const dialogRefOne = this.dialog.open(ProjectLogDetailComponent, {
        height: '80%',
        width: '60%',
        data: currentItem.id
      });
      dialogRefOne.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }

    //SagOpgave
    else if (currentItem.moduleName == 'Requisition') {
      const dialogRefTwo = this.dialog.open(RequisitionLogDetailComponent, {
        height: '70%',
        width: '60%',
        data: currentItem.id
      });
      dialogRefTwo.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }

    //Co-worker
    else if (currentItem.moduleName == 'RequisitionCoworker') {
      const dialogRefThree = this.dialog.open(RequisitionCoworkerLogDetailComponent, {
        height: '70%',
        width: '60%',
        data: currentItem.id
      });
      dialogRefThree.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }

    //Material 
    else if (currentItem.moduleName == 'RequisitionMaterial') {
      const dialogRefFour = this.dialog.open(RequisitionMaterialLogDetailComponent, {
        height: '70%',
        width: '60%',
        data: currentItem.id
      });
      dialogRefFour.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }

    //Inquiry
    else if (currentItem.moduleName == 'Inquiry') {
      const dialogRefFour = this.dialog.open(InquiryLogDetailComponent, {
        height: '70%',
        width: '60%',
        data: {id: currentItem.id, logData:this.projectCycleLogData }
      });
      dialogRefFour.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }

    //Offer
    else if (currentItem.moduleName == 'Offer') {
      const dialogRefFour = this.dialog.open(OfferLogDetailComponent, {
        height: '70%',
        width: '60%',
        data: currentItem.id
      });
      dialogRefFour.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }

    else if (currentItem.moduleName == 'OfferRoom') {
      const dilogRefSix = this.dialog.open(OfferRoomLogDetailComponent, {
        height: '70%',
        width: '60%',
        data: currentItem.id
      });
      dilogRefSix.afterClosed().subscribe(result => {
        if (result != null && result != '') {

        }
      });
    }

    //Material 
    else if (currentItem.moduleName == 'Vare') {
      const dialogRefFour = this.dialog.open(MaterialLogDetailComponent, {
        height: '80%',
        width: '70%',
        data: {id: currentItem.id, supplierList: this.SupplierList, logData:this.projectCycleLogData }
      });
      dialogRefFour.afterClosed().subscribe(result => {
        if (result != null && result != '') {
        }
      });
    }
  }

  openAnswer(log) {
    const dilogRefFive = this.dialog.open(AnswerStatusLogComponent, {
      height: "70%",
      width: "70%",
      data: log
    })
    dilogRefFive.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  viewEmailDetail(id) {
    this.projectservice.GetProjectEmailLogDetails<ResponseBase>(id)
      .subscribe({
        next: (response: ResponseBase) => {
          var url = environment.url + response.result.filePath
          window.open(url, "_blank");
        },
        error: err => {
          this.showSpinner = false;
          this.displayPagination = false;
        },
        complete: () => { }
      });
  }

  // convertDate(date) {
  //     var formatedDate;
  //     if (date) {
  //         return  formatedDate = moment(date, 'dd-MM-yyyy').format('dd.MM.yyyy')    
  //     }
  // }

    onModuleNameChange(){
      if(this.moduleName == "0")
        this.requestModel.ModuleName = null;
      else
        this.requestModel.ModuleName = this.moduleName;
      this.getLogs();
    }

  getSharedLink(obj) {
    let sharedLinkDTO = new SharedLinkDTO();
    sharedLinkDTO.path = obj.filePath + "/" + obj.fileName;
    this.showSpinner = true;
    this.dropboxFilesService.GetSharedLink<ResponseBase>(sharedLinkDTO).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        // if (this.platform.IOS) {
        //   this.viewDrpBoxFile(response.result.url);
        // } else {
          window.open(response.result.url);
        //}
      },
      error: err => {
        this.showSpinner = false;
        this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      }
    });
  }

  // viewDrpBoxFile(filename) {
  //   const dialogRef = this.dialog.open(ViewAlertModelComponent, {
  //     height: '200px',
  //     data: filename
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //     }
  //     this.fileObjList.filter(x => {
  //       if (x.checked) {
  //         x.checked = false;
  //       }
  //     });
  //   });
  // }
}
