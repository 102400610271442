import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs';
import { Settings } from 'app/main/model/Settings';
import { RequestBaseFull, RequestProjectRecuringSetting } from 'app/main/model/RequestBase';

@Injectable({
    providedIn: 'root'
})

export class SettingsService {
    private actionSettingsUrl: string;
    private unicontaApiUrl: string;

    constructor(private http: HttpClient) {
        this.actionSettingsUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
        this.unicontaApiUrl = ApiConstant.API_URL + ApiConstant.UNICONTAAPI + "/";
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetSettingsValue<T>(param1: string, param2: string, param3: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "param1=" + param1;
        }
        if (param2) {
            url += "&param2=" + param2;
        }
        if (param3) {
            url += "&param3=" + param3;
        }
        return this.http.get<T>(this.actionSettingsUrl + "getSettings?" + url);
    }

    public GetSettingsWithTemplateValue<T>(param1: string, param2: string, param3: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "param1=" + param1;
        }
        if (param2) {
            url += "&param2=" + param2;
        }
        if (param3) {
            url += "&param3=" + param3;
        }
        return this.http.get<T>(this.actionSettingsUrl + "GetSettingsWithTemplate?" + url);
    }

    public GetSettingsWithMappingValue<T>(param1: string, param2: string, param3: string, param4: boolean): Observable<T> {
        var url = "";
        if (param1) {
            url += "param1=" + param1;
        }
        if (param2) {
            url += "&param2=" + param2;
        }
        if (param3) {
            url += "&param3=" + param3;
        }
        if (param4) {
            url += "&isDefaultMapper=" + param4;
        }
        return this.http.get<T>(this.actionSettingsUrl + "getSettingsWithMappingValue?" + url);
    }

    public UpdateSettingValue<T>(setObj: Settings): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + setObj.id, setObj);
    }

    public UpdateSettingWithTemplate<T>(setObj: Settings): Observable<T> {
        var fullurl = this.actionSettingsUrl + "UpdateSettingWithTemplate/" + setObj.id;
        return this.http.put<T>(fullurl, setObj);
    }

    public GetSettingsWithMupltipleKeys<T>(param1: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "?param1=" + param1;
        }
        return this.http.get<T>(this.actionSettingsUrl + "getSettingsWithMupltipleKeys" + url);
    }

    public CreateHoliday<T>(holidayDto): Observable<T> {
        return this.http.post<T>(this.actionSettingsUrl + "CreateHoliday", holidayDto);
    }

    public UpdateHoliday<T>(id, holidayDto): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + "UpdateHoliday/" + id, holidayDto);
    }

    public DeleteHoliday<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "DeleteHoliday/" + id);
    }

    public GetHolidayList<T>(RequestHoliday: any): Observable<T> {
        let search = this.GetParams(RequestHoliday);
        return this.http.get<T>(this.actionSettingsUrl + "GetHolidayList", {
            params: search
        });
    }

    public GetCustomerVATOptionList<T>(Request: any): Observable<T> {
        let search = this.GetParams(Request);
        return this.http.get<T>(this.unicontaApiUrl + "GetVATOptionsList", {
            params: search
        });
    }

    public SaveCustomerDefaultMaterial<T>(customerDefaultMaterial: object): Observable<T> {
        return this.http.post<T>(this.actionSettingsUrl + "AddCustomerDefaultMaterial/", customerDefaultMaterial);
    }

    public DeleteCustomerDefaultMaterial<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "deleteCustomerDefaultMaterial/" + id);
    }
    public GetCustomerDefaultMaterial<T>(reqObj: RequestBaseFull): Observable<T> {
        let search = this.GetParams(reqObj);
        return this.http.get<T>(this.actionSettingsUrl + "getCustomerDefaultMaterials", {
            params: search
        });
    }

    public AddOrUpdateReviewQuestions(questions): Observable<any> {
        return this.http.post<any>(this.actionSettingsUrl + 'CreateOrUpdateCoworkerReviewQuestions', questions);
    }

    public GetCoworkerReviewQuestionList<T>(RequestCommonBase: any): Observable<T> {
        let search = this.GetParams(RequestCommonBase);
        return this.http.get<T>(this.actionSettingsUrl + "getCoworkerReviewQuestionList", {
            params: search
        });
    }

    public DeleteCoworkerReviewQuestion<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "deleteCoworkerReviewQuestion/" + id);
    }

    public GetCoworkerReviewQuestionById<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "getCoworkerReviewQuestionById?id=" + id);
    }

    public GetCoworkerReviewQuestionNotAnswer<T>(): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "getCoworkerReviewQuestionNotAnswer");
    }

    public AddCoworkerReviewAnswers(questions): Observable<any> {
        return this.http.post<any>(this.actionSettingsUrl + 'CreateCoworkerReviewAnswers', questions);
    }

    public GetCoworkerReviewAnswerList<T>(RequestCommonBase: any): Observable<T> {
        let search = this.GetParams(RequestCommonBase);
        return this.http.get<T>(this.actionSettingsUrl + "getCoworkerReviewAnswerList", {
            params: search
        });
    }
    public GetEmployeeReviewAnswerList<T>(RequestCommonBase: any): Observable<T> {
        let search = this.GetParams(RequestCommonBase);
        return this.http.get<T>(this.actionSettingsUrl + "getEmployeeReviewAnswerList", {
            params: search
        });
    }

    public GetCoworkerReviewAnswerById<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "getCoworkerReviewAnswerById?id=" + id);
    }

    public GetCoworkerReviewAnswerForPieChart<T>(): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "getCoworkerReviewAnswerForPieChart", {});
    }

    public AddReminderSchedule(schedule: any): Observable<any> {
        return this.http.post<any>(this.actionSettingsUrl + 'ReminderSchedule', schedule);
    }

    public UpdateReminderSchedule<T>(id, schedule): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + "ReminderSchedule/" + id, schedule);
    }

    public DeleteReminderSchedule<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "ReminderSchedule/" + id);
    }

    public GetReminderScheduleById<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "ReminderSchedule/" + id);
    }

    public DeleteImagesByPaths<T>(paths): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + "DeleteImagesByPath", paths);
    }

    public DeleteImagesByPath(imagePath: any): Observable<any> {
        return this.http.put<any>(this.actionSettingsUrl + "DeleteImagesByPath/", imagePath);
    }

    public RenameFile(sourceFilePath: any, newFileName: string): Observable<any> {
        return this.http.put<any>(this.actionSettingsUrl + "RenameFile?sourceFilePath=" + sourceFilePath + "&newFileName=" + newFileName, null);
    }

    public DeleteCoworkerReviewAnswer<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "deleteCoworkerReviewAnswer/" + id);
    }
    
    public DeleteAllCoworkerReviewAnswerByQuestionId<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "deleteAllCoworkerReviewAnswerByQuestionId/" + id);
    }

    public GetOfferRejectedAnswerList<T>(RequestCommonBase: any): Observable<T> {
        let search = this.GetParams(RequestCommonBase);
        return this.http.get<T>(this.actionSettingsUrl + "getOfferRejectedAnswerList", {
            params: search
        });
    }

    public AddProjectRecuringSetting(schedule: any): Observable<any> {
        return this.http.post<any>(this.actionSettingsUrl + 'ProjectRecuringSetting', schedule);
    }

    public UpdateProjectRecuringSetting<T>(id, schedule): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + "ProjectRecuringSetting/" + id, schedule);
    }

    public DeleteProjectRecuringSetting<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "ProjectRecuringSetting/" + id);
    }

    public GetProjectRecuringSettingById<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "ProjectRecuringSetting/" + id);
    }

    public GetModuleDefaultValueSetting<T>(id: string, moduleName: string): Observable<T> {
        return this.http.get<T>(this.actionSettingsUrl + "getModuleDefaultValueSettings/?id=" + id + "&ModuleName=" + moduleName);
    }

    public UpdateModuleDefaultValueSetting<T>(id: string, obj: any): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + "UpdateModuleDefaultValueSettings/" + id, obj);
    }

    public GetProjectRecuringSettingList<T>(requestPayload:  RequestProjectRecuringSetting): Observable<T> {
        let search = this.GetParams(requestPayload);
        return this.http.get<T>(this.actionSettingsUrl + "ProjectRecuringSetting/GetList", {
            params: search
        });
    }
}