import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observer } from 'rxjs/Observer';
import { Material, QuickInvoice, MaterialPostNumberDTO, MaterialApendixCalculation } from 'app/main//model/Material';
import { RequestMaterial, RequestForLocalStorage } from 'app/main/model/RequestBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { FileUpload } from 'app/main/model/FileUpload';
import { CreateFolder, SearchFolder, ListFolder, DeleteBatchFile } from 'app/main/model/DropBox';

@Injectable({ providedIn: 'root' })
export class MaterialService {
  private actionUrl: string;
  private projectActionUrl: string;
  private settingsActionUrl: string;
  private supplierActionUrl: string;
    private offerMaterialCalculationActionUrl: string;
    private offertaskActionUrlV2: string;
  constructor(private http: HttpClient) {
    this.actionUrl = ApiConstant.API_URL + ApiConstant.MATERIAL + "/";
    this.projectActionUrl = ApiConstant.API_URL + ApiConstant.PROJECT + "/";
    this.settingsActionUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
    this.supplierActionUrl = ApiConstant.API_URL + ApiConstant.SUPPLIERS + "/";
      this.offerMaterialCalculationActionUrl = ApiConstant.API_URL + ApiConstant.OFFERMATERIALCALCULATION + "/";
      this.offertaskActionUrlV2 = ApiConstant.API_URL + ApiConstant.PROJECTOFFERTASKV2+"/";
  }

  public Get<T>(RequestMaterial: any): Observable<T> {
    let search = this.GetParams(RequestMaterial);
    return this.http.get<T>(this.actionUrl, {
      params: search
    });
  }

  public GetQuickInvoiceMaterial<T>(RequestMaterial: any): Observable<T> {
    let search = this.GetParams(RequestMaterial);
    return this.http.get<T>(this.actionUrl + "GetAllQuickInvoiceMaterials", {
      params: search
    });
  }

  public GetMaterialByBarcode<T>(barcode): Observable<T> {
    return this.http.get<T>(this.actionUrl + "search/barcode/" + barcode);
  }

  public GetSupplierList<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "GetAllSupplier", {
      params: search
    });
  }

    public GetProjectsMaterialForHistory<T>(Request: any): Observable<T> {
        let search = this.GetParams(Request);
        return this.http.get<T>(this.actionUrl + "GetProjectsMaterialForHistory", {
            params: search
        });
    }
  public GetSupplierPriceChangesList<T>(): Observable<T> {
      return this.http.get<T>(this.actionUrl + "GetMaterialChangesDetails");
  }

  public setRequestParameter(model) {
    var requestForLocalStorage = new RequestForLocalStorage();
    var val = localStorage.getItem(ApiConstant.LocalRequestModel);
    if (val) {
      requestForLocalStorage = JSON.parse(val);
    }
    requestForLocalStorage.MaterialRequest = model;
    localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
  }

  public getRequestParameter() {
    var val = localStorage.getItem(ApiConstant.LocalRequestModel);
    var requestForLocalStorage = new RequestForLocalStorage();
    var requestStatusMaterial = new RequestMaterial();
    requestStatusMaterial.OrderBy = "Number DESC"
    if (val) {
      requestForLocalStorage = JSON.parse(val);
      if (requestForLocalStorage.MaterialRequest) {
        requestStatusMaterial = requestForLocalStorage.MaterialRequest;
      }
    }
    return requestStatusMaterial;
  }

  public setOfferMaterialRequestParameter(model) {
    var requestForLocalStorage = new RequestForLocalStorage();
    var val = localStorage.getItem(ApiConstant.LocalOfferMaterialRequestModel);
    if (val) {
      requestForLocalStorage = JSON.parse(val);
    }
    requestForLocalStorage.OfferMaterialRequest = model;
    localStorage.setItem(ApiConstant.LocalOfferMaterialRequestModel, JSON.stringify(requestForLocalStorage));
  }

  public getOfferMaterialRequestParameter() {
    var val = localStorage.getItem(ApiConstant.LocalOfferMaterialRequestModel);
    var requestForLocalStorage = new RequestForLocalStorage();
    var requestStatusMaterial = new RequestMaterial();
    requestStatusMaterial.OrderBy = "Number DESC";
    requestStatusMaterial.MaterialType = 22;
    if (val) {
      requestForLocalStorage = JSON.parse(val);
      if (requestForLocalStorage.OfferMaterialRequest) {
        requestStatusMaterial = requestForLocalStorage.OfferMaterialRequest;
      }
    }
    return requestStatusMaterial;
  }

  public GetSingle<T>(id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public GetSupplierListWithMaterial<T>(showAll): Observable<T> {
    return this.http.get<T>(this.actionUrl + "getSupplierListWithMaterial/" + showAll);
  }

  public Add<T>(material: Material): Observable<T> {
    return this.http.post<T>(this.actionUrl, material);
  }

  public GetQuickInvoiceFile<T>(projectId: string, requisitionId: string, isDisplayPersonalPrice :boolean = false): Observable<T> {
    return this.http.get<T>(this.projectActionUrl + "getProjectInvoice?projectId=" + projectId+"&isDisplayPersonalPrice="+isDisplayPersonalPrice);
  }

  public SendQuickInvoiceEmailWithFile<T>(projectId: string, emailid: string, isDisplayPersonalPrice :boolean = false): Observable<T> {
    return this.http.get<T>(this.projectActionUrl + "sendProjectInvoice?projectId=" + projectId + "&emails=" + emailid +"&isDisplayPersonalPrice="+isDisplayPersonalPrice);
  }

  public GenerateQuickInvoice<T>(quickInvoice: QuickInvoice): Observable<T> {
    return this.http.post<T>(this.actionUrl + "quickinvoice", quickInvoice);
  }

  public Update<T>(id: any, material: Material): Observable<T> {
    return this.http.put<T>(this.actionUrl + id, material);
  }

  public UndoSupplierPriceChanges<T>(obj: any): Observable<T> {
    return this.http.put<T>(this.supplierActionUrl + "UndoSupplierMaterialPriceChanges", obj);
  }

  public UpdateMaterialQty<T>(id: any, qty: number): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + "/materialqty/" + qty, null);
  }

  public Delete<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public IsNumberExist<T>(number: string): Promise<T> {
    return this.http.get<T>(this.actionUrl + ApiConstant.NUMBER + "/" + number).toPromise();
  }

  public AddFileToMaterial<T>(id: any, fileUpload: FileUpload): Observable<T> {
    return this.http.post<T>(this.actionUrl + id + "/file", fileUpload);
  }
  public sendEmailToSupplierWithMaterial<T>(supplier: any, isSendEmail: any): Observable<T> {
    return this.http.post<T>(this.actionUrl + "sendEmailToSupplierWithMaterial?isSendEmail=" + isSendEmail, supplier);
  }
  public GetMaterialFiles<T>(id: any, RequestMaterialFile: any): Observable<T> {
    let search = this.GetParams(RequestMaterialFile);
    return this.http.get<T>(this.actionUrl + id + "/files", {
      params: search
    });
  }

  public RemoveFileFromMaterial<T>(id: any, fileId: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id + "/file/" + fileId);
  }

  public RemoveFileFromCustomer<T>(id: any, fileId: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id + "/file/" + fileId);
  }

  public SearchFolderDrpBox<T>(searchFolder: SearchFolder): Observable<T> {
    return this.http.post<T>(ApiConstant.DrpBoxSearch, searchFolder);
  }

  public CreateFolderDrpBox<T>(createFolder: CreateFolder): Observable<T> {
    return this.http.post<any>(ApiConstant.DrpBoxCreate, createFolder);
  }

  public ListFolderDrpBox<T>(listFolder: ListFolder): Observable<T> {
    return this.http.post<any>(ApiConstant.DrpBoxList, listFolder);
  }

  public DeleteBatchFileDrpBox<T>(deleteBatchFile: DeleteBatchFile): Observable<T> {
    return this.http.post<any>(ApiConstant.DrpBoxDelete, deleteBatchFile);
  }

  public GetDrpBoxSharedLink<T>(listFolder: ListFolder): Observable<T> {
    return this.http.post<any>(ApiConstant.DrpBoxSharedLink, listFolder);
  }

  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }

  public DeleteOfferDetail<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + 'offermaterial/' + id);
  }

  public DeleteOfferPrice<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + 'offermaterialprice/' + id);
  }

  public GetColorLits<T>(): Observable<T> {
    return this.http.get<T>(this.settingsActionUrl + "getSettings" + "?param1=MaterialColorCode");
  }

  public GetMaximumMaterialNumber<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + "GetMaximumMaterialNumber");
  }

  public AddUpdateColorCodeList<T>(id: any, data: any): Observable<T> {
    return this.http.put<T>(this.settingsActionUrl + id, data);
  }

  public DeleteMaterial<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + "hardDeleteMaterial/" + id);
  }

  public GetWeatherList<T>(): Observable<T> {
    return this.http.get<T>(this.settingsActionUrl + "getSettings" + "?param1=MaterialWeatherType");
  }

  public GetProjectPriceDiscountList<T>(): Observable<T> {
    return this.http.get<T>(this.settingsActionUrl + "getSettings" + "?param1=ProjectPriceDiscount");
  }

  public AddUpdateWeatherType<T>(id: any, data: any): Observable<T> {
    return this.http.put<T>(this.settingsActionUrl + id, data);
  }

  public AddUpdateProjectPriceDiscount<T>(id: any, data: any): Observable<T> {
    return this.http.put<T>(this.settingsActionUrl + id, data);
  }

  public GetMaterialSupplierLogs<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "getMaterialSupplierLogs", {
      params: search
    });
  }

  public GetMaterialOrderList<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "getSupplierOrders", {
      params: search
    });
  }

  public UpdateMaterialStatus<T>(id, statusID, receivedDate, supplierOrderDetails): Observable<T> {
      return this.http.put<T>(this.actionUrl + "UpdateSupplierOrderStatus?id=" + id
          + "&status=" + statusID + "&receivedDate=" + receivedDate, supplierOrderDetails);
  }
  public AddOrUpdatePostNummer<T>(postnumber: MaterialPostNumberDTO): Observable<T> {
    return this.http.post<T>(this.settingsActionUrl + "AddOrUpdatePostNumber", postnumber);
  }
  public removePostNummer<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.settingsActionUrl + "DeletePostNumber/" + id);
  }

  public GetPostnumberList<T>(RequestPostnumber: any): Observable<T> {
    let search = this.GetParams(RequestPostnumber);
    return this.http.get<T>(this.settingsActionUrl + "getPostnumberList", {
      params: search
    });
  }

  public GetMaterialByItemNumber<T>(number: number): Observable<T> {
    return this.http.get<T>(this.actionUrl + "search/number/" + number);
  }

  public GetSupplierOrdersDetail<T>(id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + "getSupplierOrdersDetail?SupplierOrderID=" + id);
  }

  public AddSupplierMaterialPriceChanges<T>(materialPriceChange: any): Observable<T> {
    return this.http.post<T>(this.supplierActionUrl + "SupplierMaterialPriceChanges", materialPriceChange);
  }

  public GetSupplierMaterialPriceChangeList<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.supplierActionUrl + "SupplierMaterialPriceChangesList", {
      params: search
    });
  }

  public GetBarcodeListFromIDS<T>(materialIds: any): Observable<T> {
    return this.http.post<T>(this.actionUrl + "GetBarcodeListFromIDS", materialIds);
  }

  public GetOfferItemCalculationList<T>(RequestOfferMaterialCalculation: any): Observable<T> {
    let search = this.GetParams(RequestOfferMaterialCalculation);
    return this.http.get<T>(this.offerMaterialCalculationActionUrl, {
      params: search
    });
  }

  public addOrUpdateOfferMaterialForCalculation<T>(objMaterial: any): Observable<T> {
    return this.http.post<T>(this.offerMaterialCalculationActionUrl, objMaterial);
  }

  public removeOfferMaterialCalculation<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.offerMaterialCalculationActionUrl + id);
  }

  public GetMaterialSoldStock<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "GetMaterialSoldStock", {
        params: search
    });
}
  public GetMismatchMaterialCount<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + "GetMismatchMaterialCount");
  }

  public GetMaterialOrderHistory<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "GetMaterialOrderHistory", {
      params: search
    });
  }

  public GetMaterialLogDetails<T>(Id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + "GetMaterialLogDetails/" + Id);
  }

  public UploadSupplierPriceFile<T>(requestMaterial, formData: FormData) {
    let searchParams = this.GetParams(requestMaterial);
    return this.http.post(this.actionUrl + "UploadSupplierPriceList", formData, { headers: { IsContentType: "true" }, params: searchParams });
  }

  public UploadSupplierPriceListWithMultipleFile<T>(requestMaterial, formData: FormData) {
    let searchParams = this.GetParams(requestMaterial);
    return this.http.post(this.actionUrl + "UploadSupplierPriceListWithMultipleFile", formData, { headers: { IsContentType: "true" }, params: searchParams });
  }

    public UpdateMaterialPriceFromExcelSupplier<T>(supplierMaterilList: any, isSameSupplier: boolean, isUpdateValue: boolean) {
        return this.http.put(this.actionUrl + "UpdateMaterialPriceFromExcelSupplier/" + isSameSupplier+"/"+ isUpdateValue, supplierMaterilList);
  }

  public DeleteMaterialPriceChangesExcel<T>(): Observable<T> {
    return this.http.delete<T>(this.actionUrl + "DeleteMaterialPriceChangesExcel");
  }

  public AddMaterialByList<T>(materialList: any) {
    return this.http.post(this.actionUrl + "AddMaterialByList", materialList);
  }

  public GetMaterialInventoryFullList<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "GetMaterialInventoryFullList", {
      params: search
    });
  }

  public GetOfferMaterialPriceList<T>(type:any): Observable<T> {
    return this.http.get<T>(this.actionUrl + "GetOfferMaterialPriceList?type="+type);
  }

  public GetCalculatedOfferMaterialList<T>(MaterialObject: any): Observable<T> {
    let search = this.GetParams(MaterialObject);
    return this.http.get<T>(this.offerMaterialCalculationActionUrl + "getCalculatedOfferMaterialList",{  params: search });
  }

  public GetCalculatedOfferMaterialListPDF<T>(calculatedOfferMaterialList: any): Observable<T> {
    let par = this.GetParams(calculatedOfferMaterialList);
    return this.http.get<T>(this.offerMaterialCalculationActionUrl + "getCalculatedOfferMaterialListPDF", {
      params: par
    });
  }

  public AddUpdateMaterialAppendixCalculation<T>(materialApendixCalculationObject: MaterialApendixCalculation) {
    return this.http.post(this.actionUrl +"AddUpdateMaterialAppendixCalculation", materialApendixCalculationObject);
  }

    public CopyOfferEstimateMaterial<T>(copyOfferEstimateMaterial: any) {
        return this.http.post(this.offertaskActionUrlV2 + "CopyOfferEstimateMaterial", copyOfferEstimateMaterial);
    }

  public DeleteMaterialAppendixCalculation<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + "DeleteMaterialAppendixCalculation/"+ id);
  }

  public GetMaterialAppendixCalculationList<T>(Request: any): Observable<T> {
    let search = this.GetParams(Request);
    return this.http.get<T>(this.actionUrl + "GetMaterialAppendixCalculationList", {
      params: search
    });
  }

  public GetMaterialsForReport<T>(RequestMaterial: any): Observable<T> {
    let search = this.GetParams(RequestMaterial);
    return this.http.get<T>(this.actionUrl + "GetMaterialsForReport", {
      params: search
    });
  }
}
