export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Search': 'Søg',
      'Project': 'Projekt',
      'Type': 'Type',
      'Description': 'Beskrivelse',
      'Name': 'Navn',
      'Width': 'Bredde',
      'Height': 'Højde',
      'ADDNEWROOM': 'Tilføj nyt værelse',
      'Address': 'Adresse',
      'Email': 'Email',
      'CustomerName': 'Navn',
      'PhoneNo': 'Telefon',
      'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation',
      'City': 'By',
      'DepartmentCity': 'Afdeling By',
      'Zipcode': 'postnummer',
      'DepartmentZipCode': 'Afdeling Post nr',
      'DepartmentAddress': 'Afdeling Adresse',
      'SelectCustomer': 'Vælg kunde *',
      'SelectIndoorOutDoor': 'Vaelg Ind, ud, Sprøjt *',
      'EnterDescription': 'Indtast Beskrivelse',
      'Not-Found-CUSTOMER': 'Kunde ikke fundet',
      'Not-Found-INQUIRY': 'Forespørgsel ikke fundet',
      'Not-Found-PROJECT': 'Projekt ikke fundet',
      'Not-Found-REQUISITION': 'Rekvisition ikke fundet',
      'Inquiry': 'Forespørgsel',
      'SetAddress': 'Indstil adresse',
      'Requisition': 'Rekvisition',
      'Setasinactive': 'Indstil som inaktiv',
      'Status': 'Status',
      'Property': 'Ejendom',
      'GetInactive': 'Vis inaktive',
      'MatchCustomerInformation': '** Match kundens oplysninger baseret på navn, e-mail, telefonopkald..',
      'NoMatchCustomerInformation': 'Der blev ikke fundet nogen kundes matchdetaljer',
          'Number': 'Nummer',
          'OfferInvoiceTerms': 'Virksomhedens info tårer'
    },
    'MESSAGES': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'NoRoomList': 'Intet værelse detaljer Tilføj.',
      'CanNotConvertToProject': 'Tilføje i det mindste 1 rum til konvertering til projekt.'
    },
    'InsideOrOutsideOffer': {
      'value1': 'Inde',
      'value2': 'Uden for',
    },
    'BUTTONS': {
      'SAVE': 'Gem',
      'BACK': 'Gå tilbage',
      'ConvertToProject': 'Konverter projekt',
      'GotoProject': 'Gå til projekt',
      'SHOWCUSTOMER': 'Se Kunde',
      'NEWCUSTOMER': 'Ny Kunde',
      'UnassignCustomer': 'Fjern kunde',
      'MatchCustomer': 'Match kunde',
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indendørs ',
      'value2': 'Udendørs',
      'value3': 'Sprøjtemaling',
      'value4': 'Reklamation',
      'value5': 'Personale køb', //drawing of painter
      'value6': 'Butiks køb'     //Nordsjø logo
    },
    'selectedValueOfferStatus': {
      'value1': 'Lavet',
      'value2': 'Sendt til kunde',
      'value3': 'Godkendt af kunde',
      'value4': 'Afvist af kunde',
      'value5': 'Projekt oprettet',
    },
    'FILTERS': {
      'Select': 'Vælg'
    },
    'Validations': {
      'EmailRequired': 'Email påkrævet',
      'PhoneRequired': 'Telefon påkrævet',
      'ValidPhoneRequired': 'Indtast gyldig telefon',
      'AddressRequired': 'Adresse påkrævet',
      'CityRequired': 'By påkrævet',
      'ZipCodeRequired': 'Postnummer påkrævet',
      'ValidEmail':'Indtast gyldig e-mail adresse',
    },
    'Headers': {
      'CustomerDetail': 'Er du sikker på, at du vil vedhæfte @customerdetail til @inquirydetail?'
    },
    'TOOTIPORTITLE': {
      'AddCustomer': 'Tilføj kunde',
      'ShowDirection': 'Få retning',
      'ViewProject':'Se Projekt',
    }
  }
};
