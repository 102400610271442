import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './../translate/en';
import { locale as danish } from './../translate/ds';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-lat-long-modal',
  templateUrl: './lat-long-modal.component.html',
})
export class LatLongModalComponent implements OnInit {

  objAddress: any = {}
  constructor(private translationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<LatLongModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.objAddress.lat = this.data.lat?this.data.lat : null;
    this.objAddress.long = this.data.long ? this.data.long : null;
  }

  onConfirmAddress() {
    this.dialogRef.close(this.objAddress);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
