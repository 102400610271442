export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD': { 'TITLE': 'Dashboard' },
            'CUSTOMERS': { 'TITLE': 'Customers' },
            'MATERIALS': { 'TITLE': 'Materials' },
            'PROJECTS': { 'TITLE': 'Projects' },
            'DOCUMENTS': { 'TITLE': 'Documents' },
            'WORKHOURS': { 'TITLE': 'Work-hours' },
            'MESSAGES': { 'TITLE': 'Messages' },
            'COWORKERS': { 'TITLE': 'Co-workers' },
            'COMPANYINFO': { 'TITLE': 'Company-Info' },
            'LOGOUT': { 'TITLE': 'Logout' },
            'OFFER': { 'TITLE': 'Offers' },
            'INQUIRY': { 'TITLE': 'Inquiries' },
            'REPORT': { 'TITLE': 'Report' },
            'RESOURCECALENDAR': { 'TITLE': 'Resource Calendar' },
            'QUICKINVOICE': { 'TITLE': 'Quick Invoice' },
            'PLANNING': { 'TITLE': 'Planning' },
            'STORESALES': { 'TITLE': 'Store Sales' },
            'REMINDERS': { 'TITLE': 'Reminders' },
            'STATISTICS': { 'TITLE': 'Statistics' },
            'SETUP': { 'TITLE': 'Setup' },
            'SUPPLIERS': { 'TITLE': 'Suppliers' },
            'CARINFORMATIONS': { 'TITLE': 'Car Informations'},
            'PAYROLL': { 'TITLE': 'Payroll Entry' },
            'TERMSOFEMPLOYMENT': { 'TITLE': 'Terms of Employment' },
            'SETTINGS': { 'TITLE': 'Settings' },
            'QUALITYTEST': { 'TITLE': 'Quality Test' },
            'TOOLSLADDERS': { 'TITLE': 'Nanolink' },
            'ASSETS': { 'TITLE': 'Assets' },
            'DEVICE': { 'TITLE': 'Nano Chips' },
            'CARINFO': { 'TITLE': 'Car info' },
            'LIVEMAP': { 'TITLE': 'Live Map' },
            'NANOCHIPS': { 'TITLE': 'Nano Chips' },
            'ECONOMY': { 'TITLE': 'Economy' },
            'SUPPLIERQUICKMATERIAL': { 'TITLE': 'Project Material' },
            'OFFERMATERIALS': { 'TITLE': 'Offer Materials' },
            'PROJECTINVOICE': { 'TITLE': 'Projekt faktura' },
            'FINANCIALDETAILS': { 'TITLE': 'Financial Detail' },
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
        'FIELDS': {
            'PaymentMode1': 'Dankort',
            'PaymentMode2': 'Mastercard',
            'PaymentMode3': 'Cash',
            'PaymentMode4': 'Mobile payment',
            'PaymentMode5': 'Mobile payment KK',
            'PaymentMode6': 'Credit',
            'PaymentMode7': 'Other',
        }
    }
};
