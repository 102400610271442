export const locale = {
    lang: 'en',
    data: {
        'Title': {
            'NewMaterial': 'New Material',
            'TITLE': 'Material',

        },
        'BUTTONS': {
            'NEWCUSTOMER': 'New Customer',
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
            'CREATE_FOLDER': 'Create Folder',
            'DOWNLOAD': 'Download',
            'DELETE': 'Delete',
            'ADDSTOCK': 'Add Stock.',
            'ADDOFFER': 'Add Offer',
            'REMOVEOFFER': 'Remove Offer',
            'Return': 'Return',
            'PriceTag': 'Price tag',
            'Copy': 'Copy',
            'WebUrlLink': 'Web URL-link',
        },
        'FIELDS': {
            'CaseNo': 'Case Number.',
            'ItemNo': 'Item Number',
            'Number': 'Holdings',
            'Text': 'Text',
            'OfferText': 'Offer Name',
            'FileUpload': 'Address',
            'Active': 'Set as inactive',
            'RetailPrice': 'Retail Price',
            'PurchasePrice': 'Purchase price',
            'Unit': 'Unit',
            'InternalText': 'Internal text',
            'ShelfNo': 'Shelf no.',
            'Rows': 'Rows',
            'AutomaticallyCreate': 'Automatically Create',
            'PersonalPrice': 'Personal Price',
            'SalesPrice': 'Sales Price',
            'EnterStock': 'Enter Stock',
            'PurchaseQuantity': 'Purchase Quantity',
            'Supplier': 'Supplier',
            'SupplierMaterialNo': 'Supplier Material No',
            'MinInventory': 'Min Inventory',
            'MaxInventory': 'Max Inventory',
            'RequiredDetail': 'Color code required',
            'NoStockFeatures': 'No Stock Features',
            'NotStockList': 'Do not include in stock list',
            'ColorCode': 'Color Code',
            'Barcode': 'Edit Barcode',
            'MaterialsForTheCase': 'Materials For The Case',
            'Wages': 'Wages',
            'CalculateSalesPrice': 'Calculate Sales Price',
            'Linetype': 'Line type',
            'Reminder': 'Reminder',
            'PriceSignWithBuyQuantity': 'Price Sign With Buy Qty',
            'OnlySummer': "Only Summer",
            "WeatherType": "Weather type",
            "All": "All",
            "IsPostNumberDiscount": "ZIP code appendix",
            "Yellow": "Yellow",
            "Blue": "Blue",
            "Materialtype": 'Material type',
            'MaterialSoldOnThisYear':'Material Sold On This Year',
            'IsDisplayWebshop': 'Is DisplayWebshop',
            'DuplicateEntry':'Duplicate Entry',
            'CheckPurchasePrice':'Check PurchasePrice',
            'PurchasepriceSalesUnit':'Purchaseprice SalesUnit',
            'MaxOrderQty':'Max Order Qty',
            'PurchasingUnit': 'Purchasing Unit',
            'IsSupplierQuickMaterial': 'Add quick material to project',
            'PriceForBucket': 'Price for 1 bucket',
        },
        'Headers': {
            'Ok': 'OK',
            'Cancel': 'Cancel',
            'NewMaterial': 'Create New Material',
            'Material': 'Material',
            'NoFileSelected': 'No File Selected',
            'ChooseFile': 'Choose File',
            'FOLDEREMPTY': 'This folder is empty',
            'DeleteOffer': 'Are you sure you want to delete offer.',
            'DeleteOfferPrice': 'Are you sure you want to delete price.',
            'Barcode': 'Barcode',
            'Delete': 'Are you sure you want to delete this material from database ?'
        },
        'VALIDATIONS': {
            'ITEMNUMBER': 'Item number required',
            'TEXT': 'Text required',
            'BEHOLDING': 'Holdings required',
            'ITEMNUMBEREXIST': 'Number is already exist',
            'ITEMNUMBERMIN': 'Enter Valid Number',
            'ITEMNUMBERMAX': 'Enter Valid Number',
            'BEHOLDINGMIN': 'Enter Valid Number',
            'BEHOLDINGMAX': 'Enter Valid Number',
            'Barcode': 'Same Barcode exist',
            'Unit': 'Unit required',
            'PersonalPrice': 'Personale price required',
            'RetailPrice': 'Retail price required',
            'PurchasePrice': 'Purchase price required',
            'Supplier': 'Supplier required',
            'MinInventory': 'Enter Min Inventory',
            'MaxInventory': 'Enter Max Inventory',
            'MinPrice': 'price must be {0} % higer of purchase price. ( More than {1} )',
            'LineType': 'LineType required',
            'Materialtype': 'Materialtype required',
            'WeatherType': 'WeatherType required',
            'PurchaseQuantity': 'PurchaseQuantity required',
            'SupplierMaterialNo': 'SupplierMaterialNo required',
            'PurchasingUnit': 'Purchasing Unit required',
            'ShelfNo': 'Shelf no required',
        },
        'Tabs': {
            'BasicDetail': 'Basic Details',
            'Files': 'Files',
            'Offer': 'Offer',
            'OfferFiles': 'Offer Files',
            'OfferText': 'Offer Text',
            'ProjectsMaterial':'Sales history',
            'OfferItemCalculation': 'Offer Item Calculation',
            'MaterialOrderHistory': 'Material Order History',
            'Log': 'Log',
        },
        'PlaceHolder': {
            'DeleteAlert': 'Are you sure you want to delete?'
        },
        'MESSAGES': {
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'Created': 'Created Successfully.',
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'OfferDelMessage': 'Offer Deleted Successfully.',
            'OfferPriceDel': 'Price Deleted Successfully.',
            'OfferDelFail': 'Fail to delete offer.',
            'OfferPriceDelFail': 'Fail to delete price.',
            'Deleted': 'Deleted successfully',
            'Barcode': 'Barcode already exist.',
            'SameMaterialBarcode': 'Barcode is of same material.',
            'ItemNumberExist': 'Number is already exist',
            'CheckPurchasePrice': 'Check Purchase Price',
            'WarningForAddingMaterial': 'Warning if number is greater than.',
            'NoteForOrderSupplier': 'Note for ordering goods.',
        },
        'unitList': {
            'value1': 'PCS',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Roll',
            'value5': 'M2',
            'value6': 'KG'
        },
        'linetype': {
            'value1': 'Pay',
            'value2': 'Text',
            'value3': 'Product'
        },
        'ToolTip': {
            'ShowSktToPDF': 'stk. Antal will be added on offers PDF.'
        },
        'purchasingUnitList': {
            'value1': 'PCS',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Roll',
        },
    }
};
