export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'ProjectPagination': 'Antal projekter pr. side',
        },
        'FIELDS': {
            'PaymentMode1': 'Dankort',
            'PaymentMode2': 'Mastercard',
            'PaymentMode3': 'kontant',
            'PaymentMode4': 'Mobilpay',
            'PaymentMode5': 'Mobilepay KK',
            'PaymentMode6': 'Kredit',
            'PaymentMode7': 'Andet',
            'From': 'Fra',
            'To': 'til ',
            'visit': 'Besøg',
            'HoursBack': 'Timer tilbage',
            'RemainingTime': 'Resterende tid',
            'HourlyWage': 'Timeløn',
            'Before60Days': '*** 60 Dag gammel.',
            'Planned': 'Planlagt',
            'Text': 'Tekst*',
            'OpgaveText': 'Rekvisitions Tekst',
            'InvoiceText': 'Faktura Tekst',
            'ShowActive': 'Vis inaktiv',
            'SORTING': 'Sorter efter',
            'TEXTSEARCH': 'Søg',
            'Date': 'Modtaget Dato',
            'PriceINCVAT': 'Søg Pris incl. moms'
        },
        'MESSAGES': {
            'NORECORD': 'Intet optage fundet'
        },
        'BUTTONS': {

        },
        'FILTERS': {
            'BADGE': 'Inaktive',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
        },
        'SORTEDBY':
            [
                {
                    Text: 'Nummer (0-9)',
                    Value: 'Number'
                },
                {
                    Text: 'Nummer (9-0)',
                    Value: 'Number DESC'
                }
            ],
            'TOOTIPORTITLE': {
                'GoToRequisition': 'Gå Til Rekvisition',
                'GoToMaterial': 'Gå Til Materiale',
                'GoToMessageLog': 'Gå Til Beskedlog',
                'GoToStatus': 'Gå Til Status',
                'GoToCoworker': 'Gå Til Medarbejdere',
                'GoToEconomy': 'Gå Til Økonomi',
            }
    }
};