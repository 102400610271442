export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
      'Updated': 'Opdateret med succes',
    },
    'FIELDS': {
      'GoTo': 'Gå til',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Dato',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Dato',
      'CoworkerName': 'Medarbejder',
      'SRNO': 'Sr Nr',
      'Date': 'Dato',
    },
    'BUTTONS': {
      'Add': 'Tilføj',
        'AddInformation': 'Tilføje oplysninger',
        'AllImage': 'Alle billeder'
    },
    'Headers': {
      'DeleteCategory': 'Vil du slette kategori',
    },
    'TOOTIPORTITLE': {
      'SendEmail': 'Send E-mail',
      'ViewPDF': 'Se Pdf',
      'Edit': 'Redigere',
      'Delete': 'Slet',
      'GoToCategory':'Gå Til Kategori',
    },
    'FILTERS': {
      'SORTING': 'Sorter efter',
    },
    'SORTBY':
      [
        {
          Text: 'Navn (A-Z)',
          Value: 'Name'
        },
        {
          Text: 'Navn (Z-A)',
          Value: 'Name DESC'
        }
      ],
  }
};
