import { projectRequisition } from "./Project";
import { Customer } from "./Customer";
import { OfferMaterialWithPrice } from './OfferV2';

export class Material {
  public id: string = '';
  public itemNumber: string = '';
  public text: string = '';
  public holdingsString: string = '0';
  public holdings: number;
  public image: string = null;
  public imagePath: string = '';
  public isActive?: boolean = true;
  public isDeleted?: boolean;

  //new added fields
  public retailPrice?: number;
  public retailPriceString: string = '0';
  public purchasePrice?: number;
  public purchasePriceString: string = '0';
  public personalPrice?: number;
  public personalPriceString?: string = '0';
  public purchaseQuantity?: number;
  public purchaseQuantityString?: string = '0';
  public unit?: string;
  public internalText?: string;
  public shelfNo?: string;
  public rows?: string;
  public automaticallyCreate: string;
  public supplierId: string;
  public supplierItemNumber: string;
  public minInventory?: number;
  public minInventoryString: string = '0';
  public maxInventory?: number;
  public maxInventoryString: string = '0';
  public isRequiredText: boolean;
  public noStockFeatures: string;
  public nonStockList: string = 'F';
  public barcode: string;
  //public barcodedisplay: boolean=true;
  public isAddToOffer: boolean = false;
  public isAddMeterRangePrice: boolean = false;
  public personalPris: number;
  public colorCode: string = '';
  public offerMaterial: OfferMaterialWithPrice = new OfferMaterialWithPrice();
  public materialsForTheCase: boolean = false;
  public barcode2: string;
  //public barcode2display: boolean=true;
  public lineType: string = 'L';
  public reminder: string = '';
  public priceSignWithBuyQuantity: boolean = false;
  public isAddedFiles: boolean = false;
  public isAddedOfferFiles: boolean = false;
  public onlySummer: boolean = false;
  public weatherType: number = 0;
  public isPostNumberDiscount: boolean = false;
  public postNumberAppendix: boolean = true;
  public barcodeColorStr: string = "1";
  public barcodeColor: number;
  public materialType: string;
  public materialTypeIds: any[] = [];
  public wrongMaterialBindToOfferList: any[] = [];
  public materialSoldQTY: number;
  public checkPurchaseprice?: boolean = false;
  public purchasepriceSalesUnit?: number;
  public maxOrderNumber?: number;
  public purchasingUnit?: string;
  public offerMaterialType?: string;
  public isSupplierQuickMaterial: boolean = false;
  public priceForBucket?: number=0;
  public webUrlLink?: string;
  constructor() { }
}

export class QuickInvoiceMaterials extends Material {
  public quantity: number;
  public retailPriceTotal: number;
  public personalPriceTotal: number;
  public totalPriceVAT: number;
  public materialText: string = "";
  public colorCode: string = null;
  public sagOpgaveId: string = null;
  public price: number;
  public materialId: string = null;
  public totalPrice: number;
  public discountPercent: number = 0;
  public retailPriceDiscount: number = 0;
  public personalPriceDiscount: number = 0;
  public material: any;
  public createdDate: Date;
  public ProjectRequisitionStatusLogModel: ProjectRequisitionStatusLog;
  public noStockTraek: boolean = false;
  public imagePath: string;
  public isDuplicateEntry: boolean = false;
  public duplicateEntry: boolean = false;  
  public isChecked: boolean = false;
}

export class QuickInvoice extends Customer {
  public materialList: QuickInvoiceMaterials[];
  public projectId: string = "";
  public requisitionId: string = "";
  public invoiceBase64: string = "";
  public projectNumber: string = "";
  public fileName: string = "";
  public projectType: number;
  public note: string = "";
  public requisitionDetail: projectRequisition;
}

export class MaterialReport {
  public id: string;
  public itemNumber: string;
  public text: string;
  public retailPrice?: number;
  public purchasePrice?: number;
  public personalPrice?: number;
  public minInventory?: number;
  public maxInventory?: number;
  public holdings: number;
  public unit: string;
  public isActive?: boolean = true;
  public isDelete?: boolean;
  public displayUP: boolean = true;
  public displayDown: boolean = false;
  public total: number;
  public nonStockList: string;
  public isChecked: boolean;
  public shelfNo: string;  
}

export class supplierReport {
  public id: string;
  public itemNumber: string;
  public text: string;
  public imagePAth: string;
  public isActive?: boolean = true;
  public isDeleted?: boolean;
  public nonStockList: string;
  public retailPrice: number;
  public purchasePrice: number;
  public unit: string;
  public internalText: string;
  public shelfNo: string;
  public rows: number;
  public automaticallyCreate: string;
  public barcode: string;
  public personalPrice: number;
  public purchaseQuantity: number;
  public holdings: number;
  public minInventory: number;
  public maxInventory: number;
  public isRequiredText: boolean;
  public isChecked: boolean;
  public noStockFeatures: string;
  public updateInvoiceFile: boolean;
  public quantity: number;
  public isAddToOffer: boolean;
}

//export class MaterialReportForExcel {
//  public id: string;
//  public itemNumber: string;
//  public text: string;
//  public retailPrice?: number;
//  public purchasePrice?: number;
//  public personalPrice?: number;
//  public minInventory?: number;
//  public maxInventory?: number;
//  public holdings: number;
//  public unit: string;
//}

export class MaterialReportForExcel {
  public val1: string;
  public val2: string;
  public val3: string;
  public val4: string;
  public val5: string;
  public val6: string;
  public val7: string;
  public val8: string;
  public val9: string;
  public val10: string;
  public val11: string
  public val12: string
}

export const MaterialReportSearch: any[] = [
  { id: 0, key: 'materialReportStatus.value1' },
  { id: 1, key: 'materialReportStatus.value2' },
  { id: 2, key: 'materialReportStatus.value3' },
]

export class ProjectRequisitionStatusLog {
  //project requisition status log
  public id: string;
  public projectId: string;
  public requisitionId: string;
  public requisitionNumber: number;
  public status: number;
  public createdBy: string;
  public date: any;
  public time: any;
  public statusFrom: number;
  public StatusLogCaseValue: number;
  public debitorID: string;
  public medarbejdarID: string;
}

export class MaterialWeatherTypeDTO {
  public id: string;
  public typeId: number;
  public startMonth: number;
  public endMonth: number;
}

export class MaterialWeatherTypeDetail {
  public MaterialWeatherTypeList: MaterialWeatherTypeDTO[];
}

// export class OfferMaterial {
//     public id: string = '';
//     public materialId: string = '';
//     public text: string = '';
//     public type: number=1;
//     public isActive: boolean = false;
//     public isDeleted: boolean = false;
//     public createdBy: string = '';
//     public createdDate: Date = new Date();
//     public ModifyBy: string = '';
//     public ModifyDate: Date = new Date();
//     public ImagePath : string = '';
// }

// export class OfferMaterialWithPrice extends OfferMaterial{
//     public Image: string = '';
//     public offerMaterialPriceList: OfferMaterialPrice[];
// }

// export class OfferMaterialPrice {
//     public id: string = '';
//     public offerMaterialId: string = '';
//     public time: number = 0;
//     public price: number; 
//     public isActive: boolean = false;
//     public isDeleted: boolean = false;
//     public createdBy: string = '';
//     public createdDate: Date = new Date();
//     public modifyBy: string = '';
//     public ModifyDate: Date = new Date();
// }

export class OfferMaterialForExcel {
  public val1: string;
  public val2: string;
  public val3: string;
  public val4: string;
  public val5: string;
}

export class MaterialPostNumberDTO {
  public id: string;
  public startPostNumber?: number;
  public city: string;
  public percentage?: number;
  public remark: string;
}

export class ProjectPriceDiscountDTO {
  public id: number;
  public startPrice: number;
  public endPrice: number;
  public percentage?: number;
  public indoorOutdoorStatusID: number;
  public startDay: string;
  public endDay: string;
}

export class SuppliersMaterialPriceChanges {
  public id: string;
  public supplierID: string;
  public percentage: number = 0;
  public materialRetailPrice: boolean = false;
  public materialSalesPrice: boolean = false;
  public materialPurchasePrice: boolean = false;
  public materialPersonalPrice: boolean = false;
  public offerPrice: boolean = false;
}

export class OfferMaterialCalculation extends Material {
  public id: string = '';
  public offersMaterialId: string = '';
  public materialId: string = '';
  public quantity: number;
  public colorCode: string = null;
  public isActive?: boolean = true;
  public isDeleted?: boolean;
  public createdBy: string = '';
  public createdDate: Date;
  public modifyBy: string = '';
  public modifyDate: Date;
  public material: any;
  public discountPercent: number = 0;
  public retailPriceDiscount: number = 0;
  public personalPriceDiscount: number = 0;
  public noStockTraek: boolean = false;
  public offersMaterialPriceId: string = '';
  public timer:number;
}

export class MaterialApendixCalculation {
  public id: string;
  public number: number;
  public text: string;
  public m2200: number;
  public m2500: number;
  public m2500More: number;
  public lbM2: number;
    public lb15CmH: number;
    public lb30CmI: number;
    public lb40CmK: number;
    public lb60CmL: number;
  public stk10: number;
  public stk20: number;
  public stk20More: number;
  public appendix: string;
    public treatmentType: number;
    public min: number;
    public lbM2A: number;
    public lbM2B: number;
    public lbM2C: number;
    public lbRammerD: number;
    public lbRuderE: number;
    public lbFalsF: number;
    public lbKantG: number;
    public lbIndVangeM: number;
    public indendoersUdendoersStatistID: string;
    public indendoersUdendoersStatistIDs: any[] = [];
}

export class ProjectSupplierQuickMaterials {
  public text: string = "";
  public projectId: string = "";
  public percentage?: number;
  public amount?: number;
  public requisitionId: string = null;
  public supplierId: string = null;  
  public quantity: number;
  public retailPrice?: number;
  public personalPrice?: number;
  public purchasePrice?: number;
}

export const SupplierPriceListType: any[] = [
  { id: 1, key: 'supplierPriceListType.value1', value:"MaterialPriceList" },
  { id: 2, key: 'supplierPriceListType.value2', value:"SupplierInvoice" },
]