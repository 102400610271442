import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { RequestCustomer } from 'app/main/model/RequestBase';
type AOA = any[][];

@Component({
  selector: 'project-project-upload',
  templateUrl: './project-upload.component.html',
  styleUrls: ['./project-upload.component.scss']
})
export class ProjectUploadComponent implements OnInit {
  selectedFiles: any[] = [];
  showSpinner: boolean = false;
  sampleFileUrl: any;
  data: any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  projectUploadList: any = [];
  customers: any[];
  customerId:string = "" ;
  selectedCustomer:any;
  name?: string = "";
  //#endregion Variables

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private http: HttpClient,
    private projectService: ProjectService,
    private customersService: CustomersService
  ) { this.translationLoader.loadTranslations(english, danish); }

  ngOnInit() {
    this.sampleFileUrlInit();
    this.getCustomer(new RequestCustomer());
  }

  Upload() {
    this.showSpinner = true;
    if (this.selectedCustomer && this.selectedCustomer.id) {
      for (let obj of this.projectUploadList) {
         obj['debitorId'] = this.selectedCustomer.id;
         obj['debitorNummer'] = this.selectedCustomer.number;
         obj['debitorNavn'] = this.selectedCustomer.name;
      }
    }
    this.projectService.CreateProjectFromExcel<ResponseBase>(this.projectUploadList)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Updated").subscribe({
            next: (res) => {
              this.notificationsService.success(res);
            },
            error: err => {
            }
          });
          this.projectUploadList = response.result;
        },
        error: err => {
          this.showSpinner = false;
          this.notificationsService.error(err.error.message);
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  OnuploadFile(event) {
    if (event.target.files.length > 0)
      this.selectedFiles = event.target.files;
  }

  sampleFileUrlInit() {
    this.sampleFileUrl = environment.url + "Upload/ProjectUpload/Sample-ProjectUpload.xlsx";
  }

  downloadSampleFile() {
    this.http.get(this.sampleFileUrl, { responseType: "blob" }).subscribe((res: any) => {
      const url = window.URL.createObjectURL(res);
      //window.open(url);
      var download = document.createElement("a");
      download.href = url;
      download.download = 'Sample-ProjectUpload' + '.xlsx';
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0
        , false, false, false, false, 0, null
      );
      download.dispatchEvent(event);
    });
  }
  onFileChange(evt: any) {
    const projectList: any[] = [];
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      const headerRow = this.data[0];
      this.data = this.data.slice(1);
      const columnMapping: { [key: string]: number } = {
        "Adresse": headerRow.indexOf("Adresse"),
        "Postnr": headerRow.indexOf("Postnr"),
        "By": headerRow.indexOf("By"),
        "Telefon": headerRow.indexOf("Telefon"),
        "Email": headerRow.indexOf("Email"),
        "Status": headerRow.indexOf("Status"),
        "StartDato": headerRow.indexOf("Startdato"),
        "SlutDato": headerRow.indexOf("Slutdato"),
        "VisitDato": headerRow.indexOf("Visitdato"),
        "Farvekode": headerRow.indexOf("Farvekode"),
      };

      this.data.forEach((res) => {
        if (res[0] !== "no" && res.some((element) => element !== "")) {
          const project = {
            "adresse": res[columnMapping["Adresse"]],
              "postnr": res[columnMapping["Postnr"]] ? res[columnMapping["Postnr"]].toString() : null,
            "by": res[columnMapping["By"]],
              "telefon": res[columnMapping["Telefon"]] ? res[columnMapping["Telefon"]].toString() : null,
            "email": res[columnMapping["Email"]],
            "status": res[columnMapping["Status"]],
            "startDato":  this.convertToDate(res[columnMapping["StartDato"]]),
            "slutDato": this.convertToDate(res[columnMapping["SlutDato"]]),
            "visitDato": this.convertToDate(res[columnMapping["VisitDato"]]),
            "farvekode": res[columnMapping["Farvekode"]],
            "isInsertedStatus": null
          };

          // Check if any property has a value before pushing into customerList
          if (Object.values(project).some((value) => value !== "")) {
            projectList.push(project);
          }
        }
      });
      this.projectUploadList = projectList;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  convertToDate(excelDate: number): string {
    if(excelDate && excelDate != undefined){
      // Excel date starts from January 1, 1900
      const excelStartDate = new Date(1900, 0, 1);
      
      // Calculate the date by adding the number of days (excelDate) to the Excel start date
      const date = new Date(excelStartDate);
      date.setDate(date.getDate() + excelDate - 1);
    
      return date.toISOString().slice(0, 10);
    }
    else
      return "";
  }

  getCustomer(model) {
    model.Limit = 2000;
    model.OrderBy = "Text";
    this.showSpinner = true;
    this.customersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.showSpinner = false;
          this.getNextRequestForCustomer(response.pagination, model, response.result);
        }
        else {
          this.customers = [];
        }
      },
        error => (err) => {
          this.showSpinner = false;
          this.customers = [];
        });
  }

  getNextRequestForCustomer(pagination, model, response) {
    if (pagination.index == 1) {
      this.customers = response;
    }
    else {
      this.customers.push(...response);
    }
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCustomer(model);
  }

  onChangeCustomer(cust) {
    this.selectedCustomer = cust;
  }

  DropDownSearch(value) {
    if (value) {
      var request = new RequestCustomer();
      request.Name = value;
      try {
        if (!isNaN(Number(value.toString()))) {
          request.Name = "";
          request.Name = value;
        }
      } catch (e) {

      }
      this.getCustomer(request);
    } else {
      this.getCustomer(new RequestCustomer());
    }
  }
  GetCustomersOnClear(value) {
    if (!value) {
      this.DropDownSearch(value)
    }
  }
  _handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }

  }

}
