import { environment } from 'environments/environment';

export const ApiConstant = {
    URL: environment.url,
    API_URL: environment.url + environment.api + "/" + environment.version + "/",
    ACCOUNT: "account",
    MATERIAL: "materials",
    PROJECT: "projects",
    PROJECTMATERIAL: "casetasks",
    NUMBER: "number",
    CUSTOMER: "customers",
    COWORKER: 'coworkers',
    CHANGE_PASSWORD: 'account/change-password',
    COWORKERASUSER: 'user',
    INITIAL: "initial",
    EMAIL: 'email',
    RESET_PASSWORD: 'account/reset-password',
    REMINDER: 'reminders',
    REMINDER_STATUS: 'status',
    ALL_REMINDER_STATUS: 'updateallstatus',
    CONTACTUS: "contactus",
    WORK_HOURS: 'workhours',
    REQUISITION: 'requisition',
    ALLREQUISITIONS: 'allrequisitions',
    COMPANYINFO: 'companyinfo',
    INQUIRY: 'inquiry',
    PROJECTOFFER: 'Offer',
    PROJECTOFFERV2: 'Offerv2',
    PROJECTOFFERTASK: 'Offertask',
    PROJECTOFFERTASKV2: 'Offerv2Task',
    SETTINGS: 'Settings',
    QUALITYTEST: 'Offerv2QualityTest',
    NANOLINK: 'Nanolink',
    SMS: 'SendSMS',
    DrpBoxSearch: 'https://api.dropboxapi.com/2/files/search',
    DrpBoxCreate: 'https://api.dropboxapi.com/2/files/create_folder_v2',
    MoveFolderBatchDrpBox: 'https://api.dropboxapi.com/2/files/move_batch_v2',
    MoveFolderDrpBox: 'https://api.dropboxapi.com/2/files/move_v2',
    CopyFolderDrpBox: 'https://api.dropboxapi.com/2/files/copy_v2',
    DrpBoxList: 'https://api.dropboxapi.com/2/files/list_folder',
    DrpBoxDelete: 'https://api.dropboxapi.com/2/files/delete_batch',
    DrpBoxToken: environment.DrpBoxToken,
    DrpBoxSharedLink: 'https://api.dropboxapi.com/2/sharing/create_shared_link',
    RequisitionMtoM: 'MtoM',
    RequisitionNote: 'Note',
    RequisitionOffers: 'Offers',
    LocalRequestModel: 'LocalRequestModel',
    LocalRequestModelForAdmin: 'LocalRequestModelForAdmin',
    LocalRequestModelForMaterialAll: 'LocalRequestModelForMaterialAll',
    LocalOfferMaterialRequestModel: 'LocalOfferMaterialRequestModel',
    STATISTIC: 'statistic',
    SUPPLIERS: 'suppliers',
    CARINFORMATIONS: 'CarInformations',
    SkyHostMap: 'SkyHostMap',
    OFFERMATERIALCALCULATION: 'OffersMaterialCalculation',
    LocalResponseModel: 'LocalResponseModel',
    UNICONTA: 'uniconta',
    UNICONTAAPI: 'unicontaApi',
}


export const token = {
    TokenKey: "VibePR",
}

export const ToolbarTitle = {
    'TITLE_APPLICATIONS': 'TITLE_APPLICATIONS',
    'TITLE_DASHBOARD': 'TITLE_DASHBOARD',
    'TITLE_RENTERS': 'TITLE_RENTERS',
    'TITLE_RELOCATION': 'TITLE_RELOCATION',
    'TITLE_OURHOUSES': 'TITLE_OURHOUSES',
    'TITLE_DOCUMENTS': 'TITLE_DOCUMENTS',
    'TITLE_WORKHOURS': 'TITLE_WORKHOURS',
    'TITLE_MESSAGES': 'TITLE_MESSAGES',
    'TITLE_COWORKER': 'TITLE_COWORKER',
    'TITLE_COMPANYINFO': 'TITLE_COMPANYINFO',
    'TITLE_SETTINGS': 'TITLE_SETTINGS',
}