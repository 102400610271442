export const locale = {
    lang: 'ds',
    data: {
        'MaterialTypeCategory': {
            'Value1': 'Indendørs',
            'Value2': 'Udendørs',
            'Value3': 'Vindue',
            'Value4': 'Døre',
            'Value5': 'Sprøjtekabine',
            'Value6': 'Sprærende',
            'Value7': 'Filt',
            'Value8': 'Væv',
            'Value9': 'Rutex',
            'Value10': 'Tapet',
            'Value11': 'Paneler',
            'Value12': 'Dørkarme',
            'Value13': 'Garanti',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiatore',
            'Value17': 'Varsling',
            'Value18': 'Facader',
            'Value19': 'Skur vg.',
            'Value20': 'Afdækning',
            'Value21': 'Lofter',
            'Value22': 'Vægge',
            'Value23': 'Gulv',
            'Value24': 'Vindskeder',
            'Value25': 'Sternbrædder',
            'Value26': 'Underhæng',
            'Value27': 'Andet',
        },
        'SelectedCategoryTime': {
            'Value0': 'Vælg',
            'Value1': '1 gang',
            'Value2': '2 gange',
            'Value3': '3 gange',
            'Value4': '4 gange',
            'Value5': '5 gange',
            'Value11': 'Stk',
            'Value12': 'Meter',
            'Value13': 'Rulle',
            'Value14': 'Liter',
            'Value15': '1 Lag',
            'Value16': '2 Lag',
            'Value17': '3 Lag',
            'Value18': 'Små rum'
        },
        'selectedValueStatus': {
            //'value0': 'Anbefalet',
            //'value0': '-',
            'value1': 'Process',
            'value2': 'Planlagt besøg',
            'value3': 'Klar til opstart',
            'value4': 'Tilbud sendt',
            'value5': 'Tilbud godkendt',
            'value6': 'Planlagt',
            'value7': 'Tilbud afvist',
            'value8': 'Startet',
            'value9': 'Udført',
            'value10': 'Faktureret',
            'value11': 'Afventer',
            'value12': 'Klar fakturering',
        },
        'ExpensesType': {
            'value0': 'Vælg',
            'value1': 'Min',
            'value2': 'Beløb'
        },
        'QuestionStatusType': {
            'value0': 'Ingen',
            'value1': 'Status',
            'value2': 'Andre oplysninger'
        },
        'OfferTreatmentType': {
            'value0': 'Vælg',
            'value1': 'Træværk',
            'value2': 'Vægge',
            'value3': 'Loft/Gulv',
            'value4': 'Loft/Vægge',
        },
        'MaterialWeatherType': {
            'value0': 'Alle',
            'value1': 'Efterår',
            'value2': 'Forår',
            'value3': 'Sommer',
            'value4': 'Vinter'
        },
        'AudienceType': {
            'value1': 'Medarbejdere med spørgsmål',
            'value2': 'Kunder',
            'value3': 'Medarbejdere uden spørgsmål',
            'value4': 'Medarbejdere meddelelse',
            'value5': 'Kunder meddelelse'
        },
        'PurchaseStatus': {
            'value0': 'Alle',
            'value1': 'Personale køb',
            'value2': 'Butikskøb',
            'value3': 'Pris excl. moms',
            'value4': 'Pris incl. moms',
        },
        'materialType': {
            //'value0':'Alle',
            'value1': 'Afdækning / tape / sandpapir',
            'value2': 'Fuger',
            'value3': 'Gulv maling',
            'value4': 'Jernmaling',
            'value5': 'Kemikalier',
            'value6': 'Loft maling',
            'value7': 'Pensler',
            'value8': 'Rulle og skafter',
            'value9': 'Spartel',
            'value10': 'Spraydåser',
            'value11': 'Træværksmaling',
            'value12': 'Væg maling',
            'value13': 'Diverse',
            'value14': 'Facade Maling',
            'value15': 'Filt,væv,tapet',
            'value16': 'Maler værktøj',
            'value17': 'Spærrende Maling',
            'value18': 'Træbeskyttelse',
            'value19': 'Vindues Maling',
            'value20': 'Værktøj',
            'value21': 'Udvendigt diverse',
            'value22': 'Tilbud',
            'value23': 'Lak',
        },
        'selectedValueWeekDays': {
            'value1': 'Mandag',
            'value2': 'Trisdag',
            'value4': 'Onsdag',
            'value8': 'Torsdag',
            'value16': 'Fredag',
            'value32': 'lørdag',
            'value64': 'Søndag'
        },
        'Headers': {
            'NoFileSelected': 'Ingen fil valgt',
            'FOLDEREMPTY': 'Denne folder er tom',
            'UploadFile': 'Upload Fil',
            'ChooseFile': 'Vælg fil',
            'ChoosePicture': 'Vælg billede',
        },
        'AssetsLabels': {
            'Value1': 'Diverse',
            'Value2': 'Giraf',
            'Value3': 'Kabeltromler, Lamper',
            'Value4': 'Maskiner',
            'Value5': 'Nøgler',
            'Value6': 'Sanne',
            'Value7': 'Sikkerhed',
            'Value8': 'Slibekabine',
            'Value9': 'Slibemaskiner',
            'Value10': 'Sprøjtekabine',
            'Value11': 'Sprøjtemaskiner',
            'Value12': 'Stiger',
            'Value13': 'Støvsuger',
            'Value14': 'Tapetbord',
            'Value15': 'Trailer',
            'Value16': 'Udstue',
            'Value17': 'Vognpark',
        },
        'AssetsGroup': {
            'Value1': 'Enheder',
            'Value2': 'Vognpark',
        },
        'DeviceType': {
            'Value1': 'None',
            'Value2': 'BLE',
            'Value3': 'GPS',
            'Value4': 'GPSGATE',
            'Value5': 'QR',
            'Value6': 'LANGATE',
            'Value7': 'MOBILE',
            'Value8': 'MESHGATE',
            'Value9': 'CROWD',
        },
        'DeviceModel': {
            'Value1': 'UNKNOWN',
            'Value2': 'BT40',
            'Value3': 'BT30S',
            'Value4': 'BT41',
            'Value5': 'BT80',
            'Value6': 'GT100',
            'Value7': 'GGB100',
            'Value8': 'GGT100',
            'Value9': 'LG100',
            'Value10': 'MG100',
            'Value11': 'DR100',
            'Value12': 'DY100',
            'Value13': 'TA100',
        },
        'selectedReminderRepeatModes': {
            'value1': 'Daglige ',
            'value2': 'Ugentlig',
            'value3': 'Månedlige',
            'value4': 'årlig',
            'value5': 'Nu'
        },
        'selectedWeekedDay': {
            'value1': 'Man ',
            'value2': 'Tir',
            'value3': 'Ons',
            'value4': 'Tor',
            'value5': 'Fre',
            'value6': 'Lør',
            'value7': 'Søn'
        },
        'selectedReminderRepeatModeOfMonth': {
            'value1': 'Måneds startdag',
            'value2': 'Måneders slutdag',
            'value3': 'Månedligt med bestemt dag for uge',
            'value4': 'Månedligt med bestemt dag'
        },
        'selectedWeekOftheMonth': {
            'value1': '1st',
            'value2': '2nd',
            'value3': '3rd',
            'value4': '4th',
            'value5': '5th'
        },
        'TOOTIPORTITLE': {
            'Edit': 'Redigere',
            'Delete': 'Slet',
            'Copy': 'Kopi'
        },
        'Message': {
            'EmailSavedInQueueWanrning': 'Indstillingen er slået fra for at sende oplysninger, vi gemmer data på serveren sender næste dag.'
        },
        'supplierPriceListType': {
            'value1':"Materialeprisliste (Excel)",
            'value2':"Leverandørfaktura (PDF)"
        }
    }
};