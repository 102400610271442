export const locale = {
    lang: 'en',
    data: {
        'HEADERS': {
            'OfferTravelingAmount': 'Offer Traveling Amount Per Minute',
            'AddedToCart': 'Create/Save offer',
            'RenameFile': 'Rename file',
            'Designation': 'Designation',
            'Company': 'Company',
            'AdvanceInvoice':'Advance invoice'
        },
        'FIELDS': {
            'TravelingAmount': 'Traveling amount per. min',
            'Name':'Name',
            'AmountWithTax':'Beløb incl. vat',
        },
        'BUTTONS': {
            'Save': 'Save',
            'Back': 'Back',
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success'
        }
    }
}