import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Optional, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MaterialService } from './material.service';
import { Material } from 'app/main/model/Material';
import { RequestMaterial, SupplierRequest } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialTypeCategory, MaterialType } from 'app/constant/common.constant';
import { AuthService } from 'app/core/services/auth.service';
import { IsNumber } from 'app/core/Validators/validation';
import { TitleService } from 'app/core/services/title.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { MaterialColorCodeComponent } from './component/colorCode/colorCode.component';
import { OfferMaterialWithPrice } from 'app/main/model/OfferV2';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';
import { PriceTagComponent } from './component/pricetag/pricetag.component';
// import { MaterialType } from 'app/main/model/ProjectStatus';
import { PdfViewerModelComponent } from '../pdf-viewer/pdf-viewer.component';
import { PriceTagSizeComponent } from './component/pricetagsize/pricetagsize.component';
import { Supplier } from 'app/main/model/Supplier';
import { SupplierPriceUploadComponent } from './component/supplier-price-upload/supplier-price-upload.component';

@Component({
    selector: 'vibe-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss']
})

export class VibeMaterialComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("MaterialScan", { static: false }) materialScan: ElementRef;
    @ViewChild("MaterialSearch", { static: false }) materialSearch: ElementRef;
    requestMaterial: RequestMaterial = new RequestMaterial();
    //Variable Diclarartion
    Materials: Material[] = [];
    isCallOnInitMethod: boolean = true;
    // useradmin: boolean = false;
    // userkontor: boolean = false;
    // usermaler: boolean = false;
    // usergaest: boolean = false;
    // userguest: boolean = false;
    showSpinner: boolean = false;
    isDeleted: boolean = false;
    isShowOnlyWebShopMaterial: boolean = false;
    data: any;
    //isValid: boolean = false;
    search: any = '';
    barcode: any = '';
    type: any = 0;
    number: any = '';
    pagination: any;
    limitNumber: any;
    displayPagination: boolean = false;
    //Initiators
    //roleName: string;
    orderBy: string = "Number DESC";
    isActive: boolean = true;
    selectedIndex: number = 0;
    isScanner: boolean = true;
    selectMaterialTypeList = MaterialTypeCategory;
    offerMaterialList: OfferMaterialWithPrice[] = [];
    public denmarkDigitFormate = new DenmarkDigitFormate();
    materialType: number = 0;
    selectMaterialtype = MaterialType;
    materialBarcodeList = [];
    materialIds = [];
    misMatchMaterialCount: number = 0;
    isMismatch: boolean = false;
    supplier: any = 'All';
    IsGettingSupplier: boolean = false;
    SupplierRequest: SupplierRequest;
    SupplierList: Supplier[] = [];
    SearchedSupplierList: Supplier[] = [];
    settingMasterValues: any[] = [];
    roleClaims: any;
    hasOrderItemPermision: boolean = false;
    hasOrderdItemPermision: boolean = false;
    hasSoldStockPermision: boolean = false;
    hasLogPermision: boolean = false;
    hasMaterialCalculationContainerPermision: boolean = false;
    isUseScannerDevice: boolean = false;
    isOfferMaterialRoute: boolean = false;
    private currentRoute: string = "";
    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<MaterialColorCodeComponent>,
        private _router: Router, private authService: AuthService, private titleService: TitleService,
        private _route: ActivatedRoute) {
        this.titleService.SetTitle("TITLE_MATERIALS");
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.currentRoute = this._router.url.split('/').pop();
        if(this.currentRoute === "offermaterials"){
            this.isOfferMaterialRoute = true;
            this.titleService.SetTitle("TITLE_OFFERMATERIALS");
            //this.materialType = 7;
        }
        else
            this.selectMaterialtype = this.selectMaterialtype.filter(item => item.id != 22);
        this.authService.hasClaim(this.roleClaims.Material_Order_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasOrderItemPermision = hasClaim
             else 
                this.hasOrderItemPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Material_Ordered_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasOrderdItemPermision = hasClaim
             else 
                this.hasOrderdItemPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Material_Sold_Stock).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasSoldStockPermision = hasClaim
             else 
                this.hasSoldStockPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Material_Log).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasLogPermision = hasClaim
             else 
                this.hasLogPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Material_Calculation_Container).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasMaterialCalculationContainerPermision = hasClaim
             else 
                this.hasMaterialCalculationContainerPermision = false;
        });
        
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.requestMaterial = (this.currentRoute === "offermaterials" ? this.materialService.getOfferMaterialRequestParameter() : this.materialService.getRequestParameter());
        this.isActive = false;
        //this.requestMaterial.IsActive = false;
        this.isDeleted = this.requestMaterial.IsDeleted;
        //this.search = this.requestMaterial.Text;
        this.type = this.requestMaterial.Type ? this.requestMaterial.Type : 0;
        this.supplier = this.requestMaterial.SupplierID ? this.requestMaterial.SupplierID : "All";
        this.materialType = this.requestMaterial.MaterialType ? this.requestMaterial.MaterialType : 0;
        this.orderBy = this.requestMaterial.OrderBy;
        this.isMismatch = false;
        this.requestMaterial.IsMismatch = this.isMismatch;
        this.isShowOnlyWebShopMaterial = this.requestMaterial.IsShowOnlyWebShopMaterial;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));        
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if (tempSettingObject && JSON.parse(tempSettingObject).find(x => x.name == 'materials').isInitialLoading)
            this.GetMaterial();

        const tempMaterialOtherSettingList = this.settingMasterValues.find(s => s.key1 === "Material" && s.key2 === "OtherSetting");
        if (tempMaterialOtherSettingList && tempMaterialOtherSettingList.value) {
            const sessionVal = JSON.parse(tempMaterialOtherSettingList.value);
            if (sessionVal && sessionVal.IsUseScannerDevice)
                this.isUseScannerDevice = sessionVal.IsUseScannerDevice;
        }

        this._route.queryParams.subscribe(params => {
            if (params && params.tab == 'order') {
                this.selectedIndex = 1;
            }
        });
        this.getMismatchMaterialCount();
        this.GetSupplierList();
        this.translate.onLangChange
            .subscribe((event: LangChangeEvent) => {
                // console.log('onLangChange', event);
                this.GetSupplierList();
            });
    }

    ngOnDestroy(): void {
        this.requestMaterial.Text = ''
        if(this.currentRoute === "offermaterials")
            this.materialService.setOfferMaterialRequestParameter(this.requestMaterial);
        else
            this.materialService.setRequestParameter(this.requestMaterial);
        //this.materialService.setRequestParameter(this.requestMaterial);
    }

    ngAfterViewInit() {
        this.materialSearch.nativeElement.focus();
        // setTimeout(() => {
        //   if (!this.search) {
        //     this.materialScan.nativeElement.focus();
        //   }
        // }, 2000)
    }

    GetMaterial() {
        this.showSpinner = true;
        this.requestMaterial.IsOfferMaterial = this.isOfferMaterialRoute;
        if (!this.requestMaterial.Limit)
            this.requestMaterial.Limit = 500;
        if(this.currentRoute === "offermaterials")
            this.materialService.setOfferMaterialRequestParameter(this.requestMaterial);
        else
            this.materialService.setRequestParameter(this.requestMaterial);
        this.materialService.Get<ResponseBase>(this.requestMaterial)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Materials = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                }
                else {
                    this.isCallOnInitMethod = false;
                    this.Materials = [];
                    this.displayPagination = false;
                }
                this.showSpinner = false;

            },
                error => (err) => {
                    this.isCallOnInitMethod = false;
                    this.Materials = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }
    getDeletedMaterials(isDeleted) {
        this.requestMaterial.Index = 1;
        this.requestMaterial.IsDeleted = this.isDeleted = isDeleted;
        if (this.isDeleted) {
            this.GetMaterial();
        }
    }
    checkAccess(id) {
        this.EditCoWorker(id);
    }

    EditCoWorker(id) {
        if (this.currentRoute === "offermaterials") {
            this._router.navigate(['/materials/edit', id], {
                state: { moduleDataList: this.Materials },
                queryParams: { isOfferMaterial: true }
            });
        }
        else {
            this._router.navigate(['/materials/edit', id], {
                state: { moduleDataList: this.Materials }
            }); //navigationExtras
        }
    }

    SearchOnTextByEnter() {
        if (this.search) {
            this.requestMaterial.Text = this.search.trim();
            this.isActive = true;
            this.requestMaterial.IsActive = !this.isActive;
        } else {
            this.requestMaterial.Text = "";
            this.requestMaterial.IsActive = !this.isActive;
        }
        this.requestMaterial.Index = 1;
        this.type = 0;
        this.requestMaterial.Type = "0";
        this.requestMaterial.MaterialType = 0;
        this.materialType = 0;
        this.requestMaterial.SupplierID = "";
        this.supplier = "All";
        this.requestMaterial.IsDeleted = false;
        this.isDeleted = false;
        this.requestMaterial.IsShowOnlyWebShopMaterial = false;
        this.isShowOnlyWebShopMaterial = false;
        this.requestMaterial.IsMismatch = false;
        this.isMismatch = false;
        this.GetMaterial();
    }

    Search(value) {
        if (this.search) {
            this.requestMaterial.Text = this.search.trim();
            this.isActive = true;
            this.requestMaterial.IsActive = !this.isActive;
        } else {
            this.requestMaterial.Text = "";
        }
        this.requestMaterial.Index = 1;
        this.requestMaterial.Type = this.type;
        this.requestMaterial.MaterialType = this.materialType;
        if (this.supplier) {
            this.requestMaterial.SupplierID = this.supplier == "All" ? "" : this.supplier;
        } else {
            this.supplier = "All";
            this.requestMaterial.SupplierID = "";
        }
        this.GetMaterial();
    }

    SearchByBarcode(event) {
        this.search = "";
        this.requestMaterial.Text = "";
        if (this.barcode) {
            this.showSpinner = true;
            this.barcode = this.barcode.trim();
            var findMaterial = this.Materials.find(o => o.barcode == this.barcode || o.barcode2 == this.barcode);
            if (!findMaterial) {

                this.GetMaterialByBarcode();
            }
            else {
                this.showSpinner = false;
                this._router.navigate(['/materials/edit', findMaterial.id]); //navigationExtras
            }
        }
    }

    AddMaterialUsingScanner(flag) {
        this.isScanner = !flag;
    }
    AddMaterialManual(flag) {
        this.isScanner = !flag;
    }
    searchBarcode() {
        if (this.barcode) {
            this.barcode = this.barcode.trim();
            var findMaterial = this.Materials.find(o => o.barcode == this.barcode || o.barcode2 == this.barcode);
            if (!findMaterial) {
                this.translate.get("MESSAGES.NoBarcodeAttachedToMaterial").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.commonService.playFailedTone();
                    },
                    error: err => {
                    }
                });
            }
            else {
                this._router.navigate(['/materials/edit', findMaterial.id]); //navigationExtras
            }
        }
    }

    GetMaterialByBarcode() {
        this.showSpinner = true;
        this.materialService.GetMaterialByBarcode<ResponseBase>(this.barcode)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response.result) {
                    this._router.navigate(['/materials/edit', response.result.id]); //navigationExtras
                }
                else {
                    setTimeout(() => {
                        this.translate.get("MESSAGES.NoBarcodeAttachedToMaterial").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                                this.commonService.playFailedTone();
                            },
                            error: err => {
                            }
                        });
                    })
                }

            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {

                });
    }

    receivePaginatonEvent(event) {
        this.requestMaterial.Index = event.pageIndex;
        this.requestMaterial.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.GetMaterial();
    }

    receiveFilterEvent(event) {
        this.orderBy = event;
        this.requestMaterial.OrderBy = this.orderBy;
        if (this.limitNumber == undefined) {
            this.requestMaterial.Limit = 100
        }
        else {
            this.requestMaterial.Limit = this.limitNumber;
        }
        this.GetMaterial();
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    onSetActive(active) {
        this.requestMaterial.Index = 1;
        this.isActive = active;
        this.requestMaterial.IsActive = !this.isActive;
        this.GetMaterial();
    }
    TabChange(event) {
        this.setQueryParams();
        this.selectedIndex = event.index;
        if (this.selectedIndex == 0) {
            this.GetMaterial();
        }
    }

    setQueryParams() {
        const qParams: Params = {};
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: qParams,
            queryParamsHandling: ''
        });
    }

    getMultiBarcode(printSize) {
        this.materialIds = [];
        this.Materials.forEach(e => {
            this.materialIds.push(e.id);
        });
        let MatIds = {
            MaterialIds: this.materialIds,
            PrintSize: printSize
        }
        this.showSpinner = true;
        this.materialService.GetBarcodeListFromIDS(MatIds).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                //this.materialBarcodeList = response.result//.myResponse;
                //this.openMultiBarcodePopup();
                var data = response.result.replace("data:application/pdf;base64,", "");
                this.showSpinner = true;
                const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                    height: '90%',
                    width: '90%',
                    data: {
                        base64: data,
                        filename: response.result.filename + '.pdf'
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.showSpinner = false;
                    if (result) {
                    }
                });
            },
            error: err => {
                this.materialBarcodeList = [];
                this.showSpinner = false;
            }
        });
    }

    openMultiBarcodePopup() {
        const dialogRef = this.dialog.open(PriceTagComponent, {
            height: '100%',
            width: '70%',
            data: { materialBarcodeList: this.materialBarcodeList },
            disableClose: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    OpnePriceSizeTag() {
        const dialogRef = this.dialog.open(PriceTagSizeComponent, {
            height: '25%',
            width: '25%',
            disableClose: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getMultiBarcode(result);
            }
        });
    }
    getMismatchMaterialCount() {
        this.materialService.GetMismatchMaterialCount().subscribe({
            next: (response: ResponseBase) => {
                this.misMatchMaterialCount = response.result;
            },
            error: err => {
            },
            complete: () => {
            }
        });
    }
    getMisMatchMaterials() {
        this.requestMaterial = new RequestMaterial();
        this.requestMaterial.Index = 1;
        this.requestMaterial.IsMismatch = this.isMismatch;
        this.search = "";
        this.barcode = "";
        this.supplier = "All";
        this.type = 0;
        this.materialType = 0;
        this.isActive = false;
        this.isDeleted = false;
        this.isShowOnlyWebShopMaterial = false;
        if (this.isMismatch) {
            this.GetMaterial();
        }
    }

    changeShowOnlyWebShopMaterial() {
        this.requestMaterial.Index = 1;
        this.requestMaterial.IsShowOnlyWebShopMaterial = this.isShowOnlyWebShopMaterial;
        this.GetMaterial();
    }

    GetSupplierList() {
        this.SupplierRequest = new SupplierRequest();
        this.SupplierRequest.Index = 1;
        this.SupplierRequest.Limit = 100;
        this.IsGettingSupplier = true;
        this.materialService.GetSupplierList<ResponseBase>(this.SupplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.SupplierList = response.result;

                    this.SupplierList.sort(function (a, b) {
                        return a.business.localeCompare(b.business);
                    });

                    // let all = new Supplier();
                    // all.id = "All";
                    // //all.business = "All";
                    // this.translate.get("FILTERS.All").subscribe({
                    //     next: (res) => {
                    //         all.business = res;
                    //     },
                    //     error: err => {
                    //         all.id = "All";
                    //     }
                    // });
                    // this.SupplierList.unshift(all)
                },
                error: err => { },
                complete: () => {
                    this.IsGettingSupplier = false;
                }
            });
    }

    SupplierSearch(value) {
        if (value === '') {
            return this.SearchedSupplierList = []
        }
        this.SearchedSupplierList = this.SupplierList.filter((supplier) => {
            return supplier.business.toLowerCase().startsWith(value.toLowerCase())
        })
    }

    OpenSuppExlUpl() {
        const dialogRef = this.dialog.open(SupplierPriceUploadComponent, {
            data: {},
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    trackByFn(index: number, item: any): any {
        return item.id; 
    }
}
