//angular
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
//core
import { ApiConstant } from 'app/core/constant/ApiConstant';
//services
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CommonService } from 'app/core/services/common.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
//DropBox
import { ListFolder, CreateFolder, FolderListDTO, FolderListNavigationDTO, DeleteFolder, SharedLinkDTO, SearchFile, FilesObj, SearchFolderOrFiles } from 'app/main/model/DropBox';
import { CreteFolderModalComponent } from './component/create-folder-popup/create-folder-popup.component';
//envirnment
import { environment } from 'environments/environment';
//Model
import { ProjectBasedNotification } from 'app/main/model/Project';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
//translation
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ViewAlertModelComponent } from './component/view-alert-model/view-alert-model.component';
import { ResponseBase } from '../../model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { TextEditorTextAreaComponent } from 'app/main/pages/text-editor-textarea/text-editor-textarea.component';
import { Settings } from '../../model/Settings';
import { SettingConstant } from 'app/core/constant/SettingConstant';
import { DropboxFilesService } from './dropbox-files.service';
import { Platform } from '@angular/cdk/platform';


@Component({
    selector: "vibe-dropbox-files",
    templateUrl: "./dropbox-files.component.html",
    styleUrls: ["./dropbox-files.component.scss"]
})

export class VibeDropboxFileComponent implements OnInit {
    @Input() fileFolderPathData: Array<FolderListNavigationDTO> = [];
    @Input() hideFolderForEmployeeFolder: boolean = false;
    @Input() extraParamToSend: any = [];
    @Input() isSearch: boolean = false;
    @Input() moduleName: string = "";
    @Input() searchValue: string = "";
    @Input() isDisplayOnlySearchFolder: boolean = false;
    @Input() isDisplaySelectedAllFiles: boolean = false;
    @Output() isFileExist = new EventEmitter<boolean>();
    @Output() totalFileCount = new EventEmitter<number>();
    @Output() filesSelectionListOutEvent = new EventEmitter<Array<FolderListDTO>>();
    filesSelectionList: Array<FolderListDTO> = [];
    moduleId: string;
    folderMatchLength: boolean = false;
    isActive: boolean;
    showSpinner = false;
    fileObjList: Array<FolderListDTO>;
    fileFolderPath: any = [];
    checkedList = [];
    listFolder: ListFolder;
    createFolder: CreateFolder;
    notificationModel: ProjectBasedNotification = new ProjectBasedNotification();
    SearchFileName: string = "";
    //roleName: string = "";
    settingObj: Settings;
    searchFileAndFolder: SearchFolderOrFiles;
    orderBy: string = "Name";
    roleClaims: any;
    hasPermision: boolean = false;
    isHideAddFolderOpt: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private _router: Router,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private projectService: ProjectService,
        private commonService: CommonService,
        private _route: ActivatedRoute,
        private dropboxFilesService: DropboxFilesService,
        private authService: AuthService,
        private platform: Platform,) {
        this.translationLoader.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.authService.hasClaim(this.roleClaims.Document_Modify).subscribe(hasClaim => {
            if (hasClaim)
                this.hasPermision = hasClaim
            else
                this.hasPermision = false;
        });
        this.moduleId = this._route.snapshot.params.id;
        this.fileObjList = Array<FolderListDTO>();
        if (this.fileFolderPathData.length>0 && !this.hideFolderForEmployeeFolder && !this.isSearch) {
            this.GetFileList();
        }
        else if (this.hideFolderForEmployeeFolder && !this.isSearch) {
            this.GetSettingValueForHideFolder();
        } else if (this.isSearch) {
            this.SearchFileName = this.searchValue;
            this.SearchFile();
        }
    }

    GetSettingValueForHideFolder() {
        this.dropboxFilesService.GetShowOnlyAdminFolderName<ResponseBase>(SettingConstant.Admin_Show_Document)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.settingObj = response.result;
                    this.GetFileListWithHideFolder();
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    GetFileListWithHideFolder() {
        this.checkedList = [];
        this.fileObjList = Array<FolderListDTO>();
        this.listFolder = new ListFolder();
        this.listFolder.folderPath = this.GetCurrentFolderPath();
        var visiblePath = this.fileFolderPathData.filter(o => o.isDisplay);
        this.listFolder.projectId = this.moduleId;
        if (this.moduleName == "Project" && visiblePath && visiblePath.length == 1) {
            this.listFolder.isProjectRootFolder = true;
        }
        this.showSpinner = true;
        this.dropboxFilesService.GetListFolderDrpBox<ResponseBase>(this.listFolder).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (!this.hasPermision && this.hideFolderForEmployeeFolder) {
                    const isdesplayCount = this.fileFolderPathData.filter(o => o.isDisplay === true);
                    if (isdesplayCount.length === 1) {
                        if (this.settingObj !== null && this.settingObj !== undefined) {
                            const settingValue = this.settingObj.value.toLowerCase().split(",").map(function (item) {
                                return item.trim();
                            })
                            response.result.myResponse.filter(o => {
                                //const path
                                if (!settingValue.find(p => p === o.name.toLowerCase().trim())) {
                                    this.fileObjList.push(o);
                                }
                            })
                        }
                    }
                }
                else
                    this.fileObjList = response.result.myResponse;
                if (this.fileObjList.length > 0) {
                    this.isFileExist.emit(true);
                    this.receiveFilterEvent("Name");
                }
                else {
                    this.isFileExist.emit(false);
                }
            },
            error: err => {
                this.showSpinner = false;
            }
        });
    }

    GetFileList() {
        this.checkedList = [];
        this.fileObjList = Array<FolderListDTO>();
        this.listFolder = new ListFolder();
        this.listFolder.folderPath = this.GetCurrentFolderPath();
        var visiblePath = this.fileFolderPathData.filter(o => o.isDisplay);
        this.listFolder.projectId = this.moduleId;
        if (this.moduleName == "Project" && visiblePath && visiblePath.length == 1) {
            this.listFolder.isProjectRootFolder = true;
        }
        this.showSpinner = true;
        this.dropboxFilesService.GetListFolderDrpBox<ResponseBase>(this.listFolder).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.fileObjList = response.result.myResponse;
                if (response.result.myResponse.length > 0) {
                    this.isFileExist.emit(true);
                    this.receiveFilterEvent("Name");
                    this.totalFileCount.emit(response.result.myResponse.length);
                    if (this.isDisplaySelectedAllFiles) {
                        this.fileObjList.filter(o => {
                            var selectedFile = this.filesSelectionList.find(p => p.pathLower == o.pathLower);
                            if (selectedFile) {
                                o.checked = selectedFile.checked;
                            }
                        })
                    }
                }
                else {
                    this.isFileExist.emit(false);
                    this.totalFileCount.emit(0);
                }
            },
            error: err => {
                this.showSpinner = false;
                //this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                //  next: (res) => {
                //    this.notificationsService.error(res);
                //  },
                //  error: err => {
                //  }
                //});
            }
        });
    }

    openCreateFolderModal() {
        const dialogRef = this.dialog.open(CreteFolderModalComponent, {
            height: '200px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.CreateFolderFromPath(result);
            }
        });
    }

    CreateFolderFromPath(folderName) {
        this.createFolder = new CreateFolder();
        this.createFolder.folderName = folderName;
        this.createFolder.folderPath = this.GetCurrentFolderPath();
        var visiblePath = this.fileFolderPathData.filter(o => o.isDisplay);
        this.listFolder.projectId = this.moduleId;
        if (this.moduleName == "Project" && visiblePath && visiblePath.length == 1) {
            this.listFolder.isProjectRootFolder = true;
        }
        this.showSpinner = true;
        this.dropboxFilesService.CreateFolder(this.createFolder).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.GetFileList();
            },
            error: err => {
                this.showSpinner = false;
                if (err.status == 409) {
                    this.translate.get("MESSAGES.FileConflict").subscribe({
                        next: (res) => {
                            //this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
                else {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },
                        error: err => {
                        }
                    });
                }
            }
        });
    }

    selectItem(event, obj) {
        if (event.checked) {
            obj.checked = true;
            this.checkedList.push(obj.name);
            this.filesSelectionList.push(obj);
            this.filesSelectionListOutEvent.emit(this.filesSelectionList);
        }
        else {
            obj.checked = false;
            this.checkedList = this.checkedList.filter(x => x != obj.name);
            this.filesSelectionList = this.filesSelectionList.filter(x => x.pathLower != obj.pathLower);
            this.filesSelectionListOutEvent.emit(this.filesSelectionList);
        }
    }

    deleteDrpBoxFile() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteProjectFileAlert' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteDrpBoxItem();
            }
        });
    }

    deleteDrpBoxItem() {
        var path = this.GetCurrentFolderPath();
        var pathList = [];
        for (var i = 0; i < this.checkedList.length; i++) {
            pathList.push(path + "/" + this.checkedList[i]);
        }
        var deleteBatch = new DeleteFolder();
        deleteBatch.pathWithName = pathList;
        deleteBatch.moduleId = this.moduleId;
        deleteBatch.filePath = path;
        this.showSpinner = true;
        this.dropboxFilesService.DeleteFolderOrFile<ResponseBase>(deleteBatch).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                setTimeout(() => {
                    this.GetFileList();
                }, 2000);
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.showSpinner = false;
                this.checkedList = [];
                this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    goToFolder(obj) {
        if (obj.isFolder) {
            if (obj.isSearch) {
                this.SetPathAndBreadcrumbFromPath(obj.pathDisplay)
                this.GetFileList();
            }
            else {
                if (this.moduleName == "Material" && obj.name == "Instructions" && this.fileFolderPathData.length > 2 &&
                    this.fileFolderPathData[this.fileFolderPathData.length - 2].name == "Material") {
                    this.isHideAddFolderOpt = true;
                }
                else {
                    this.isHideAddFolderOpt = false;
                }
                var localObj = new FolderListNavigationDTO();
                localObj.name = obj.name;
                localObj.isDisplay = true;
                this.fileFolderPathData.push(localObj);
                this.GetFileList();
            }
        }
        else {
            var sharedLinkDTO = new SharedLinkDTO();
            sharedLinkDTO.path = obj.pathLower +"/"+ obj.name;
            this.showSpinner = true;
            this.dropboxFilesService.GetSharedLink<ResponseBase>(sharedLinkDTO).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    if (this.platform.IOS) {
                        this.viewDrpBoxFile(response.result.url);
                    } else {
                        window.open(response.result.url);
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    SetPathAndBreadcrumbFromPath(path) {
        let folderList = path.split("/");
        folderList = folderList.filter(o => {
            if (o)
                return o;
        });
        const tempFileFolderPathData = [];
        for (let i = 0; i < folderList.length; i++) {
            if (this.fileFolderPathData.length > i && folderList[i] === this.fileFolderPathData[i].name) {
                tempFileFolderPathData.push(this.fileFolderPathData[i]);
            }
            else {
                const localObj = new FolderListNavigationDTO();
                localObj.name = folderList[i];
                localObj.isDisplay = true;
                tempFileFolderPathData.push(localObj);
            }
        }
        this.fileFolderPathData = tempFileFolderPathData;
    }

    GetCurrentFolderPath() {
        var path = "";
        for (var i = 0; i < this.fileFolderPathData.length; i++) {
            path += "/" + this.fileFolderPathData[i].name;
        }
        return path;
    }

    viewDrpBoxFile(filename) {
        const dialogRef = this.dialog.open(ViewAlertModelComponent, {
            height: '200px',
            data: filename
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
            this.fileObjList.filter(x => {
                if (x.checked) {
                    x.checked = false;
                }
            });
        });
    }

    goToNavigate(folderName, length) {
        if (this.moduleName == "Material" && folderName == "Instructions" && this.fileFolderPathData.length > 2 &&
            this.fileFolderPathData[this.fileFolderPathData.length - 2].name == "Material") {
            this.isHideAddFolderOpt = true;
        }
        else {
            this.isHideAddFolderOpt = false;
        }
        var pathList = [];
        for (var i = 0; i <= length; i++) {
            pathList.push(this.fileFolderPathData[i]);
        }
        this.fileFolderPathData = pathList;
        this.GetFileList();
    }

    OnuploadFile(event) {
        if (event.target.files.length > 0) {
            const files = event.target.files;
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("file[]", files[i]);
            }
            formData.append("RootFolderPath", this.GetCurrentFolderPath());
            formData.append("ModuleID", this.moduleId);
            if (this.extraParamToSend && this.extraParamToSend.length > 0) {
                for (var i = 0; i < this.extraParamToSend.length; i++) {
                    formData.append(this.extraParamToSend[i].name, this.extraParamToSend[i].value);
                }
            }
            this.showSpinner = true;
            this.dropboxFilesService.UploadFiles(formData).subscribe({
                next: (response) => {
                    this.GetFileList();
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }



    SearchFile() {
        if (this.SearchFileName) {
            this.fileObjList = [];
            this.showSpinner = true;
            this.searchFileAndFolder = new SearchFolderOrFiles();
            this.searchFileAndFolder.searchValue = this.SearchFileName;
            this.searchFileAndFolder.path = this.GetCurrentFolderPath();
            var visiblePath = this.fileFolderPathData.filter(o => o.isDisplay);
            this.searchFileAndFolder.projectId = this.moduleId;
            if (this.moduleName == "Project" && visiblePath && visiblePath.length == 1) {
                this.searchFileAndFolder.isProjectRootFolder = true;
            }
            this.dropboxFilesService.GetSearchFileOrFolder<ResponseBase>(this.searchFileAndFolder).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    //this.fileObjList = response.result.myResponse;
                    if (!this.hasPermision && this.hideFolderForEmployeeFolder) {
                        const isdesplayCount = this.fileFolderPathData.filter(o => o.isDisplay === true);
                        if (isdesplayCount.length === 1) {
                            if (this.settingObj !== null && this.settingObj !== undefined) {
                                const settingValue = this.settingObj.value.toLowerCase().split(",").map(function (item) {
                                    return item.trim();
                                })
                                response.result.myResponse.filter(o => {
                                    //const path
                                    if (settingValue.filter(p => o.pathLower.includes(this.searchFileAndFolder.path.toLowerCase() + "/" + p.toLowerCase())).length == 0) {
                                        o.isSearch = true;
                                        this.fileObjList.push(o);
                                    }
                                })
                            }
                        }
                    }
                    else {
                        response.result.myResponse.filter(o => {
                            o.isSearch = true;
                        });
                        if (this.isDisplayOnlySearchFolder) {
                            this.fileObjList = response.result.myResponse.filter(x => x.isFolder == true);
                        } else {
                            this.fileObjList = response.result.myResponse;
                        }
                    }
                },
                error: err => {
                    this.showSpinner = false;
                }
            });
        } else {
            this.GetFileList();
        }
    }

    popupOpenForHideFolderName() {
        const dialogRef = this.dialog.open(TextEditorTextAreaComponent, {
            height: '400px',
            width: '500px',
            data: { 'displayData': this.settingObj ? this.settingObj.value : "" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.isSave) {
                this.settingObj.value = result.value;
                this.dropboxFilesService.UpdateSettingValuue<ResponseBase>(this.settingObj.id, this.settingObj)
                    .subscribe((response: ResponseBase) => {
                        if (response) {
                            this.settingObj = response.result;
                            this.translate.get("MESSAGES.Updated").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                        error => (err) => {
                        },
                        () => {
                        });
            }
        });
    }

    receiveFilterEvent(event) {
        this.orderBy = event;
        let folderList: any;
        let fileList: any;
        if (this.fileObjList.length > 0) {
            folderList = this.fileObjList.filter(x => x.isFolder == true);
            fileList = this.fileObjList.filter(x => x.isFile == true);
            //folder list
            if (folderList.length > 0) {
                if (this.orderBy == "Name") {
                    folderList.sort((a, b) => !!a.name ? a.name.localeCompare(b.name) : null);
                }
                else if (this.orderBy == "Name DESC") {
                    folderList.sort((a, b) => !!b.name ? b.name.localeCompare(a.name) : null);
                }
            }

            //file list
            if (fileList.length > 0) {
                if (this.orderBy == "Name") {
                    fileList.sort((a, b) => !!a.name ? a.name.localeCompare(b.name) : null);
                }
                else if (this.orderBy == "Name DESC") {
                    fileList.sort((a, b) => !!b.name ? b.name.localeCompare(a.name) : null);
                }
            }

            if (this.orderBy == "Name") {
                folderList = [...folderList, ...fileList];
            } else {
                folderList = [...fileList, ...folderList];
            }
            this.fileObjList = folderList;
        }
    }
}