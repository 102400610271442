export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForLeaveNotification': 'Ansøg om frihed',
            'HeaderForRemoveNotification': 'Notifikation på  planlægning fjernes',
            'HeaderForInquiryNotification': 'Notifikation på forespørgsler',
            'HeaderForOfferNotification': 'Notifikation på tilbud er godkendt',
            'HeaderForOfferRejectedNotification': 'Notifikation på tilbud er afvist',
            'HeaderForProjectStatusBeforeSevenDaysNotification': 'Notifikation på projektstatus (inden for 7 dage)',
            'HeaderForProjectWarningMaterialColorcodeNotification': 'Notifikation på projekt forskellige Materiale farvekode',
        },
        'FIELDS': {
            'DaysToSend': 'Send x dage før',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'EmailText': 'E-mail tekst',
            'ExecuteTime':'Udfør tid',
            'IsCreateReminder':'Opret påmindelse?',
            'NotesLabel':"Slet ikke et præfiks '@'-tegnord.",
            'ProjectWarningMaterialColorCodeReminderExtraTextLabel':"Farvekode"
        },
        'VALIDATIONS': {
            'DaysToSend': 'dage at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'Label': {
            'InquirySMSLabel': 'Fjern venligst ikke @CustomerName ,@InquiryDate, @InquiryNumber og @Address fra SMS tekst.',
            'PlanningLeaveSMSLabel': 'Fjern venligst ikke @CoworkerName og @LeaveDate fra SMS tekst.',
            'OfferSMSLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber, @OfferAddress og @OfferTotal fra SMS tekst.',
            'OfferRejectedSMSLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber, @OfferAddress og @OfferTotal fra SMS tekst.',
            'ProjectStatusBeforeSevenDaysSMSLabel': 'Fjern venligst ikke @Count fra SMS tekst.',
            'ProjectWarningMaterialColorcodeSMSLabel': 'Fjern venligst ikke @ProjectNumber og @ProjectAddress SMS tekst.',
            'InquiryNotificationLabel': 'Fjern venligst ikke @CustomerName ,@InquiryDate, @InquiryNumber og @Address fra notifikations tekst.',
            'PlanningLeaveNotificationLabel': 'Fjern venligst ikke @CoworkerName og @LeaveDate fra notifikations tekst.',
            'OfferNotificationLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber, @OfferAddress og @OfferTotal fra notifikations tekst.',
            'OfferRejectedNotificationLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber, @OfferAddress og @OfferTotal fra notifikations tekst.',
            'ProjectStatusBeforeSevenDaysNotificationLabel': 'Fjern venligst ikke @Count fra notifikations tekst.',
            'ProjectWarningMaterialColorcodeNotificationLabel': 'Fjern venligst ikke @ProjectNumber og @ProjectAddress fra notifikations tekst.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
