export const locale = {
  lang: 'ds',
  data: {
    'BUTTONS': {
      'Upload': 'Upload',
      'Cancel': 'Tilbage',
      "Update": "Opdatere",
      "Reset": "Nulstil / annullere",
      "SampleFileDownload": "Prøve fil download",
      'ViewPDF':"Se faktura PDF"
    },
    'FIELDS': {
      'SelectSupplier': 'Vælg Leverandør',
      'UploadPriceList': 'Upload prisliste',
      'ChooseFile': 'Vælg fil',
      'Material': 'Materiale',
      'PersonalTotalPrice': 'Personal i alt',
      'Quantity': 'Antal',
      'ProductNo': 'Vare Nr',
      'OrderQuantity': 'Bestille Antal',
      'MinQuantity': 'Min Antal',
      'MaxQuantity': 'Max Antal',
      'TotalPurchasePrice': 'I alt Indkøbs Pris',
      'Total': 'I alt',
      'Inventory': 'Beholding',
      'ShowAll': 'Vis alle',
      'ProjectNumber': 'Sag Nummer',
      'RequisitionNo': 'Opgave nr.',
      'CaseNo': 'Sags nummer',
      'ItemNo': 'Vare nummer',
      'Number': 'Beholdning',
      'Text': 'Tekst',
      'OfferText': 'Tilbudsnavn',
      'FileUpload': 'Adresse',
      'Active': 'Indstil som inaktiv',
      'RetailPrice': 'Udsalgspris',
      'PurchasePrice': 'Indkøbspris',
      'Unit': 'Enhed',
      'InternalText': 'Intern tekst',
      'ShelfNo': 'Hylde nr.',
      'Rows': 'Række',
      'AutomaticallyCreate': 'Opret automatisk',
      'PersonalPrice': 'Personale pris',
      'SalesPrice': 'Udsalgspris',
      'EnterStock': 'Indtast Holdings',
      'PurchaseQuantity': 'Indkøbsmængde',
      'Supplier': 'Leverandør',
      'SupplierItemNumber': 'Leverandør varenummer',
      'MinInventory': 'Min Beholdning',
      'MaxInventory': 'Max Beholdning',
      'RequiredDetail': 'Påkrævet detalje',
      'NoStockFeatures': 'Ingen Lagertræk',
      'NotStockList': 'Ikke Lager liste',
      'ColorCode': 'Farvekode',
      'Barcode': 'Rediger Stregkode',
      'MaterialsForTheCase': 'Materialer Til Sagen',
      'Wages': 'Arbejdsløn',
      'CalculateSalesPrice': 'Beregn Udsalgspris',
      'Linetype': 'Linjetype',
      'Reminder': 'Påmindelses',
      'PriceSignWithBuyQuantity': 'Prisskilt Med Køb Antal',
      'OnlySummer': "Kun Sommer",
      "WeatherType": "Vejrtype",
      "All": "Alle",
      "IsPostNumberDiscount": "Post Nr. Tillæg",
      "Yellow": "Gul",
      "Blue": "Blå",
      "Materialtype": 'Vare type',
      'MaterialSoldOnThisYear': 'Materiale solgt i år',
      'IsDisplayWebshop': 'Er DisplayWebshop',
      'DuplicateEntry': 'Dublet indtastning',
      'CheckPurchasePrice': 'Check Indkøbspris',
      'PurchasepriceSalesUnit': 'Indkøbspris pr. salgsenhed',
      'MaxOrderQty': 'Max order antal',
    },
    'LABELS': {
      'TITLE': 'Leverandør materiale prisliste upload (.xls,.xlsx)',
      'NoFileSelected': 'Ingen fil valgt',
      'FileSelected': 'Fil valgt',
      'ConfMsg': 'Du ændrer Pris pr ',
      'Supplier': 'Leverandør',
      "MaterialChangesList": "Materialeændringsliste",
      "MaterialDifferentSupplierList": "Materiale forskellig leverandørliste",
      "NewMaterialList": "Ny materialeliste",
      'PriceListType':"Prisliste Type"
      
    },
    'MESSAGES': {
      'Created': 'Oprettet succesfuldt.',
      'Updated': 'Opdateret succesfuldt.',
      'Failed': 'mislykkedes',
      'Deleted': 'Slettet med succes',
      'Notes': '**Bemærk - Inaktive eller slettede materialer er fremhævet i rød farve'
    },
    'VALIDATIONS': {
      'ITEMNUMBER': 'Vare nummer påkrævet',
      'TEXT': 'Tekst påkrævet',
      'BEHOLDING': 'Beholdning påkrævet',
      'ITEMNUMBEREXIST': 'Nummeret eksisterer allerede',
      'ITEMNUMBERMIN': 'Gyldigt nummer påkrævet',
      'ITEMNUMBERMAX': 'Gyldigt nummer påkrævet',
      'BEHOLDINGMIN': 'Enter Valid Number',
      'BEHOLDINGMAX': 'Enter Valid Number',
      'Barcode': 'Samme stregkode findes',
      'Unit': 'Enhed påkrævet',
      'PersonalPrice': 'Personale pris påkrævet',
      'RetailPrice': 'Udsalgspris påkrævet',
      'PurchasePrice': 'Indkøbspris påkrævet',
      'Supplier': 'Vælg Leverandør',
      'MinInventory': 'Min Beholdning påkrævet',
      'MaxInventory': 'Max Beholdning påkrævet',
      'MinPrice': 'prisen skal være {0} % højere af købsprisen. ( Mere end {1} )',
      'LineType': 'Linjetype påkrævet',
      'Materialtype': 'Vare type påkrævet',
      'WeatherType': 'Vejrtype påkrævet',
      'PurchaseQuantity': 'Indkøbsmængde påkrævet',
      'SupplierMaterialNo': 'Leverandør varenummer påkrævet',
      'PurchasingUnit': 'Indkøbsenhed påkrævet',
      'ShelfNo': 'Hylde nr påkrævet',
      'SupplierItemNumber': 'Indtast Leverandør varenummer',
    },
    'unitList': {
      'value1': 'stk.',
      'value2': 'Liter',
      'value3': 'Meter',
      'value4': 'Rulle',
      'value5': 'M2',
      'value6': 'KG'
    },
    'linetype': {
      'value1': 'Løn',
      'value2': 'Tekst',
      'value3': 'Vare'
    },
  }
}; 
