import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MaterialApendixCalculation } from 'app/main/model/Material';
import { IsNumber } from 'app/core/Validators/validation';
import { MaterialService } from '../../material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { OfferTreatmentType } from 'app/constant/common.constant';
import { Router } from '@angular/router';
import { ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';

@Component({
    selector: 'app-add-apendix-calculation',
    templateUrl: './add-apendix-calculation.component.html',
    styleUrls: ['./add-apendix-calculation.component.scss'],
})
export class AddApendixCalculationComponent implements OnInit, OnChanges {
    @Input() materialApendixCalculationObject: MaterialApendixCalculation;
    @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() backClicked: EventEmitter<any> = new EventEmitter<any>();
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    showSpinner = false;
    apendixCalculationForm: FormGroup;
    offerTreatmentTypeList = OfferTreatmentType;
    OfferTreatment: any = [];
    materialField: number = 1;
    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private materialService: MaterialService,
        private notificationsService: NotificationsService,
        private _router: Router,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.selectIndoorOutDoorStatus = this.selectIndoorOutDoorStatus.filter(o => o.id == 1 || o.id == 2);
        if (this.materialApendixCalculationObject && this.materialApendixCalculationObject.indendoersUdendoersStatistID) {
            this.materialApendixCalculationObject.indendoersUdendoersStatistIDs = [];
            this.materialApendixCalculationObject.indendoersUdendoersStatistID.split(",").filter(o => {
                this.materialApendixCalculationObject.indendoersUdendoersStatistIDs.push(Number(o));
            });
        }
        this.OfferTreatment = this.offerTreatmentTypeList.filter(x => x.id != 0);
        this.apendixCalculationForm = this.formBuilder.group({
            id: [null],
            number: [null],
            text: [null],
            m2200: [null],
            m2500: [null],
            m2500More: [null],
            lbM2: [null],
            lb15CmH: [null],
            lb30CmI: [null],
            lb40CmK: [null],
            lb60CmL: [null],
            stk10: [null],
            stk20: [null],
            stk20More: [null],
            appendix: [null],
            treatmentType: [null],
            min: [null],
            lbM2A: [null],
            lbM2B: [null],
            lbM2C: [null],
            lbRammerD: [null],
            lbRuderE: [null],
            lbFalsF: [null],
            lbKantG: [null],
            lbIndVangeM: [null],
            indendoersUdendoersStatistIDs: [null],
        });
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    save() {
        this.showSpinner = true;
        this.materialApendixCalculationObject.indendoersUdendoersStatistIDs = this.materialApendixCalculationObject.indendoersUdendoersStatistIDs.filter(o => o != 0);
        this.materialApendixCalculationObject.indendoersUdendoersStatistID = this.materialApendixCalculationObject.indendoersUdendoersStatistIDs.join(",");
        this.materialService.AddUpdateMaterialAppendixCalculation<ResponseBase>(this.materialApendixCalculationObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get(response.message).subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.saveClicked.emit(true);
                            this.apendixCalculationForm.reset();
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.notificationsService.error(err.error.message);
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    back() {
        this.backClicked.emit(true);
        this.apendixCalculationForm.reset();
    }

    appendixCalculationTreatment(event) {
        this.materialField = event;
    }

}
