export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header-Invoice-Email': 'Læs e-mail med fakturaoplysninger',
            'SMSText': 'Fjern venligst ikke @SupplierName, fra SMS tekst.',
            'NotificationText': 'Fjern venligst ikke @SupplierName, fra Notifikation tekst.',
        },
        'FIELDS': {
            'Email': 'Email',
            'Password': 'Adgangskode',
            'ImapServer': 'Imap Server',
            'ImapPort': 'Imap Portnummer',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
         },
        'VALIDATIONS': {
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
        },
        'MESSAGES': {
            'Saved': 'Gemt Succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        },
        'TOOTIPORTITLE': {
            'IsSendEmail': 'Er Send E-mail',
            'IsSendNotification': 'Er Send notifikation',
            'IsSendSMS': 'Er Send SMS',
        }
    }
};

