export const locale = {
    lang: 'en',
    data: {
        'PROJECT': {
            'TITLE': 'Project',
            'DESC': 'Hi Project!',
            'NewProject': 'New Project'
        },
        'TITLE': {
            'ProjectPagination': 'Antal projekter pr. side',
        },
        'BUTTONS': {
            'BtnCancel': 'Cancel',
            'BtnSave': 'Save',
            'BtnAdd': 'Add',
            'BtnRoute': 'Route plan',
            'BtnAddToAll': 'Add to all',
            'Back': 'Cancel',
            'BtnAddNew': 'Add New Requisition',
            'UplodPicture': 'Upload Picture',
            'CREATE_FOLDER': 'Create Folder',
            'DOWNLOAD': 'Download',
            'DELETE': 'Delete',
            'CHANGESTATUS': 'Change Status',
            'MOVETOFINISHFOLDER': 'Move to finish folder',
            'LowInvoice': 'Low Invoice',
            'BtnGoToPlanning': 'Go To Planning',
            'MoveMaterials': 'Move materials',
            'CreateInvoice': 'Create an invoice',
            'AdvanceInvoice':'Advance Invoice'
        },
        'FIELDS': {
            'PickupLift': 'Pickup Lift',
            'DeliveryLift': 'Delivery Lift',
            'Caseno': 'Case Number',
            'Co-workerOnTheCase': 'Co-worker on the case',
            'Status': 'Status',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'Responsibleforsecurity': 'Responsible for security',
            'CustName': 'Customer Name',
            'Material': 'Material',
            'Add': 'Address',
            'Address': 'Address',
            'Picture': 'Picture',
            'Zipcode': 'Zipcode',
            'Zipcode1': 'Zip',
            'Document/File': 'Document/File',
            'City': 'City',
            'City2': 'City',
            'Price-included': 'Price incl. VAT',
            'Price-excluded': 'Price excl. VAT',
            'Offer-Price-included': 'Offer price incl. VAT',
            'Offer-Price-excluded': 'Offer price excl. VAT',
            'Add2': 'Address 2',
            'VAT': 'VAT/moms',
            'Text': 'Text*',
            'MaterialText': 'Material Text',
            'MaterialTextColor': 'Text',
            'OpgaverText': 'Task Text',
            'OpgaveText': 'Requisition Text',
            'InvoiceText': 'Invoice Text',
            'Price/Included': 'Price/Included',
            'Requisition': 'Requisition No.',
            'Estimate': 'Estimate Price',
            'Initialar': 'Initials *',
            'startDate': 'Start Date ',
            'Size': 'Size ',
            'EndDate': 'End Date ',
            'UploadFile': 'Upload File',
            'ChooseFile': 'Choose File',
            'ChoosePicture': 'Choose Picture',
            'Quantity': 'Quantity',
            'PhoneNumber': 'Phone Number',
            'Selectcustomer': 'Select customer',
            'Active': 'Set as inactive',
            'PhoneNo': 'Phone No.',
            'Not-Found-Worker': 'Co-worker not found',
            'Not-Found-MATERIAL': 'Material not found',
            'Not-Found-CUSTOMER': 'Customer not found',
            'Not-Found-QUANTITY': 'Quantity not found',
            'ResponsibleCoworker': 'Responsible Coworker',
            'Coworker': 'Coworker',
            'Lift': 'Left',
            'HowManyDaysleft': 'How many days left',
            'Stigemand': 'Riseman',
            'HowManyDaysStigemand': 'How many days Riseman',
            'CaseComingDate': 'Case coming date',
            'hoursOnCase': 'Hours on case',
            'noOfDays': 'No of days',
            'Number': 'Sr.',
            'OpgaveNumber': 'Requisition No.',
            'Total': 'Total',
            'DepartmentNumber': 'Customer Number',
            'DepartmentName': 'Customer Name',
            'DepartmentCity': 'City',
            'DepartmentZipCode': 'Zipcode',
            'DepartmentAddress': 'Address',
            'DepartmentPhone': 'Phone',
            'RequisitionNumber': 'Enter Task No.',
            'RequisitionTask': 'Enter Task.',
            'RequisitionText': 'Enter Requisition Text',
            'RequisitionNo': 'Enter Requisition No',
            'EnterAddress': 'Enter Address',
            'EnterPhone': 'Enter Phone No.',
            'EnterZipCode': 'Enter ZipCode',
            'EnterEmail': 'Enter Email',
            'EnterCity': 'Enter City',
            'SelectEndDate': 'Select end date',
            'SelectStartDate': 'Select Start date',
            'SelectIndoorOrOutdoor': 'Select any one',
            'SelectCustomer': 'Select Customer',
            'Week': 'Week',
            'WorkingHours': 'Working Hours',
            'SattisticQuestion1': 'Reason for the case is dropped',
            'SattisticQuestion2': 'Where does the customer come from',
            'SattisticQuestion3': 'Seen on painting work',
            'Email': 'Email',
            'UrgentOrNot': 'Urgent/Not Urgent',
            'StrictlySpeaking': 'Strictly Speaking',
            'OfferNumber': 'Offer Number',
            'SelectYear': 'Select Year',
            'visit': 'Visit',
            'SecurityOfficer': 'Security Officer',
            'EmployeeResponsible': 'Employee Responsible',
            'Unit': 'Unit',
            'IncludeActive': 'Include all inactive',
            'Timer': 'Timer',
            'HoursLeft': 'Hours Left',
            'RetailPrice': 'Retail Price pr. pcs',
            'CalculatePrice': 'Calculate Price',
            'TotalPrice': 'Total Price',
            'received': 'Received',
            'TotalIncludedVAT': 'Offer Price incl. VAT',
            'colorCode': 'Color code',
            'MaterialTotalPrice': 'Material Total',
            'MaterialTotalPriceIncludeVAT': 'Material Total incl. VAT',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'MaterialPersonalTotalPrice': 'Material Personal Price Total',
            'DoNotUpdateTimer': 'Do Not Update Hour',
            'AddInActiveMaterials': 'Add InActive Materials',
            'ProfitSubProfit': 'Profit / Sub-Profit',
            'DateonCreate': 'Date',
            'DeleteEveryone': 'Delete employee on all tasks',
            'DeleteEmployee': 'Delete employee on tasks',
            'messagehoursOnCase': 'entry valid hours for the case',
            'SuggestedHours': 'Suggested hours',
            'IsHourlyWage': 'Is case on hourly pay',
            'HourlyWage': 'Hourly rate',
            'RequiredLiftStartDate': 'Select Start Date',
            'StartDateRequired': 'Start date required',
            'StartTimeRequired': 'Start time required',
            'VistRequired': 'Vist Required',
            'VistTimeRequired': 'Vist time required',
            'Discount': 'Discount',
            'PriceExclVAT': 'Price excl vat',
            'Name': 'Name',
            'MobileNo': 'Mobile No',
            'DonePercentage': 'Done %',
            'Not-Found-Status': 'Status not found',
            'AmountIncludeVat': 'Price incl. VAT',
            'PaymentMode': 'Payment Mode',
            'NoOfWorkflows': 'Number of workflows',
            'M2Walls': 'M2 Walls',
            'M2Ceiling': 'M2 Ceiling',
            'Billed': 'Billed date',
            'DoNotInvoice': 'Do not invoice',
            'PurchasePrice': 'Purchase price',
            'ShowMoreDetail': 'Show more detail',
            'User': 'User',
            'MaterialCreation': 'Material Creation',
            'CoworkerWorkingRate': 'Coworker working rate',
            'Vat': 'Excl. VAT',
            'HourlyPayment': 'Case on hourly pay',
            'Price': 'Pris',
            'ValidEmail': 'Enter valid email',
            'IsSendInvoiceBeforeFinished': 'Is send invoice before finished',
            'AmountToGenerateInvoice': 'On account invoice must be for DKK (Excl. VAT)',
            'MoreAmountToGenerateInvoice': 'Send on account invoice when case is over DKK (Excl. VAT)',
        },
        'WeekDays': {
            'Monday': 'Mon',
            'Tueday': 'Tue',
            'Wednesday': 'Wed',
            'Thursday': 'Thu',
            'Friday': 'Fri',
            'Saturday': 'Sat',
            'Sunday': 'Sun'
        },
        'Headers': {
            'NewProject': 'New Project Form',
            'NoFileSelected': 'No File Selected',
            'NoPictureSelected': 'No Picture Selected',
            'MaterialList': 'Material List',
            'AddNewMaterial': 'Add New Material',
            'AddNewCo-worker': 'Add new Co-worker',
            'AddResponsibleCo-worker': 'Add Co-worker',
            'Co-workerlist': 'Co-worker List',
            'ProjectfileList': 'Uploaded files',
            'Noco-workeradded': 'No co-worker added',
            'Nomaterialadded': 'No material added',
            'DeleteAlert': 'Are you sure you want to delete?',
            'NoRequsitionAddedYet': 'No Requsition added yet',
            'NoCoworkerAddedYet': 'No Co-worker added yet',
            'RequisitionList': 'Requisition List',
            'Requisition': 'Requisition',
            'LogTabSRNO': 'SR NO',
            'LogTabRequisitionnumber': 'Requisition number',
            'LogTabChangedon': 'Changed on',
            'LogTabChangedtime': 'Changed time',
            'LogTabChangedfrom': 'Changed from',
            'LogTabChangedto': 'Changed to',
            'ChooseFile': 'Choose File',
            'FOLDEREMPTY': 'This folder is empty',
            'LogTabChangedby': 'Changed by',
            'LogTabCreatedby': 'Created by',
            'NoLogAvailable': 'No Log Available',
            'Desc': 'Descripion',
            'MaterialNo': 'Number',
            'EconomyTabMessage': 'Fill economy detail is this case on Hourly pay or fixed price ? you move to Economy detail ?',
            'StatusAlertMessage': 'Are you shaur you want to change status',
            'DeleteMaterial': 'Do you want to delete "@MaterialName"?',
            'DeleteRequisitionMessage': 'Do you want to delete this task?',
            'DeleteProject': 'Do you want to delete this Project?',
            'DeleteSelectedMaterial': 'Do you want to delete selected materials?',
        },
        'Tabs': {
            'BasicDetail': 'Basic Detail',
            'Material': 'Materials',
            'Co-worker': 'Co-workers',
            'Pricing': 'Pricing',
            'Files': 'Files',
            'SpecialInfo': 'Special Info',
            'Status': 'Status',
            'Requisition': 'Requisition Info',
            'Log': 'Log',
            'Statistic': 'Statistic',
            'Work-hourse': 'Work-hours',
            'WeekWiseReport': 'Weekly Report',
            'YearWiseReport': 'Yearly Report',
            'Economy': 'Economy',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
            'MessageLog': 'Message Log',
            'Rooms': 'Room / Building',
            'QualityTest': 'Quality Test',
            'Location':'Location'
        },
        // 'selectedValueStatus': {
        //     //'value0': 'Registered',
        //     'value0': '-',
        //     'value1': 'Process',
        //     'value2': 'Scheduled visit',
        //     'value3': 'Ready to boot',
        //     'value4': 'Offers sent',
        //     'value5': 'Offer approved',
        //     'value6': 'Planned',
        //     'value7': 'Offer rejected',
        //     'value8': 'Started',
        //     'value9': 'Performed',
        //     'value10': 'Billed',
        //     'value11': 'Pending',
        //     'value12': 'Ready billing',
        // },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff Purchase', //drawing of painter
            'value6': 'Store Purchase' //Nordsjø logo
        },
        'selectedOption': {
            'value1': 'No',
            'value2': 'Yes',
        },
        'reasonForProjectDropped': {
            'value0': 'Please select',
            //'value1': 'Too expensive',
            //'value2': 'Too slow to answer',
            'value1': 'optional text',
            'value2': 'For animals',
            'value3': 'Did not respond quickly enough',
            'value4': 'lack of time'
        },
        'whereDoesTheCustomerComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Website',
            'value3': 'Facebook',
            'value4': 'Recommendation',
            'value5': 'Regular customer',
            'value6': 'Manual',
            'value7': 'Telephone',
        },
        'UrgentOrNot': {
            'value0': 'select',
            'value1': 'Urgent',
            'value2': 'Not Urgent',
        },
        'MESSAGES': {
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'EmailSent': 'Email sent successfully',
            'PersonalPurchaseMessage': 'This is a staff purchase - press save to enter materials',
            'MaterialPriseMoreOne': 'Enter material price',
            'EnterText': 'Enter Text.',
            'MaterialQTYRequired': 'Enter Qty.',
            'FeildUnderFinishFolder': '*** This Project folder is under finish folder.',
            'SpecialInfoStartDate': 'Requisition status is planned and start date is blank',
            'InactiveRequisitionPlaniingMessage': 'Do not set inactive requisition until you have planned hours completed.',
            'ExcludePriceNotification': "Insert Price excl. VAT or Hourly wage in case of hourly wage !",
            'RequisitionNumber': 'Requisition number is required',
            'MaterialInOrderProcess': '**Materials in an ordered process',
            'CustomerRequisitionNumber': 'Selected customer must have insert Requisition number.',
        },
        'monthList': {
            'value1': 'Jan',
            'value2': 'Feb',
            'value3': 'Mar',
            'value4': 'Apr',
            'value5': 'May',
            'value6': 'Jun',
            'value7': 'Jul',
            'value8': 'Aug',
            'value9': 'Sep',
            'value10': 'Oct',
            'value11': 'Nov',
            'value12': 'Dec',
        },
        'StatusLogCases': {
            'value1': 'Status Updated',
            'value2': 'Coworker Deleted',
            'value3': 'Statick Data Updated',
            'value4': 'Pricing Data Changed',
            'value5': 'Coworker Added',
            'value6': 'Material Added',
            'value7': 'Material Updated',
            'value8': 'Material Deleted',
            'value9': 'Email Sent From Project',
            'value10': 'Email Sent From Customer'
        },
        'paymentModeList': {
            'value1': 'Dankort',
            'value2': 'Mastercard',
            'value3': 'Cash',
            'value4': 'Mobile payment',
            'value5': 'Mobile payment KK',
            'value6': 'Credit',
            'value7': 'Other',
        },
        'selectedOptionForLoftAndRiseMan': {
            'value1': 'No',
            'value2': 'Yes',
        },
        'TOOTIPORTITLE':{
            'AddCustomer':'Add Customer',
            'Edit':'Edit',
            'Delete':'Delete',
            'DeleteAll':'Delete All',
            'IsDelete':'Is Delete',
            'IsActive':'Is Active',
            'Copy':'Copy',
            'SendSMS':'Send SMS',
            'SendEmail':'Send Email',
            'DeleteProject':'Delete Project',
            'GoToInquiry':'Go To Inquiry',
            'UpdateStatisticInfo':'Update Statistic Info',
            'EditRequisition':'Edit Requisition',
            'EditMaterial':'Edit Material',
            'DeleteMaterial':'Delete Material',
            'RefreshMaterial':'Refresh Materials'

        },
        'VALIDATIONS':{
            'AddressRequired':'Address is required',
            'CityRequired':'City is required',
            'PhoneRequired':'Phone No is required',
            'ValidPhoneRequired': 'Enter Valid Phone',
            'ZipcodeRequired':'Zipcode is required',
            'EmailRequired':'Email is required',
            'ValidEmail':'Enter valid email address',
            'WhiteSpaceNotAllowed':'Only white space not allowed'
        }
    }
};

