export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header': 'Uniconta legitimationsoplysninger',
            'Comapny': 'Firma',
        },
        'FIELDS': {
            'UserName': 'Bruger navn',
            'Password': 'Adgangskode',
            'AccessId': 'Access id',
            'ComapnyName': 'Firma navn',
            'ComapnyId': 'Firma id',
            'GenerateSalesOrders': 'Generer salgsordrer',
            'GenerateInvoices': 'Generer fakturaer',
            'GenerateTXTFile': 'Generer TXT File',
            'Path': 'Sti/filnavn',        
            'AmountToGenerateInvoice': 'Beløb for at generere faktura',
            'SelectForInvoiceLine': 'Vælg Til fakturalinje',
            'ESCHoursToSend': 'Til kl.', 
            'SelectCoworker': 'Vælg medarbejdar',
            'MoreAmountToGenerateInvoice':'Mere beløb til at generere faktura',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Gemt Succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
            'AddCompany':'Tilføj firma'
        },
        'Help':{
            'Note':'Brug kun skråstreg(/) i stiindstilling',
        }
    }
};

