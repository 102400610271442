import { Component, OnInit, Input } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CustomersService } from "app/main/pages/customer/customer.service"
import { RequestBaseFull, RequestMaterial } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MatDialog } from '@angular/material';
import { Material } from 'app/main/model/Material';
import { MaterialService } from 'app/main/pages/material/material.service';
import { MaterialType } from 'app/constant/common.constant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { SettingsService } from 'app/main/pages/settings/settings.service';

@Component({
    selector: 'vibe-customer-default-materials',
    templateUrl: './customer-default-materials.component.html',
    styleUrls: ['./customer-default-materials.component.scss']
})
export class CustomerDefaultMaterialsComponent implements OnInit {
    showSpinner = false;
    @Input() customerId: string;
    requestModel: RequestBaseFull = new RequestBaseFull();
    customerMaterialData: any = [];
    Materials: Material[] = [];
    tempMaterials: Material[] = [];
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;
    requestMaterial: RequestMaterial = new RequestMaterial();
    DisplayVal: any[] = ['itemNumber', 'text'];
    selectedMaterialId: string = '';
    materialType: number = 0;
    selectMaterialtype = MaterialType;

    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private customersService: CustomersService,
        private settingsService: SettingsService,
        private materialService: MaterialService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        public dialog: MatDialog, ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.requestModel.Index = 1;
        this.requestModel.Limit = 100;
        this.requestModel.IsActive = false;
        this.requestModel.OrderBy = "ModifyDate DESC";
        this.getCustomerDefaultMaterials();
        var reqModel = new RequestMaterial();
        this.getMaterial(reqModel, true);
    }

    getMaterialOnType(typeId) {
        if (typeId != 0) {
            this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
        } else {
            this.Materials = this.tempMaterials;
        }
    }

    getMaterial(model, displayLoader) {
        this.showSpinner = displayLoader;
        model.Limit = 400;
        model.OrderBy = "Text";
        this.materialService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.getNextRequestForMaterial(response.pagination, model, response.result);
                    setTimeout(() => {
                        //this.barcodeFeild.nativeElement.focus();
                    });
                }
                else {
                    this.showSpinner = false;
                    this.Materials = [];
                    this.tempMaterials = [];
                }
            },
            error => (err) => {
                this.showSpinner = false;
                this.Materials = [];
                this.tempMaterials = [];
            },
            () => {
            });
    }

    getNextRequestForMaterial(pagination, model, response) {
        if (pagination.index == 1) {
            this.Materials = response;
            this.tempMaterials = response;
        }
        else {
            this.Materials.push(...response);
            this.tempMaterials = this.Materials;
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getMaterial(model, false);
    }

    onMaterialChange(e) {
    }

    DropDownSearch(value) {
        if (this.materialType > 0) {
            this.Materials = this.tempMaterials.filter(o => (
                o.text.toLowerCase().includes(value.toLowerCase())
                || o.itemNumber.toLowerCase().includes(value.toLowerCase()))
                && o.materialType
                && o.materialType
                && o.materialType.split(',').find(p => p == (this.materialType.toString()))
            );
        } else {
            this.Materials = this.tempMaterials.filter(o => (
                o.text.toLowerCase().includes(value.toLowerCase())
                || o.itemNumber.toLowerCase().includes(value.toLowerCase())
            ));
        }
    }

    addCustomerDefaultMaterial() {
        this.showSpinner = true;
        if(this.customerMaterialData.length>0){
          var tempData =  this.customerMaterialData.filter(x=>x.vareID==this.selectedMaterialId);
          if(tempData && tempData.length>0){
            this.translate.get('MESSAGES.MaterialAlreadyAdded').subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
            return;
          }
        }
        let customerDefaultMaterial = {
            'VareID': this.selectedMaterialId
        }
        this.settingsService.SaveCustomerDefaultMaterial<ResponseBase>(customerDefaultMaterial)
            .subscribe({
                next: (response: ResponseBase) => {
                    if(response.statusCode==200) {
                        this.showSpinner = false;
                        this.translate.get('MESSAGES.MaterialAdded').subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                        this.requestModel.Index = 1;
                        this.requestModel.Limit = 24;
                        this.requestModel.OrderBy = "ModifyDate DESC";
                        this.reset()
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    if (err.status != 409)
                        this.translate.get("MESSAGES.MaterialAddFailed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                },
                complete: () => { }
            });
    }

    getCustomerDefaultMaterials() {
        this.showSpinner = true;
        this.settingsService.GetCustomerDefaultMaterial<ResponseBase>(this.requestModel)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response != null && response.result) {
                        this.customerMaterialData = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                    }
                    else {
                        this.customerMaterialData = [];
                        this.displayPagination = false;
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.customerMaterialData = [];
                    this.displayPagination = false;
                },
                complete: () => { }
            });
    }

    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.getCustomerDefaultMaterials();
    }

    deleteCustomerDefaultMaterial(id) {
        var message = "";
        this.translate.get("MESSAGES.DeleteAlert").subscribe({
            next: (res) => {
                message = res;
            }, error: err => { }
        })
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.settingsService.DeleteCustomerDefaultMaterial<ResponseBase>(id)
                    .subscribe((response: ResponseBase) => {
                        this.showSpinner = false;
                        if (response != null) {
                            if(response.statusCode==200) {
                                this.translate.get('MESSAGES.MaterialDeleted').subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                    }
                                });
                                this.reset()
                            }
                        }
                        else {
                            this.translate.get("MESSAGES.MaterialDeleteFailed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error => (err) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.MaterialDeleteFailed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    });
            }
        });        
    }

    reset() {
        this.materialType = 0;
        this.selectedMaterialId = '';
        this.getCustomerDefaultMaterials()
    }
  
}
