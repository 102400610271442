import { Component, OnInit, ElementRef, NgZone, ViewChild, Inject, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { AgmInfoWindow, MapsAPILoader } from '@agm/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GoogleMapVehicleAddressteService } from './google-map-vehicle-address.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { VehicleFullInfoList } from 'app/main/model/SkyHost';
import { CarInformation } from 'app/main/model/CarInformation';
import { AssetsGroup, AssetsLabels } from 'app/constant/common.constant';
import { TitleService } from 'app/core/services/title.service';
import { BaseRequest, CarInformationsRequest, RequestForDeviceTripList, RequestForCarPositions } from 'app/main/model/RequestBase';
import { DatePipe } from '@angular/common';
import { CarInformationsService } from 'app/main/pages/car-information/car-informations.service';
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { AuthService } from 'app/core/services/auth.service';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

declare var google: any;
export class LocationProps {
    id: string;
    name: string;
    lat: number;
    lng: number;
    label: string;
    markerLabel: string;
    street: string;
    address: string;
    date: string;
    diverName: string;
    diverPhone: string;
}
export class DateRoutes {
    date: string;
    routes: DrawRoutes[] = []
}
export class DrawRoutes {
    isShow: boolean;
    coordinates: coordinates[] = [];
    renderOptions: PolylineOptions;
    tripMeters: number
}
export class PolylineOptions {
    polylineOptions: RoutePolylineOptions
    suppressMarkers: boolean
    suppressInfoWindows: boolean
}
export class RoutePolylineOptions {
    strokeColor: string;
    strokeWeight: number;
}
export class coordinates {
    lat: number;
    lng: number;
    street: string;
    time: string;
}
const colors: string[] = [
    '#B132F0', '#B1F023', '#B11032', '#1032B1', '#107632', '#321010', '#802A2A', '#CD5555', '#EEB4B4', '#F08080', '#660000', '#EE0000', '#FF6F6F', '#C65D57', '#AF4035', '#CC1100', '#FF2400', '#6A5ACD', '#7B68EE', '#9370DB', '#8B008B', '#9400D3', '#9932CC', '#BA55D3', '#800080', '#D8BFD8', '#DDA0DD', '#EE82EE', '#FF00FF', '#DA70D6', '#C71585', '#DB7093', '#FF1493', '#FF69B4', '#FFB6C1', '#FFC0CB', '#FAEBD7', '#F5F5DC', '#FFE4C4', '#FFEBCD', '#F5DEB3', '#FFF8DC', '#FFFACD', '#FAFAD2', '#FFFFE0', '#8B4513', '#A0522D', '#D2691E', '#CD853F', '#F4A460', '#DEB887', '#D2B48C', '#BC8F8F', '#FFE4B5', '#FFDEAD', '#FFDAB9', '#FFE4E1', '#FFF0F5', '#FAF0E6', '#FDF5E6', '#FFEFD5', '#FFF5EE', '#F5FFFA', '#708090', '#778899', '#B0C4DE', '#E6E6FA', '#FFFAF0', '#F0F8FF', '#F8F8FF', '#F0FFF0', '#FFFFF0', '#F0FFFF', '#FFFAFA', '#000000', '#696969', '#808080', '#A9A9A9', '#C0C0C0', '#D3D3D3', '#DCDCDC', '#F5F5F5', '#FFFFFF'];

@Component({
    selector: 'app-google-map-vehicle-address',
    templateUrl: './google-map-vehicle-address.component.html',
    styleUrls: ["./google-map-vehicle-address.component.scss"],
})

export class GoogleMapVehicleAddressComponent implements OnInit {
    previous: AgmInfoWindow;
    showSpinner: boolean = false;
    //infowindow = null;
    @ViewChild("infowindow", { static: false }) infowindow: ElementRef;
    displayMap: boolean = true;
    vehicleFullInfoList: VehicleFullInfoList;
    selectedVehicle: any = [];
    selectedVehicleID: string = "0";
    coordinatesDrawLineList: DateRoutes[] = [];
    uniqDateArray = [];
    currentRouteIndex = 0;
    currentRoutes = [];
    center = {
        lat: 55.6713408,
        lng: 11.6205256
    };
    zoom = 10;
    coordinates: any = [];
    allLocationsList: LocationProps[] = [];
    locations: LocationProps[] = [];
    directionStartDate: Date = new Date();
    directionEndDate: Date = new Date();
    icon = {
        labelOrigin: { x: 16, y: 48 },
        //url: "https://webapitest.vibesite.dk/Upload/CoWorker/f418f71e-55b8-4df7-8954-fffee6a29ecb.png",
        //url: "https://maps.google.com/mapfiles/kml/paddle/red-blank.png",
        url: "assets/icons/arrow/vibe-vehicle-marker-cluster-icon.png",
        scaledSize: {
            width: 32,
            height: 32
        },
    };
    routeList: any = [];
    // Cars information
    CarInformationsRequest: CarInformationsRequest = new CarInformationsRequest();
    orderBy: string = "Number";
    CarInformationList: CarInformation[] = [];
    selectAssetsGroup = AssetsGroup;
    assetsMasterList: any[] = [];
    assetsList: any[] = [];
    requestBase: any;
    filter: any = 2;
    coWorkerId: string;
    deviceTripInfoList: any = [];
    requestForDeviceTripList: any = new RequestForDeviceTripList();
    requestForCarPositions: any = new RequestForCarPositions();
    selectAssetsLables = AssetsLabels;
    currentselectedLables: any = "";
    driverName: string;
    driverPhone: string;
    roleClaims: any;
    selectedCarId: string = "";
    selectedTrackDeviceID: string = "";
    origin: any;
    destinations: any[] = [];
    settingMasterValues: any = [];
    isUseNanolink: boolean = false;
    constructor(public translationLoader: FuseTranslationLoaderService,
        private googleMapVehicleAddressteService: GoogleMapVehicleAddressteService,
        private carInformationsService: CarInformationsService,
        private nanolinkService: NanolinkService,
        private authService: AuthService,
        private titleService: TitleService,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        @Optional() private dialogRef: MatDialogRef<GoogleMapVehicleAddressComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_LIVEMAP");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.selectAssetsLables = this.selectAssetsLables.filter(x => x.showInLiveMap == true);
        this.coWorkerId = this.authService.getCoWorkerId();
        if (this.data && this.data.tripInfo) {
            this.routeList = this.data.tripInfo;
            this.showMapDirection();
        }
        else {
            this.requestBase = new BaseRequest();
            this.requestBase.Index = 1;
            this.requestBase.Limit = 1000;
            this.getAssetsList();

            this.CarInformationsRequest = new CarInformationsRequest();
            this.CarInformationsRequest.Index = 1;
            this.CarInformationsRequest.Limit = 100;
            this.CarInformationsRequest.OrderBy = this.orderBy;
            this.GetCarInformationsList();
        }
        this.getSettingsForNanoLink();
    }

    getSettingsForNanoLink() {
        var nanolinkSet = this.settingMasterValues.find(o => o.key1 == "AllOtherSettings" && o.key2 == "AllOtherSettings");
        if (nanolinkSet) {
            var allOtherSettingJson = JSON.parse(nanolinkSet.value);
            this.isUseNanolink = allOtherSettingJson.UseNanoLink;
            this.selectAssetsGroup = this.isUseNanolink ? this.selectAssetsGroup : this.selectAssetsGroup.filter(x => x.value != 'Nanno brikker');
        }
    }

    SearchVehicle() {
        if (this.selectedVehicleID == null || this.selectedVehicleID == "All") {
            //this.getAssetsList();
            this.OnChangeGroup();
            this.currentRouteIndex = 0;
            this.currentRoutes = []
        }
        else {
            this.showSpinner = true;
            var findedLocation = [];
            if (this.isUseNanolink) {
                findedLocation = this.assetsList.filter(a => a.nanolinkDeviceID === this.selectedVehicleID);
            }
            else {
                findedLocation = this.assetsList.filter(a => a.id === this.selectedVehicleID);
            }
            this.selectedCarId = findedLocation ? findedLocation[0].id : "";
            this.selectedTrackDeviceID = findedLocation ? (findedLocation[0].trackDeviceID ? findedLocation[0].trackDeviceID : "") : "";
            if (findedLocation && findedLocation.length > 0) {
                this.locations = [];
                this.locations = findedLocation;
                this.locations[0].lat = Number(findedLocation[0].latitude)
                this.locations[0].lng = Number(findedLocation[0].longitude)
                this.locations[0].label = (this.filter == 2 ? findedLocation[0].coworkerName : ""),
                    this.locations[0].markerLabel = (this.filter == 2 ? findedLocation[0].carDescription : ""),
                    this.center.lat = Number(findedLocation[0].latitude);
                this.center.lng = Number(findedLocation[0].longitude);
                this.allLocationsList = this.locations;
            }
            this.directionStartDate = new Date();
            this.directionEndDate = new Date();
            if (this.filter == 2) {
                if (!this.isUseNanolink)
                    this.GetCarPositionList();
                else
                    this.GetDeviceTripInfoList();
            }
            this.showSpinner = false;
        }
    }



    onClickInfoView() {
        if (this.infowindow) {
            //this.infowindow.close();
        }
        this.infowindow = new google.maps.InfoWindow();
    }

    clickedMarker(infoWindow: AgmInfoWindow) {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infoWindow;
    }
    onFocusIn(event) {
        event.model.show();
    }

    routeDateSelection(e) {
        this.currentRouteIndex = e;
        this.currentRoutes = this.coordinatesDrawLineList[this.currentRouteIndex].routes
    }

    showMapDirection() {
        this.showSpinner = true;
        this.coordinates = [];
        this.coordinatesDrawLineList = [];
        this.uniqDateArray = [];
        this.currentRouteIndex = 0;
        this.currentRoutes = [];
        if (this.routeList && this.routeList.length > 0) {
            let dateRoutes = new DateRoutes();
            let listOfRoutes: any[] = this.routeList
            for (let i = 0; i < listOfRoutes.length; i++) {
                const uniqDate = this.uniqDateArray.find(o => o === this.datePipe.transform(listOfRoutes[i].startDateTime, 'yyyy-MM-dd'));
                if (!uniqDate) {
                    this.uniqDateArray.push(this.datePipe.transform(listOfRoutes[i].startDateTime, 'yyyy-MM-dd'));
                }
            }
            for (let j = 0; j < this.uniqDateArray.length; j++) {
                dateRoutes = new DateRoutes();
                dateRoutes.date = this.uniqDateArray[j];
                dateRoutes.routes = []
                let colorCodeIncrementNumber = 1;

                const obj = listOfRoutes.filter(o =>
                    this.datePipe.transform(o.startDateTime, 'yyyy-MM-dd') == this.uniqDateArray[j]);
                for (let i = 0; i < obj.length; i++) {
                    if (obj) {
                        let drawRoute = new DrawRoutes()
                        drawRoute.isShow = (i == 0) ? true : false;
                        drawRoute.coordinates = []
                        drawRoute.renderOptions = new PolylineOptions()
                        drawRoute.tripMeters = obj[i].tripMeters;

                        let coort = new coordinates();
                        coort.lat = Number(obj[i].startLatitude);
                        coort.lng = Number(obj[i].startLongitude);
                        coort.street = "";//obj[i].start.street;
                        coort.time = this.datePipe.transform(obj[i].startDateTime, 'HH:mm');
                        drawRoute.coordinates.push(coort);
                        coort = new coordinates()
                        coort.lat = Number(obj[i].endLatitude);
                        coort.lng = Number(obj[i].endLongitude);
                        coort.street = "";//obj[i].stop.street;
                        coort.time = this.datePipe.transform(obj[i].endDateTime, 'HH:mm');
                        drawRoute.coordinates.push(coort);

                        drawRoute.renderOptions.polylineOptions = new RoutePolylineOptions()
                        drawRoute.renderOptions.polylineOptions.strokeColor = colors[colorCodeIncrementNumber];
                        colorCodeIncrementNumber = colorCodeIncrementNumber + 1;
                        drawRoute.renderOptions.polylineOptions.strokeWeight = 5
                        drawRoute.renderOptions.suppressMarkers = true;
                        drawRoute.renderOptions.suppressInfoWindows = true;
                        dateRoutes.routes.push(drawRoute);
                    }
                }
                this.coordinatesDrawLineList.push(dateRoutes);
            }
            if (this.coordinatesDrawLineList.length) {
                this.routeDateSelection(0)
            }
        }
        this.showSpinner = false;
        this.displayMap = true;

    }

    GetCarInformationsList() {
        this.CarInformationList = [];
        this.showSpinner = true;
        this.carInformationsService.Get<ResponseBase>(this.CarInformationsRequest)
            .pipe(
                debounceTime(100), 
                distinctUntilChanged()
            )
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response && response.result) {
                        if (this.isUseNanolink) {
                            this.CarInformationList = response.result.filter(c => (c.nanolinkDeviceID != null && c.nanolinkDeviceID != undefined && c.nanolinkDeviceID != ""));
                        }
                        else {
                            this.CarInformationList = response.result;
                        }
                        this.OnChangeGroup();
                    }
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    getAssetsList() {
        this.showSpinner = true;
        this.assetsList = [];
        this.nanolinkService.GetAssetsList(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.assetsMasterList = response.result.filter(x => x.nanolinkDeviceID);
                    this.OnChangeGroup();
                }

            },
            error: err => {
                this.showSpinner = false;
                this.assetsList = [];
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    OnChangeGroup() {
        this.selectedVehicleID = null;
        this.locations = [];
        this.allLocationsList = [];
        this.coordinatesDrawLineList = [];
        this.selectedVehicle = [];
        this.assetsList = [];
        this.currentRoutes = [];
        if (this.filter == 1) {
            const allElelemnt = {
                "id": "All",
                "nanolinkDeviceID": "All",
                "brand": "All",
                "model": "",
                "number": "",
                "keywords": "",
                "description": "All",
                "groups": 1,
                "labels":""
            }
            //this.assetsList.push(...this.assetsMasterList);
            this.assetsList = this.assetsMasterList;
            if (this.currentselectedLables)
                this.assetsList = this.assetsList.filter(c => { return c.labels.split(',').find(p => p == this.currentselectedLables) })
            this.assetsList = [allElelemnt, ...this.assetsList]
            this.selectedVehicleID = "All";
        }
        else if (this.filter == 2) {
            var carInformation = new CarInformation();
            carInformation.id = "All";
            carInformation.vehicleNo = 'All';
            carInformation.nanolinkDeviceID = "All";
            this.selectedVehicleID = "All";
            this.assetsList.push(carInformation)
            this.assetsList.push(...this.CarInformationList);
        }
        this.assetsList.forEach((e: any) => {
            if (e.latitude && e.longitude) {
                this.locations.push({
                    id: e.id,
                    name: (this.filter == 2 ? e.carBrand : e.brand) + " " + e.model,
                    lat: Number(e.latitude),
                    lng: Number(e.longitude),
                    label: (this.filter == 2 ? e.coworkerName : e.brand),
                    markerLabel: (this.filter == 2 ? e.carDescription : e.brand),
                    street: "",
                    address: "",
                    date: "",
                    diverName: "",
                    diverPhone: "",
                });
                //this.selectedVehicle.push({ id: e.id, value: e.desc });
            }
        });
        // if(this.selectedVehicle.length) {
        //     this.selectedVehicle.unshift({ "id": "0", "value": "All" })
        // }
        this.allLocationsList = this.locations;
    }

    GetDeviceTripInfoList() {
        this.requestForDeviceTripList.StartDate = moment(this.directionStartDate).format("YYYY-MM-DD");
        this.requestForDeviceTripList.EndDate = moment(this.directionEndDate).format("YYYY-MM-DD");
        this.requestForDeviceTripList.CoworkerID = this.coWorkerId;
        this.requestForDeviceTripList.NanolinkDeviceID = this.isUseNanolink ? this.selectedVehicleID : null;
        this.routeList = [];
        this.currentRoutes = [];
        this.nanolinkService.GetDeviceTripInfoList<ResponseBase>(this.requestForDeviceTripList)
            .subscribe((response: ResponseBase) => {
                if (response && response.result) {
                    this.routeList = response.result
                    this.showMapDirection();
                }
            }, error => (err) => { },
                () => {
                });
    }

    GetCarPositionList() {
        this.requestForCarPositions.StartDate = moment(this.directionStartDate).format("YYYY-MM-DD");
        this.requestForCarPositions.EndDate = this.requestForCarPositions.StartDate;
        this.requestForCarPositions.TrackDeviceID = this.selectedTrackDeviceID ? this.selectedTrackDeviceID : "";
        this.requestForCarPositions.CarID = this.selectedCarId;
        this.routeList = [];
        this.currentRoutes = [];
        this.carInformationsService.GetCarPositions<ResponseBase>(this.requestForCarPositions)
            .subscribe((response: ResponseBase) => {
                if (response && response.result) {
                    this.routeList = response.result.myResponse;
                    if (this.routeList.length > 0) {
                        this.origin = { lat: this.routeList[0].lat, lng: this.routeList[0].lng };
                        this.destinations = this.routeList.length > 1 ? this.routeList.slice(1).map(record => ({ lat: record.lat, lng: record.lng })) : this.origin;
                    }
                }
            }, error => (err) => { },
                () => {
                });
    }

    callDeviceRouteTrip() {
        if (this.filter == 2) {
            if (!this.isUseNanolink)
                this.GetCarPositionList();
            else
                this.GetDeviceTripInfoList();
        }
    }
}
