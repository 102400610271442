import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";
import { FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";

@Component({
    selector: "vibe-material-settings",
    templateUrl: "./material-settings.component.html",
    styleUrls: ["./material-settings.component.scss"]
})

export class MaterialSettingsComponent implements OnInit {
    showSpinnerForMaterialEconomyScreen: boolean = false;
    showSpinnerForMaterialOverViewOnOfferSettingScreen: boolean = false;
    showSpinnerForMaterialOtherSetting: boolean = false;
    settingMasterValues:any[] = [];
    PersonalPricePercObj: Settings;
    SellingPricePercObj: Settings;
    MaterialOtherSettingObj: Settings;
    isShowOfferMaterialOnErrorList: boolean = false;
    ShowOfferMaterialOnErrorListObj: Settings;
    materialSettings: any[] = [];

    materialSettingForm = this.formBuilder.group({
        PersonalPricePerc: [null, Validators.required],
        SellingPricePerc: [null, Validators.required],
        //OrderQty: [null, Validators.required],
    });

    otherSettingForm = this.formBuilder.group({
        IsUseScannerDevice: [false],
        IsShowPersonalPrice: [false],
        WarningPriceForMaxTotalPrice: [null],

    });

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private settingsService: SettingsService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getMaterialSettings();
        // this.getSettingsForRetailPriceMustBeHigherOnPurchasePriceInPercentage();
        // this.getSettingsForPersonalPriceMustBeHigherOnPurchasePriceInPercentage();
        //this.getSettingsForOfferMaterialOnErrorList();
        //this.getSettingsForOfferMaterialWarningScreen();
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    }
    getMaterialSettings() {
        this.showSpinnerForMaterialEconomyScreen = true;
        this.showSpinnerForMaterialOverViewOnOfferSettingScreen = true;
        this.showSpinnerForMaterialOtherSetting = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Material", null, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinnerForMaterialEconomyScreen = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.materialSettings= response.result.myResponse;         
                    var tempSellingPriceObject = this.materialSettings.find(s => s.key1 == "Material" && s.key2 == "RetailPriceMustBeHigherOnPurchasePriceInPercentage");
                    if (tempSellingPriceObject) {
                        this.SellingPricePercObj = tempSellingPriceObject;
                        this.materialSettingForm.patchValue({'SellingPricePerc': this.SellingPricePercObj.value});
                        this.changeInLocalStorage(this.SellingPricePercObj.id, this.SellingPricePercObj)
                    }
                    var tempPersonalPriceObject = this.materialSettings.find(s => s.key1 == "Material" && s.key2 == "PersonalPriceMustBeHigherOnPurchasePriceInPercentage");
                    if (tempPersonalPriceObject) {
                        this.PersonalPricePercObj = tempPersonalPriceObject;
                        this.materialSettingForm.patchValue({'PersonalPricePerc': this.PersonalPricePercObj.value});
                        this.changeInLocalStorage(this.PersonalPricePercObj.id, this.PersonalPricePercObj)
                    }
                    
                    var tempOfferMaterialOnErrorListObject = this.materialSettings.find(s => s.key1 == "Material" && s.key2 == "OfferMaterialShowOnErrorList");
                    if (tempOfferMaterialOnErrorListObject) {
                        this.ShowOfferMaterialOnErrorListObj = tempOfferMaterialOnErrorListObject;
                        this.isShowOfferMaterialOnErrorList = Boolean(JSON.parse(tempOfferMaterialOnErrorListObject.value));
                        this.changeInLocalStorage(this.ShowOfferMaterialOnErrorListObj.id, this.ShowOfferMaterialOnErrorListObj);
                    }

                    const tempMaterialOtherSettingObject = this.materialSettings.find(s => s.key1 == "Material" && s.key2 == "OtherSetting");
                    if (tempMaterialOtherSettingObject) {
                        this.MaterialOtherSettingObj = tempMaterialOtherSettingObject;
                        if(this.MaterialOtherSettingObj.value)
                            this.otherSettingForm.patchValue(JSON.parse(this.MaterialOtherSettingObj.value));
                        this.changeInLocalStorage(this.MaterialOtherSettingObj.id, this.MaterialOtherSettingObj);
                    }
                    
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinnerForMaterialEconomyScreen = false;
                this.showSpinnerForMaterialOverViewOnOfferSettingScreen = false;
                this.showSpinnerForMaterialOtherSetting = false;
            }
        });
    }

    // getSettingsForRetailPriceMustBeHigherOnPurchasePriceInPercentage() {
    //     this.showSpinnerForMaterialEconomyScreen = true;
    //     this.settingsService.GetSettingsValue<ResponseBase>("Material", "RetailPriceMustBeHigherOnPurchasePriceInPercentage", null).subscribe({
    //         next: (response: ResponseBase) => {
    //             this.showSpinnerForMaterialEconomyScreen = false;
    //             if (response != null && response.result && response.result.myResponse.length > 0) {
    //                 this.SellingPricePercObj = response.result.myResponse[0];
    //                 this.materialSettingForm.patchValue({'SellingPricePerc': this.SellingPricePercObj.value});
    //                 this.changeInLocalStorage(this.SellingPricePercObj.id, this.SellingPricePercObj)
    //             }
    //         },
    //         error: err => {
    //         },
    //         complete: () => {
    //             this.showSpinnerForMaterialEconomyScreen = false;
    //         }
    //     });
    // }

    // getSettingsForPersonalPriceMustBeHigherOnPurchasePriceInPercentage() {
    //     this.showSpinnerForMaterialEconomyScreen = true;
    //     this.settingsService.GetSettingsValue<ResponseBase>("Material", "PersonalPriceMustBeHigherOnPurchasePriceInPercentage", null).subscribe({
    //         next: (response: ResponseBase) => {
    //             this.showSpinnerForMaterialEconomyScreen = false;
    //             if (response != null && response.result && response.result.myResponse.length > 0) {
    //                 this.PersonalPricePercObj = response.result.myResponse[0];
    //                 this.materialSettingForm.patchValue({'PersonalPricePerc': this.PersonalPricePercObj.value});
    //                 this.changeInLocalStorage(this.PersonalPricePercObj.id, this.PersonalPricePercObj)
    //             }
    //         },
    //         error: err => {
    //         },
    //         complete: () => {
    //             this.showSpinnerForMaterialEconomyScreen = false;
    //         }
    //     });
    // }

    // getSettingsForOfferMaterialWarningScreen() {
    //     this.showSpinnerForMaterialWarningScreen = true;
    //     this.settingsService.GetSettingsValue<ResponseBase>("Material", "HowMuchMaxQuantityAdd", null).subscribe({
    //         next: (response: ResponseBase) => {
    //             this.showSpinnerForMaterialWarningScreen = false;
    //             if (response != null && response.result && response.result.myResponse.length > 0) {
    //                 this.MaterialWarningObj = response.result.myResponse[0];
    //                 this.warningSettingForm.patchValue({'OrderQty': this.MaterialWarningObj.value});
    //                 this.changeInLocalStorage(this.MaterialWarningObj.id, this.MaterialWarningObj)
    //             }
    //         },
    //         error: err => {
    //         },
    //         complete: () => {
    //             this.showSpinnerForMaterialWarningScreen = false;
    //         }
    //     });
    // }

    // getSettingsForOfferMaterialOnErrorList() {
    //     this.showSpinnerForMaterialOverViewOnOfferSettingScreen = true;
    //     this.settingsService.GetSettingsValue<ResponseBase>("Material", "OfferMaterialShowOnErrorList", null).subscribe({
    //         next: (response: ResponseBase) => {
    //             this.showSpinnerForMaterialOverViewOnOfferSettingScreen = false;
    //             if (response != null && response.result && response.result.myResponse.length > 0) {
    //                 this.ShowOfferMaterialOnErrorListObj = response.result.myResponse[0];
    //                 this.isShowOfferMaterialOnErrorList = Boolean(JSON.parse(response.result.myResponse[0].value));
    //                 this.changeInLocalStorage(this.ShowOfferMaterialOnErrorListObj.id, this.ShowOfferMaterialOnErrorListObj);
    //             }
    //         },
    //         error: err => {
    //         },
    //         complete: () => {
    //             this.showSpinnerForMaterialOverViewOnOfferSettingScreen = false;
    //         }
    //     });
    // }

    changeInLocalStorage(ID, new_obj) {
        if(this.settingMasterValues.find(obj => obj.id==ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {     
                if(old_obj.id==ID) {
                    this.settingMasterValues[index]=new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    changeSettingsForRetailPriceMustBeHigherOnPurchasePriceInPercentage() {
        this.showSpinnerForMaterialEconomyScreen = true;
        this.SellingPricePercObj.value = this.materialSettingForm.value.SellingPricePerc;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.SellingPricePercObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinnerForMaterialEconomyScreen = false;
                if (response != null && response.result) {
                    this.SellingPricePercObj = response.result;
                    this.changeInLocalStorage(this.SellingPricePercObj.id, this.SellingPricePercObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinnerForMaterialEconomyScreen = false;
            }
        });
    }

    changeSettingsForPersonalPriceMustBeHigherOnPurchasePriceInPercentage() {
        this.showSpinnerForMaterialEconomyScreen = true;
        this.PersonalPricePercObj.value = this.materialSettingForm.value.PersonalPricePerc;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.PersonalPricePercObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinnerForMaterialEconomyScreen = false;
                if (response != null && response.result) {
                    this.PersonalPricePercObj = response.result;
                    this.changeInLocalStorage(this.PersonalPricePercObj.id, this.PersonalPricePercObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinnerForMaterialEconomyScreen = false;
            }
        });
    }

    changeOfferMaterialOnErrorList() {
        this.showSpinnerForMaterialOverViewOnOfferSettingScreen = true;
        this.ShowOfferMaterialOnErrorListObj.value =  `${ this.isShowOfferMaterialOnErrorList}`;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.ShowOfferMaterialOnErrorListObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinnerForMaterialOverViewOnOfferSettingScreen = false;
                if (response != null && response.result) {
                    this.isShowOfferMaterialOnErrorList = Boolean(JSON.parse(response.result.value));
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinnerForMaterialOverViewOnOfferSettingScreen = false;
            }
        });
    }

    changeMaterialOtherSettings() {
        this.showSpinnerForMaterialOtherSetting = true;
        this.MaterialOtherSettingObj.value = JSON.stringify(this.otherSettingForm.value);
        this.settingsService.UpdateSettingValue<ResponseBase>(this.MaterialOtherSettingObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinnerForMaterialOtherSetting = false;
                if (response != null && response.result) {
                    this.MaterialOtherSettingObj = response.result;
                    if(this.MaterialOtherSettingObj.value)
                        this.otherSettingForm.patchValue(JSON.parse(this.MaterialOtherSettingObj.value));
                    this.changeInLocalStorage(this.MaterialOtherSettingObj.id, this.MaterialOtherSettingObj)
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinnerForMaterialOtherSetting = false;
            }
        });
    }

    onSubmit() {
        if(!this.materialSettingForm.valid) {
            return;
        } 

        this.changeSettingsForRetailPriceMustBeHigherOnPurchasePriceInPercentage();
        this.changeSettingsForPersonalPriceMustBeHigherOnPurchasePriceInPercentage();
    }

    // onWarningSubmit() {                
    //     if(!this.warningSettingForm.valid) {
    //         return;
    //     } 

    //     this.changeSettingsForWarnings();
    // }
}