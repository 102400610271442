import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CkeditorConfig } from 'app/constant/common.constant';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EmailSendingParamForSettings } from 'app/main/model/common';
import { SettingValueForAll } from 'app/main/model/Settings';

@Component({
  selector: 'app-setting-email-tempalte-editor',
  templateUrl: './setting-email-tempalte-editor.component.html',
  styleUrls: ['./setting-email-tempalte-editor.component.scss']
})
export class SettingEmailTempalteEditorComponent implements OnInit {
  emailForm: FormGroup;
  public editor;
  public editorContent = "";
  emailBody: string = "";
  isLoadDefaultEmailTemplate: boolean = true;
  showSpinner: boolean = false;
  emailSendingParam: EmailSendingParamForSettings = new EmailSendingParamForSettings();

  @ViewChild('allSelected', { static: true }) private allSelected: MatOption;
  //settingValue: SettingValue = new SettingValue();
  settingValue: SettingValueForAll = new SettingValueForAll();
  note: string = "";



  public editorOptions = {
    placeholder: "insert content..."
  };
  config = CkeditorConfig;
  isLoadSupplierEmailTemplate: boolean = true;


  constructor(public dialogRef: MatDialogRef<SettingEmailTempalteEditorComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
  }


  ngOnInit() {
    this.config.height = 600;
    this.settingValue = this.data.settingValue;
    this.note = this.data.Note ? this.data.Note : "";
    this.emailForm = this.formBuilder.group({
      subject: [null, Validators.required],
    });
    this.showSpinner = true;
    if (!this.data.IsOther) {
      this.emailSendingParam.body = this.settingValue.Body;
      this.emailSendingParam.subject = this.settingValue.Subject;
      this.emailSendingParam.templateFilePath = this.settingValue.TemplateFilePath;
    }
    else if (this.data.IsBday) {
      if (this.data.IsSameDay) {
        this.emailSendingParam.body = this.settingValue.OtherBody;
        this.emailSendingParam.subject = this.settingValue.OtherSubject;
        this.emailSendingParam.templateFilePath = this.settingValue.OtherTemplateFilePath;
      }
      else {
        this.emailSendingParam.body = this.settingValue.OtherBeforeXDaysBody;
        this.emailSendingParam.subject = this.settingValue.OtherBeforeXDaysSubject;
        this.emailSendingParam.templateFilePath = this.settingValue.OtherBeforeXDaysTemplateFilePath;
      }
    }
    else {
      this.emailSendingParam.body = this.settingValue.OtherBody;
      this.emailSendingParam.subject = this.settingValue.OtherSubject;
      this.emailSendingParam.templateFilePath = this.settingValue.OtherTemplateFilePath;
    }
    this.showSpinner = false;
    this.emailForm.patchValue(this.emailSendingParam);
  }

  Save() {
    this.showSpinner = true;
    if (!this.emailForm.invalid) {
      this.emailSendingParam.subject = this.emailForm.controls['subject'].value;
      if (!this.data.IsOther) {
        this.settingValue.Body = this.emailSendingParam.body;
        this.settingValue.Subject = this.emailSendingParam.subject;
        this.settingValue.TemplateFilePath = this.emailSendingParam.templateFilePath;
      }
      else if (this.data.IsBday) {
        if (this.data.IsSameDay) {
          this.settingValue.OtherBody = this.emailSendingParam.body;
          this.settingValue.OtherSubject = this.emailSendingParam.subject;
          this.settingValue.OtherTemplateFilePath = this.emailSendingParam.templateFilePath;
        }
        else {
          this.settingValue.OtherBeforeXDaysBody = this.emailSendingParam.body;
          this.settingValue.OtherBeforeXDaysSubject = this.emailSendingParam.subject;
          this.settingValue.OtherBeforeXDaysTemplateFilePath = this.emailSendingParam.templateFilePath;
        }
      }
      else {
        this.settingValue.OtherBody = this.emailSendingParam.body;
          this.settingValue.OtherSubject = this.emailSendingParam.subject;
          this.settingValue.OtherTemplateFilePath = this.emailSendingParam.templateFilePath;
      }
      this.showSpinner = false;
      this.dialogRef.close(this.settingValue);
    }
    else {
      this.showSpinner = false;
      return;
    }
  }

  colseTextEditor() {
    this.dialogRef.close();
  }

  LoadSupplierEmailTemplate() {
    // this.showSpinner = true;

  }


}
