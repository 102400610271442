export const locale = {
    lang: 'en',
    data: {
        'Tabs': {
            'TabFiles': 'Files',
            'TabBasicDetail': 'Basic Detail',
            'TabNote': 'Note',
            'TabLog':'Log'
        },
        'BUTTONS': {
            'BTNSAVE': 'Save',
            'BACK': 'Back',
            'BTNCOMPLETED': 'Completed',
            'BTNREMINDER': 'Set Reminder',
            'InActive': 'Indstil car som inaktiv',
            'Active': 'Indstil car som aktiv',
        },
        'FIELDS': {
            'VehicleNo': 'Vehicle No',
            'IMEINo': 'IMEI No',
            'CarBrand': 'Car Brand',
            'Model': 'Model',
            'RegNo': 'Reg No', 
            'Color': 'Color',
            'RegDate': 'Reg Date',
            'LastSightDate': 'Last Sight Date',
            'LastSightCoworker': 'Last Sight Coworker',
            'NextSightDate': 'Next Sight Date',
            'NextSightCoworker': 'Next Sight Coworker',
            'LastServiceDate': 'Last Service Date',
            'LastServiceCoworker': 'Last Service Coworker',
            'NextServiceDate': 'Next Service Date',
            'NextServiceCoworker': 'Next Service Coworker',
            'Leasing': 'Leasing',
            'ServiceAgreement': 'Service Agreement',
            'RoadsideAssistance': 'Road Side Assistance',
            'CompanyName': 'Company Name',
            'ContactPerson': 'Contact Person',
            'PhoneNo': 'Phone No',
            'RunningKM': 'Running KM',
            'NextServiceAfterKM': 'Next Service After KM',
            'LastServiceKM': 'Last Service KM',
            'NextServiceKM': 'Next Service KM',
            'AssignCoworker': 'Assign Coworker',
            'ParticulateFilter': 'Is Add Filter',
            'Adblue': 'Is Diesel',
            'SummerTires': 'Summer tires',
            'WinterTires': 'Winter tires',
            'AllSeasonTires': 'All-season tires',
            'NanolinkDevice':'Nanolink device',
            'Latitude':'Latitude',
            'Longitude':'Longitude'
        },
        'VALIDATIONS': {
            'VehicleNo': 'Enter Vehicle No',
            'Coworker': 'Enter Co-worker Name',
        },
        'MESSAGES': {
            'CompletedNextSide': 'Do you want to change the next sight seen to {Date} ?',
            'CompletedNextService': 'Do you want to change the next service to  {Date} ?',
            'ItemNumberExist': 'Number is already exist',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'ReminderConfirmation': 'Do you want to set reminder ?',
            'ReminderAdded': 'Reminder Added successfully',
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'SendSMS':'Send SMS',
            'SendEmail':'Send Email',
            'DeleteCar':'Delete Car',
        },
  }
};
